import { ProjectStrings } from "strings";

export const BELONGING_TO_VALUE = {
  ALL: "all",
  IN_ORGANISATION: "in-organisation",
  NOT_IN_ORGANISATION: "not-in-organisation",
};

export const BELONGING_TO_OPTIONS = [
  {
    title: ProjectStrings.PROJECTS_FILTER_ALL_ORGANISATION,
    value: BELONGING_TO_VALUE.ALL,
  },
  {
    title: ProjectStrings.PROJECTS_FILTER_IN_ORGANISATION,
    value: BELONGING_TO_VALUE.IN_ORGANISATION,
  },
  {
    title: ProjectStrings.PROJECTS_FILTER_NOT_IN_ORGANISATION,
    value: BELONGING_TO_VALUE.NOT_IN_ORGANISATION,
  },
];
