import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./style";
import {
  useOrganisationInfo,
  useUserOrganisationPermissions,
} from "services/OrganisationService";
import Spinner from "ui/Spinner";
import { OrganisationStrings } from "strings";

export const OrganisationSimpleCard = ({
  userId,
  organisationId,
  currentOrganisationId,
  actionTitle = OrganisationStrings.CARD_ITEM_SELECT,
  onSelect,
}) => {
  const classes = useStyles();
  const orgInfo = useOrganisationInfo(organisationId);
  const { userPermissions } = useUserOrganisationPermissions({
    userId,
    organisationId: organisationId,
  });

  const onSelectHandle = () => onSelect(orgInfo);

  if (!orgInfo) return <Spinner />;

  return (
    <div className={classes.item}>
      <div className={classes.contentWrapper}>
        <img
          className={classes.image}
          src={orgInfo.imageURL}
          alt={orgInfo.name}
        />
        <div className={classes.titleWrapper}>
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            {orgInfo.name}
          </Typography>
          <Typography variant="body2">{userPermissions?.name}</Typography>
        </div>
      </div>
      {orgInfo.organisationId === currentOrganisationId && (
        <Typography className={classes.current} variant="body2">
          {OrganisationStrings.CARD_ITEM_CURRENT}
        </Typography>
      )}
      {orgInfo.organisationId !== currentOrganisationId && (
        <Button className={classes.selectButton} onClick={onSelectHandle}>
          {actionTitle}
        </Button>
      )}
    </div>
  );
};
