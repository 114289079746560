import React from "react";
import { Dialog, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  progressContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  progress: {
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(2),
    fontWeight: "bold",
  },
  bottom: {
    backgroundColor: theme.palette.background.default,
    fontWeight: "normal",
  },
}));

const ProgressDialog = ({ open, config: { title, current, total, desc } }) => {
  const classes = useStyles();
  const content =
    typeof current !== "undefined" &&
    typeof total !== "undefined" &&
    `${current}/${total}: `;
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => {}}>
      <div className={classes.progressContent}>
        <div className={classes.progress}>{title}</div>
        <LinearProgress />
        <div className={clsx(classes.progress, classes.bottom)}>
          {content && <b>{content}</b>}
          {desc}
        </div>
      </div>
    </Dialog>
  );
};

export default ProgressDialog;
