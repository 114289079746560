import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { useStyles } from "./style";
import { useMobileLayout } from "hooks/uiHooks";

export const EmptyData = (props) => {
  const { title, description, icon, actionTitle, onClick } = props;
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  return (
    <div className={classes.container}>
      {icon && <div className={classes.icon}>{icon}</div>}
      <div className={classes.textWrapper}>
        <Typography className={classes.title} variant="body1">
          {title}
        </Typography>
        {description && (
          <Typography className={classes.description} variant="body2">
            {description}
          </Typography>
        )}
      </div>
      {actionTitle && onClick && (
        <Button className={classes.button} onClick={onClick}>
          {actionTitle}
        </Button>
      )}
    </div>
  );
};
