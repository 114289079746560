import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  progress: {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "10",
  },
}));

const Spinner = ({ size = 40 }) => {
  const classes = useStyles();
  return (
    <div className={classes.progress}>
      <CircularProgress size={size} />
    </div>
  );
};

export default Spinner;
