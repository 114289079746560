import { useState, useEffect } from "react";
import { getCollectionGroup } from "./FirestoreService";

const COLLECTIONS = {
  PROJECTS_AND_INFO: "projects_and_info",
  PROJECT_MEDIA_LINKS: "media_links",
  PROJECT_MEDIA_LINK_VISITS: "visits",
};

const PROTOCOL = window.location.protocol;
const HOST = window.location.host;

const LINK_TYPE = {
  EMBED: "embed",
  PREVIEW: "preview",
  QR: "qr",
};

// get url of a link object
// this has to be called at run-time since it depends on the host
export const getLinkUrl = (link) => {
  const url = `${PROTOCOL}//${HOST}/media/${link.linkId}`;
  if (link.type === LINK_TYPE.EMBED) {
    return `<iframe width="${link.width}" height="${link.height}" src="${url}" frameborder="0" />`;
  } else {
    return url;
  }
};

export const getMediaLink = async (linkId) => {
  const collection = COLLECTIONS.PROJECT_MEDIA_LINKS;
  const where = [["linkId", "==", linkId]];
  const links = await getCollectionGroup({ collection, where });
  if (!links) return links;
  const link = links[linkId];
  if (!link) return link;
  if (link.path && link.path.startsWith(COLLECTIONS.PROJECTS_AND_INFO + "/")) {
    // extract projectId from path
    link.projectId = link.path.match(
      new RegExp(
        `${COLLECTIONS.PROJECTS_AND_INFO}/(.+)/${COLLECTIONS.PROJECT_MEDIA_LINKS}/`
      )
    )[1];
  }
  return { ...link, url: getLinkUrl(link) };
};

// returns a media link using collection group
export const useMediaLink = (linkId) => {
  const [link, setLink] = useState();

  useEffect(() => {
    getMediaLink(linkId).then((res) => {
      setLink(res);
    });
  }, [linkId]);

  return link;
};

// add timestamp to medialink
export const useMediaLinkTimestamp = ({ link }) => {
  const [linkWithParams, setLinkWithParams] = useState();
  useEffect(() => {
    if (!link) return;
    const linkWithTimestamp = new URL(link);
    linkWithTimestamp.searchParams.set("t", Date.now());
    linkWithTimestamp.searchParams.set("origin", window.location.origin);

    const searchParams = new URLSearchParams(window.location.search);
    const redirectToURL = searchParams.get("redirectTo");

    if (redirectToURL) {
      linkWithTimestamp.searchParams.set("redirectTo", redirectToURL);
    }
    const redirectTarget = searchParams.get("target");
    if (redirectTarget) {
      linkWithTimestamp.searchParams.set("target", redirectTarget);
    }
    setLinkWithParams(linkWithTimestamp);
  }, [link]);
  return linkWithParams;
};

// checks whether a media link's has expired
// date is check against local date
// time is discarded
export const isMediaLinkExpired = (link) => {
  if (!link?.expiry) return false;
  const expiry = link.expiry.toDate();
  const timzoneOffset = new Date().getTimezoneOffset();
  var timeOffsetInMS = timzoneOffset * 60000;
  expiry.setTime(expiry.getTime() + timeOffsetInMS); // convert expiry date to locale date
  expiry.setHours(0, 0, 0, 0); // discard time
  const today = new Date();
  return expiry <= today;
};

export const MEDIA_LINKS_SORT_OPTON_FIELD = {
  SORT_OPTION_MOSTRECENT: "created",
  SORT_OPTION_NAME: "name",
};

export const MEDIA_LINKS_SORT_OPTION = Object.keys(
  MEDIA_LINKS_SORT_OPTON_FIELD
);
