import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    padding: theme.spacing(1),
  },
}));
