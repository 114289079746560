import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useRelativeTimeString } from "../../utils/localeUtils";
import {
  useDeviceDeploymentState,
  DEPLOYMENT_STATUSES,
  TASK_TYPES,
} from "../../services/ProjectService";

import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

import {
  // DeployStrings,
  DeviceStrings,
} from "../../strings";

const MAX_DISPLAY_WORKING = 5;

const useStyles = makeStyles((theme) => {
  return {
    title: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      padding: 0,
    },
    row: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    progress: {
      marginBottom: theme.spacing(3),
    },
    caption: {
      fontWeight: "bold",
    },
  };
});

const statusToString = (status, checkComplianceOnly) => {
  var strings = DeviceStrings.DEPLOY_PROGRESS_STATUS;
  if (checkComplianceOnly) {
    strings = DeviceStrings.COMPLIANCE_CHECK_PROGRESS_STATUS;
  }
  switch (status) {
    case DEPLOYMENT_STATUSES.COMPLETED:
      return strings.COMPLETED;
    case DEPLOYMENT_STATUSES.FAILED:
      return strings.FAILED;
    case DEPLOYMENT_STATUSES.WORKING:
      return strings.WORKING;
    case DEPLOYMENT_STATUSES.PENDING:
      return strings.PENDING;
    case DEPLOYMENT_STATUSES.NOT_STARTED:
    default:
      return strings.NOT_STARTED;
  }
};

const typeToString = (type, checkComplianceOnly) => {
  var strings = DeviceStrings.DEPLOY_TASK_TYPE;
  if (checkComplianceOnly) {
    strings = DeviceStrings.COMPLIANCE_CHECK_TYPE;
  }
  switch (type) {
    case TASK_TYPES.COPY_APP_FILE_TO_EXTERNAL:
      return strings.COPY_APP_FILE_TO_EXTERNAL;
    case TASK_TYPES.DOWNLOAD_CUSTOM_FILE:
      return strings.DOWNLOAD_CUSTOM_FILE;
    case TASK_TYPES.DOWNLOAD_MEDIA_FILE:
      return strings.DOWNLOAD_MEDIA_FILE;
    case TASK_TYPES.EXECUTE_COMMAND:
      return strings.EXECUTE_COMMAND;
    case TASK_TYPES.INSTALL_APPLICATION:
      return strings.INSTALL_APPLICATION;
    default:
      return strings.UNKNOWN;
  }
};

const ProgressBar = ({ deploying, error = false }) =>
  deploying ? (
    <LinearProgress />
  ) : (
    <LinearProgress
      variant="determinate"
      value={100}
      color={error ? "secondary" : "primary"}
    />
  );

const TaskRow = ({ label, list }) => {
  const classes = useStyles();
  if (!list || list.length === 0) return <></>;
  return (
    <div className={classes.row}>
      {`${label}: `}
      {list?.length > MAX_DISPLAY_WORKING
        ? `${list?.length} items`
        : list?.join(", ")}
    </div>
  );
};

const TaskType = ({ type, data, deploying, checkComplianceOnly }) => {
  const classes = useStyles();
  const notReady = data?.failed?.length > 0;
  return (
    <>
      <div className={classes.row}>
        <span className={classes.caption}>{`${typeToString(
          type,
          checkComplianceOnly
        )}: [${data?.completed}/${data?.total}]`}</span>
      </div>
      {data?.failed ? (
        <TaskRow
          label={DeviceStrings.DEPLOY_STATUS_FAILED}
          list={data?.failed}
        />
      ) : data?.working ? (
        <TaskRow
          label={DeviceStrings.DEPLOY_STATUS_WORKING}
          list={data?.working}
        />
      ) : (
        data?.pending && (
          <TaskRow
            label={DeviceStrings.DEPLOY_STATUS_PENDING}
            list={data?.pending}
          />
        )
      )}
      <div className={classes.progress}>
        <ProgressBar
          deploying={data?.completed < data?.total && deploying}
          error={notReady}
        />
      </div>
    </>
  );
};

const DeploymentDialog = ({
  deviceId,
  projectId,
  open,
  onClose,
  checkCompliance = false,
}) => {
  const classes = useStyles();

  const state = useDeviceDeploymentState(projectId, deviceId, checkCompliance);
  const deploying = state?.status === DEPLOYMENT_STATUSES.WORKING;
  const error = state?.status === DEPLOYMENT_STATUSES.FAILED;
  const startedTime = useRelativeTimeString(state?.timestamp);
  // no need to show some types for compliance check
  const taskTypesToExclude = checkCompliance ? [
    TASK_TYPES.COPY_APP_FILE_TO_EXTERNAL,
    TASK_TYPES.EXECUTE_COMMAND,
  ] : [];

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle className={classes.title} disableTypography>
        <Box pr={2}>
          <CloudDownloadOutlinedIcon />
        </Box>
        <Typography variant="caption">
          {checkCompliance
            ? DeviceStrings.COMPLIANCE_CHECK_PROGRESS_TITLE
            : DeviceStrings.DEPLOY_PROGRESS_TITLE}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          {statusToString(state?.status, checkCompliance)}
          {startedTime && ` (${startedTime})`}
        </div>
        <div className={classes.progress}>
          <ProgressBar deploying={deploying} error={error} />
        </div>

        {state?.types &&
          Object.entries(state?.types)
            .filter(([k, v]) => !taskTypesToExclude.includes(k))
            .map(([type, data], i) => (
              <TaskType
                key={`type-${i}`}
                type={type}
                data={data}
                deploying={deploying}
                checkComplianceOnly={checkCompliance}
              />
            ))}
      </DialogContent>
    </Dialog>
  );
};

export default DeploymentDialog;
