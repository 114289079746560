import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: ({ mobile }) => ({
    display: "flex",
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: "5px",
    flexDirection: "column",
    width: mobile ? "auto" : "460px",
  }),
  header: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderBottom: "solid 1px #D0D0D0",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  menuWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  codeName: {
    fontWeight: 700,
  },
  role: {
    color: "#777777",
  },
  copyUrl: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    padding: 0,
    "&:hover": {
      "& > p": {
        color: theme.palette.grey[800],
      },
    },
  },
  url: {
    display: "flex",
    color: "#777777",
    maxWidth: "160px",
  },
  users: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  copyIcon: {
    marginLeft: "8px",
    width: "16px",
    height: "16px",
  },
  codeLeft: {
    display: "flex",
    alignItems: "center",
    borderRight: "solid 1px #D0D0D0",
  },
  codeRight: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
  },
}));
