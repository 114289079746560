import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import EditIcon from "@material-ui/icons/Edit";
import PermMediaOutlinedIcon from "@material-ui/icons/PermMediaOutlined";

import { useProjectInfo } from "../../services/ProjectService";
import { useUserName } from "../../services/UserService";
import { updateMediaDraftStorageInfo } from "../../services/ApiService";
import {
  getRelativeTimeString,
  getTimeString,
  formatFileSize,
  displayLoadingText,
} from "../../utils/localeUtils";

import MyCard from "./MyCard";
import { ProjectStrings } from "../../strings";
import Avatars from "./Avatars";
const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(0.5),
    "&:first-child": {
      marginTop: 0,
    },
    color: "black",
  },
  caption: {
    marginRight: theme.spacing(1),
    fontWeight: "bold",
  },
  button: {
    marginLeft: theme.spacing(1),
    "&:first-child": {
      marginLeft: 0,
    },
  },
  approvalsContentOuterContainer: {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
  approvalsContentInnerContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    borderRadius: "10px",
    overflowX: "auto",
  },
  buttonContentContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  myCardOuterContainer: {
    display: "flex",
    flexDirection: "column",
  },
  myCardInnerContainer: {
    display: "flex",
  },
  contentOuterContainer: {
    width: "50%",
  },
  contentInnerContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const Time = ({ timestamp }) => (
  <Tooltip title={getTimeString(timestamp)}>
    <span>{getRelativeTimeString(timestamp)}</span>
  </Tooltip>
);

const DraftCard = ({
  projectId,
  draft,
  live,
  liveApprovals,
  highlight,
  progress,
  onEdit,
  onUpload,
  onChangeName,
  onDelete,
  onPreview,
  onPublish,
  previewing,
  canEdit,
  canApprove,
}) => {
  const classes = useStyles();
  // only get project info for live
  const projectInfo = useProjectInfo({ projectId: live && projectId });
  const name = useUserName(draft?.createdBy ?? projectInfo?.uploader?.usersId);
  const timestamp = draft?.createdAt ?? projectInfo?.uploader?.timestamp;
  const hasDraftId = !!draft?.myStoriesId;
  const approvals = liveApprovals ?? draft?.approvals;
  const info = draft?.storageInfo;
  const [updating, setUpdating] = useState(false);
  const [expandApprovalItem, setExpandApprovalItem] = useState(false);

  const onClickExpandApprovals = () => {
    setExpandApprovalItem(true);
  };

  useEffect(() => {
    // skip until we have a draft without info
    if (updating || !draft || draft?.storageInfo) return;

    setUpdating(true);
    // skip draftName for live
    updateMediaDraftStorageInfo({ projectId, draftName: !live && draft?.id });
  }, [draft, projectId, updating, live]);

  const config = {
    icon: live ? <PermMediaOutlinedIcon /> : <EditIcon />,
    title: draft?.name ?? draft?.id ?? ProjectStrings.MEDIA_LIVE_CONTENT,
    onClick: () => {},
    progress,
    tags: [],
    overflow: [],
  };
  if (!live && hasDraftId)
    config.tags.push(ProjectStrings.MEDIA_DRAFT_TAG_MYSTORIES);
  if (previewing) config.tags.push(ProjectStrings.MEDIA_DRAFT_TAG_PREVIEWING);
  if (!live) {
    config.overflow.push(
      ...[
        {
          label: ProjectStrings.MEDIA_DRAFT_MENU_EDIT,
        },
        {
          label: ProjectStrings.MEDIA_DRAFT_MENU_CHANGE_NAME,
          onClick: () => {
            onChangeName(draft.id, draft.name);
          },
          disabled: progress || !onChangeName || !canEdit,
        },
        {
          label: ProjectStrings.MEDIA_DRAFT_MENU_MANAGE,
        },
        {
          label: ProjectStrings.MEDIA_DRAFT_MENU_DELETE,
          onClick: () => {
            onDelete(draft.id, draft.name);
          },
          disabled: progress || !onDelete || !canEdit,
        },
      ]
    );
  }

  const _onEdit = () => {
    if (draft.myStoriesId && onEdit) onEdit(draft.myStoriesId);
  };

  const _onPreview = () => {
    if (onPreview) onPreview(draft?.id, draft?.name);
  };

  const _onPublish = () => {
    if (onPublish) onPublish(draft.id, draft.name);
  };

  const fileSize = displayLoadingText(info && formatFileSize(info.size));
  const files = displayLoadingText(
    info &&
      `${info.count}${ProjectStrings.MEDIA_PROJECT_CONTENT_CONTAINS_CONTENT}`
  );
  const updated = displayLoadingText(
    info &&
      (info.lastUpdated ? (
        <Time timestamp={info.lastUpdated} />
      ) : (
        ProjectStrings.MEDIA_PROJECT_CONTENT_NEVER
      ))
  );

  const creatorContent = draft && (
    <>
      <div className={classes.row}>
        <span variant="body1" className={classes.caption}>
          Created:
        </span>
        <Time timestamp={timestamp} />
      </div>
      <div className={classes.row}>
        <span className={classes.caption}>Created by:</span>
        <span>{name}</span>
      </div>
    </>
  );

  const approvalsContent = approvals && (
    <div className={classes.approvalsContentOuterContainer}>
      <div className={classes.approvalsContentInnerContainer}>
        <Avatars
          approvals={approvals}
          expandApprovalItem={expandApprovalItem}
          onClickExpand={onClickExpandApprovals}
          live={live}
        />
      </div>
    </div>
  );

  const buttonPreview = (
    <Button
      variant="contained"
      className={classes.button}
      onClick={_onPreview}
      disabled={progress || previewing || !canApprove || !info?.count}
    >
      Preview
    </Button>
  );

  const buttonContent = (
    <div className={classes.buttonContentContainer}>
      {!live && (
        <>
          {hasDraftId && (
            <Button
              variant="contained"
              className={classes.button}
              onClick={_onEdit}
              disabled={!canEdit}
            >
              Edit
            </Button>
          )}
          <Button
            variant="contained"
            className={classes.button}
            onClick={onUpload}
            disabled={progress || !canEdit}
          >
            Upload
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={_onPublish}
            disabled={progress || !canEdit}
          >
            Publish
          </Button>
        </>
      )}
      {buttonPreview}
    </div>
  );

  return (
    <MyCard
      config={config}
      canRead={true}
      canEdit={false}
      highlight={highlight}
    >
      <div
        className={classes.myCardOuterContainer}
        onMouseLeave={() => setExpandApprovalItem(false)}
      >
        <div className={classes.myCardInnerContainer}>
          <div className={classes.contentOuterContainer}>
            <div className={classes.contentInnerContainer}>
              {creatorContent}
              <div className={classes.row}>
                <span className={classes.caption}>
                  {ProjectStrings.MEDIA_PROJECT_CONTENT_FILE_SIZE}
                </span>
                <span>{fileSize}</span>
              </div>
              <div className={classes.row}>
                <span className={classes.caption}>
                  {ProjectStrings.MEDIA_PROJECT_CONTENT_CONTAINS}
                </span>
                <span>{files}</span>
              </div>
              <div className={classes.row}>
                <span className={classes.caption}>
                  {ProjectStrings.MEDIA_PROJECT_CONTENT_LAST_UPDATED}
                </span>
                <span>{updated}</span>
              </div>
            </div>
          </div>
          <div className={classes.contentOuterContainer}>
            <div className={classes.contentInnerContainer}>
              {buttonContent}
              {!live && approvalsContent}
            </div>
          </div>
        </div>
        {live && approvalsContent}
      </div>
    </MyCard>
  );
};

export default DraftCard;
