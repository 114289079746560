import React, { useMemo } from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";

import { DefaultStrings } from "strings";
import { NameContent } from "./components";
import { useStyles } from "./styles";
import { useOrganisationInfo } from "services/OrganisationService";
import LinearSpinner from "ui/LinearSpinner";
import { EditIcon } from "assets/icons";

export const OrganisationCard = ({ organisationId, onClick, loading }) => {
  const classes = useStyles();
  const organisation = useOrganisationInfo(organisationId);

  const name = useMemo(
    () =>
      organisation && organisation.name
        ? organisation.name
        : DefaultStrings.APP_NAME,
    [organisation]
  );

  const onClickHandle = () => {
    onClick && onClick(organisationId);
  };

  if (!organisation) return null;

  const renderContent = (
    <>
      {loading && <LinearSpinner />}
      {organisation.imageURL && (
        <CardMedia className={classes.media} image={organisation.imageURL} />
      )}

      <Box className={classes.titleWrapper}>
        <NameContent name={name} />
        <EditIcon className={classes.editIcon} />
      </Box>
    </>
  );

  const cardContent = (
    <Card className={classes.card}>
      {loading ? (
        <div className={classes.action}>{renderContent}</div>
      ) : (
        <CardActionArea className={classes.action} onClick={onClickHandle}>
          {renderContent}
        </CardActionArea>
      )}
    </Card>
  );

  return cardContent;
};
