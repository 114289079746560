import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  codeTeam: (mobile) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: mobile
      ? theme.spacing(1)
      : `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
          2
        )}px ${theme.spacing(2)}px`,
  }),
  wapper: (mobile) => ({
    display: "flex",
    flexDirection: mobile ? "column" : "row",
    alignItems: mobile ? "left" : "center",
    paddingTop: theme.spacing(1),
  }),
  teamWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  participants: (mobile) => ({
    position: mobile ? "absolute" : "relative",
    left: mobile ? "0" : null,
    bottom: mobile ? "-10px" : null,
    marginLeft: theme.spacing(2),
  }),
}));
