import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    borderTop: "solid 1px #D0D0D0",
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  userRoleWrapper: {
    display: "flex",
    flexGrow: 1,
    color: "#747474",
  },
  userRole: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
  button: {
    color: theme.palette.common.black,
  },
}));
