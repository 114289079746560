import React from "react";
import Box from "@material-ui/core/Box";

import { useMobileLayout } from "hooks/uiHooks";
import UserAvatar from "ui/UserAvatar";
import { useUserPendingInvite } from "services/OrganisationService";

export const UserInviteAvatar = ({ code, organisationId, index }) => {
  const mobile = useMobileLayout();
  const userInvite = useUserPendingInvite({
    code,
    organisationId,
  });

  const avatarTeamSize = mobile ? 7 : 4;

  if (!userInvite) return null;

  return (
    <Box
      style={{
        marginLeft: index === 0 ? "unset" : "-6px",
      }}
    >
      <UserAvatar
        userId={userInvite.usersId}
        userEmail={userInvite.email}
        size={avatarTeamSize}
      />
    </Box>
  );
};
