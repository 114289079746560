import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import AuthRoute from "./route/AuthRoute";
import { ROUTES, getProjectJoinRoute } from "./route";
import {
  userExist,
  userNotExist,
  userExistAndInProject,
  userIsAdmin,
  userExistAndInOrganisation,
} from "./services/AuthService";
import {
  ProjectOverviewPage,
  ProjectMediaPage,
  ProjectDevicesPage,
  ProjectAnalyticsPage,
  ProjectTeamPage,
} from "./pages/project";
import { ProjectsPage } from "pages/projects";
import NewsPage from "./pages/news";
import { AdminPage } from "pages/admin";
import { OrganisationPage } from "pages/organisation";
import { ProfilePage } from "./pages/profile";
import HomePage from "./pages/home";
import MediaPage from "./pages/media";
import DownloadPage from "./pages/Download";
import UpdateDevicePage from "./pages/updateDevice";
import RemotePage from "./pages/RemotePage";
import { DefaultStrings, ProjectStrings } from "./strings/";

const DefaultPage = <Redirect to={ROUTES.LOGIN} />;

const Routes = () => {
  return (
    <Switch>
      {/* general user routes */}
      <AuthRoute
        exact
        path={[ROUTES.PROJECTS_SHARED, ROUTES.PROJECT_JOIN, ROUTES.PROJECT_NEW]}
        component={ProjectsPage}
        authCheckFunc={userExist}
        authFailPath={ROUTES.LOGIN}
      />
      <AuthRoute
        exact
        path={[ROUTES.PROJECTS, ROUTES.PROJECTS_ALL]}
        component={ProjectsPage}
        authCheckFunc={userExistAndInOrganisation}
        authFailPath={ROUTES.PROJECTS_SHARED}
      />

      <AuthRoute
        exact
        path={[
          ROUTES.ORGANISATION_DASHBOARD,
          ROUTES.ORGANISATION_TEAM,
          ROUTES.ORGANISATION_GUESTS,
          ROUTES.ORGANISATION_ACTIVITY,
          ROUTES.ORGANISATION_SETTINGS,
        ]}
        component={OrganisationPage}
        authCheckFunc={userExistAndInOrganisation}
        authFailPath={ROUTES.LOGIN}
      />
      {/* route for SuperAdmin only on the organisation dashboard */}
      <AuthRoute
        exact
        path={ROUTES.ORGANISATION_PROJECTS}
        component={OrganisationPage}
        authCheckFunc={userIsAdmin}
        authFailPath={ROUTES.LOGIN}
      />
      <AuthRoute
        exact
        path={ROUTES.ORGANISATION_JOIN}
        component={OrganisationPage}
        authCheckFunc={userExist}
        authFailPath={ROUTES.SIGNUP}
      />

      <AuthRoute
        exact
        path={ROUTES.PROFILE}
        component={ProfilePage}
        authCheckFunc={userExist}
        authFailPath={ROUTES.LOGIN}
      />
      <AuthRoute
        exact
        path={ROUTES.NEWS}
        component={NewsPage}
        authCheckFunc={userExist}
        authFailPath={ROUTES.LOGIN}
      />
      <Route
        path={ROUTES.JOIN}
        render={(props) => (
          <Redirect to={getProjectJoinRoute(props.match.params.code)} />
        )}
      />
      <AuthRoute
        exact
        path={ROUTES.LOGIN}
        component={HomePage}
        authCheckFunc={userNotExist}
        authFailPath={ROUTES.PROJECTS}
        redirectToOriginal
      />
      <AuthRoute
        exact
        path={ROUTES.SIGNUP}
        component={HomePage}
        authCheckFunc={userNotExist}
        authFailPath={ROUTES.PROJECTS}
      />
      <Route exact path={ROUTES.MEDIA} component={MediaPage} />

      {/* project routes */}
      <AuthRoute
        exact
        path={ROUTES.PROJECT_OVERVIEW}
        component={ProjectOverviewPage}
        authCheckFunc={userExistAndInProject}
        authFailPath={ROUTES.PROJECTS}
        authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
      />
      <AuthRoute
        exact
        path={[
          ROUTES.PROJECT_MEDIA,
          ROUTES.PROJECT_MEDIA_MESSAGES,
          ROUTES.PROJECT_MEDIA_EDIT_TAB,
          ROUTES.PROJECT_MEDIA_PREVIEW,
        ]}
        component={ProjectMediaPage}
        authCheckFunc={userExistAndInProject}
        authFailPath={ROUTES.PROJECTS}
        authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
      />
      <AuthRoute
        exact
        path={[
          ROUTES.PROJECT_DEVICES,
          ROUTES.PROJECT_DEVICE_SINGLE,
          ROUTES.PROJECT_DEVICE_TAB,
        ]}
        component={ProjectDevicesPage}
        authCheckFunc={userExistAndInProject}
        authFailPath={ROUTES.PROJECTS}
        authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
      />
      <AuthRoute
        exact
        path={ROUTES.PROJECT_ANALYTICS}
        component={ProjectAnalyticsPage}
        authCheckFunc={userExistAndInProject}
        authFailPath={ROUTES.PROJECTS}
        authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
      />
      <AuthRoute
        exact
        path={[ROUTES.PROJECT_TEAM, ROUTES.PROJECT_TEAM_SHARE_TAB]}
        component={ProjectTeamPage}
        authCheckFunc={userExistAndInProject}
        authFailPath={ROUTES.PROJECTS}
        authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
      />

      {/* admin routes */}
      <AuthRoute
        exact
        path={[ROUTES.REMOTE_MAIN, ROUTES.REMOTE_DEVICE]}
        component={RemotePage}
        authCheckFunc={userIsAdmin}
        authFailPath={ROUTES.PROJECTS}
        authFailMessage={DefaultStrings.ERROR_NO_ACCESS}
      />

      <AuthRoute
        exact
        path={[ROUTES.ADMIN, ROUTES.ADMIN_ORGANISATIONS, ROUTES.ADMIN_PROJECTS]}
        component={AdminPage}
        authCheckFunc={userIsAdmin}
        authFailPath={ROUTES.LOGIN}
        authFailMessage={DefaultStrings.ERROR_NO_ACCESS}
      />

      <Route exact path={ROUTES.DOWNLOAD} component={DownloadPage} />

      <Route exact path={ROUTES.UPDATE_DEVICE} component={UpdateDevicePage} />

      {/* catch-all (with no path) */}
      {DefaultPage}
    </Switch>
  );
};

export default Routes;
