import React from "react";
import Typography from "@material-ui/core/Typography";

import UserAvatar from "ui/UserAvatar";
import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { useUserName } from "services/UserService";

export function Header({ userId, email, onClick }) {
  const mobile = useMobileLayout();
  const classes = useStyles();
  const userName = useUserName(userId);
  const avatarTeamSize = mobile ? 4 : 4.5;

  return (
    <div className={classes.container} onClick={onClick}>
      <UserAvatar userId={userId} userEmail={email} size={avatarTeamSize} />
      <div className={classes.titleWrapper}>
        {userName && (
          <>
            <Typography variant="caption">{userName}</Typography>
            <Typography className={classes.email} variant="body2">
              {email}
            </Typography>
          </>
        )}
        {!userName && <Typography variant="caption">{email}</Typography>}
      </div>
    </div>
  );
}
