import React, { useState, useContext, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { joinProject as joinProjectApi } from "services/ApiService";
import MetaTags from "ui/MetaTags";
import ProjectList from "ui/ProjectList";
import ProjectDialog from "ui/dialogs/ProjectDialog";
import Spinner from "ui/Spinner";
import { HomeStrings } from "strings";
import { useCurrentOrganisation } from "services/UserService";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { JoinDialog } from "ui/dialogs";
import { getProjectOverviewRoute, ROUTES } from "route";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

const Projects = ({ userId }) => {
  const history = useHistory();
  const matchSharedProjects = !!useRouteMatch({
    path: ROUTES.PROJECTS_SHARED,
    exact: true,
  });
  const matchNew = !!useRouteMatch(ROUTES.PROJECT_NEW);

  const matchAllProjects = !!useRouteMatch(ROUTES.PROJECTS_ALL);
  const selectedOrganisationId = useCurrentOrganisation();
  const { actionType, onAction } = useContext(MainContext);

  const [showProjectList, setShowProjectList] = useState(true);
  const [projectIdToJoin, setProjectIdToJoin] = useState(null);
  const [approvalRequested, setApprovalRequested] = useState(false);

  useEffect(() => {
    if (matchNew) {
      onAction(GLOBAL_ACION_TYPES.PROJECT_CREATE, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchNew]);

  const onJoinCloseHandle = () => {
    setApprovalRequested(false);
    setProjectIdToJoin(null);
    onAction(GLOBAL_ACION_TYPES.PROJECT_JOIN, null);
  };

  const { isSuperAdmin } = useUserOrganisationPermissions({
    userId,
    organisationId: selectedOrganisationId,
  });

  const configProjectDialog = {
    onClose: () => onAction(GLOBAL_ACION_TYPES.PROJECT_CREATE, false),
    onConfirm: () => {
      onAction(GLOBAL_ACION_TYPES.PROJECT_CREATE, false);
      setShowProjectList(false);
    },
    onComplete: () => setShowProjectList(true),
  };

  const configProjectList = {
    organisationId: matchSharedProjects ? null : selectedOrganisationId,
    showOrgProjects: matchAllProjects,
    onClick: (projectId, joined) => {
      if (!joined && isSuperAdmin === false) {
        // open join pop-up window
        setProjectIdToJoin(projectId);
      }
    },
    clickToOpen: true,
    onAdd: () => onAction(GLOBAL_ACION_TYPES.PROJECT_CREATE, true),
  };

  const configJoinDialog = {
    projectId: projectIdToJoin,
    userId,
    approvalRequested,
    onOk: () => {
      onJoinCloseHandle();
    },
    onClose: (id) => {
      onAction(GLOBAL_ACION_TYPES.PROJECT_JOIN, null);
      setProjectIdToJoin(id);
    },
    onJoin: async (projectId, joinCode, isApprovalRequired) => {
      await joinProjectApi({ projectId, joinCode });

      if (isApprovalRequired) {
        setApprovalRequested(true);
        return;
      }

      setProjectIdToJoin(null);
      history.push(getProjectOverviewRoute(projectId));
    },
  };

  return (
    <>
      <MetaTags
        title={HomeStrings.META_TITLE_PROJECTS}
        description={HomeStrings.META_DESCRIPTION_PROJECTS}
      />
      {showProjectList ? (
        <ProjectList config={configProjectList} />
      ) : (
        <Spinner />
      )}
      <ProjectDialog
        userId={userId}
        organisationId={selectedOrganisationId}
        open={actionType[GLOBAL_ACION_TYPES.PROJECT_CREATE]}
        config={configProjectDialog}
      />
      <JoinDialog
        open={!!actionType[GLOBAL_ACION_TYPES.PROJECT_JOIN]}
        config={configJoinDialog}
      />
    </>
  );
};

const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

export const ProjectsPage = connect(mapStateToProps)(Projects);
