import React from "react";

import StatCard from "./statCard/StatCard";
import { useOrganisationDevicesStatistics } from "services/OrganisationService";
import { OrganisationStrings } from "strings";
import { DeviceIcon } from "assets/icons";

const OrganisationDeviceStatCard = ({ organisationId, canRead }) => {
  const stats = useOrganisationDevicesStatistics(organisationId);
  return (
    <StatCard
      icon={<DeviceIcon />}
      title={OrganisationStrings.OVERVIEW_DEVICES_STAT_TITLE}
      loading={typeof stats === "undefined"}
      live={stats?.live || 0}
      online={stats?.online || 0}
      compliant={stats?.compliant || 0}
      canRead={canRead}
    />
  );
};

export default OrganisationDeviceStatCard;
