import React, { useState, useEffect } from "react";
import { Chip, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KEYBOARD_KEYS } from "utils/projectUtils";
import { useStyles } from './styles';

const MAX_INPUT_VALUE_LENGTH = 30;

const DialogContent = ({ items, suggestedItems, maxTagsCount, inputTitle, errorMessage, onChange }) => {
  const classes = useStyles();

  const [errorText, setErrorText] = useState(null);
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [usedKeyArrow, setUsedKeyArrow] = useState(false);
  const [itemsToUpdate, setItemsToUpdate] = useState(items);
  const itemsJoinedStr = [...items].join(';');

  useEffect(() => {
    if (itemsJoinedStr) {
      setItemsToUpdate(items);
    }
    // eslint-disable-next-line
  }, [itemsJoinedStr]);

  // Create suggested options list
  var options = suggestedItems
    ? Object.entries(suggestedItems)
      .filter(item => !itemsToUpdate.includes(item[0]))
      .sort((a, b) => a[0].localeCompare(b[0])) // sort by key
      .sort((a, b) => a[1] > b[1] ? -1 : 1) // sort by count
      .map(([key]) => key)
    : [];

  const deleteTag = (tagName) => () => {
    setItemsToUpdate((prevState) => prevState.filter(tag => tag !== tagName));
    onChange(tagName, 'remove');
  };

  const onAutocompleteKeyDownHandle = (event) => {
    if ([KEYBOARD_KEYS.ARROW_DOWN, KEYBOARD_KEYS.ARROW_UP].includes(event.keyCode)) {
      setUsedKeyArrow(true);
      return;
    }

    // add a tag on ENTER button pushed
    if ([KEYBOARD_KEYS.ENTER, KEYBOARD_KEYS.SPACE].includes(event.keyCode) && !usedKeyArrow) {
      const eventValue = event.target.value.trim();

      if (errorText || !eventValue) {
        // Return undefined if errorText is not null, eg. when a tag already added
        return;
      }  

      if (!eventValue) {
        setErrorText("Tag name must not be empty");
        return;
      }
      setUsedKeyArrow(false);
      // Tags limit check
      if (itemsToUpdate.length > maxTagsCount) {
        setErrorText(errorMessage.replace("{number}", maxTagsCount));
        return;
      }

      let valueToUpdate = eventValue;
      // Find existed tag in the suggested list
      const existedSuggested = options.find((option) => option.toLowerCase() === eventValue.toLowerCase());
      // If the tag exists in the suggested list assign to value for updating
      if (existedSuggested) {
        valueToUpdate = existedSuggested;
      }
      setItemsToUpdate((prevState) => [...prevState, valueToUpdate]);
      onChange(valueToUpdate, 'add');
      setInputValue('');
      setValue('');
    }
  };

  const onAutocompleteChangeHandle = (event, valueToUpdate) => {
    event.stopPropagation();
    const valueToUpdateTrimed = valueToUpdate.trim();

    // Check if value length is exceeded max input length
    if (valueToUpdateTrimed.length > MAX_INPUT_VALUE_LENGTH) {
      return;
    }

    setErrorText(null);
    setInputValue(valueToUpdateTrimed);
    
    const existedValue = itemsToUpdate
      .find((item) => item.toLowerCase() === valueToUpdateTrimed.toLowerCase());

    if (existedValue) {
      setErrorText("Tag already exists");
      return;
    }    

    // add a tag on ENTER button pushed
    if (event.keyCode === KEYBOARD_KEYS.ENTER) {
      
      setUsedKeyArrow(false);

      // limit check
      if (itemsToUpdate.length > maxTagsCount) {
        setErrorText(errorMessage.replace("{number}", maxTagsCount));
        return;
      }
   
      setItemsToUpdate((prevState) => [...prevState, valueToUpdateTrimed]);
      onChange(valueToUpdateTrimed, 'add');
      setInputValue('');
      setValue('');
    }
  };

  const onInputChangeHandle = (event) => {
    setErrorText(null);

    const tagValue = event.target.value.trim();
    
    setValue(tagValue);

    const existedValue = itemsToUpdate
      .find((item) => item.toLowerCase() === tagValue.toLowerCase());
    
    if (existedValue) {
      setErrorText("Tag already exists");
      return;
    }    
    
    // addTag if ENTER or SPACE
    if ([KEYBOARD_KEYS.ENTER, KEYBOARD_KEYS.SPACE].includes(event.keyCode)) {
      if (!tagValue) {
        setErrorText("Tag name must not be empty");
        return;
      }

      // limit check
      if (itemsToUpdate.length > maxTagsCount) {
        setErrorText(errorMessage.replace("{number}", maxTagsCount));
        return;
      }

      setItemsToUpdate((prevState) => [...prevState, tagValue]);
      onChange(tagValue, 'add');
      setValue('');
    }
  };

  return (
    <>
      {itemsToUpdate &&
        itemsToUpdate.map(item => {
          return (
            <Chip
              key={item}
              label={item}
              onDelete={deleteTag(item)}
              className={classes.chip} />
          );
        })
      }
      {suggestedItems && (
        <Autocomplete
          options={options}
          value={value}
          inputValue={inputValue}
          onInputChange={(event, newValue) => {
            onAutocompleteChangeHandle(event, newValue);
          }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyDown={onAutocompleteKeyDownHandle}
              label={inputTitle}
              helperText={errorText}
              error={!!errorText}
              margin="normal"
              fullWidth
              autoFocus
            />
          )}
        />
      )}
      {!suggestedItems && (
        <TextField
          className={classes.textfield}
          label={inputTitle}
          inputProps={{ maxLength: maxTagsCount }}
          value={value}
          onKeyDown={onInputChangeHandle}
          onChange={onInputChangeHandle}
          helperText={errorText}
          error={!!errorText}
          fullWidth
          autoFocus
        />
      )}
    </>
  );
};

export default DialogContent;