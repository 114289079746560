import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(1),
    color: "black",
    fontSize: "2rem",
    fontWeight: 700,
  },
}));
