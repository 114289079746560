import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import STRINGS from "strings/device";

const useStyles = makeStyles(() => {
	return {
		deviceIMeAndActionContainer: {
			display: "flex",
			gap: 6,
			justifyContent: "center",
			alignItems: "center",
		},
		tooltipItem: {
			fontWeight: "bold",
		},
		tooltipItemContainer: {
			marginTop: 10,
			marginBottom: 10,
			fontSize: 12,
		},
		customWidth: {
			maxWidth: 500,
		},
		tooltipItemsContainer: {
			padding: 7,
		},
	}
});

const StoreDetailsToolTip = ({ storeDetails }) => {
	const classes = useStyles();
	return (
		<div className={classes.tooltipItemsContainer}>
			<div className={classes.tooltipItemContainer}>
				<span className={classes.tooltipItem}>{STRINGS.STORE_POSTCODE} </span>
				{storeDetails?.storeId}
			</div>
			<div className={classes.tooltipItemContainer}>
				<span className={classes.tooltipItem}>{STRINGS.STORE_COUNTRY} </span>
				{storeDetails?.country}
			</div>
			<div className={classes.tooltipItemContainer}>
				<span className={classes.tooltipItem}>{STRINGS.STORE_EDIT_POSTCODE} </span>
				{storeDetails?.postcode}
			</div>
			<div className={classes.tooltipItemContainer}>
				<span className={classes.tooltipItem}>{STRINGS.STORE_EDIT_RETAILER} </span>
				{storeDetails?.retailer}
			</div>
		</div>
	);
};

export default StoreDetailsToolTip;
