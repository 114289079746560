import {DeviceStrings} from "strings";

/** Build filter items for dropdown list */
export const buildFilterItems = (devices, key) => {
  const items = devices
    .map((d) => d[key]) // get items from device by key
    .filter((item => item)); // remove undefined items if appears
  
  const itemsMap = new Map();

  const updateMap = (item) => {
    if (itemsMap.has(item)) {
      // Update if key exists
      itemsMap.set(
        item, {
          title: itemsMap.get(item).title,
          additionalText: itemsMap.get(item).additionalText + 1
        }
      );
    } else {
      // Add new key with initial occurance value
      itemsMap.set(item, {
        title: item,
        additionalText: 1
      });
    }
  };

  // Count items occurrence for devices
  items.forEach((item) => {
    if (!item) return;

    if (Array.isArray(item)) {
      // if item is array of items go through them and add to the map collection
      item.forEach((arrItem) => updateMap(arrItem));
    } else {
      // item as string 
      updateMap(item);
    }
  });

  // Sort ascending
  const sortedAsc = new Map([...itemsMap].sort());

  return new Map([[DeviceStrings.FILTER_ALL, { title: DeviceStrings.FILTER_ALL }], ...sortedAsc])
};

/** 
 * Filter by key 
 * targetList: array of object to search in
 * selected: array of string, search keywords
 * key: value for accessing to specific objec's property
 * */
export const filterByKey = (targetList, selected, key) => {
  // Check if some selected item exists
  const checkFilterExistance = (searchValue) => {
    return selected.some((key) => key === searchValue);
  };

  return targetList?.filter((item) => {
    const searchValue = item[key];

    //Check if a search value is an array, then iterate through list
    if (Array.isArray(searchValue)) {
      return searchValue
        .some((value) => checkFilterExistance(value));

    } else {
      return checkFilterExistance(searchValue);
    }
  });
}