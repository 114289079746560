import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Organisations from "./organisations/Organisations";
import Projects from "./projects/Projects";
import { ROUTES } from "route";

export const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

const Index = ({ userId, match }) => {
  return (
    <>
      <Switch>
        <Route
          path={ROUTES.ADMIN_ORGANISATIONS}
          component={Organisations}
          exact
        />
        <Route path={ROUTES.ADMIN_PROJECTS} component={Projects} exact />
        <Route
          path="*"
          render={() => <Redirect to={ROUTES.ADMIN_ORGANISATIONS} />}
        />
      </Switch>
    </>
  );
};

export const AdminPage = connect(mapStateToProps)(Index);
