import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  compressImageFile,
  VIDEOS_TYPES,
  IMAGE_TYPES,
} from "../utils/fileUtils";
import { isUndefined } from "../utils/generalUtils";
import { uploadFile } from "../services/StorageService";
import {
  updateDocument,
  getCollectionNewDocId,
  useCollection,
  useCollectionOrdered,
  queryDocuments,
} from "./FirestoreService";
import { insertDateIntoMessages } from "./UiService";

const COLLECTIONS = {
  CHAT_ROOMS: "chat_rooms",
  MESSAGES: "messages",
  USERS: "users",
};

const KEYS = {
  DEVICE_ID: "deviceId",
  PROJECT_ID: "projectId",
  ORGANISATION_ID: "organisationId",

  USER_ID: "uid",
};

export const ROOM_TYPES = {
  DEVICE_MESSAGE: {
    acceptFileInput: VIDEOS_TYPES.concat(IMAGE_TYPES),
  },
  ORGANISATION_MESSAGE: {
    acceptFileInput: VIDEOS_TYPES.concat(IMAGE_TYPES),
  },
  MEDIA_MESSAGE: { acceptFileInput: IMAGE_TYPES },
};

// maximum file size
export const mb = 10;
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

/**
 * Chatroom
 */

// update/create a new chatroom with given key and id
const initChatRoom = ({ roomId, key, id }) => {
  const collection = COLLECTIONS.CHAT_ROOMS;
  const doc = roomId;
  const data = { [key]: id };
  return updateDocument({
    collection,
    doc,
    data,
  });
};

// create a new chatroom and return the new id
const createNewChatRoom = async ({ key, id }) => {
  const roomId = getCollectionNewDocId(COLLECTIONS.CHAT_ROOMS);
  await initChatRoom({ roomId, key, id });
  return roomId;
};

const useChatRoomId = ({ key, id }) => {
  const collection = COLLECTIONS.CHAT_ROOMS;
  const where = [[key, "==", id]];
  const rooms = useCollection({ collection: id && collection, where });
  const [roomId, setRoomId] = useState();

  useEffect(() => {
    // loading
    if (isUndefined(rooms)) return;

    // no existing room
    if (!rooms) {
      // create a new chatroom with provided key and id
      createNewChatRoom({ key, id }).then((id) => setRoomId(id));
    } else {
      const ids = Object.keys(rooms);
      const size = ids.length;
      // there should only be one room, but if there is more than one
      // then we return the first and log a warning
      // instead of complicating the logic
      if (size > 1) {
        console.warn(`Multiple chatrooms found for ${key}=${id}`);
      }
      setRoomId(ids[0]);
    }
  }, [key, id, rooms]);

  return roomId;
};

export const useDeviceChatRoomId = (deviceId) =>
  useChatRoomId({ key: KEYS.DEVICE_ID, id: deviceId });

export const useProjectChatRoomId = (projectId) =>
  useChatRoomId({ key: KEYS.PROJECT_ID, id: projectId });

export const useOrganisationChatRoomId = (organisationId) =>
  useChatRoomId({ key: KEYS.ORGANISATION_ID, id: organisationId });

const getChatRoomId = async ({ key, id }) => {
  const collection = COLLECTIONS.CHAT_ROOMS;
  const wheres = [[key, "==", id]];
  const docs = await queryDocuments({ collection, wheres });

  if (docs.length === 0) {
    return await createNewChatRoom({ key, id });
  } else if (docs.length > 1) {
    console.warn(`Multiple chatrooms found for ${key}=${id}`);
  }
  return docs[0].id;
};

export const getProjectChatRoomId = (projectId) =>
  getChatRoomId({ key: KEYS.PROJECT_ID, id: projectId });

/**
 * Messages
 */

// get messages in a chatroom
// date in epoch format will be added between different dates
export const useChatRoomMessages = (roomId, count) => {
  return insertDateIntoMessages({
    messages: useCollectionOrdered({
      collection: COLLECTIONS.CHAT_ROOMS,
      doc: roomId,
      subcollection: COLLECTIONS.MESSAGES,
      orderBy: [["createdAt", "desc"]],
      limit: count,
    }),
    timestampKey: "createdAt",
    addAtEnd: true,
  });
};

// get all the (added) dates in a messages array
export const getMessagesDates = (messages) =>
  messages?.filter((msg) => typeof msg === "number");

// return actual message count in a messages array
// excluding dates
export const getMessagesCount = (messages) =>
  messages?.filter((msg) => typeof msg === "object").length;

/**
 * Misc
 */

// compresses a file, uploads it to Google Cloud Storage a returns the DownloadUrl
export const getDownloadURL = async (file, roomId) => {
  const compressedFile = await compressImageFile(file);
  const uid = uuidv4();
  const url = await uploadFile({
    file: compressedFile,
    path: `chatImages/${roomId}/${uid}-${compressedFile.name}`,
  });
  return url;
};
