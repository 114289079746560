import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

import { useRelativeTimeString } from "../../utils/localeUtils";
import {
  useDeviceCompliant,
  useDeviceLastComplianceCheckTimestamp,
  useDeviceV2,
} from "../../services/ProjectService";
import {
  requestComplianceCheck,
  ccCompliance,
} from "../../services/ApiService";

import ButtonCard from "./ButtonCard";
import DeploymentDialogV2 from "../dialogs/DeploymentDialogV2";
import CallMadeIcon from "@material-ui/icons/CallMade";

import AssignmentLateOutlinedIcon from "@material-ui/icons/AssignmentLateOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";

import { DefaultStrings, DeviceStrings } from "../../strings";

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      position: "absolute",
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
  };
});

const DeviceComplianceCard = ({
  deviceId,
  projectId,
  showDesc,
  canRead,
  canEdit,
  useCc = false, // CC = old cloud control app
}) => {
  const [inProgress, setInProgress] = useState([]);
  const [requestTimestamp, setRequestTimestamp] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(true);
  const classes = useStyles();
  const isDeviceV2 = useDeviceV2(projectId, deviceId);

  const compliant = useDeviceCompliant(projectId, deviceId);
  const icon = compliant ? (
    <AssignmentTurnedInOutlinedIcon color="primary" />
  ) : (
    <AssignmentLateOutlinedIcon />
  );
  const title = compliant
    ? DeviceStrings.COMPLIANCE_TITLE_COM
    : DeviceStrings.COMPLIANCE_TITLE_NON;
  const lastCheckedTimestamp = useDeviceLastComplianceCheckTimestamp(
    projectId,
    deviceId
  );
  const lastChecked =
    useRelativeTimeString(lastCheckedTimestamp) || DeviceStrings.NEVER;
  const hasCheckedBefore = !!lastChecked;

  const deviceLoading = inProgress.includes(deviceId);

  // track mounted state
  // this is necessary to avoid state update after unmounted
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      requestTimestamp &&
      lastCheckedTimestamp &&
      requestTimestamp < lastCheckedTimestamp
    ) {
      // stop progress
      if (mounted.current && deviceLoading) {
        setInProgress(inProgress.filter((i) => i !== deviceId));
        setRequestTimestamp(null);
      }
    }
  }, [
    requestTimestamp,
    lastCheckedTimestamp,
    deviceId,
    inProgress,
    deviceLoading,
  ]);

  const request = () => {
    setInProgress([...inProgress, deviceId]);
    setRequestTimestamp(Date.now());

    const call = useCc ? requestComplianceCheck : ccCompliance;
    call({ deviceId, projectId }).then((result) => {
      if (result.success) {
        enqueueSnackbar(DeviceStrings.REQUEST_SENT, { variant: "success" });
      } else {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(result.errors[0]);
      }
    });
  };

  const configCard = {
    icon,
    title: useCc ? `${title} (CC)` : title,
    buttonLabel: DeviceStrings.COMPLIANCE_BUTTON,
    desc: showDesc ? DeviceStrings.COMPLIANCE_DESC : null,
    fullHeight: true,
    onClick: () => {
      request();
    },
    progress: deviceLoading,
    disableButton: deviceLoading || !canEdit,
  };

  // only for V2 device
  const progressIcon = isDeviceV2 && (hasCheckedBefore || deviceLoading) && (
    <IconButton className={classes.icon} onClick={() => setShowDialog(true)}>
      <CallMadeIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <ButtonCard config={configCard} canRead={canRead}>
        <div>
          <b>{DeviceStrings.LAST_CHECKED}</b>
          {lastChecked && " " + lastChecked}
          {progressIcon}
        </div>
      </ButtonCard>
      <DeploymentDialogV2
        open={showDialog}
        deviceId={deviceId}
        projectId={projectId}
        onClose={() => {
          if (mounted.current) setShowDialog(false);
        }}
        checkCompliance={true}
      />
    </>
  );
};

export default DeviceComplianceCard;
