import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { useStyles } from "./style";
import { DefaultStrings } from "strings";
import { MoreIcon } from "assets/icons";

const menuItemColors = { default: "#323232", error: "#B23A48" };
/**
 *
 * @param {
 *  options: [
 *    { label: string, onClick: () => {}, color: {[key: string]: string} disabled: boolean }
 *  ]
 *  posX: 'left' | 'center' | 'right' | number
 *  posY: 'top' | 'center' | 'bottom' | number
 * }
 * @returns
 */
export const OverflowMenu = ({ options, posX = "right", posY = "top" }) => {
  const classes = useStyles();

  const [anchor, setAnchor] = useState(null);

  const onClickHandle = (action) => () => {
    setAnchor(null);
    if (action) action();
  };
  const onCloseHandle = () => setAnchor(null);
  const onOpenHandle = (ev) => setAnchor(ev.currentTarget);

  return (
    <>
      <IconButton
        aria-label={DefaultStrings.BUTTON_OVERFLOW}
        edge="end"
        color="inherit"
        onClick={onOpenHandle}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menuOverflow"
        anchorEl={anchor}
        keepMounted
        transformOrigin={{
          vertical: posY,
          horizontal: posX,
        }}
        open={!!anchor}
        onClose={onCloseHandle}
      >
        {options.map((option, idx) => (
          <MenuItem
            key={option.label}
            className={classes.menuItem}
            style={{
              color:
                option.color in menuItemColors
                  ? menuItemColors[option.color]
                  : menuItemColors.default,
            }}
            onClick={onClickHandle(option.onClick)}
            disabled={option.disabled}
          >
            {option.label}
            {options.length - 1 !== idx && (
              <div className={classes.bottomBorder}></div>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
