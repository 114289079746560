import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { useSnackbar } from "notistack";

import Spinner from "../ui/Spinner";

import { isUndefined } from "../utils/generalUtils";
import { addCookieBannerScript } from "../services/UiService";
import { redirectToAccounts, checkAuthStatus } from "../services/AuthService";
import { useCurrentOrganisation } from "services/UserService";

import {
  getProjectIdFromPathname,
  getOrganisationIdFromPathname,
} from "./index";

/**
 * props:
 *
 * user
 * location
 * authCheckFunc - function to authenticate this page
 * authFailPath - path to redirect to if failed authentication
 * authFailMessage - message to display (in toast) if failed authentication
 * redirectToOriginal - redirect to original page, instead of authFailPath. Only used by login page
 * skipCookieBanner - skip checking to show cookie banner
 */
const AuthRoute = (props) => {
  const {
    user,
    location,
    authCheckFunc,
    authFailPath,
    authFailMessage,
    redirectToOriginal,
    skipCookieBanner,
  } = props;
  const path = location.pathname; // location.pathname instead of path because path can be a list from router
  const from = location.state?.from;
  const initialLogged = isUndefined(location.state?.initialLogged)
    ? !!user.uid
    : location.state?.initialLogged;
  const emailVerified = user?.emailVerified;
  const [authResult, setAuthResult] = useState();
  const [checkingAuth, setCheckingAuth] = useState(true);
  const mounted = useRef(true);
  const selectedOrganisationId = useCurrentOrganisation();

  const projectId = getProjectIdFromPathname(path);
  const organisationId = getOrganisationIdFromPathname(path);
  const { enqueueSnackbar } = useSnackbar();
  const userReady = user.isLoaded;
  const orgId = organisationId || selectedOrganisationId;

  // check cookie banner
  useEffect(() => {
    if (!skipCookieBanner) addCookieBannerScript();
  }, [skipCookieBanner]);

  // check email verification
  useEffect(() => {
    if (emailVerified === false) redirectToAccounts();
  }, [emailVerified]);

  // check firebase auth
  useEffect(() => {
    if (!userReady) return;
    setCheckingAuth(true);
    checkAuthStatus()
      .catch(() => {})
      .finally(() => {
        if (mounted.current) setCheckingAuth(false);
      });
    return () => {
      mounted.current = false;
    };
  }, [userReady]);

  // useEffect for auth check
  useEffect(() => {
    const updateAuthPass = async () => {
      // do nothing until user is loaded
      if (!userReady) return;

      // skip if still checking auth
      if (checkingAuth) return;

      // check if the user is authorized
      const checkId = orgId && !projectId ? orgId : projectId;
      const authPassNew = await authCheckFunc(user.uid, checkId);

      // skip if path and result are still the same
      if (authResult?.pass === authPassNew && path === authResult?.path) return;

      // show fail message if
      if (
        !authPassNew && // currently failed
        authFailMessage // message defined
      ) {
        enqueueSnackbar(authFailMessage, { variant: "warning" });
      }

      // store the auth result so we know when it changes
      setAuthResult({
        pass: authPassNew,
        path: path,
      });
    };

    updateAuthPass();
  }, [
    user,
    projectId,
    authCheckFunc,
    path,
    authResult,
    enqueueSnackbar,
    authFailMessage,
    checkingAuth,
    userReady,
    location.pathname,
    orgId,
  ]);

  // checking auth
  if (checkingAuth) return <Spinner />;

  // if path has changed, wait until we check auth again
  // because render() will be called before userEffect()
  if (authResult?.path !== path) return <Spinner />;

  // if failed authentication
  if (!authResult?.pass) {
    // if redirectToOriginal + not initially logged, then use the original path (from)
    // otherwise use authFailPath
    const redirectPath =
      redirectToOriginal && !initialLogged && from ? from : authFailPath;

    const state = redirectToOriginal
      ? undefined // clear the state if redirectToOriginal
      : {
          redirect: true,
          from: from ?? path, // keep track of from, or use path (first time)
          initialLogged,
        };
    // location.state persists after reload, use with caution
    console.debug(`Redirecting to ${redirectPath} from ${from}, path=${path}`);
    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state,
        }}
      />
    );
  }

  // passed authentication

  // check email verified
  if (emailVerified === false) return <></>;

  if (!props.location.state) props.location.state = {};
  props.location.state.initialLogged = initialLogged;

  return <Route {...props} />;
};

// connect firebase.auth as user
export default connect(({ firebase: { auth } }) => ({ user: auth }))(AuthRoute);
