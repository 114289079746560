import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import PermMediaIcon from "@material-ui/icons/PermMedia";

import {
  useProjectMediaSize,
  useProjectInfo,
  useProjectResolution,
  useProjectDraftExist,
} from "../../services/ProjectService";

import Spinner from "../Spinner";
import { EmptyData } from "ui/emptyData";

import { ProjectStrings } from "../../strings";

import { PublicMediaFrame, MediaFrame } from ".";

const MAX_FRAME_HEIGHT_RATIO = 2 / 3; // max ratio of innerHeight

const getScale = (height, zoom) =>
  zoom *
  (Math.round(Math.min(MAX_FRAME_HEIGHT_RATIO * window.innerHeight, height)) /
    height);

const getSizeWithPadding = (size, padding) =>
  parseInt(size) + parseInt(2 * padding + 4); // + 4 to address for iframe default border

const useStyles = makeStyles((theme) => ({
  frame: ({ width, height, scale }) => {
    const padding = Math.round(theme.spacing(4) / scale);
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      backgroundColor: "#FFFFFF",
      borderRadius: 20,
      width: getSizeWithPadding(width, padding),
      height: getSizeWithPadding(height, padding),
      padding,
      transform: `translate(-50%, -50%) scale(${scale})`,
      transition: "all 0.3s ease",
    };
  },
  frameContent: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  placeholder: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  contentScale: ({ scale }) => ({
    height: "100%",
    transform: `scale(${1 / scale})`,
  }),
}));

const MediaContainer = ({
  projectId,
  mediaLink,
  width,
  height,
  zoom,
  updated,
  draftId,
  liveDraftId,
}) => {
  const scale = getScale(height, zoom);
  const classes = useStyles({ width, height, scale });
  const mediaExist = useProjectDraftExist({
    projectId,
    draftName: draftId,
  });

  let content;
  if (projectId) {
    // if reading from a project
    if (typeof mediaExist !== "boolean") return <Spinner />;

    content = mediaExist ? (
      <MediaFrame
        projectId={projectId}
        updated={updated}
        draftId={draftId}
        liveDraftId={liveDraftId}
      />
    ) : (
      <div className={classes.placeholder}>
        <div className={classes.contentScale}>
          <EmptyData
            title={ProjectStrings.MEDIA_PROJECT_CONTENT_NO_CONTENT_TITLE}
            description={ProjectStrings.MEDIA_PROJECT_CONTENT_NO_CONTENT_DESC}
            // TODO: add an action to add a new media "actionTitle", "onClick"
            icon={<PermMediaIcon />}
          />
        </div>
      </div>
    );
  } else if (mediaLink) {
    // if reading from a media link
    if (mediaLink.link) {
      content = <PublicMediaFrame mediaLink={mediaLink} />;
    } else {
      console.warn(`Media link doesn't exist (${mediaLink})`);
    }
  }

  return (
    <Card className={classes.frame}>
      <div className={classes.frameContent}>{content}</div>
    </Card>
  );
};

// Card for project media with built-in white rounded frame
// either projectId or mediaLink should be provided
const MediaCard = ({ projectId, zoom, mediaLink, draftId, draftName }) => {
  const info = useProjectInfo({ projectId });
  const sizeKey = useProjectResolution(projectId);
  const size = useProjectMediaSize({ key: sizeKey });

  const width = info && size ? size.width : mediaLink ? mediaLink.width : null;
  const height =
    info && size ? size.height : mediaLink ? mediaLink.height : null;
  if (!width || !height) return <></>;

  return (
    <MediaContainer
      projectId={projectId}
      mediaLink={mediaLink}
      width={width}
      height={height}
      zoom={zoom}
      updated={info?.mediaLastUpdated}
      draftId={draftId}
      draftName={draftName}
      liveDraftId={info?.mediaDraftVersion}
    />
  );
};

export default MediaCard;
