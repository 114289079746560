import React from "react";
import { Typography } from "@material-ui/core";

const MultilineText = ({ text }) => {
  return (
    <>
      <Typography>{text.title}</Typography>
      <ul style={{ padding: "0 18px" }}>
        {text.list.map((item) => (
          <li key={item.key}>
            <Typography>
              <span style={{ marginRight: 8, fontWeight: 700 }}>
                {item.key}
              </span>
              <span>{item.value}</span>
            </Typography>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MultilineText;
