import React from "react";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

import MyCard from "./MyCard";
import { OrganisationStrings } from "strings";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#eaeaea",
    color: theme.palette.common.black,
    maxWidth: "310px",
  },
}));

const ConnectedDomainsCard = ({ connectedDomains, canRead }) => {
  const classes = useStyles();

  const configCard = {
    icon: <DnsOutlinedIcon />,
    title: OrganisationStrings.SETTINGS_CONNECTED_DOMAINS_TITLE,
    info: OrganisationStrings.SETTINGS_CONNECTED_DOMAINS_INFO,
  };

  return (
    <MyCard config={configCard} canRead={canRead}>
      {connectedDomains?.map((domain) => (
        <Chip key={domain} label={domain} className={classes.chip}></Chip>
      ))}
    </MyCard>
  );
};

export default ConnectedDomainsCard;
