import React from "react";
// https://www.chartjs.org/docs/3.3.0/getting-started/integration.html#bundlers-webpack-rollup-etc
import 'chart.js/auto';
import { Line } from "react-chartjs-2";

const LinePlot = ({ data, options }) => {
  return <Line data={data} options={options} />;
};

export default LinePlot;
