import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  useDeviceDeploying,
  useDeviceDeployLastStage,
  useDeviceLastDeployStart,
} from "../../hooks/deviceHooks";
import {
  getLastAllStageText,
  getLastContentStageText,
  getLastAppStageText,
} from "../../utils/deployUtils";
import { useGlobalApps } from "../../services/AppService";

import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

import { DeployStrings, DeviceStrings } from "../../strings";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      padding: 0,
    },
    row: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    progress: {
      marginBottom: theme.spacing(3),
    },
  };
});

const ProgressBar = ({ deploying }) =>
  deploying ? (
    <LinearProgress />
  ) : (
    <LinearProgress variant="determinate" value={100} />
  );

const DeploymentDialog = ({ deviceId, projectId, started, open, onClose }) => {
  const deploying = useDeviceDeploying(deviceId);
  const deployStart = useDeviceLastDeployStart(deviceId);
  const stage = useDeviceDeployLastStage(deviceId);
  // global apps to map from id to name
  const appInfos = useGlobalApps();
  const classes = useStyles();

  if (typeof deploying === "undefined") return <></>;

  // show as deploying:
  // 1) parent started deploy
  // 2) actually deploying
  const showDeploying = started || deploying;

  // waiting to deploy = parent started but device not started yet
  const waitingDeploy = started && !deploying;

  // no previous deploy update
  const noDeployRecord = deploying === null;

  // last stage is valid only if timestamp is after deploy start
  const stageValid =
    stage && deployStart && stage.timestamp * 1000 > deployStart;

  // show content and app stages only if:
  // 1) not showing deploying, or
  // 2) stage is valid and not waiting to deploy
  const showStage = !showDeploying || (stageValid && !waitingDeploy);

  const progress = stage && stage.project_progress;
  const allText = waitingDeploy
    ? DeployStrings.DEPLOY_WAITING
    : noDeployRecord
    ? DeployStrings.DEPLOY_NO_RECORD
    : !showDeploying
    ? DeployStrings.DEPLOY_COMPLETED
    : !showStage
    ? DeployStrings.DEPLOY_STARTED
    : getLastAllStageText(progress, appInfos);
  const contentText = showStage && getLastContentStageText(progress);
  const appText = showStage && getLastAppStageText(progress, appInfos);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle className={classes.title} disableTypography>
        <Box pr={2}>
          <CloudDownloadOutlinedIcon />
        </Box>
        <Typography variant="caption">
          {DeviceStrings.DEPLOY_PROGRESS_TITLE}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.row}>{allText}</div>
        <div className={classes.progress}>
          <ProgressBar deploying={showDeploying} />
        </div>

        {contentText && (
          <>
            <div className={classes.row}>{contentText}</div>
            <div className={classes.progress}>
              <ProgressBar deploying={showDeploying} />
            </div>
          </>
        )}

        {appText && (
          <>
            <div className={classes.row}>{appText}</div>
            <div className={classes.progress}>
              <ProgressBar deploying={showDeploying} />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DeploymentDialog;
