import React from "react";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import Box from "@material-ui/core/Box";

import { useOrganisationInfo } from "services/OrganisationService";
import { Image, MenuItemStyled, Title, Border } from "./styles";

export const OrganisationItem = ({ organisationId, selectedId, onSelect }) => {
  const organisationInfo = useOrganisationInfo(organisationId);

  const onSelectMostResent = () => {
    onSelect(organisationInfo);
  };

  if (
    !organisationInfo ||
    selectedId === organisationId ||
    organisationInfo.deleted
  )
    return null;

  return (
    <MenuItemStyled
      key={organisationId}
      value={organisationId}
      selected={selectedId === organisationId}
      onClick={onSelectMostResent}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Image
          src={organisationInfo.imageURL}
          alt={`organisation-${organisationId}`}
        />
        <Title>{organisationInfo.name}</Title>
      </Box>
      <ArrowForwardIosOutlinedIcon />
      <Border />
    </MenuItemStyled>
  );
};
