import React, { useState, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import MyCard from "./MyCard";
import { LockIcon } from "assets/icons";
import SettingsDialog from "ui/dialogs/SettingsDialog";

const SettingsCard = ({
  title,
  selectTitle,
  description,
  info,
  icon,
  value = "",
  locked,
  errorMessage,
  options,
  canRead,
  canEdit,
  hideLockedSwitch,
  onOk,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [showDialog, setShowDialog] = useState(false);
  const [updateValue, setUpdateValue] = useState(value);
  const [lockedValue, setLockedValue] = useState(!!locked);
  const [progress, setProgress] = useState(false);

  const valueTitle = useMemo(() => {
    if (!value || !options) return "";

    const idx = options.findIndex((opt) => opt.value === value);

    if (idx === -1) return "Not set";

    return options[idx].title;
  }, [options, value]);

  const isDisableOk = useMemo(
    () =>
      !canEdit || // disable if no permissions to edit
      (locked && hideLockedSwitch) || // disable if locked is true and lock switch is hidden, e.g. used on the project settings (Manage access tab)
      (updateValue === value && lockedValue === locked), // disable if value and locked are not changed

    [canEdit, hideLockedSwitch, locked, lockedValue, updateValue, value]
  );

  useEffect(() => {
    setUpdateValue(value);
  }, [value]);

  useEffect(() => {
    setLockedValue(!!locked);
  }, [locked]);

  const configDialog = {
    config: {
      selectTitle: selectTitle,
      description: description,
      options: options,
      canEdit: canEdit,
      hideLockedSwitch,
      onSelectChange: (event) => setUpdateValue(event.target.value),
      onSwitchChange: () => setLockedValue(!lockedValue),
    },
    dialog: {
      icon,
      title,
      disableOk: isDisableOk,
      onClose: () => {
        setShowDialog(false);
        setUpdateValue(value);
        setLockedValue(locked);
      },
      onOk: () => {
        setProgress(true);
        setShowDialog(false);
        onOk(updateValue, lockedValue)
          .then(() => setProgress(false))
          .catch((err) => {
            setProgress(false);
            setUpdateValue(value);
            setLockedValue(locked);
            enqueueSnackbar(errorMessage, {
              variant: "error",
            });
          });
      },
    },
  };

  const configCard = {
    icon,
    title,
    info,
    onClick: () => setShowDialog(true),
    progress,
  };

  if (typeof canRead === "undefined" || typeof canEdit === "undefined")
    return null;

  return (
    <>
      <MyCard config={configCard} canRead={canRead} canEdit={canEdit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "6px 0",
            color: locked ? "rgba(0, 0, 0, 0.38)" : "#747474",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography>{valueTitle}</Typography>
            {locked && (
              <Box sx={{ marginLeft: "12px" }}>
                <LockIcon style={{ width: " 16px", height: "16px" }} />
              </Box>
            )}
          </Box>
        </Box>
      </MyCard>
      <SettingsDialog
        open={showDialog}
        config={configDialog}
        value={updateValue}
        locked={lockedValue}
      />
    </>
  );
};

export default SettingsCard;
