const STRINGS = {
  TEAM_CODE_NO_SHARE_CODES: "No share codes",
  TEAM_CODE_MANAGE: "Manage Access",
  TEAM_CODE_SHARE_CODE_TITLE: "Share Codes",
  TEAM_CODE_SETTINGS_TITLE: "Settings",
  TEAM_CODE_AWIATING_APPROVAL_TITLE: "Awaiting Approval",
  TEAM_CODE_ADD_BUTTON: "Add A New Share Code",
  TEAM_CODE_ADD_DESC: "Team members will have access to your project",
  TEAM_CODE_GENERATE_BUTTON: "Generate",
  TEAM_CODE_GENERATE_DESC: "Generate a new share code for the project",
  TEAM_CODE_MENU_DELETE: "Delete share code",
  TEAM_CODE_DELETED: "Share Code Deleted",
  TEAM_CODE_GENERATED: "Share Code Generated",
  TEAM_CODE_SHARE: "Share Code",
  TEAM_CODE_SHARE_DESC: "Generate a new share code for the project",
  TEAM_CODE_NAME: "Name",
  TEAM_CODE_DEFAULT_ROLE: "Default Role",
  TEMA_CODE_CARD_LEGACY_CODE: "(Legacy Code)",
  TEMA_CODE_CARD_DEFAULT_ROLE: "Default Role: ",
  TEAM_CODE_CARD_COPY_CODE: "Copy Code",
  TEAM_CODE_CARD_COPY_LINK: "Copy Link",
  TEAM_CODE_COPIED: "Code copied",
  TEAM_CODE_LINK_COPIED: "Link copied",
  TEAM_CODE_CREATOR: "Creator",
  TEAM_CODE_TEAM: "Team:",
  TEAM_CODE_DELETE_CONFIRM_TITLE: "Are you sure you want to delete this?",
  TEAM_CODE_PROJECT_DELETE_CONFIRM_DESC:
    "All members in this share code will be removed from this project!",
  TEAM_CODE_CARD_CAPTION_COPY: "Copy",
  TEAM_CODE_CARD_CAPTION_MANAGE: "Manage",
  TEAM_MEMBER_REMOVE_CONFIRM_TITLE: `Remove "{name}"`,
  TEAM_MEMBER_REMOVE_CONFIRM_DESC: `Are you sure you want to delete "{name}" from your project?`,

  TEAM_MEMBER_REMOVED: "Team member removed",
  REMOVE_TEAM_MEMBER: "Remove User",
  UPDATE_TEAM_MEMBER_ROLE_TITLE: "User role",
  UPDATE_TEAM_MEMBER_ROLE: "User role updated",

  GUEST_ACCESS_TITLE: "Guest Access",
  GUEST_ACCESS_SELECT_TITLE: "Access",
  GUEST_ACCESS_SELECT_DESC:
    "Choose if the project can be accessed by external guests.",
  GUEST_ACCESS_INFO:
    "Guest Access controls whether external guests can access your project",
  GUEST_ACCESS_UPDATE_ERROR: "Something went wrong with updating guest access",

  PROJECT_ACCESS_TITLE: "Project Access",
  PROJECT_ACCESS_SELECT_TITLE: "Access",
  PROJECT_ACCESS_SELECT_DESC: {
    title: "Choose how the project can be joined:",
    list: [
      {
        key: "Private:",
        value: "Projects require a share code to join.",
      },
      {
        key: "Open:",
        value:
          "Project is available for all users in your organisation to join.",
      },
    ],
  },
  PROJECT_ACCESS_INFO: "Project Access controls how project can be joined",
  PROJECT_ACCESS_UPDATE_ERROR:
    "Something went wrong with updating project access",

  PROJECT_JOIN_APPROVAL_TITLE: "Project Join Approval",
  PROJECT_JOIN_APPROVAL_SELECT_TITLE: "Method",
  PROJECT_JOIN_APPROVAL_SELECT_DESC: {
    title: "Select the default method for joining the project:",
    list: [
      {
        key: "Auto:",
        value: "Users can access the project immediately upon joining.",
      },
      {
        key: "Required:",
        value:
          "Users will be added to a list for approval before they can access the project.",
      },
    ],
  },
  PROJECT_JOIN_APPROVAL_INFO:
    "Project Join Approval determines whether users can access project immediately upon joining or if they need to be approved first",
  PROJECT_JOIN_APPROVAL_UPDATE_ERROR:
    "Something went wrong with updating project approval",

  PROJECT_DEFAULT_ROLE_TITLE: "Default Project Role",
  PROJECT_DEFAULT_ROLE_SELECT_TITLE: "Role",
  PROJECT_DEFAULT_ROLE_SELECT_DESC: {
    title:
      "Choose the default role assigned to users when they join the project:",
    list: [
      {
        key: "Owner:",
        value:
          "Has full control over the project, including all settings and management tasks.",
      },
      {
        key: "Editor:",
        value:
          "Can manage devices, media, and analytics, but does not have full administrative control.",
      },
      {
        key: "Viewer:",
        value: "Can only view media and analytics without making any changes.",
      },
    ],
  },
  PROJECT_DEFAULT_ROLE_INFO:
    "The Default Project Role determines role assigned to users when they join project",
  PROJECT_USER_ROLE: "User Project Role",
  PROJECT_DEFAULT_ROLE_UPDATE_ERROR:
    "Something went wrong with updating default role",
  TEAM_AWAITING_APPROVAL: "Awaiting approval",
  TEAM_AWAITING_APPROVAL_INFO:
    "These users are awaiting approval to join the project",
  TEAM_AWAITING_APPROVAL_APPROVE_ALL: "Approve All",
  TEAM_AWAITING_APPROVAL_REJECT_ALL: "Reject All",
  TEAM_AWAITING_APPROVAL_MANAGE_BUTTON: "Manage",
  TEAM_AWAITING_APPROVAL_IS_AWAITING_APPROVAL: "is awaiting approval",
  TEAM_AWAITING_APPROVAL_ARE_AWAITING_APPROVAL: "are awaiting approval",
  TEAM_AWAITING_APPROVAL_NO_USERS: "No users awaiting approval",
  TEAM_AWAITING_APPROVAL_NO_FOR_SEARCH: "No users awaiting approval found",
};

export default STRINGS;
