import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CompositeDecorator,
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";

import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import InsertCommentOutlinedIcon from "@material-ui/icons/InsertCommentOutlined";

import MyCard from "./MyCard";
import MyDialog from "../MyDialog";
import { RichEditor, RichLink } from "../draftjs/RichEditor";

import { findLinkEntities, createEditorState } from "../../utils/draftJsUtils";
import { isUndefined } from "../../utils/generalUtils";

import { DefaultStrings } from "../../strings/";
import { useRef } from "react";

const OVERFLOW_ID = "noteCard";
const MAX_LENGTH = 500;

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  note: {
    whiteSpace: "pre-line",
  },
  editor: {
    minHeight: 200,
    background: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 7,
  },
}));

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: RichLink,
  },
]);

const NoteCard = ({ defaultNote = "", onSave = null, canRead, canEdit }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [editorState, setEditorState] = useState();
  const initialEditorState = createEditorState(defaultNote, decorator);
  const editorRef = useRef(null);
  const [progress, setProgress] = useState(false);

  const currentState = isUndefined(editorState)
    ? initialEditorState
    : editorState;

  const onInlineStyleClick = (e, style) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(currentState, style));
  };

  // reference: https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/link/link.html
  const onInsertLinkClick = () => {
    const selection = currentState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = currentState.getCurrentContent();
      const startKey = selection.getStartKey();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      // since we only have one type of entity (Link)
      const hasLink = !!blockWithLinkAtBeginning.getEntityAt(0);

      if (hasLink) {
        // unset link
        setEditorState(RichUtils.toggleLink(currentState, selection, null));
      } else {
        // construct link
        const url = blockWithLinkAtBeginning.getText();
        const contentStateWithEntity = contentState.createEntity(
          "LINK",
          "MUTABLE",
          { url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(currentState, {
          currentContent: contentStateWithEntity,
        });
        setEditorState(
          RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
          )
        );
      }
    }
  };

  const onClickEditor = () => {
    if (editorRef.current) editorRef.current.focus();
  };

  const configCard = {
    icon: <InsertCommentOutlinedIcon />,
    title: DefaultStrings.CARD_NOTES_TITLE,
    overflowId: OVERFLOW_ID,
    progress,
    fullHeight: true,
    onClick: (e) => {
      // only show dialog if not click on an anchor tag
      if (e.target.parentNode.parentNode.tagName !== "A") setShowDialog(true);
    },
  };

  const configDialog = {
    icon: <InsertCommentOutlinedIcon />,
    title: DefaultStrings.CARD_NOTES_TITLE,
    onClose: () => {
      setShowDialog(false);
      setEditorState(undefined); // clear editor
    },
    onOk: () => {
      setShowDialog(false);
      setProgress(true);
      const rawContent = JSON.stringify(
        convertToRaw(currentState.getCurrentContent())
      );
      if (onSave)
        onSave(rawContent).finally(() => {
          setProgress(false);
        });
    },
    disableOk: false,
    actions: [
      {
        title: DefaultStrings.NOTE_BOLD,
        icon: <FormatBoldIcon />,
        onClick: (e) => onInlineStyleClick(e, "BOLD"),
        active: currentState?.getCurrentInlineStyle().has("BOLD"),
      },
      {
        title: DefaultStrings.NOTE_UNDERLINE,
        icon: <FormatUnderlinedIcon />,
        onClick: (e) => onInlineStyleClick(e, "UNDERLINE"),
        active: currentState?.getCurrentInlineStyle().has("UNDERLINE"),
      },
      {
        title: DefaultStrings.NOTE_ITALIC,
        icon: <FormatItalicIcon />,
        onClick: (e) => onInlineStyleClick(e, "ITALIC"),
        active: currentState?.getCurrentInlineStyle().has("ITALIC"),
      },
      {
        title: DefaultStrings.NOTE_LINK,
        icon: <InsertLinkIcon />,
        onClick: onInsertLinkClick,
      },
    ],
  };

  return (
    <>
      <MyCard config={configCard} canRead={canRead} canEdit={canEdit}>
        <div id={OVERFLOW_ID}>
          <Editor editorState={initialEditorState} readOnly={true} />
        </div>
      </MyCard>
      <MyDialog open={showDialog} config={configDialog}>
        <div className={classes.editor} onClick={onClickEditor}>
          <RichEditor
            editorState={currentState}
            setEditorState={setEditorState}
            maxLength={MAX_LENGTH}
            ref={editorRef}
          />
        </div>
      </MyDialog>
    </>
  );
};

export default NoteCard;
