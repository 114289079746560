import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import RestrictedContent from "../RestrictedContent";

import { useMobileLayout } from "../../hooks/uiHooks";

/**
 * sample config
 */
// const config = {
//   icon: <AppsOutlinedIcon />,
//   title: "Default Apps",
//   desc: "Essential apps required to keep MyPlayer platform running smoothly.",
//   buttonLabel: "Edit Apps",
//   onClick: () => {
//     console.debug(ProjectStrings.MEDIA_EDIT_TITLE);
//   },
//   fullHeight: true,
//   autoExpand: true,
//   image: "imageURL",
//   progress: true,
//   disableButton: false,
//   sidePanel: <Component />,
// };

const useStyles = makeStyles((theme) => ({
  root: ({ autoExpand }) => ({
    height: autoExpand ? "100%" : "auto",
    width: autoExpand ? "100%" : "auto",
    display: "flex",
  }),
  cardContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  container: {
    flexGrow: 1,
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  media: {
    flexGrow: 100,
    minHeight: 150,
  },
  title: {
    "&": {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(3),
      "& .MuiCardHeader-content": {
        marginRight: theme.spacing(1),
      },
    },
  },
  content: ({ fullHeight, mobile }) => ({
    paddingTop: 0,
    ...(!fullHeight && !mobile && { height: 1 }),
    flexGrow: 1,
    position: "relative",
    overflowY: "auto",
  }),
  app: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  button: ({ mobile }) => ({
    width: mobile ? "auto" : "168px",
    whiteSpace: mobile ? "auto" : "nowrap",
  }),
  icon: {
    boxSizing: "border-box",
    margin: 4,
    width: 36,
    height: 36,
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
  sidePanel: ({ showSidePanel }) => ({
    minWidth: showSidePanel ? "30%" : 0,
    maxWidth: showSidePanel ? "30%" : 0,
    transition: "all .5s ease",
    borderLeft: showSidePanel ? `solid 1px ${theme.palette.border}` : 0,
    margin: `${theme.spacing(2)}px 0px`,
    padding: `0px ${theme.spacing(showSidePanel ? 2 : 0)}px`,
  }),
}));

// canRead must be given
// canRead = undefined => loading spinner
// canRead = false => restricted content
// canRead = true => normal content
const ButtonCard = ({
  children,
  config: {
    icon,
    title,
    desc,
    buttonLabel,
    onClick,
    fullHeight,
    autoExpand = true,
    image,
    progress,
    disableButton,
    sidePanel,
  },
  canRead,
}) => {
  const [showSidePanel, setShowSidePanel] = useState(false);

  const mobile = useMobileLayout();
  const classes = useStyles({ fullHeight, mobile, autoExpand, showSidePanel });

  const onClickIcon = () => {
    setShowSidePanel(!showSidePanel);
  };

  const media = image && <CardMedia className={classes.media} image={image} />;
  const button = buttonLabel && (
    <Button
      variant="contained"
      className={classes.button}
      onClick={onClick}
      disabled={disableButton || progress}
    >
      {buttonLabel}
    </Button>
  );
  const descContent = desc && (
    <Box pb={2}>
      <Typography variant="body2">{desc}</Typography>
    </Box>
  );
  const progressContent = progress && <LinearProgress />;
  const sidePanelContent = sidePanel && (
    <>
      <div style={{ paddingTop: 16 }}>
        <div className={classes.icon} onClick={onClickIcon}>
          <span className="material-symbols-outlined">
            {showSidePanel ? "arrow_right" : "arrow_left"}
          </span>
        </div>
      </div>
      <div className={classes.sidePanel}>{sidePanel}</div>
    </>
  );

  return (
    <Card className={classes.root}>
      <div className={classes.cardContainer}>
        {progressContent}
        <div className={classes.container}>
          {canRead && media}
          <CardHeader
            title={title}
            action={button}
            avatar={icon}
            titleTypographyProps={{ variant: "caption" }}
            className={classes.title}
          />
          <CardContent className={classes.content}>
            <RestrictedContent permitted={canRead} text={false}>
              {descContent}
              {children}
            </RestrictedContent>
          </CardContent>
        </div>
      </div>
      {sidePanelContent}
    </Card>
  );
};

export default ButtonCard;
