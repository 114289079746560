import React from "react";
import {
  Box,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {useGlobalApps} from "../services/AppService";
import {copyAppsInfo} from "../utils/appsUtils";

import Spinner from "./Spinner";

import {
  getAppAltVersions,
  getAppDisplayName,
  getAppVersionName,
  getAppName,
  getAppPackageName,
  getAppVersion,
  getAppIconUrl,
  isAppDefault,
  isAppSelected,
  setAppVersion,
  setAppSelected,
} from "../utils/appsUtils";

const useStyles = makeStyles((theme) => {
  return {
    app: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    width: {
      maxWidth : 275
    },
    name: {
      flexGrow: 1,
    },
  };
});

const AppsList = ({apps, defaultOnly, edit}) => {
  const classes = useStyles();
  // get all apps for the info
  const globalApps = useGlobalApps(defaultOnly);

  if (typeof apps === "undefined" || !globalApps) {
    return <Spinner />;
  }

  // always use apps info from global
  const projectApps = copyAppsInfo(apps, globalApps);

  const isEditing = edit && edit.appState && edit.setAppState;

  // get current app state
  // 1st from edit.appState
  // 2nd from default "apps"
  // 3rd empty dict
  const getCurrentAppState = (app, useDefault = false) => {
    const pkgName = getAppPackageName(app);
    return pkgName in edit.appState
      ? edit.appState[pkgName]
      : useDefault
      ? apps[pkgName]
      : {};
  };

  const onSelectAppVer = (app, ver) => {
    onSelectApp(app, ver, true);
  };

  const onSelectApp = (app, ver, selected) => {
    const pkgName = getAppPackageName(app);
    const appVer = getAppVersion(app);
    const currentState = getCurrentAppState(app);

    // remember the original "selected" state
    if ("original" in currentState === false) {
      currentState.original = !!isAppSelected(app);
    }

    const original = currentState.original;
    if (original === selected && appVer === ver) {
      // new state = original
      // removed app from state (no change)
      const newState = {...edit.appState};
      delete newState[pkgName];
      edit.setAppState(newState);
    } else {
      setAppVersion(currentState, ver);
      setAppSelected(currentState, selected);

      // merged updated app with app state
      edit.setAppState({
        ...edit.appState,
        ...{[pkgName]: currentState},
      });
    }
  };

  const NameContent = ({app}) => {
    const altVersions = getAppAltVersions(app);
    if (isEditing && altVersions) {
      // edit mode

      const currentState = getCurrentAppState(app, true);
      const currentVer = getAppVersion(currentState);
      return (
        <Select
          value={currentVer}
          className={classes.width}
          onChange={(e) => onSelectAppVer(app, e.target.value)}
        >
          {altVersions.map((ver) => {
            const versionName = getAppVersionName(app, ver);
            return (
              <MenuItem key={versionName} value={ver}>
                {versionName}
              </MenuItem>
            );
          })}
        </Select>
      );
    } else {
      // read mode

      return <Typography variant="body2">{getAppDisplayName(app)}</Typography>;
    }
  };

  const AppRadio = ({app}) => {
    // return nothing if not editing or default app
    if (!isEditing || isAppDefault(app)) return null;

    const ver = getAppVersion(app);
    const currentState = getCurrentAppState(app, true);
    const currentSelected = isAppSelected(currentState);
    return (
      <Radio
        checked={currentSelected}
        color="primary"
        onClick={(e) => onSelectApp(app, ver, !currentSelected)}
      />
    );
  };

  return (
    <>
      {Object.entries(projectApps)
        .sort((a, b) => isAppSelected(b[1]) - isAppSelected(a[1]))
        .map(([id, app]) => {
          const appName = getAppName(app);
          return (
            <Box key={id} className={classes.app}>
              <Box p={1}>
                <img
                  alt={appName}
                  key={appName}
                  src={getAppIconUrl(app)}
                  width="30"
                />
              </Box>
              <Box pl={2} className={classes.name}>
                <NameContent app={app} />
              </Box>
              <AppRadio app={app} />
            </Box>
          );
        })}
    </>
  );
};

export default AppsList;
