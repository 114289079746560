import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../../hooks/uiHooks";

import ProjectCard from "../cards/projectCard/ProjectCard";
import Spinner from "../Spinner";

const useStyles = makeStyles((theme) => {
  return {
    dialog: (mobile) => ({
      width: mobile ? "100%" : "auto",
      height: mobile ? "100%" : "50%",
      minWidth: 300,
      maxWidth: mobile ? "unset" : "340px",
      minHeight: 480,
      maxHeight: "unset,",
    }),
    cardContainer: {
      position: "relative",
      flexGrow: 1,
      height: 1,
    },
    actions: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(2),
    },
  };
});

/**
 * sample config
 */
// const config = {
//   info: {},
//   onClose: () => {},
// }

// a change of info will trigger open
const ProjectPreviewDialog = ({
  children,
  config: { info, onClose, loading = false, progress },
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!info || loading);
  }, [info, loading]);

  if (!info && !loading) return null;

  const config = info && {
    info,
    showBrand: !!info.brand,
    showDescription: !!info.desc,
    disableShadow: true,
    loading: progress,
  };

  const content = loading ? (
    <Spinner />
  ) : (
    <>
      <div className={classes.cardContainer}>
        <ProjectCard projectId={info.projectId} config={config} />
      </div>
      <div className={classes.actions}>{children}</div>
    </>
  );

  return (
    <Dialog
      fullWidth
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: { root: classes.dialog },
      }}
    >
      {content}
    </Dialog>
  );
};

export default ProjectPreviewDialog;
