import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  action: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  media: {
    flexGrow: 8,
    height: 1,
  },
  content: {
    flexGrow: 3,
    height: 1,
  },
  emptyMedia: {
    width: "100%",
    fontSize: 80,
    fontWeight: 900,
  },
  name: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 600,
  },
  permission: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#777777",
  },
}));
