import React, {useCallback, useState} from "react";
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {debounce} from "../../services/UiService";
import {
  remoteRefresh,
  remoteKeyboardInput,
  remoteBackspace,
} from "../../services/DeviceService";

import ButtonCard from "../cards/ButtonCard";
import RemoteLog from "../remote/RemoteLog";

import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";

import {DeviceStrings} from "../../strings";

const useStyles = makeStyles((theme) => ({
  inputMain: {
    padding: 24,
    height: "100%",
  },
  inputText: {
    position: "relative",
  },
  inputBackspace: ({disabled}) => ({
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    padding: 16,
    display: "flex",
    cursor: disabled ? "auto" : "pointer",
  }),
}));

const RemotePanelInput = ({
  projectId,
  deviceId,
  disabled,
  onStart,
  onComplete,
}) => {
  const classes = useStyles({disabled});

  const [keyboardInput, setKeyboardInput] = useState("");

  const refresh = () => remoteRefresh({projectId, deviceId});

  // debounce won't work without useCallback()
  const onText = useCallback(
    debounce((value) => {
      if (disabled) return;

      onStart();
      remoteKeyboardInput({projectId, deviceId, text: value})
        .then(() => {
          setKeyboardInput("");
          refresh();
        })
        .catch(() => {
          // stop
          onComplete();
        });
    }, 1000),
    []
  );
  const onChangeInput = (e) => {
    const text = e.target.value;
    setKeyboardInput(text);
    onText(text);
  };

  const onBackspace = () => {
    if (disabled) return;

    onStart();
    remoteBackspace({projectId, deviceId})
      .then(() => {
        setKeyboardInput("");
        refresh();
      })
      .catch(() => {
        // stop
        onComplete();
      });
  };

  return (
    <div className={classes.inputMain}>
      <ButtonCard
        config={{
          icon: <TextFieldsOutlinedIcon />,
          title: DeviceStrings.REMOTE_PANEL_INPUT_TITLE,
          fullHeight: true,
          desc: DeviceStrings.REMOTE_PANEL_INPUT_DESC,
          sidePanel: <RemoteLog deviceId={deviceId} />,
        }}
        canRead={true}
      >
        <div className={classes.inputText}>
          <TextField
            fullWidth
            disabled={disabled}
            label={DeviceStrings.REMOTE_PANEL_INPUT_TEXT_LABEL}
            value={keyboardInput}
            onChange={onChangeInput}
          />
          <div className={classes.inputBackspace} onClick={onBackspace}>
            <span className="material-symbols-outlined">backspace</span>
          </div>
        </div>
      </ButtonCard>
    </div>
  );
};

export default RemotePanelInput;
