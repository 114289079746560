import { useEffect } from "react";

import { useDocument, useCollectionNoStore } from "./FirestoreService";
import {
  useUserName,
  useCurrentUserId,
  useUserNewsLastRead,
} from "./UserService";
import { isUndefined } from "../utils/generalUtils";

import { EventStrings, EventSelfStrings, EventYouString } from "../strings";
import * as routes from "../route";

/**
 * EVENT_TYPES should align with cloud functions
 * To add a new event type:
 * 1. add new entry in EVENT_TYPES
 * 1. add same entry in EVENT_GROUPBYS
 * 1. add same entry in strings/events.STRINGS
 * 1. add same entry in strings/events.SELF_STRINGS
 * 1. if event type has a link, add same entry in redirect()
 */
export const EVENT_TYPES = {
  PROJECT_NEW: "PROJECT_NEW", // done
  PROJECT_JOIN: "PROJECT_JOIN", // done
  PROJECT_LEAVE: "PROJECT_LEAVE", // done
  PROJECT_TRANSFER: "PROJECT_TRANSFER", // done

  PROJECT_HARDWARE_UPDATE: "PROJECT_HARDWARE_UPDATE", // done
  PROJECT_NOTE_UPDATE: "PROJECT_NOTE_UPDATE", // done
  PROJECT_STATUS_UPDATE: "PROJECT_STATUS_UPDATE", // done
  PROJECT_INFO_UPDATE: "PROJECT_INFO_UPDATE", // done
  PROJECT_QR_SETUP_UPDATE: "PROJECT_QR_SETUP_UPDATE", // done

  PROJECT_MEDIA_UPLOAD: "PROJECT_MEDIA_UPLOAD", // done
  PROJECT_MEDIA_APPROVE: "PROJECT_MEDIA_APPROVE", // done
  PROJECT_MEDIA_APPS_UPDATE: "PROJECT_MEDIA_APPS_UPDATE", // done
  PROJECT_MEDIA_RESOLUTION_UPDATE: "PROJECT_MEDIA_RESOLUTION_UPDATE", // done
  PROJECT_MEDIA_LINK_CREATE: "PROJECT_MEDIA_LINK_CREATE", // done
  PROJECT_MEDIA_LINK_DELETE: "PROJECT_MEDIA_LINK_DELETE", // done
  PROJECT_MEDIA_LINK_UPDATE: "PROJECT_MEDIA_LINK_UPDATE", // done
  PROJECT_MEDIA_MESSAGE_TEXT: "PROJECT_MEDIA_MESSAGE_TEXT", // done
  PROJECT_MEDIA_MESSAGE_PHOTO: "PROJECT_MEDIA_MESSAGE_PHOTO", // done
  PROJECT_MEDIA_MESSAGE_DELETE: "PROJECT_MEDIA_MESSAGE_DELETE", // done

  PROJECT_MEDIA_DRAFT_CREATE: "PROJECT_MEDIA_DRAFT_CREATE",
  PROJECT_MEDIA_DRAFT_UPDATE: "PROJECT_MEDIA_DRAFT_UPDATE",
  PROJECT_MEDIA_DRAFT_APPROVE: "PROJECT_MEDIA_DRAFT_APPROVE",
  PROJECT_MEDIA_DRAFT_DELETE: "PROJECT_MEDIA_DRAFT_DELETE",
  PROJECT_MEDIA_DRAFT_PUBLISH: "PROJECT_MEDIA_DRAFT_PUBLISH",
  PROJECT_MEDIA_DRAFT_UPDATE_NAME: "PROJECT_MEDIA_DRAFT_UPDATE_NAME",

  PROJECT_TEAM_REMOVE_USER: "PROJECT_TEAM_REMOVE_USER", // done
  PROJECT_TEAM_UPDATE_USER_PERMISSION: "PROJECT_TEAM_UPDATE_USER_PERMISSION", // done
  PROJECT_TEAM_CODE_NEW: "PROJECT_TEAM_CODE_NEW", // done
  PROJECT_TEAM_CODE_DELETE: "PROJECT_TEAM_CODE_DELETE", // done

  ORGANISATION_NEW: "ORGANISATION_NEW",
  ORGANISATION_INFO_UPDATE: "ORGANISATION_INFO_UPDATE",
  ORGANISATION_INFO_DELETE: "ORGANISATION_INFO_DELETE",
  ORGANISATION_MESSAGE_TEXT: "ORGANISATION_MESSAGE_TEXT",
  ORGANISATION_MESSAGE_PHOTO: "ORGANISATION_MESSAGE_PHOTO",
  ORGANISATION_MESSAGE_VIDEO: "ORGANISATION_MESSAGE_VIDEO",
  ORGANISATION_MESSAGE_DELETE: "ORGANISATION_MESSAGE_DELETE",

  ORGANISATION_INVITE_ACCEPT: "ORGANISATION_INVITE_ACCEPT",
  ORGANISATION_INVITE_DECLINE: "ORGANISATION_INVITE_DECLINE",
  ORGANISATION_LEAVE: "ORGANISATION_LEAVE",

  ORGANISATION_TEAM_UPDATE_USER_PERMISSION:
    "ORGANISATION_TEAM_UPDATE_USER_PERMISSION",
  ORGANISATION_TEAM_REMOVE_USER: "ORGANISATION_TEAM_REMOVE_USER",

  PROJECT_ANALYTICS_UPDATE: "PROJECT_ANALYTICS_UPDATE", // done

  DEVICE_REQUEST_DECOMMISSION: "DEVICE_REQUEST_DECOMMISSION", // done
  DEVICE_REQUEST_RECOMMISSION: "DEVICE_REQUEST_RECOMMISSION", // done
  DEVICE_UPDATE_STORE: "DEVICE_UPDATE_STORE", // done
  DEVICE_UPDATE_SIM: "DEVICE_UPDATE_SIM", // done
  DEVICE_UPDATE_NOTE: "DEVICE_UPDATE_NOTE", // done
  DEVICE_MESSAGE_TEXT: "DEVICE_MESSAGE_TEXT", // done
  DEVICE_MESSAGE_PHOTO: "DEVICE_MESSAGE_PHOTO", // done
  DEVICE_MESSAGE_VIDEO: "DEVICE_MESSAGE_VIDEO", // done
  DEVICE_MESSAGE_DELETE: "DEVICE_MESSAGE_DELETE", // done
  DEVICE_TRANSFER: "DEVICE_TRANSFER", // done
  DEVICE_NEW: "DEVICE_NEW", // done
  DEVICE_UPDATE_TAGS: "DEVICE_UPDATE_TAGS", // done

  USER_UPDATE_PROFILE: "USER_UPDATE_PROFILE",
};

export const EVENT_CATEGORIES = {
  PROJECT: "PROJECT",
  MEDIA: "MEDIA",
  DEVICE: "DEVICE",
  ANALYTICS: "ANALYTICS",
  TEAM: "TEAM",
  USER: "USER",
  ORGANISATION: "ORGANISATION",
};

const COLLECTION = "system_events";

const EVENT_GROUPBYS = {
  PROJECT: [
    EVENT_TYPES.PROJECT_NEW,
    EVENT_TYPES.PROJECT_HARDWARE_UPDATE,
    EVENT_TYPES.PROJECT_NOTE_UPDATE,
    EVENT_TYPES.PROJECT_STATUS_UPDATE,
    EVENT_TYPES.PROJECT_INFO_UPDATE,
    EVENT_TYPES.PROJECT_TRANSFER,
  ],
  MEDIA: [
    EVENT_TYPES.PROJECT_MEDIA_UPLOAD,
    EVENT_TYPES.PROJECT_MEDIA_APPROVE,
    EVENT_TYPES.PROJECT_MEDIA_APPS_UPDATE,
    EVENT_TYPES.PROJECT_MEDIA_RESOLUTION_UPDATE,
    EVENT_TYPES.PROJECT_MEDIA_LINK_CREATE,
    EVENT_TYPES.PROJECT_MEDIA_LINK_DELETE,
    EVENT_TYPES.PROJECT_MEDIA_LINK_UPDATE,
    EVENT_TYPES.PROJECT_MEDIA_MESSAGE_TEXT,
    EVENT_TYPES.PROJECT_MEDIA_MESSAGE_PHOTO,
    EVENT_TYPES.PROJECT_MEDIA_MESSAGE_DELETE,
    EVENT_TYPES.PROJECT_MEDIA_DRAFT_CREATE,
    EVENT_TYPES.PROJECT_MEDIA_DRAFT_UPDATE,
    EVENT_TYPES.PROJECT_MEDIA_DRAFT_APPROVE,
    EVENT_TYPES.PROJECT_MEDIA_DRAFT_DELETE,
    EVENT_TYPES.PROJECT_MEDIA_DRAFT_PUBLISH,
    EVENT_TYPES.PROJECT_MEDIA_DRAFT_UPDATE_NAME,
  ],
  DEVICE: [
    // EVENT_TYPES.DEVICE_REQUEST_ONLINE,
    EVENT_TYPES.DEVICE_REQUEST_DECOMMISSION,
    EVENT_TYPES.DEVICE_REQUEST_RECOMMISSION,
    EVENT_TYPES.DEVICE_UPDATE_STORE,
    EVENT_TYPES.DEVICE_UPDATE_SIM,
    EVENT_TYPES.DEVICE_UPDATE_NOTE,
    EVENT_TYPES.DEVICE_MESSAGE_TEXT,
    EVENT_TYPES.DEVICE_MESSAGE_PHOTO,
    EVENT_TYPES.DEVICE_MESSAGE_VIDEO,
    EVENT_TYPES.DEVICE_MESSAGE_DELETE,
    EVENT_TYPES.DEVICE_TRANSFER,
    EVENT_TYPES.DEVICE_NEW,
    EVENT_TYPES.DEVICE_UPDATE_TAGS,
  ],
  ANALYTICS: [EVENT_TYPES.PROJECT_ANALYTICS_UPDATE],
  TEAM: [
    EVENT_TYPES.PROJECT_JOIN,
    EVENT_TYPES.PROJECT_LEAVE,
    EVENT_TYPES.PROJECT_TEAM_REMOVE_USER,
    EVENT_TYPES.PROJECT_TEAM_UPDATE_USER_PERMISSION,
    EVENT_TYPES.PROJECT_TEAM_CODE_NEW,
    EVENT_TYPES.PROJECT_TEAM_CODE_DELETE,
    EVENT_TYPES.ORGANISATION_INVITE_ACCEPT,
    EVENT_TYPES.ORGANISATION_INVITE_DECLINE,
    EVENT_TYPES.ORGANISATION_LEAVE,
    EVENT_TYPES.ORGANISATION_TEAM_REMOVE_USER,
    EVENT_TYPES.ORGANISATION_TEAM_UPDATE_USER_PERMISSION,
  ],
  USER: [EVENT_TYPES.USER_UPDATE_PROFILE],
  DEVICES: [EVENT_TYPES.PROJECT_QR_SETUP_UPDATE],
  ORGANISATION: [
    EVENT_TYPES.ORGANISATION_NEW,
    EVENT_TYPES.ORGANISATION_INFO_UPDATE,
    EVENT_TYPES.ORGANISATION_INFO_DELETE,
    EVENT_TYPES.ORGANISATION_MESSAGE_TEXT,
    EVENT_TYPES.ORGANISATION_MESSAGE_PHOTO,
    EVENT_TYPES.ORGANISATION_MESSAGE_VIDEO,
    EVENT_TYPES.ORGANISATION_MESSAGE_DELETE,
  ],
};

const KEYS = {
  CATEGORY: "category",
  DEVICE_ID: "deviceId",
  PROJECT_ID: "projectId",
  ORGANISATION_ID: "organisationId",
  TIMESTAMP: "timestamp",
  TYPE: "type",
  USER_ID: "userId",
  USERS: "users",
};

const getCategory = (type) => {
  for (const group in EVENT_GROUPBYS) {
    if (EVENT_GROUPBYS[group].includes(type)) return group;
  }
  console.error(`Unknown event type: ${type}`);
};

/**
 * validate function to be called once during init (at bottom of page)
 */
const validate = () => {
  // check all events defined in EVENT_GROUPBYS
  Object.keys(EVENT_TYPES).forEach((type) => {
    getCategory(type);

    // check all events have string defined
    if (!EventStrings[type]) {
      console.error(`${type} is not defined in EventStrings!`);
    }
    if (!EventSelfStrings[type]) {
      console.error(`${type} is not defined in EventSelfStrings!`);
    }

    // check redirect()
    if (
      redirect({
        event: {
          [KEYS.TYPE]: type,
          [KEYS.PROJECT_ID]: "",
          [KEYS.ORGANISATION_ID]: "",
          [KEYS.DEVICE_ID]: "",
        },
      }) === null
    )
      console.error(`${type} is not defined in redirect()!`);
  });
};

export const getEventCategories = () => Object.keys(EVENT_CATEGORIES);

export const getDefaultCategoriesSettings = () =>
  Object.fromEntries(Object.keys(EVENT_CATEGORIES).map((key) => [key, true]));

export const useEventMessage = (event) => {
  const userId = useCurrentUserId();
  const userName = useUserName(event?.userId);
  if (event.msg) return event.msg;
  const strings = userId === event.userId ? EventSelfStrings : EventStrings;
  if (!(event.type in strings)) return "";
  let res = strings[event.type];
  if (userName) res = res.replace("{userName}", userName);
  res = res.replace("{organisationName}", event.organisationName);
  res = res.replace("{projectName}", event.projectName);
  // targetName
  const targetName = event.targetName ?? "";
  const isTarget = userId === event.targetUserId;
  res = res.replace("{targetName}", isTarget ? EventYouString : targetName);
  // replace empty string
  res = res.replace(': ""', "");
  return res;
};

export const useEventInfo = (id) =>
  useDocument({
    collection: COLLECTION,
    doc: id,
    path: `eventInfo/${id}`,
  });

/**
 * Users
 */

const useUserLastEventTimestamp = () => {
  const events = useUserEvents({ limit: 1 });
  if (!events) return events;
  return events[0][KEYS.TIMESTAMP];
};

export const useUserHasNewEvent = () => {
  const lastRead = useUserNewsLastRead();
  const lastEventTimestamp = useUserLastEventTimestamp();
  if (isUndefined(lastRead) || isUndefined(lastEventTimestamp)) return;

  // if there is no event then no unread
  if (!lastEventTimestamp) return false;
  // if there is no last read or last event newer than last read, then unread
  return !lastRead || lastEventTimestamp > lastRead;
};

/**
 * get events for the current user
 * limit may be overriden by globalLimit to retain previously fetched result
 */
let globalLimit = 0;
export const useUserEvents = ({ limit }) => {
  const userId = useCurrentUserId();
  useEffect(() => {
    if (limit && limit > globalLimit) {
      globalLimit = limit;
    }
  }, [limit]);
  const config = {
    collection: userId && COLLECTION,
    where: [[KEYS.USERS, "array-contains", userId]],
    orderBy: [KEYS.TIMESTAMP, "desc"],
    limit: Math.max(globalLimit, limit),
  };
  const docs = useCollectionNoStore(config);
  return (
    docs &&
    Object.entries(docs)
      // add id
      .map(([k, v]) => ({
        ...v,
        id: k,
      }))
      // sort
      .sort((a, b) => b[KEYS.TIMESTAMP] - a[KEYS.TIMESTAMP])
  );
};

/**
 * Global
 */
const redirectByOrganisationEvent = (event) => {
  const organisationId = event[KEYS.ORGANISATION_ID];

  switch (event[KEYS.TYPE]) {
    case EVENT_TYPES.ORGANISATION_NEW:
    case EVENT_TYPES.ORGANISATION_INFO_UPDATE:
      return routes.getOrganisationDashboardRoute(organisationId);

    case EVENT_TYPES.ORGANISATION_INFO_DELETE:
      return routes.ROUTES.ORGANISATIONS;

    case EVENT_TYPES.ORGANISATION_INVITE_ACCEPT:
    case EVENT_TYPES.ORGANISATION_INVITE_DECLINE:
    case EVENT_TYPES.ORGANISATION_LEAVE:
    case EVENT_TYPES.ORGANISATION_TEAM_REMOVE_USER:
    case EVENT_TYPES.ORGANISATION_TEAM_UPDATE_USER_PERMISSION:
      return routes.getOrganisationTeamRoute(organisationId);

    case EVENT_TYPES.ORGANISATION_MESSAGE_TEXT:
    case EVENT_TYPES.ORGANISATION_MESSAGE_PHOTO:
    case EVENT_TYPES.ORGANISATION_MESSAGE_VIDEO:
    case EVENT_TYPES.ORGANISATION_MESSAGE_DELETE:
      return routes.getOrganisationActivityRoute(organisationId);
    default:
      return null;
  }
};

const redirectByProjectEvent = (event) => {
  const projectId = event[KEYS.PROJECT_ID];

  switch (event[KEYS.TYPE]) {
    case EVENT_TYPES.PROJECT_NEW:
    case EVENT_TYPES.PROJECT_HARDWARE_UPDATE:
    case EVENT_TYPES.PROJECT_NOTE_UPDATE:
    case EVENT_TYPES.PROJECT_STATUS_UPDATE:
    case EVENT_TYPES.PROJECT_INFO_UPDATE:
    case EVENT_TYPES.PROJECT_TRANSFER:
      return routes.getProjectOverviewRoute(projectId);

    case EVENT_TYPES.PROJECT_JOIN:
    case EVENT_TYPES.PROJECT_LEAVE:
    case EVENT_TYPES.PROJECT_TEAM_REMOVE_USER:
    case EVENT_TYPES.PROJECT_TEAM_UPDATE_USER_PERMISSION:
      return routes.getProjectTeamRoute(projectId);

    case EVENT_TYPES.PROJECT_TEAM_CODE_NEW:
    case EVENT_TYPES.PROJECT_TEAM_CODE_DELETE:
      return routes.getProjectTeamShareTabRoute(projectId);

    case EVENT_TYPES.PROJECT_MEDIA_UPLOAD:
    case EVENT_TYPES.PROJECT_MEDIA_APPROVE:
      return routes.getProjectMediaRoute(projectId);

    case EVENT_TYPES.PROJECT_MEDIA_APPS_UPDATE:
      return routes.getProjectMediaEditTabRoute(projectId, "apps");

    case EVENT_TYPES.PROJECT_MEDIA_RESOLUTION_UPDATE:
    case EVENT_TYPES.PROJECT_MEDIA_DRAFT_CREATE:
    case EVENT_TYPES.PROJECT_MEDIA_DRAFT_UPDATE:
    case EVENT_TYPES.PROJECT_MEDIA_DRAFT_APPROVE:
    case EVENT_TYPES.PROJECT_MEDIA_DRAFT_DELETE:
    case EVENT_TYPES.PROJECT_MEDIA_DRAFT_PUBLISH:
    case EVENT_TYPES.PROJECT_MEDIA_DRAFT_UPDATE_NAME:
      return routes.getProjectMediaEditTabRoute(projectId, "content");

    case EVENT_TYPES.PROJECT_MEDIA_LINK_CREATE:
    case EVENT_TYPES.PROJECT_MEDIA_LINK_DELETE:
    case EVENT_TYPES.PROJECT_MEDIA_LINK_UPDATE:
      return routes.getProjectMediaEditTabRoute(projectId, "showroom");

    case EVENT_TYPES.PROJECT_MEDIA_MESSAGE_TEXT:
    case EVENT_TYPES.PROJECT_MEDIA_MESSAGE_PHOTO:
    case EVENT_TYPES.PROJECT_MEDIA_MESSAGE_DELETE:
      return routes.getProjectMediaMessagesRoute(projectId);

    // case EVENT_TYPES.DEVICE_REQUEST_ONLINE:
    case EVENT_TYPES.DEVICE_REQUEST_DECOMMISSION:
    case EVENT_TYPES.DEVICE_REQUEST_RECOMMISSION:
    case EVENT_TYPES.DEVICE_UPDATE_STORE:
    case EVENT_TYPES.DEVICE_UPDATE_SIM:
    case EVENT_TYPES.DEVICE_NEW:
    case EVENT_TYPES.DEVICE_UPDATE_TAGS:
      // tab should align with DevicePanel
      if (KEYS.DEVICE_ID in event)
        return routes.getProjectDeviceTabRoute(
          projectId,
          event[KEYS.DEVICE_ID],
          "general"
        );
      break;
    case EVENT_TYPES.DEVICE_UPDATE_NOTE:
      // tab should align with DevicePanel
      if (KEYS.DEVICE_ID in event)
        return routes.getProjectDeviceTabRoute(
          projectId,
          event[KEYS.DEVICE_ID],
          "note"
        );
      break;
    case EVENT_TYPES.DEVICE_MESSAGE_TEXT:
    case EVENT_TYPES.DEVICE_MESSAGE_PHOTO:
    case EVENT_TYPES.DEVICE_MESSAGE_DELETE:
    case EVENT_TYPES.DEVICE_MESSAGE_VIDEO:
      // tab should align with DevicePanel
      if (KEYS.DEVICE_ID in event)
        return routes.getProjectDeviceTabRoute(
          projectId,
          event[KEYS.DEVICE_ID],
          "message"
        );
      break;
    case EVENT_TYPES.DEVICE_TRANSFER:
      if (KEYS.DEVICE_ID in event)
        return routes.getProjectDevicesRoute(projectId);
      break;
    case EVENT_TYPES.PROJECT_ANALYTICS_UPDATE:
      return routes.getProjectAnalyticsRoute(projectId);
    case EVENT_TYPES.USER_UPDATE_PROFILE:
      return routes.getProfileRoute();
    case EVENT_TYPES.PROJECT_QR_SETUP_UPDATE:
      routes.getProjectDevicesRoute(projectId);
      break;
    default:
      return null;
  }
};

export const redirect = ({ event, history }) => {
  if (!event) return;

  if (!(KEYS.PROJECT_ID in event) && !(KEYS.ORGANISATION_ID in event)) {
    if (!(KEYS.ORGANISATION_ID in event)) {
      console.warn(`Event has no "${KEYS.ORGANISATION_ID}"`);
    }
    if (!(KEYS.PROJECT_ID in event)) {
      console.warn(`Event has no "${KEYS.PROJECT_ID}"`);
    }
    return;
  }

  let route = null;

  if (KEYS.PROJECT_ID in event) {
    route = redirectByProjectEvent(event);
  }

  if (route === null && KEYS.ORGANISATION_ID in event) {
    route = redirectByOrganisationEvent(event);
  }

  if (route && history) {
    console.debug("Redirecting to:", route);
    history.push(route);
  }
  return route;
};

validate();
