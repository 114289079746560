import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useMapUrl } from "../services/MapsService";
import { useMobileLayout } from "../hooks/uiHooks";
import { areDictsEqual } from "../utils/generalUtils";
import { isLoading } from "../utils/uiUtils";

import Spinner from "./Spinner";

const useStyles = makeStyles((theme) => ({
  mapsImageRoot: (mobile) => ({
    flexGrow: 1,
    height: "1px",
    position: "relative",
    backgroundColor: "white",
    minHeight: mobile ? "200px" : "120px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    overflow: "hidden",
  }),
  mapsImageContent: {
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
}));

const MapsImage = memo(({ url }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const map = (
    <div
      className={classes.mapsImageContent}
      style={{
        backgroundImage: `url(${url}`,
      }}
    />
  );

  const content = isLoading(url) ? <Spinner /> : url ? map : <></>;

  return <div className={classes.mapsImageRoot}>{content}</div>;
});

// const location = {
//   place: "Myplayer",
//   postcode: "SE162DB",
//   country: "GB",
// };
// const options = {
//     width: 600,
//     height: 600,
//     zoom: 15,
// }
const isEqual = (p1, p2) =>
  areDictsEqual(p1.location, p2.location) &&
  areDictsEqual(p1.options, p2.options);

const MapsImageLoader = memo(({ location, options }) => {
  const url = useMapUrl({ location, options });

  return <MapsImage url={url} />;
}, isEqual);

export default MapsImageLoader;
