import { useHistory } from "react-router-dom";

import { ORG_TABS_KEYS } from "./config";
import { OrganisationStrings } from "strings";
import {
  getOrganisationDashboardRoute,
  getOrganisationTeamRoute,
  getOrganisationProjectsRoute,
  getOrganisationGuestsRoute,
  getOrganisationActivityRoute,
  getOrganisationSettingsRoute,
} from "route";

export const useOrganisationTabs = (organisationId, isSuperAdmin) => {
  const history = useHistory();
  if (typeof isSuperAdmin === "undefined") return;

  const tabsConfig = [
    {
      key: ORG_TABS_KEYS.DASHBOARD,
      label: OrganisationStrings.TAB_TITLE_DASHBOARD,
      routeFunc: getOrganisationDashboardRoute,
    },

    {
      key: ORG_TABS_KEYS.TEAM,
      label: OrganisationStrings.TAB_TITLE_TEAM,
      routeFunc: getOrganisationTeamRoute,
    },
    {
      key: ORG_TABS_KEYS.GUESTS,
      label: OrganisationStrings.TAB_TITLE_GUESTS,
      routeFunc: getOrganisationGuestsRoute,
    },
    {
      key: ORG_TABS_KEYS.ACTIVITY,
      label: OrganisationStrings.TAB_TITLE_ACTIVITY,
      routeFunc: getOrganisationActivityRoute,
    },
    {
      key: ORG_TABS_KEYS.SETTINGS,
      label: OrganisationStrings.TAB_TITLE_SETTINGS,
      routeFunc: getOrganisationSettingsRoute,
    },
  ];

  if (isSuperAdmin) {
    tabsConfig.splice(1, 0, {
      key: ORG_TABS_KEYS.PROJECTS,
      label: OrganisationStrings.TAB_TITLE_PROJECTS,
      routeFunc: getOrganisationProjectsRoute,
    });
  }

  return tabsConfig.map((cfg) => ({
    key: cfg.key,
    label: cfg.label,
    onClick: () => history.push(cfg.routeFunc(organisationId)),
  }));
};
