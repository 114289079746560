import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

function StatItem({ header, content }) {
  const classes = useStyles();
  return (
    <Box flexGrow="1">
      <Typography>{header}</Typography>
      <Typography className={classes.content}>{content}</Typography>
    </Box>
  );
}

export default StatItem;
