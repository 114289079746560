import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: "#323232",
    paddingTop: theme.spacing(1.5),
  },
  bottomBorder: {
    position: "absolute",
    borderBottom: "1px solid #E8E9EA",
    bottom: 0,
    left: "8px",
    right: "8px",
  },
}));
