import React, { useRef, useEffect } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const BOTTOM_ID = "bottom";

/**
 * sample config
 */
// const config = {
//   onLoadMore: (size) => {},
// }

const useStyles = makeStyles((theme) => {
  return {
    bottom: {
      minHeight: 2,
      width: "100%",
    },
  };
});

// An empty container to provide infinite scrolling
// Consume should define how the items are displayed (eg. flexbox)
const InfiniteScroll = ({
  children,
  size,
  max,
  config: { onLoadMore },
}) => {
  const classes = useStyles();
  const loadMoreRef = useRef(null);
  
  useEffect(() => {
    const callback = (entries) => {
      if (entries.length > 0 && entries[0].isIntersecting) {
        if (onLoadMore) onLoadMore(size);
      }
    };

    const ref = loadMoreRef.current;
    if (!ref || size <= 0) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };
    // console.debug("Observing", size, max);
    const observer = new IntersectionObserver(callback, options);
    observer.observe(ref);

    return () => observer.unobserve(ref);
  }, [size, max, onLoadMore]);

  return (
    <>
      {children}
      {/* a dummy box to detect intersection */}
      <Box ref={loadMoreRef} id={BOTTOM_ID} className={classes.bottom} />
    </>
  );
};

export default InfiniteScroll;
