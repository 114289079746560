import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import { getProjectPermissionName } from "utils/permissionUtils";
import { useProjectBasicPermissionsOptions } from "services/PermissionService";
import { useStyles } from "./style";
import { CheckIcon, DeleteIcon } from "assets/icons";

export function Actions({ permissionsKey, onOpen, onAccept, onReject }) {
  const classes = useStyles();
  const permissionsOptions = useProjectBasicPermissionsOptions();

  return (
    <div className={classes.container}>
      <Box className={classes.userRoleWrapper} onClick={onOpen}>
        <Typography className={classes.userRole}>
          {getProjectPermissionName(permissionsKey, permissionsOptions)}
        </Typography>
      </Box>
      <div>
        <IconButton className={classes.button} onClick={onAccept}>
          <CheckIcon />
        </IconButton>
        <IconButton className={classes.button} onClick={onReject}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}
