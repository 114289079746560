import React, { useState, useEffect, useRef } from "react";
import { Box, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { useDeviceStoreInfo } from "../../services/DeviceService";
import { updateDeviceStore } from "../../services/ApiService";
import { isUndefined } from "../../utils/generalUtils";
import { getCountryFromLocale } from "utils/localeUtils";

import OverflowTypography from "../OverflowTypography";
import ButtonCard from "./ButtonCard";
import MyDialog from "../MyDialog";
import MapsImage from "../MapsImage";
import { useCountries, useRetailers } from "services/ProjectService";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";

import { DefaultStrings, DeviceStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  row: (fullSize) => ({
    display: "flex",
    alignItems: "baseline",
    marginTop: fullSize ? theme.spacing(1) : 0,
    "&:first-child": {
      marginTop: 0,
    },
  }),
  header: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(0.5),
  },
  autoComplete: {
    backgroundColor: theme.palette.background.default,
  },
  countryContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

// align with MapsImage
const constructLocation = (storeInfo) => {
  if (!storeInfo) return storeInfo;
  return {
    place: `${storeInfo.retailer},${storeInfo.storeId}`,
    postcode: storeInfo.postcode,
    country: storeInfo.country,
  };
};

// align with MapsImage
const constructMapOptions = (fullSize) => {
  const width = fullSize ? 600 : 600;
  const height = fullSize ? 600 : 400;
  const zoom = fullSize ? 15 : 12;
  return { width, height, zoom };
};

const filterOptionsCountries = createFilterOptions({
  stringify: ({ iso, displayName }) => `${displayName} ${iso}`,
});

const filterOptionsRetailers = createFilterOptions({
  stringify: ({ name }) => name,
});

const getCountryFlagUrl = (country) =>
  `https://flagcdn.com/w40/${country?.iso?.toLowerCase()}.png`;

const DeviceStoreCard = ({
  projectId,
  deviceId,
  fullSize,
  canRead,
  canEdit
}) => {
  const classes = useStyles(fullSize);
  const storeInfo = useDeviceStoreInfo(deviceId);

  const [inProgress, setInProgress] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [editStoreInfo, setEditStoreInfo] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(true);

  const currentStoreInfo = isUndefined(editStoreInfo)
    ? storeInfo
    : editStoreInfo;
  const deviceLoading = inProgress.includes(deviceId);

  const countries = useCountries() || [];
  const retailers = useRetailers() || [];

  // track mounted state
  // this is necessary to avoid state update after unmounted
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const configCard = {
    icon: <StoreOutlinedIcon />,
    title: DeviceStrings.STORE_TITLE,
    onClick: () => {
      setShowDialog(true);
    },
    progress: deviceLoading,
    disableButton: deviceLoading || !canEdit,
    fullHeight: true,
    autoExpand: false,
  };
  if (fullSize) configCard.buttonLabel = DeviceStrings.STORE_BUTTON;

  const disableOk =
    storeInfo &&
    currentStoreInfo &&
    Object.keys(storeInfo).every(
      (key) => storeInfo[key] === currentStoreInfo[key]
    );

  const update = () => {
    setInProgress([...inProgress, deviceId]);
    updateDeviceStore({ projectId, deviceId, info: currentStoreInfo })
      .then((result) => {
        enqueueSnackbar(DeviceStrings.STORE_UPDATED, { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(err);
      })
      .finally(() => {
        setInProgress((p) => p.filter((i) => i !== deviceId));
        // reset edit to original
        setEditStoreInfo(undefined);
      });
  };

  const configDialog = {
    icon: <StoreOutlinedIcon />,
    title: DeviceStrings.STORE_TITLE,
    onClose: () => {
      setShowDialog(false);
      // reset edit to original
      setEditStoreInfo(undefined);
    },
    onOk: () => {
      setShowDialog(false);
      update();
    },
    disableOk,
  };

  const updateStoreInfo = (key, value) => {
    setEditStoreInfo({
      ...currentStoreInfo,
      [key]: value,
    });
  };

  const dialogContent = (
    <>
      <TextField
        label={DeviceStrings.STORE_EDIT_ID}
        value={currentStoreInfo?.storeId || ""}
        fullWidth
        onChange={(e) => updateStoreInfo("storeId", e.target.value)}
      />
      <Box mt={2}>
        <Autocomplete
          className={classes.autoComplete}
          options={countries.sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          )}
          filterOptions={filterOptionsCountries}
          classes={{
            option: classes.option,
          }}
          onChange={(_, newValue) =>
            newValue && updateStoreInfo("country", newValue.iso)
          }
          getOptionLabel={(option) => option.displayName}
          defaultValue={{
            displayName: getCountryFromLocale(storeInfo?.country),
          }}
          getOptionSelected={(option, value) =>
            option.iso === value.iso ||
            value.displayName === getCountryFromLocale(option.iso)
          }
          autoHighlight
          renderOption={(option) => (
            <div className={classes.countryContainer}>
              <img
                width="20"
                src={getCountryFlagUrl(option)}
                alt="country-flag"
              />
              <span>{option.displayName}</span>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={DeviceStrings.STORE_CHOOSE_COUNTRY_LABEL}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
            />
          )}
        />
      </Box>
      <Box mt={2}>
        <TextField
          label={DeviceStrings.STORE_EDIT_POSTCODE}
          value={currentStoreInfo?.postcode || ""}
          fullWidth
          onChange={(e) => updateStoreInfo("postcode", e.target.value)}
        />
      </Box>
      <Box mt={2}>
        <Autocomplete
          className={classes.autoComplete}
          options={retailers}
          filterOptions={filterOptionsRetailers}
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) =>
            option.name ?? currentStoreInfo["retailer"]
          }
          defaultValue={{
            name: storeInfo?.retailer,
          }}
          freeSolo
          onChange={(_, newValue) =>
            newValue && updateStoreInfo("retailer", newValue.name ?? newValue)
          }
          onInputChange={(_, newInputValue) =>
            updateStoreInfo("retailer", newInputValue)
          }
          getOptionSelected={(option, value) => option.name === value.name}
          autoHighlight
          renderOption={(option) => <span>{option.name}</span>}
          renderInput={(params) => (
            <TextField
              {...params}
              label={DeviceStrings.STORE_CHOOSE_RETAILER_LABEL}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
            />
          )}
        />
      </Box>
    </>
  );

  const displayedCountry =
    getCountryFromLocale(storeInfo?.country) || storeInfo?.country;

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <MapsImage
        location={constructLocation(storeInfo)}
        options={constructMapOptions(fullSize)}
      />
      <ButtonCard config={configCard} canRead={canRead}>
        <div className={classes.main}>
          <div className={classes.row}>
            <div className={classes.header}>{DeviceStrings.STORE_ID}</div>
            <OverflowTypography variant="body2">
              {storeInfo?.storeId}
            </OverflowTypography>
          </div>
          {fullSize && (
            <div className={classes.row}>
              <div className={classes.header}>
                {DeviceStrings.STORE_COUNTRY}
              </div>
              <OverflowTypography variant="body2">
                {displayedCountry}
              </OverflowTypography>
            </div>
          )}
          <div className={classes.row}>
            <div className={classes.header}>{DeviceStrings.STORE_POSTCODE}</div>
            <OverflowTypography variant="body2">
              {storeInfo?.postcode}
            </OverflowTypography>
          </div>
          <div className={classes.row}>
            <div className={classes.header}>{DeviceStrings.STORE_RETAILER}</div>
            <OverflowTypography variant="body2">
              {storeInfo?.retailer}
            </OverflowTypography>
          </div>
        </div>
      </ButtonCard>
      <MyDialog open={showDialog} config={configDialog}>
        {dialogContent}
      </MyDialog>
    </div>
  );
};

export default DeviceStoreCard;
