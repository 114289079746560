import { useHistory } from "react-router-dom";

import { ProjectStrings } from "strings";
import { ROUTES } from "route";

export const useProjectsTabs = (selectedOrganisationId) => {
  const history = useHistory();

  const tabsProjectsConfig = (organisationId) => {
    if (typeof organisationId === "undefined") return {};

    const tabs = {};

    if (organisationId) {
      tabs["MY_PROJECTS"] = {
        key: "MY_PROJECTS",
        label: ProjectStrings.TAB_TITLE_MY_PROJECTS,
        path: ROUTES.PROJECTS,
      };
      tabs["ALL_PROJECTS"] = {
        key: "ALL_PROJECTS",
        label: ProjectStrings.TAB_TITLE_ALL_PROJECTS,
        path: ROUTES.PROJECTS_ALL,
      };
    }

    tabs.SHARED_PROJECTS = {
      key: "SHARED_PROJECTS",
      label: ProjectStrings.TAB_TITLE_SHARED_PROJECTS,
      path: ROUTES.PROJECTS_SHARED,
    };

    return tabs;
  };

  const tabs = Object.entries(tabsProjectsConfig(selectedOrganisationId));

  if (tabs.length === 0) return [];

  return tabs.map(([key, cfg]) => ({
    key,
    label: cfg.label,
    path: cfg.path,
    onClick: () => {
      history.push(cfg.path);
    },
  }));
};
