import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import { DefaultStrings } from "strings";

const useStyles = makeStyles((theme) => ({
  sortIcon: {
    display: "flex",
    marginLeft: theme.spacing(1),
    visibility: "hidden",
  },
  sortSelected: {
    backgroundColor: theme.palette.primary.main,
    "& .sortIcon": {
      color: "red",
    },
  },
  sortIconSelected: {
    visibility: "visible",
  },
}));

export const SortMenu = ({ actions }) => {
  const [anchor, setAnchor] = useState(null);
  const classes = useStyles();

  const onOpenHandle = (e) => setAnchor(e.currentTarget);
  const onCloseHandle = () => setAnchor(null);
  const onClickHandle = (func) => () => {
    if (func) func();
  };

  return (
    <>
      <IconButton
        aria-label={DefaultStrings.BUTTON_SORT}
        color="inherit"
        onClick={onOpenHandle}
      >
        <SortIcon />
      </IconButton>
      <Menu
        id="menuSort"
        anchorEl={anchor}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={!!anchor}
        onClose={onCloseHandle}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            className={clsx(action.selected && classes.sortSelected)}
            onClick={onClickHandle(action.onClick)}
          >
            <span>{action.label}</span>
            <div
              className={clsx(
                classes.sortIcon,
                action.selected && classes.sortIconSelected
              )}
            >
              {action.desc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
