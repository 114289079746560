import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { useConfirm } from "../../hooks/uiHooks";
import { decommissionDevice } from "../../services/ApiService";
import {
  useDeviceLive,
  useDeviceDecommissionInfo,
} from "../../services/DeviceService";
import { isLoading } from "../../utils/uiUtils";
import { getRelativeTimeString } from "../../utils/localeUtils";

import ButtonCard from "./ButtonCard";
import WeekendOutlinedIcon from "@material-ui/icons/WeekendOutlined";
import LocalCafeOutlinedIcon from "@material-ui/icons/LocalCafeOutlined";

import { DefaultStrings, DeviceStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  header: {
    marginRight: theme.spacing(0.5),
  },
}));

const DecommisionContent = ({ deviceId }) => {
  const classes = useStyles();
  const info = useDeviceDecommissionInfo(deviceId);
  const time = info?.at && getRelativeTimeString(info.at);
  if (isLoading(info) || !time) return <></>;
  return (
    <>
      <Typography variant="caption" className={classes.header}>
        {DeviceStrings.RETIRE_DATE}
      </Typography>
      <span>{time}</span>
    </>
  );
};

const DeviceRetireCard = ({
  projectId,
  deviceId,
  fullHeight = true,
  canRead,
  canEdit,
}) => {
  const decommissioned = !useDeviceLive(deviceId);
  const confirm = useConfirm();
  const [inProgress, setInProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(true);

  // this is necessary to avoid state update after unmounted
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const decommission = (decommissioned) => {
    setInProgress(true);
    const msg = decommissioned
      ? DeviceStrings.RETIRE_SUCCEED
      : DeviceStrings.RETIRE_ACTIVATE_SUCCEED;
    decommissionDevice({ projectId, deviceId, decommissioned }).then(
      (result) => {
        if (result.success) {
          enqueueSnackbar(msg, { variant: "success" });
        } else {
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          console.warn(result.errors[0]);
        }
        if (mounted.current) setInProgress(false);
      }
    );
  };

  const onConfirmActivate = () => {
    const message = DeviceStrings.RETIRE_ACTIVATE_CONFIRM_MSG;
    confirm({ message })
      .then(() => {
        decommission(false);
      })
      .catch((error) => {});
  };

  const onConfirmRetire = () => {
    const message = DeviceStrings.RETIRE_CONFIRM_MSG;
    confirm({ message })
      .then(() => {
        decommission(true);
      })
      .catch((error) => {});
  };

  const configCard = {
    icon: decommissioned ? <LocalCafeOutlinedIcon /> : <WeekendOutlinedIcon />,
    title: decommissioned
      ? DeviceStrings.RETIRE_ACTIVATE_TITLE
      : DeviceStrings.RETIRE_TITLE,
    buttonLabel: decommissioned
      ? DeviceStrings.RETIRE_ACTIVATE_BUTTON
      : DeviceStrings.RETIRE_BUTTON,
    desc: decommissioned
      ? DeviceStrings.RETIRE_ACTIVATE_DESC
      : DeviceStrings.RETIRE_DESC,
    fullHeight,
    onClick: () => {
      decommissioned ? onConfirmActivate() : onConfirmRetire();
    },
    progress: inProgress,
    disableButton: inProgress || !canEdit,
  };

  return (
    <ButtonCard config={configCard} canRead={canRead}>
      {decommissioned ? <DecommisionContent deviceId={deviceId} /> : <br />}
    </ButtonCard>
  );
};

export default DeviceRetireCard;
