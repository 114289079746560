import imageCompression from "browser-image-compression";

export const getFileListSize = (fileList) => {
  return Object.entries(fileList).reduce((total, current) => {
    if (current[0] !== "length") {
      return total + current[1].size;
    } else return total;
  }, 0);
};

export const getFileName = (file) => file.name;

export const getFilePath = (file) =>
  file.webkitRelativePath.substring(
    file.webkitRelativePath.indexOf("/"),
    file.webkitRelativePath.lastIndexOf("/")
  );

export const downloadBlob = (filename = "MyProjects", text, type) => {
  const blob = new Blob([text], {
    type: `text/${type};charset=utf-8;`,
    endings: "native",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };

  a.addEventListener("click", clickHandler, false);
  a.click();
};

// TODO: it can be done without altering document body like downloadBlob()?
// can combine the two functions for the document part
// canvas is not a file-related object so either:
// 1) function take data URI as input, or
// 2) rename (rework) this file as something more than about file
export const downloadQR = (canvas, fileName) => {
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");

  const downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  if (fileName) downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadCanvas = downloadQR;

export const downloadImg = (img, filename) => {
  const canvas = document.createElement("canvas");
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  downloadCanvas(canvas, filename);
};

const COMPRESS_OPTIONS = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  fileType: "image/jpeg",
};

export const compressImageFile = async (imageFile) => {
  try {
    const compressedFile = await imageCompression(imageFile, COMPRESS_OPTIONS);
    return compressedFile;
  } catch (error) {
    console.warn(error);
    return imageFile;
  }
};

export const IMAGE_TYPES = ["image/gif", "image/jpeg", "image/png"];
export const VIDEOS_TYPES = ["video/mp4"];

export const isFileImage = (file) => file && IMAGE_TYPES.includes(file["type"]);
export const isFileVideo = (file) =>
  file && VIDEOS_TYPES.includes(file["type"]);
