import React, {useEffect, useRef, useState} from "react";
import {Paper, Tooltip} from "@material-ui/core";
import Xarrow from "react-xarrows";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from "notistack";

import ControlPointIcon from "@material-ui/icons/ControlPoint";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import {
  useDeviceScreen,
  useDeviceScreenRefreshing,
  remoteRefresh,
  remoteHome,
  remoteBack,
  remoteRecent,
  remoteClick,
  remoteSwipe,
  useDeviceOnlineRequest,
  useDeviceOnlineResponse,
} from "../../services/DeviceService";
import {ccOnline} from "../../services/ApiService";
import {useMobileLayout} from "../../hooks/uiHooks";

import RemotePanelInput from "../remote/RemotePanelInput";
import RemotePanelApps from "../remote/RemotePanelApps";
import RemotePanelFiles from "../remote/RemotePanelFiles";

import {DeviceStrings, DefaultStrings} from "../../strings";

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
  },
  screenImageFrame: {
    backgroundColor: "white",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(5),
    paddingBottom: 0,
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  screenImageContainer: {
    flexGrow: 1,
    width: "100%",
    position: "relative",
    borderWidth: 2,
    borderStyle: "inset",
    boxSizing: "border-box",
    display: "flex",
  },
  button: {
    boxSizing: "border-box",
    margin: 16,
    padding: 16,
    width: 52,
    height: 52,
    borderRadius: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
  buttonActive: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  buttonDisabled: {
    cursor: "default",
    color: "rgba(0,0,0,0.3)",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonMain: {
    width: 64,
    height: 64,
    borderRadius: 32,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.1) 0px 5px 4px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonMainDisabled: {
    cursor: "default",
    color: "rgba(0,0,0,0.3)",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  screenControlBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  screenControlPanelContainer: ({open}) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    height: open ? "auto" : 0,
    minHeight: open ? "30%" : 0,
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    transition: "all .2s ease",
    overflowY: "auto",
  }),
}));

const ControlPanel = ({open, children}) => {
  const classes = useStyles({open});
  return <div className={classes.screenControlPanelContainer}>{children}</div>;
};

const ScreenControl = ({controls, active}) => {
  const classes = useStyles();
  const getClass = ({main, active, disabled}) => {
    let ret = classes.button;
    if (main) {
      ret += ` ${classes.buttonMain} ${
        disabled ? classes.buttonMainDisabled : ""
      }`;
    } else if (active) {
      ret += ` ${classes.buttonActive}`;
    } else {
      ret += ` ${disabled ? classes.buttonDisabled : ""}`;
    }
    return ret;
  };
  return (
    <div className={classes.screenControlBar}>
      {controls.map((k, i) => (
        <Tooltip title={k.disabled ? "" : k.label} key={`control-${i}`}>
          <div
            className={getClass({
              main: k.main,
              active: i === active,
              disabled: k.disabled,
            })}
            onClick={() => k?.onClick(i)}
          >
            {i === active ? <CloseOutlinedIcon fontSize="large" /> : k.icon}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

const ScreenCard = ({projectId, deviceId, canRead, canEdit, active}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const {enqueueSnackbar} = useSnackbar();
  const screen = useDeviceScreen(deviceId);
  const refreshing = useDeviceScreenRefreshing(deviceId);
  const [imageSize, setImageSize] = useState();
  const [swipeStart, setSwipeStart] = useState();
  const [loading, setLoading] = useState();
  const [currentControl, setCurrentControl] = useState();
  const [frameSize, setFrameSize] = useState({
    width: 768 + 80,
    height: 432 + 136,
  });

  // online check
  const timestampRequest = useDeviceOnlineRequest(deviceId)?.timestamp;
  const timestampResponse = useDeviceOnlineResponse(deviceId)?.timestamp;
  const [shouldSendRequest, setShouldSendRequest] = useState(true);
  const [doneSendRequest, setDoneSendRequest] = useState(false);
  const requestRef = useRef();
  const responseRef = useRef();

  // drawings
  const [clickPoint, setClickPoint] = useState();
  const [swipeStartPoint, setSwipeStartPoint] = useState();
  const [swipeEndPoint, setSwipeEndPoint] = useState();

  // controls
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showApps, setShowApps] = useState(false);
  const [showFiles, setShowFiles] = useState(false);

  const imageRef = useRef();
  const inProgress = loading || refreshing;

  const imageRatio = imageSize?.ratio;

  const mounted = useRef(true);

  const progressStart = () => {
    if (!mounted.current) return;
    setLoading(true);
  };

  const progressClear = () => {
    if (!mounted.current) return;
    setLoading(false);
    setSwipeStart(null);
    setClickPoint(null);
    setSwipeStartPoint(null);
    setSwipeEndPoint(null);
  };

  // clear progress when refreshing changes from true to false
  useEffect(() => {
    mounted.current = true;
    if (!refreshing) {
      progressClear();
    }
    return () => {
      mounted.current = false;
    };
  }, [refreshing]);

  useEffect(() => {
    if (!active) return;
    if (!mounted.current) return;

    const updateFrameSize = (width, height, targetRatio) => {
      const allowedImageHeight = height - 8 - 8 - 40 - 96; // 8 = margin, 40 = top padding, 96 = control bar
      const allowedImageWidth = width - 8 - 8 - 40 - 40; // 8 = margin, 40 = top/bottom padding
      const allowedRatio = allowedImageWidth / allowedImageHeight;
      if (allowedRatio > targetRatio) {
        // use height (landscape)
        setFrameSize({
          width: allowedImageHeight * targetRatio + 80,
          height: allowedImageHeight + 136,
        });
      } else {
        // use width (portrait)
        setFrameSize({
          width: allowedImageWidth + 80,
          height: allowedImageWidth / targetRatio + 136,
        });
      }
    };

    const elem = document.getElementById("screenContainer");
    const targetRatio = imageRatio || 16 / 9;
    updateFrameSize(elem.clientWidth, elem.clientHeight, targetRatio);
  }, [active, imageRatio, projectId, deviceId]);

  // this necessary for setTimeout to get the current value
  useEffect(() => {
    requestRef.current = timestampRequest;
    responseRef.current = timestampResponse;
  }, [timestampRequest, timestampResponse]);

  useEffect(() => {
    if (!mounted.current) return;
    if (deviceId?.length >= 15) {
      setDoneSendRequest(false);
      setShouldSendRequest(true);
    }
  }, [deviceId]);

  // trigger this useEffect if
  // - active changes from false to true
  // - projectId or deviceId changes
  useEffect(() => {
    if (!active) return;
    if (!shouldSendRequest) return;
    if (doneSendRequest) return;
    if (!deviceId) return;
    if (!mounted.current) return;

    setDoneSendRequest(false);
    setShouldSendRequest(false);
    ccOnline({projectId, deviceId})
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) return;
          if (
            !responseRef.current || // no response
            responseRef.current < requestRef.current // or no updated response since last request
          ) {
            console.warn(
              "ccOnline: Device is offline",
              `req=${requestRef.current}, res=${responseRef.current}`
            );
            enqueueSnackbar("Device is offline", {
              variant: "warning",
            });
            setDoneSendRequest(true);
          }
        }, 5000);
      })
      .catch((err) => {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, {variant: "error"});
        console.warn("ccOnline", err);
      });
  }, [
    projectId,
    deviceId,
    enqueueSnackbar,
    active,
    shouldSendRequest,
    doneSendRequest,
  ]);

  useEffect(() => {
    if (!active) return;
    if (doneSendRequest) return;
    if (!mounted.current) return;
    if (
      timestampResponse >= timestampRequest // and response after request (online)
    ) {
      console.debug(
        "ccOnline: Device is online",
        `req=${timestampRequest}, res=${timestampResponse}`
      );
      enqueueSnackbar("Device is online", {
        variant: "success",
      });
      setDoneSendRequest(true);
    }
  }, [
    active,
    timestampRequest,
    timestampResponse,
    enqueueSnackbar,
    doneSendRequest,
  ]);

  const onLoad = ({target: img}) => {
    setImageSize({
      width: img.naturalWidth,
      height: img.naturalHeight,
      ratio: img.naturalWidth / img.naturalHeight,
    });
  };

  // remote functions
  const refresh = () => {
    if (inProgress) return;
    setLoading(true);
    remoteRefresh({projectId, deviceId});
  };
  const home = () => {
    if (inProgress) return;
    progressStart();
    remoteHome({projectId, deviceId})
      .then(() => {
        refresh();
      })
      .catch(() => {
        // stop
        progressClear();
      });
  };
  const back = () => {
    if (inProgress) return;
    progressStart();
    remoteBack({projectId, deviceId})
      .then(() => {
        refresh();
      })
      .catch(() => {
        // stop
        progressClear();
      });
  };
  const recent = () => {
    if (inProgress) return;
    progressStart();
    remoteRecent({projectId, deviceId})
      .then(() => {
        refresh();
      })
      .catch(() => {
        // stop
        progressClear();
      });
  };

  // get remote xy and relative xy from page xy (event)
  // img xy = relative to <img> (may have whitespace due to scale)
  // file xy = relative to image file (not affected by scale)
  // remote xy = relative to remote screen
  // insideFile = page xy within file boundaries
  //
  // PS: mobile should have height = 100% of parent, width = 100% of self
  const getRemoteXY = (pageX, pageY) => {
    // rect = image rendered rect
    const rect = imageRef.current.getBoundingClientRect();
    // scaled ratio
    // imageSize = image original size
    // rect = rendered <img> size
    const widthRatio = imageSize.width / rect.width;
    const heightRatio = imageSize.height / rect.height;
    // pageX = X relative to left edge of entire document
    // x = x of <img>
    const x = pageX - rect.x;
    // pageY = Y relative to top edge of entire document
    // y = y of <img>
    const y = pageY - rect.y;
    let startX = 0,
      startY = 0,
      ratio = 0;
    // we need to find out the scaled actual image content area
    if (widthRatio > heightRatio) {
      // white space vertical
      // start x = 0
      startY = Math.round((rect.height - rect.width / imageSize.ratio) / 2);
      ratio = widthRatio;
    } else {
      // white space horizontal
      // start y = 0
      startX = Math.round((rect.width - rect.height * imageSize.ratio) / 2);
      ratio = heightRatio;
    }
    const fileX = Math.round((x - startX) * ratio);
    const fileY = Math.round((y - startY) * ratio);
    const remoteX = Math.round(fileX / screen?.size);
    const remoteY = Math.round(fileY / screen?.size);
    return {
      imgX: x,
      imgY: y,
      fileX,
      fileY,
      remoteX,
      remoteY,
      insideFile:
        fileX >= 0 &&
        fileY >= 0 &&
        fileX < imageSize.width &&
        fileY < imageSize.height,
    };
  };
  const onClick = (pageX, pageY) => {
    if (inProgress) return;
    const {imgX, imgY, remoteX, remoteY, insideFile} = getRemoteXY(
      pageX,
      pageY
    );

    if (!insideFile) return;

    setLoading(true);
    setClickPoint([imgX, imgY]);
    remoteClick({projectId, deviceId, x: remoteX, y: remoteY})
      .then(() => {
        refresh();
      })
      .catch((err) => {
        // stop
        progressClear();
      });
  };
  const onSwipeStart = (pageX, pageY) => {
    const {imgX, imgY, remoteX, remoteY, insideFile} = getRemoteXY(
      pageX,
      pageY
    );
    if (inProgress || !insideFile) return;
    setSwipeStartPoint([imgX, imgY]);
    setSwipeStart([remoteX, remoteY, Date.now()]);
  };
  const onSwipeEnd = (pageX, pageY) => {
    if (inProgress) return;
    const {imgX, imgY, remoteX, remoteY, insideFile} = getRemoteXY(
      pageX,
      pageY
    );
    if (!insideFile || !swipeStart) {
      return progressClear();
    }
    if (swipeStart[0] === remoteX && swipeStart[1] === remoteY) {
      setSwipeStartPoint(null);
      setSwipeStart(null);
      return onClick(pageX, pageY);
    }
    setSwipeEndPoint([imgX, imgY]);
    setLoading(true);
    remoteSwipe({
      projectId,
      deviceId,
      x1: swipeStart[0],
      y1: swipeStart[1],
      x2: remoteX,
      y2: remoteY,
      ms: Date.now() - swipeStart[2],
    })
      .then(() => {
        refresh();
      })
      .catch(() => {
        // stop
        progressClear();
      });
  };
  const onTouchStart = (e) => {
    // only handle single touch
    const touches = e.changedTouches;
    if (touches.length !== 1) return;

    const touch = touches.item(0);
    onSwipeStart(touch.pageX, touch.pageY);
  };
  const onTouchEnd = (e) => {
    // only handle single touch
    const touches = e.changedTouches;
    if (touches.length !== 1) return;

    const touch = touches.item(0);
    onSwipeEnd(touch.pageX, touch.pageY);
  };
  const onMouseDown = (e) => {
    onSwipeStart(e.pageX, e.pageY);
  };
  const onMouseUp = (e) => {
    onSwipeEnd(e.pageX, e.pageY);
  };

  const screencap = (
    <img
      alt="Sreen"
      style={{
        height: "100%",
        width: "100%",
        objectFit: "contain",
        opacity: inProgress ? 0.3 : 1,
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
      }}
      src={screen?.path}
      ref={imageRef}
      onLoad={onLoad}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      draggable={false}
    />
  );
  const clickIcon = (
    <ControlPointIcon
      color="primary"
      style={{
        position: "absolute",
        left: clickPoint?.[0],
        top: clickPoint?.[1],
        transform: "translate(-50%, -50%)",
        display: clickPoint && inProgress ? "block" : "none",
      }}
    />
  );
  const startDiv = (
    <div
      id="divSwipeStart"
      style={{
        position: "absolute",
        left: swipeStartPoint?.[0],
        top: swipeStartPoint?.[1],
      }}
    />
  );
  const endDiv = (
    <div
      id="divSwipeEnd"
      style={{
        position: "absolute",
        left: swipeEndPoint?.[0],
        top: swipeEndPoint?.[1],
      }}
    />
  );
  const swipeArrow = (
    <Xarrow
      start="divSwipeStart"
      end="divSwipeEnd"
      // straight will trigger two svg errors
      path={"straight"}
      color="#F78130"
    />
  );

  const onClickPanelControl = (index) => {
    setShowKeyboard(currentControl !== 4 && index === 4);
    setShowApps(currentControl !== 5 && index === 5);
    setShowFiles(currentControl !== 6 && index === 6);

    setCurrentControl(index < 4 || currentControl === index ? null : index);
  };
  const controlButtons = [
    {
      icon: (
        <span className="material-symbols-outlined" style={{fontSize: 36}}>
          arrow_back_ios_new
        </span>
      ),
      onClick: back,
      label: DeviceStrings.REMOTE_INPUT_BACK,
      disabled: inProgress,
    },
    {
      icon: (
        <span className="material-symbols-outlined" style={{fontSize: 36}}>
          radio_button_unchecked
        </span>
      ),
      onClick: home,
      label: DeviceStrings.REMOTE_INPUT_HOME,
      disabled: inProgress,
    },
    {
      icon: (
        <span className="material-symbols-outlined" style={{fontSize: 36}}>
          check_box_outline_blank
        </span>
      ),
      onClick: recent,
      label: DeviceStrings.REMOTE_INPUT_RECENT,
      disabled: inProgress,
    },
    {
      main: true,
      icon: (
        <span className="material-symbols-outlined" style={{fontSize: 36}}>
          center_focus_strong
        </span>
      ),
      onClick: refresh,
      label: DeviceStrings.REMOTE_REFRESH,
      disabled: inProgress,
    },
    {
      icon: (
        <span className="material-symbols-outlined" style={{fontSize: 36}}>
          keyboard
        </span>
      ),
      onClick: onClickPanelControl,
      label: DeviceStrings.REMOTE_INPUT_LABEL,
    },
    {
      icon: (
        <span className="material-symbols-outlined" style={{fontSize: 36}}>
          apps
        </span>
      ),
      onClick: onClickPanelControl,
      label: DeviceStrings.REMOTE_APPS,
    },
    {
      icon: (
        <span className="material-symbols-outlined" style={{fontSize: 36}}>
          upload
        </span>
      ),
      onClick: onClickPanelControl,
      label: DeviceStrings.REMOTE_FILE_LABEL,
    },
  ];

  return (
    <div id="screenContainer" className={classes.screenContainer}>
      <Paper
        className={classes.screenImageFrame}
        elevation={1}
        style={{
          width: frameSize.width,
          height: frameSize.height,
        }}
      >
        <div className={classes.screenImageContainer}>
          {screen?.path && (
            <>
              {screencap}
              {clickIcon}
              {startDiv}
              {endDiv}
              {swipeEndPoint && swipeArrow}
            </>
          )}
          <ControlPanel open={showKeyboard}>
            <RemotePanelInput
              projectId={projectId}
              deviceId={deviceId}
              disabled={inProgress}
              onStart={progressStart}
              onComplete={progressClear}
            />
          </ControlPanel>
          <ControlPanel open={showApps}>
            <RemotePanelApps
              projectId={projectId}
              deviceId={deviceId}
              disabled={inProgress}
              onStart={progressStart}
              onComplete={progressClear}
            />
          </ControlPanel>
          <ControlPanel open={showFiles}>
            <RemotePanelFiles
              projectId={projectId}
              deviceId={deviceId}
              disabled={inProgress}
              onStart={progressStart}
              onComplete={progressClear}
            />
          </ControlPanel>
        </div>
        <ScreenControl controls={controlButtons} active={currentControl} />
      </Paper>
    </div>
  );
};

export default ScreenCard;
