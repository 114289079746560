const STRINGS = {
  SORT_OPTION_MOSTRECENT: "Most Recent",
  SORT_OPTION_IMEI: "IMEI",
  SORT_OPTION_STOREID: "Store ID",
  SORT_OPTION_ONLINE: "Online",
  SORT_OPTION_COMPLIANCE: "Compliance",

  TAB_GENERAL: "General",
  TAB_NOTES: "Note",
  TAB_MESSAGES: "Message",
  TAB_TRANSFER: "Transfer",
  TAB_TRANSFER_CC: "Transfer (CC)",
  TAB_REMOTE: "Remote",
  TAB_CC: "CC",

  MENU_CREATE_DISPLAY_DEVICE: "Display Device",
  MENU_CREATE_QR_DEVICE: "QR Device",
  MENU_DECOMMISSION_DEVICES: "Decommission Devices",
  MENU_LIVE_DEVICES: "Live Devices",
  MENU_EXPORT_CSV: "Export to CSV",
  MENU_EXPORT_CSV_ADMIN: "Export to CSV (admin)",
  MENU_EXPORT_CSV_DOWNLOADING: "Export to CSV (Downloading...)",
  MENU_SELECT_MULTI_DEVICES: "Select Multiple Devices",

  LAST_CHECKED: "Last checked:",
  NEVER: "Never",
  LOADING: "...",
  DECOMMISION_DESC: "You are currently viewing decommission devices.",
  LIVE_DEVICES_BUTTON: "View Live Devices",
  REQUEST_SENT: "Request sent",

  STORE_TITLE: "Store Details",
  DEVICE_PIN_HELPER: "Pin number shown on the device",
  STORE_BUTTON: "Edit Store",
  STORE_ID: "Store ID:",
  STORE_POSTCODE: "Postcode:",
  STORE_COUNTRY: "Country:",
  STORE_RETAILER: "Retailer:",
  STORE_MAP_INVALID: "Failed to identify location",
  STORE_EDIT_ID: "Store ID",
  STORE_ID_NAME: "Store Name / Store ID",
  STORE_PIN_CODE: "Device Pin",
  STORE_EDIT_POSTCODE: "Postcode",
  STORE_EDIT_RETAILER: "Retailer",
  STORE_EDIT_COUNTRY: "Country",
  STORE_UPDATED: "Store details updated.",
  STORE_CHOOSE_COUNTRY_LABEL: "Choose a country",
  STORE_CHOOSE_RETAILER_LABEL: "Choose a retailer",
  STORE_CONFIRM_DETAILS: "Confirm Details",

  NOTE_UPDATED: "Note updated.",

  ONLINE_TITLE_ON: "Online",
  QR_CODE: "QR Code",
  QR_CODE_DESC: "Here is the link for this device",
  ONLINE_TITLE_OFF: "Offline",
  ONLINE_TITLE_ERROR: "Requires attention",
  ONLINE_BUTTON: "Request Online",
  DOWNLOAD: "Download",
  ONLINE_DESC: "Check the connectivity status of this device.",
  LAST_ONLINE: "Last online:",
  COMPLIANCE_TITLE_COM: "Compliant",
  COMPLIANCE_TITLE_NON: "Non-compliant",
  COMPLIANCE_BUTTON: "Check Compliance",
  COMPLIANCE_DESC: "Check media and app version on this device",

  REMOTE_TITLE: "Remote Access",
  REMOTE_BUTTON: "Cloud Control",
  REMOTE_DESC: "Gain remote access to this device",

  COMMAND_TITLE: "Command",
  COMMAND_EDIT_LABEL: "Execute SU command",
  COMMAND_SEND_LABEL: "Send",
  COMMAND_TARGET: "Target",
  COMMAND_ACTION: "Action",
  COMMAND_ADVANCED: "Advanced",
  COMMAND_GET_LINK: "Link",

  DEPLOY_TITLE: "Deployment",
  DEPLOY_BUTTON: "Deploy Content",
  LAST_DEPLOYED: "Last deployed:",
  DEPLOY_DESC: "Deploy the latest media and app to this device.",
  DEPLOY_CONFIRM_MSG:
    "Are you sure you want to deploy media content to this device?",
  DEPLOY_PROGRESS_TITLE: "Deployment Status",
  COMPLIANCE_CHECK_PROGRESS_TITLE: "Compliance Check Status",
  DEPLOY_HEADER_DEPLOY: "Deployment:",
  DEPLOY_STATUS_DEPLOYING: "Deploying",
  DEPLOY_STSTUA_COMPLETED: "Completed",
  DEPLOY_STSTUA_NEVER: "Never",
  DEPLOY_HEADER_FILES_UPDATED: "Files Updated:",
  DEPLOY_HEADER_APPS_INSTALLED: "Apps Installed:",

  DEPLOY_OVERALL_STATUS: {
    COMPLETED: "Device Ready",
    FAILED: "Deployment Failed",
    PENDING: "Deployment Required",
    WORKING: "Deployment to Start",
    DOWNLOAD_FILES: "Downloading files",
    COPY_FILES: "Copying files",
    INSTALL_APPLICATIONS: "Installing apps",
    EXECUTE_COMMANDS: "Executing commands",
  },
  COMPLIANCE_CHECK_OVERALL_STATUS: {
    COMPLETED: "Device Ready",
    FAILED: "Compliance Check Failed",
    PENDING: "Deployment Required",
    WORKING: "Compliance Check to Start",
    DOWNLOAD_FILES: "Checking files",
    COPY_FILES: "Checking files",
    INSTALL_APPLICATIONS: "Checking apps",
    EXECUTE_COMMANDS: "Checking commands",
  },
  DEPLOY_PROGRESS_STATUS: {
    COMPLETED: "Deployment completed",
    FAILED: "Deployment failed",
    WORKING: "Deployment in progress",
    PENDING: "Deployment status checked",
    NOT_STARTED: "Deployment not started",
  },
  COMPLIANCE_CHECK_PROGRESS_STATUS: {
    COMPLETED: "Compliance check completed",
    FAILED: "Compliance check failed",
    WORKING: "Compliance check in progress",
    PENDING: "Compliance status checked",
    NOT_STARTED: "Compliance check not started",
  },
  DEPLOY_TASK_TYPE: {
    COPY_APP_FILE_TO_EXTERNAL: "Copy Files",
    DOWNLOAD_CUSTOM_FILE: "Download Files",
    DOWNLOAD_MEDIA_FILE: "Download Media Files",
    EXECUTE_COMMAND: "Execute Commands",
    INSTALL_APPLICATION: "Install Applications",
    UNKNOWN: "Unknown Task",
  },
  COMPLIANCE_CHECK_TYPE: {
    COPY_APP_FILE_TO_EXTERNAL: "Check Files",
    DOWNLOAD_CUSTOM_FILE: "Check Files",
    DOWNLOAD_MEDIA_FILE: "Check Media Files",
    EXECUTE_COMMAND: "Check Commands",
    INSTALL_APPLICATION: "Check Applications",
    UNKNOWN: "Unknown Task",
  },
  DEPLOY_STATUS_FAILED: "Failed",
  DEPLOY_STATUS_WORKING: "Working",
  DEPLOY_STATUS_PENDING: "Pending",

  SIM_TITLE: "SIM",
  SIM_BUTTON: "Edit SIM",
  SIM_NUMBER: "SIM number:",
  SIM_EDIT: "SIM number",
  SIM_UPDATED: "SIM number updated.",
  SIM_FAILED_TO_UPDATE: "Failed to update SIM number",

  REMOTE_REFRESH_SCREEN: "Refresh Screen",
  REMOTE_INPUT_LABEL: "Input",
  REMOTE_COMMAND_LABEL: "Command",
  REMOTE_FILE_LABEL: "File",
  REMOTE_LOG_LABEL: "Log",
  REMOTE_INPUT_HOME: "Home",
  REMOTE_INPUT_RECENT: "Recent",
  REMOTE_INPUT_BACK: "Back",
  REMOTE_REFRESH: "Refresh",
  REMOTE_FILE_SOURCE_PATH_LABEL: "Source file path",
  REMOTE_FILE_DEVICE_PATH_LABEL: "Device file path",
  REMOTE_FILE_PATH_LABEL: "Device file path",
  REMOTE_FILE_PUT_LABEL: "Put",
  REMOTE_FILE_GET_LABEL: "Get",
  REMOTE_APPS: "Apps",

  REMOTE_PANEL_INPUT_TITLE: "Text Fields",
  REMOTE_PANEL_INPUT_DESC: "Easily input text into a text field",
  REMOTE_PANEL_INPUT_TEXT_LABEL: "Insert Text",
  REMOTE_PANEL_APPS_TITLE: "Apps Control +",
  REMOTE_PANEL_APPS_DESC: "Send special command to app installed on the device",
  REMOTE_PANEL_APPS_BUTTON_LABEL: "Send Command",
  REMOTE_PANEL_FILES_PUT_TITLE: "Put",
  REMOTE_PANEL_FILES_PUT_DESC: "Upload a file to the device",
  REMOTE_PANEL_FILES_PUT_BUTTON_LABEL: "Upload to Device",
  REMOTE_PANEL_FILES_PUT_TEXT_LABEL: "Enter Source File Path",
  REMOTE_PANEL_FILES_GET_TITLE: "Get",
  REMOTE_PANEL_FILES_GET_DESC: "Download a file from the device",
  REMOTE_PANEL_FILES_GET_BUTTON_LABEL: "Download",
  REMOTE_PANEL_FILES_GET_TEXT_LABEL: "Enter Device File Path",

  RETIRE_TITLE: "Retire Device",
  RETIRE_BUTTON: "Decommission",
  RETIRE_DESC: "Archive this device if it's no longer in use.",
  RETIRE_CONFIRM_MSG: "Are you sure you want to decommission this device?",
  RETIRE_SUCCEED: "Device successfully decommissioned.",
  RETIRE_ACTIVATE_TITLE: "Activate Device",
  RETIRE_ACTIVATE_BUTTON: "Re-Commission",
  RETIRE_ACTIVATE_DESC:
    "Re-activate this device to put back into live devices.",
  RETIRE_ACTIVATE_CONFIRM_MSG: "Are you sure you want to activate this device?",
  RETIRE_ACTIVATE_SUCCEED: "Device successfully re-activated.",
  RETIRE_DATE: "Decommissioned on:",

  TRANSFER_DESC: "Select a project to transfer device to",
  TRANSFER_SEARCH: "Search project",
  TRANSFER_BUTTON: "Transfer",
  TRANSFER_CONFIRM: "Are you sure you want to transfer to this project?",
  TRANSFER_TITLE: "Transferring",
  TRANSFER_PROGRESS: "Moving device to new project...",
  TRANSFER_COMPLETED: "Device transferred or being transferred",

  INSTRUCTION_TITLE: "How to add a new device",
  INSTRUCTION_DOWNLOAD_CAPTION: "Download MyProject",
  INSTRUCTION_DOWNLOAD_DESC:
    "From the device that you would like to add, go to the following website and download our latest app",
  INSTRUCTION_DOWNLOAD_EXTRA: "app.myplayer.io/download",
  INSTRUCTION_INSTALL_CAPTION: "Install MyProject",
  INSTRUCTION_INSTALL_DESC:
    "Navigate to the download folder, select and install the app by following the on screen instructions",
  INSTRUCTION_ADD_CAPTION: "Add to project",
  INSTRUCTION_ADD_DESC:
    "Launch the MyProject app and use the project Share Code to add the device to this project",
  INSTRUCTION_ADD_EXTRA:
    "You can find the project Share Code in Team and selecting the + icon on left hand side. Alternatively, go there now via the button below:",
  INSTRUCTION_ADD_BUTTON: "Share Code",

  CREATE_DEVICE_BUTTON: "Create Devices",
  CREATE_DEVICE_TITLE: "Create QR Devices",
  CREATE_DEVICE_DESC:
    "Choose the number of QR devices to generate for your project",
  DEVICE_NUMBER: "Number",
  DEVICES_CREATED: "Devices Created",
  CREATING_DEVICES: "Creating Devices",
  CREATING_DEVICES_PROGRESS: "Creating devices for your project...",

  LIST_MORE_HIDDEN_DEVICES: "{} more hidden devices",
  NO_DEVICES_TITLE: "No devices in your project",
  NO_DEVICES_TITLE_DESC: "Devices display your project's media",
  PLACEHOLDER_DESC_MOBILE: "To get started, select the orange Add icon.",

  STAT_TOTAL: "Total",
  STAT_ONLINE: "Online",
  STAT_COMPLIANT: "Compliant",
  STAT_DECOMMISSIONED: "Decommissioned",
  STAT_SMART_TAGS: "SmartTags",

  EXPORT_DEVICES: "Download will start once all data is ready.",

  MESSAGES_PLACEHOLDER: "Type a message",
  MESSAGES_FILE_TYPE_ERROR:
    "That file type is not supported. Please upload an image or a video.",

  INVALID_DEVICE_MSG: "Invalid device",

  MULTI_ONLINE_CONFIRM:
    "Are you sure you want to request online for {x} devices?",
  MULTI_COMPLIANCE_CONFIRM:
    "Are you sure you want to check compliance for {x} devices?",
  MULTI_DEPLOY_CONFIRM:
    "Are you sure you want to deploy content to {x} devices?",
  MULTI_SELECT_ALL: "Select All",
  MULTI_SELECT_NONE: "Select None",
  MULTI_SELECT_CANCEL: "Cancel",

  FILTER_COUNTRY: "Country",
  FILTER_TAG: "Tag",
  FILTER_RETAILER: "Retailer",
  FILTER_ALL: "All",

  CONTROL_NA_TITLE: "Remote control is not available on mobile.",
  CONTROL_NA_DESC:
    "Please visit our website from a laptop or PC to take full advantage of our services.",

  GENERAL_HEARTBEAT_TITLE: "Heart Beat",
  GENERAL_HEARTBEAT_TODAY: "Today",

  GENERAL_EDIT_TAG_TITLE: "Tags",
  GENERAL_EDIT_TAG_BUTTON: "Edit tag",
  TAG_BUTTON: "Add Tags",
  GENERAL_EDIT_TAG_INPUT_TITLE: "Add tag...",
  GENERAL_EDIT_TAG_MAX_LIMIT: "Maximum device limit is {number}!",

  TAGS_UPDATE_SUCCEED: "Tags successfully updated",
  TAGS_UPDATE_ERROR: "Something went wrong with updating tags",

  CONNECTED_DEVICES_TITLE: "Connected Devices",
  CONNECTED_DEVICES_REFRESH: "Refresh",

  CREATE_SMART_TAGS_BUTTON: "Create Smart Tags",
  CREATE_SMART_TAGS_NO_SMART_TAGS: "No Smart Tag selected",
  CREATE_SMART_TAGS_DIALOG_TITLE: "Create Smart Tags",
  CREATE_SMART_TAGS_DIALOG_URL_TITLE: "Smart Tags",
  CREATE_SMART_TAGS_DIALOG_DOWNLOAD_BUTTON_TITLE: "Download Smart Tags",
  CREATE_SMART_TAGS_DIALOG_SELECTED_TITLE: "Selected Smart Tags",
  CREATE_SMART_TAGS_DIALOG_DOWNLOADING_TITLE: "Creating Smart Tags",
  CREATE_SMART_TAGS_DIALOG_DOWNLOADING_DESC:
    "Please wait for the download to begin",

  CHATROOM_NO_MESSAGE_TITLE: "No activity in your device",
  CHATROOM_NO_MESSAGE_DESC: "There has been no activity in your device",
};

export default STRINGS;
