import { trackToMyPlayerGA } from "services/ApiService";

const TAG = "[ MyGA ]";

class MyPlayerGA {
    constructor(deviceId) {
        this.deviceId = deviceId;
        this.client_id = Date.now().toString();
        this.session_id = this.client_id;
        this.engagement_time_msec = 0;
    }

    getEngagementTime = () => {
        let new_engagement_time_msec = Date.now() - this.engagement_time_msec;
        this.engagement_time_msec = Date.now();
        if (new_engagement_time_msec === 0) {
            new_engagement_time_msec = 1;
        }
        return new_engagement_time_msec;
    }

    sendEventGA4 = (event) => {
        event.params.deviceId = this.deviceId;
        event.params.session_id = this.session_id;
        event.params.engagement_time_msec = this.getEngagementTime();
        const data = {
            client_id: this.client_id,
            events: [event],
        };
        trackToMyPlayerGA(data);
    }

    track = (name, params = {}) => {
        console.info(`${TAG} track: ${name} = ${JSON.stringify(params)}`);
        const event = {
            name,
            params 
        };
        this.sendEventGA4(event);
    }

    set(key, value) {
        console.info(`${TAG} set: ${key} = ${JSON.stringify(value)}`);
    }
}

export const createMyPlayerAnalytics = (id, options, callback) => {
    return new MyPlayerGA(id, options, callback);
};
