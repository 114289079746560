export const GLOBAL_ACION_TYPES = {
  ORGANISATION_CREATE: "ORGANISATION_CREATE",
  ORGANISATION_INVITE: "ORGANISATION_INVITE",
  ORGANISATION_LEAVE: "ORGANISATION_LEAVE",
  ORGANISATION_DELETE: "ORGANISATION_DELETE",
  PROJECT_TRANSFER: "PROJECT_TRANSFER",
  PROJECT_DELETE: "PROJECT_DELETE",
  PROJECT_JOIN: "PROJECT_JOIN",
  PROJECT_LEAVE: "PROJECT_LEAVE",
  PROJECT_CREATE: "PROJECT_CREATE",
  PROJECT_TEAM_MANAGE: "PROJECT_TEAM_MANAGE",
  PROJECT_ANALYTICS_CODE: "PROJECT_ANALYTICS_CODE",
  PROJECT_ANALYTICS_TRACKING_DETAILS: "PROJECT_ANALYTICS_TRACKING_DETAILS",
  PROJECT_MEDIA_EDIT: "PROJECT_MEDIA_EDIT",
  PROJECT_MEDIA_APPROVE: "PROJECT_MEDIA_APPROVE",
  PROJECT_MEDIA_SHARE: "PROJECT_MEDIA_SHARE",
  PROJECT_MEDIA_ACTIVITY: "PROJECT_MEDIA_ACTIVITY",
  PROJECT_DEVICES_QR_DEVICE_CREATE: "PROJECT_DEVICES_QR_DEVICE_CREATE",
  PROJECT_DEVICES_INSTRACTION: "PROJECT_DEVICES_INSTRACTION",
  PROJECT_DEVICES_MULTIPLE: "PROJECT_DEVICES_MULTIPLE",
  PROJECT_DEVICES_LIVE: "PROJECT_DEVICES_LIVE",
  PROJECT_DEVICES_EXPORTING: "PROJECT_DEVICES_EXPORTING",
  PROJECT_DEVICES_EXPORTING_ADMIN: "PROJECT_DEVICES_EXPORTING_ADMIN",
};
