import { makeStyles, styled } from "@material-ui/core/styles";
import { ListItemText } from "@material-ui/core";

export const TextWrapper = styled('div')({
  '&': {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: '32px',
  },
});

export const MainText = styled(ListItemText)({
  '&': {
    flex: "unset",
  },
});

export const AdditionalText = styled(ListItemText)({
  '&': {
    flex: "unset",
    '& > span': {
      fontFamily: 'myplayer-monospace',
    }
  },
});

export const useStyles = makeStyles((theme) => ({
  formControl: (mobile) => ({
    padding: theme.spacing(mobile ? 2 : 1),
    minWidth: 150,
    maxWidth: 450,
    width: mobile ? "100%" : "auto",
  }),
  formLabel: (mobile) => ({
    padding: theme.spacing(mobile ? 2 : 1),
  }),
}));