import React from "react";

import { openCloudControl } from "../../utils/deviceUtils";

import ButtonCard from "./ButtonCard";

import DevicesIcon from "@material-ui/icons/Devices";

import { DeviceStrings } from "../../strings";

const DeviceRemoteCard = ({ deviceId, showDesc, canRead, canEdit }) => {
  const configCard = {
    icon: <DevicesIcon />,
    title: DeviceStrings.REMOTE_TITLE,
    buttonLabel: DeviceStrings.REMOTE_BUTTON,
    desc: showDesc ? DeviceStrings.REMOTE_DESC : null,
    fullHeight: true,
    onClick: () => {
      openCloudControl(deviceId);
    },
    disableButton: !canEdit,
  };

  return (
    <ButtonCard config={configCard} canRead={canRead}>
      {showDesc ? <br /> : DeviceStrings.REMOTE_DESC}
    </ButtonCard>
  );
};

export default DeviceRemoteCard;
