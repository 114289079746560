import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
  },
  itemContainer: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  main: ({ mobile }) => ({
    display: "flex",
    flexWrap: "wrap",
    flexDirection: mobile ? "column" : "row",
    [theme.breakpoints.up("md")]: {
      width: "800px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  }),
}));
