import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { useStyles } from "./style";

export const TextInput = ({
  name,
  label,
  value = "",
  isInValid,
  endIcon,
  endActionIcon,
  fullWidth = true,
  pattern,
  onChange,
  onKeyDown,
  onClick,
}) => {
  const classes = useStyles();

  const renderEndAdorment = () => {
    if (!endIcon && !endActionIcon) return <></>;
    if (endIcon) return endIcon;
    if (endActionIcon && onClick)
      return (
        <IconButton
          name={name}
          aria-label={`input ${name}`}
          onClick={onClick}
          edge="end"
        >
          {endActionIcon}
        </IconButton>
      );
  };

  return (
    <FormControl variant="filled" fullWidth={fullWidth}>
      <InputLabel htmlFor={`filled-input${name ? `-${name}` : ""}`}>
        {label}
      </InputLabel>
      <FilledInput
        id={`filled-input${name ? `-${name}` : ""}`}
        className={classes.textfield}
        label={label}
        value={value}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        error={isInValid}
        inputProps={{ pattern }}
        endAdornment={
          <InputAdornment position="end">{renderEndAdorment()}</InputAdornment>
        }
        fullWidth={fullWidth}
      />
    </FormControl>
  );
};
