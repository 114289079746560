import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";

import {
  sessionLogin,
  redirectToLogin,
  redirectToSignup,
} from "../../services/AuthService";

import { ROUTES } from "../../route";

const HomePage = (props) => {
  const login = useRouteMatch(ROUTES.LOGIN);

  const from = props.location.state?.from;

  useEffect(() => {
    // try to login with session
    // do nothing if succeed since AuthRoute will redirect
    sessionLogin().catch(() => {
      // if failed, redirect to accounts
      if (login) redirectToLogin(from);
      else redirectToSignup(from);
    });
  }, [from, login]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default HomePage;
