import React, {memo} from "react";
import {makeStyles} from "@material-ui/core/styles";

import ScreenCard from "./cards/ScreenCard";
import RestrictedContent from "./RestrictedContent";
import Spinner from "./Spinner";
import MobileContent from "./MobileContent";

import {useMobileLayout} from "../hooks/uiHooks";
import {areDictsEqual} from "../utils/generalUtils";
import {isLoading} from "../utils/uiUtils";

import {DeviceStrings} from "../strings";

const useStyles = makeStyles((theme) => ({
  deviceGeneralRoot: (mobile) => ({
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
    display: "flex",
    flexWrap: mobile ? "wrap" : "nowrap",
    overflow: "auto",
  }),
  stretchWidth: (mobile) => ({
    flexGrow: 1,
    maxHeight: "100%",
    width: mobile ? "100%" : 1,
    padding: mobile ? 0 : theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }),
  stretchHeight: (mobile) => ({
    flexGrow: mobile ? 0 : 1,
    height: mobile ? "auto" : 1,
    paddingTop: theme.spacing(2),
    "&:first-child": {
      paddingTop: 0,
    },
    minHeight: mobile ? 140 : 100,
    boxSizing: "content-box",
  }),
  decommissionContainer: {
    minHeight: 160,
  },
}));

const DeviceRemote = ({
  deviceId,
  projectId,
  halfOpened,
  permissions,
  active,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  if (isLoading(deviceId)) return <Spinner />;

  // const fullSize = mobile || !halfOpened;

  if (mobile)
    return (
      <MobileContent
        title={DeviceStrings.CONTROL_NA_TITLE}
        desc={DeviceStrings.CONTROL_NA_DESC}
      />
    );

  const liveContent = permissions?.canWriteDevices && (
    <div className={classes.deviceGeneralRoot}>
      <div className={classes.stretchWidth}>
        <div className={classes.stretchHeight}>
          <ScreenCard
            projectId={projectId}
            deviceId={deviceId}
            active={active}
          />
        </div>
      </div>
    </div>
  );

  return (
    <RestrictedContent permitted={permissions?.canWriteDevices}>
      {liveContent}
    </RestrictedContent>
  );
};

const isEqual = (props1, props2) =>
  props1?.projectId === props2?.projectId &&
  props1?.deviceId === props2?.deviceId &&
  props1?.halfOpened === props2?.halfOpened &&
  props1?.active === props2?.active &&
  areDictsEqual(props1.permissions, props2.permissions);

export default memo(DeviceRemote, isEqual);
