import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  domainsWrapper: {
    margin: "0 -8px",
  },
  chipEndIcon: {
    color: "inherit",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  domainDescription: {
    marginTop: theme.spacing(2),
    color: "#777777",
    fontSize: "12px",
  },
}));
