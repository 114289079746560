import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import { getOrgPermissionName } from "utils/permissionUtils";
import { useStyles } from "./style";
import { SendIcon, DeleteIcon } from "assets/icons";
import { useTeamOrganisationPermissionsOptions } from "services/PermissionService";

export function Actions({
  permissionsKey,
  progress,
  onResend,
  onDelete,
  onClick,
}) {
  const classes = useStyles();
  const permissionsOptions = useTeamOrganisationPermissionsOptions();

  return (
    <div className={classes.container}>
      <Box className={classes.userRoleWrapper} onClick={onClick}>
        <Typography className={classes.userRole}>
          {getOrgPermissionName(permissionsKey, permissionsOptions)}
        </Typography>
      </Box>
      <div>
        <IconButton
          className={classes.button}
          onClick={onResend}
          disabled={progress}
        >
          <SendIcon />
        </IconButton>
        <IconButton
          className={classes.button}
          onClick={onDelete}
          disabled={progress}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}
