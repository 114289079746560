export const getItemLocalStorage = ({ key }) => {
  try {
    const value = window.localStorage.getItem(key);
    console.debug("local storage value", value);
    return value;
  } catch (e) {
    console.log(e);
    return;
  }
};

export const setItemLocalStorage = ({ key, value }) => {
  try {
    window.localStorage.setItem(key, value);
    return true;
  } catch (e) {
    console.log(e);
    return;
  }
};
