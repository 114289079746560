import { useEffect, useState } from "react";
import { API_KEY } from "../configs/mapsConfig";

import { getCountryFromLocale } from "../utils/localeUtils";
import { isLoading } from "../utils/uiUtils";

const MAP_VALID_MIN_LENGTH = 5544;

const DEFAULTS = {
  ZOOM: 15,
  WIDTH: 300,
  HEIGHT: 300,
  COLOR: "red",
  SIZE: "small",
};

// construct a query string for Google Maps static map API
// valid only if all params are provided
// eg. Myplayer,SE162DB,United+Kingdom
const getLocationQueryString = ({ place, postcode, country }) => {
  let query = "";
  if (!place || !postcode || !country) return null;
  if (place) query += place;
  if (postcode) query += query.length > 0 ? `,${postcode}` : postcode;
  if (country) {
    const c = getCountryFromLocale(country);
    const res = c ?? country;
    query += query.length > 0 ? `,${res}` : res;
  }
  return query.replace(/ /g, "+");
};

const getMapUrl = ({ location, options }) => {
  if (!location) return location;
  if (!options) return options;
  const locationQuery = getLocationQueryString(location);
  if (!locationQuery) return locationQuery;
  const { width, height, zoom } = options;
  const color = DEFAULTS.COLOR;
  const size = DEFAULTS.SIZE;
  return `https://maps.googleapis.com/maps/api/staticmap?center=${locationQuery}&zoom=${zoom}&size=${width}x${height}&markers=color:${color}|size:${size}|${locationQuery}&key=${API_KEY}`;
};

// object to cache URL valid results to avoid unnecessary fetch
const mapValid = {};

const isMapRespValid = (resp) =>
  // TODO: hack assume invalid image size is 5544
  parseInt(resp.headers.get("content-length")) > MAP_VALID_MIN_LENGTH;

// const location = {
//   place: "Myplayer",
//   postcode: "SE162DB",
//   country: "GB",
// };
// const options = {
//     width: 600,
//     height: 600,
//     zoom: 15,
// }
export const useMapUrl = ({ location, options }) => {
  const url = getMapUrl({ location, options });
  const [result, setResult] = useState();

  useEffect(() => {
    if (isLoading(url)) return;
    if (!url) {
      setResult(null);
      return;
    }
    if (mapValid[url]) {
      console.debug(`useMapUrl: valid from cache`, url);
      setResult(url);
      return;
    }

    // get HEAD of url
    fetch(url, { method: "HEAD" })
      .then((resp) => {
        const valid = isMapRespValid(resp);
        console.debug(`useMapUrl: fetch result`, valid, url);
        setResult(valid ? url : null);
        if (valid) mapValid[url] = true;
      })
      .catch((err) => {
        console.error("useMapValid", err);
        setResult(null);
      });
  }, [url]);

  return result;
};
