import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DeviceNoteCard from "../ui/cards/DeviceNoteCard";
import RestrictedContent from "./RestrictedContent";
import Spinner from "./Spinner";

import { useMobileLayout } from "../hooks/uiHooks";
import { areDictsEqual } from "../utils/generalUtils";
import { isLoading } from "../utils/uiUtils";

const useStyles = makeStyles((theme) => {
  return {
    root: (mobile) => ({
      height: "100%",
      width: "100%",
      display: "flex",
      // alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(mobile ? 1 : 2),
    }),
    container: (mobile) => ({
      maxHeight: `min(100%, 400px)`,
      width: mobile ? "100%" : 600,
      padding: theme.spacing(1),
    }),
  };
});

const DeviceNotes = ({ deviceId, projectId, permissions, active }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  if (isLoading(deviceId) || isLoading(active)) return <Spinner />;

  return (
    <RestrictedContent permitted={permissions?.canReadDeviceExtra}>
      <div className={classes.root}>
        <div className={classes.container}>
          {active && (
            <DeviceNoteCard
              deviceId={deviceId}
              projectId={projectId}
              canRead={permissions?.canReadDeviceExtra}
              canEdit={permissions?.canWriteDeviceExtra}
            />
          )}
        </div>
      </div>
    </RestrictedContent>
  );
};

const isEqual = (props1, props2) =>
  props1?.projectId === props2?.projectId &&
  props1?.deviceId === props2?.deviceId &&
  props1?.active === props2?.active &&
  areDictsEqual(props1.permissions, props2.permissions);

export default memo(DeviceNotes, isEqual);
