import React, { forwardRef } from "react";
import MenuItem from "@material-ui/core/MenuItem";

import { isCodeMutable } from "utils/teamUtils";
import { TeamCodeStrings } from "strings";

export const MenuItemDelete = forwardRef((props, ref) => {
  const { info, canEdit, onDelete } = props;
  const canDelete = isCodeMutable(info) && canEdit;

  return (
    <MenuItem ref={ref} onClick={onDelete} disabled={!canDelete}>
      {TeamCodeStrings.TEAM_CODE_MENU_DELETE}
    </MenuItem>
  );
});
