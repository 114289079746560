import React, { useState } from "react";
import { TextField } from "@material-ui/core";

import { useSnackbar } from "notistack";
import { useDeviceSim } from "../../services/DeviceService";
import { updateDeviceSim } from "../../services/ApiService";
import { isUndefined } from "../../utils/generalUtils";
import { displayLoadingText } from "../../utils/localeUtils";

import ButtonCard from "./ButtonCard";
import MyDialog from "../MyDialog";

import SimCardOutlinedIcon from "@material-ui/icons/SimCardOutlined";

import { DeviceStrings } from "../../strings";

const DeviceSimCard = ({
  projectId,
  deviceId,
  fullHeight = true,
  canRead,
  canEdit,
}) => {
  const sim = useDeviceSim(deviceId);
  const [editSim, setEditSim] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const currentSim = !isUndefined(editSim) ? editSim : sim;

  const desc = (
    <>
      <b>{DeviceStrings.SIM_NUMBER}</b>
      {" " + displayLoadingText(sim)}
    </>
  );

  const update = () => {
    setProgress(true);
    updateDeviceSim({ projectId, deviceId, sim: editSim })
      .then(() => {
        enqueueSnackbar(DeviceStrings.SIM_UPDATED, {
          variant: "success",
        });
      })
      .catch((err) => {
        console.warn(err);
        enqueueSnackbar(DeviceStrings.SIM_FAILED_TO_UPDATE, {
          variant: "error",
        });
      })
      .finally(() => {
        setProgress(false);
      });
  };

  const configCard = {
    icon: <SimCardOutlinedIcon />,
    title: DeviceStrings.SIM_TITLE,
    buttonLabel: DeviceStrings.SIM_BUTTON,
    desc,
    fullHeight,
    onClick: () => {
      setShowDialog(true);
    },
    disableButton: !canEdit,
    progress,
  };

  const configDialog = {
    icon: <SimCardOutlinedIcon />,
    title: DeviceStrings.SIM_TITLE,
    onClose: () => {
      setShowDialog(false);
      setEditSim(undefined);
    },
    onOk: () => {
      setShowDialog(false);
      setEditSim(undefined);
      update();
    },
    // disable OK button
    disableOk: isUndefined(editSim) || editSim === sim,
  };

  const updateSim = (e) => {
    const input = e.target.value;
    // validate if string only conatins a number
    if (/^\d+$/.test(input) || input === "") {
      setEditSim(input);
    }
  };

  return (
    <>
      <ButtonCard config={configCard} canRead={canRead}>
        <br />
      </ButtonCard>
      <MyDialog open={showDialog} config={configDialog}>
        <TextField
          label={DeviceStrings.SIM_EDIT}
          value={currentSim}
          fullWidth
          onChange={updateSim}
          autoFocus
        />
      </MyDialog>
    </>
  );
};

export default DeviceSimCard;
