import { useHistory } from "react-router-dom";

import { OrganisationStrings } from "strings";
import { ROUTES } from "route";

export const adminOrgsTabsConfig = {
  ORGANISATIONS: {
    label: OrganisationStrings.ADMIN_TITLE_ORGANISATIONS,
    route: ROUTES.ADMIN_ORGANISATIONS,
  },
  PROJECTS: {
    label: OrganisationStrings.ADMIN_TITLE_PROJECTS,
    route: ROUTES.ADMIN_PROJECTS,
  },
};

// return an array of tabs to be used by MyAppBar
export const useAdminOrganisationTabs = () => {
  const history = useHistory();

  return Object.entries(adminOrgsTabsConfig).map(([key, cfg]) => ({
    key,
    label: cfg.label,
    onClick: () => history.push(cfg.route),
  }));
};
