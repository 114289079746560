import React from "react";
import { Provider, useSelector } from "react-redux";
import { ReactReduxFirebaseProvider, isLoaded } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import store from "./redux/store";
import firebaseConfig from "./configs/firebaseConfig";
import rrfConfig from "./configs/rrfConfig";

import Spinner from "./ui/Spinner";
import "./css/App.css";
import themeMyPlayer from "./configs/theme";
import ConfirmDialogProvider from "./context/ConfirmDialogProvider";
import Routes from "Routes";
import PageLayout from "ui/pageLayout/PageLayout";
import { MainContextProvider } from "context/MainContext";

// firebase.firestore.setLogLevel('debug');

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const customHistory = createBrowserHistory();

firebase.initializeApp(firebaseConfig);

export const Firestore = firebase.firestore();
export const Firebase = firebase.app();
export const Storage = firebase.storage().ref();
export const FieldPath = firebase.firestore.FieldPath;
export const FieldValue = firebase.firestore.FieldValue;

/**
 * below is for running Firestore emulator
 */
// if (window.location.hostname === "localhost") {
//   console.debug("Using Firestore emulator...")
//   Firestore.settings({
//     host: "localhost:8080",
//     ssl: false
//   });
// }

// show spinner when auth is not loaded
const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  if (!isLoaded(auth) || !profile.isLoaded) return <Spinner />;
  return children;
};

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <MuiThemeProvider theme={themeMyPlayer}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            autoHideDuration={3000}
          >
            <ConfirmDialogProvider>
              <DndProvider backend={HTML5Backend}>
                <AuthIsLoaded>
                  <CssBaseline />
                  <Router history={customHistory}>
                    <MainContextProvider>
                      <PageLayout>
                        <Routes />
                      </PageLayout>
                    </MainContextProvider>
                  </Router>
                </AuthIsLoaded>
              </DndProvider>
            </ConfirmDialogProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
