import React, {useState} from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from "notistack";

import {ccCommand} from "../../services/ApiService";
import {useRemoteCommands} from "../../services/RemoteService";

import ButtonCard from "../cards/ButtonCard";
import RemoteLog from "../remote/RemoteLog";

import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import {selectMenuProps} from "../../configs/propsConfig";
import {DefaultStrings, DeviceStrings} from "../../strings";

const useStyles = makeStyles((theme) => ({
  appsMain: {
    padding: 24,
    height: "100%",
  },
  appsContainer: {
    marginBottom: theme.spacing(2),
    minWidth: 200,
  },
  appsRow: {
    display: "flex",
    alignItems: "center",
  },
  appsDropdown: {
    flexGrow: 1,
    margin: 5,
  },
  appsClickable: {
    cursor: "pointer",
  },
  appsAdvanced: {
    padding: 5,
  },
}));

const RemotePanelApps = ({
  projectId,
  deviceId,
  disabled,
  onStart,
  onComplete,
}) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const remoteCommands = useRemoteCommands(projectId);

  const [commandInput, setCommandInput] = useState("");
  const [target, setTarget] = useState("");
  const [action, setAction] = useState("");
  const [expanded, setExpanded] = useState(false);

  const targets = remoteCommands ? Object.keys(remoteCommands) : [];
  const actions = target ? Object.keys(remoteCommands[target].actions) : [];
  const sendCommand = () => {
    onStart();

    const commandObj = remoteCommands[target]?.actions?.[action];
    const command = commandObj?.command ?? commandInput;
    const flags = commandObj?.flags;
    ccCommand({
      projectId,
      deviceId,
      command,
      flags,
    })
      .then((result) => {
        if (result.success) {
          enqueueSnackbar(DeviceStrings.REQUEST_SENT, {variant: "success"});
        } else {
          enqueueSnackbar(DefaultStrings.ERROR_MSG, {variant: "error"});
          console.warn(result.errors[0]);
        }
      })
      .finally(() => {
        onComplete();
      });
  };

  const onChangeTarget = (event) => {
    setTarget(event.target.value);
    setAction("");
    setCommandInput("");
  };

  const onChangeAction = (event) => {
    const actionIndex = event.target.value;
    setAction(actionIndex);
    setCommandInput(remoteCommands[target].actions[actionIndex].command);
  };

  const onChangeCommand = (event) => {
    setTarget("");
    setAction("");
    setCommandInput(event.target.value);
  };

  return (
    <div className={classes.appsMain}>
      <ButtonCard
        config={{
          icon: (
            <span className="material-symbols-outlined" style={{fontSize: 30}}>
              apps
            </span>
          ),
          title: DeviceStrings.REMOTE_PANEL_APPS_TITLE,
          buttonLabel: DeviceStrings.REMOTE_PANEL_APPS_BUTTON_LABEL,
          desc: DeviceStrings.REMOTE_PANEL_APPS_DESC,
          fullHeight: true,
          onClick: sendCommand,
          disableButton: disabled || !commandInput,
          sidePanel: <RemoteLog deviceId={deviceId} />,
        }}
        canRead={true}
      >
        <div className={classes.appsContainer}>
          <div className={classes.appsRow}>
            <FormControl className={classes.appsDropdown}>
              <InputLabel>{DeviceStrings.COMMAND_TARGET}</InputLabel>
              <Select
                value={target}
                onChange={onChangeTarget}
                MenuProps={selectMenuProps}
              >
                {targets.map((value) => (
                  <MenuItem key={value} value={value}>
                    {remoteCommands[value].displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.appsDropdown}>
              <InputLabel>{DeviceStrings.COMMAND_ACTION}</InputLabel>
              <Select
                disabled={!target}
                value={action}
                onChange={onChangeAction}
                MenuProps={selectMenuProps}
              >
                {actions.map((value) => (
                  <MenuItem key={value} value={value}>
                    {remoteCommands[target].actions[value].label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            className={`${classes.appsRow} ${classes.appsClickable}`}
            onClick={() => setExpanded(!expanded)}
          >
            <span className={classes.appsAdvanced}>
              {DeviceStrings.COMMAND_ADVANCED}
            </span>
            {expanded ? (
              <ExpandMoreOutlinedIcon color="primary" />
            ) : (
              <ExpandLessOutlinedIcon color="primary" />
            )}
          </div>
          <Collapse in={expanded}>
            <TextField
              label={DeviceStrings.COMMAND_EDIT_LABEL}
              value={commandInput}
              fullWidth
              onChange={onChangeCommand}
              autoFocus
            />
          </Collapse>
        </div>
      </ButtonCard>
    </div>
  );
};

export default RemotePanelApps;
