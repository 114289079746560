const STRINGS = {
  DEPLOY_WAITING: "Waiting to deploy...",
  DEPLOY_NO_RECORD: "No previous deployment",
  DEPLOY_COMPLETED: "Deployment completed",
  DEPLOY_STARTED: "Deployment started",

  STAGE_TASTK_RESTART_SERVICES: "Restarting services...",
  STAGE_NOTIFY_DEVICE_COMPLIANT: "Updating project compliance...",
  STAGE_ON_END_TASKS_COMPLETED: "All tasks completed",
  STAGE_ON_UPDATES_COMPLETED: "All updates completed",
  STAGE_ON_ALL_MEDIA_FILES_HANDLED: "All media files handled",
  STAGE_PROCESS_MEDIA_FILES: "Processing media files...",
  STAGE_ON_DEVICE_ADDED_TO_PROJECT: "Device added to project",
  STAGE_ON_DEVICE_REGISTRATION: "Device registered",
  STAGE_INSTALL_PROJECT: "Installing project...",
  STAGE_ON_PROJECT_OBTAINED: "Project info obtained",
  STAGE_HANDLE_START_TASKS: "Starting tasks...",

  // app
  STAGE_ON_PACKAGE_INSTALLED: "Installed app: {}",
  STAGE_PROCESS_UNINSTALL_PKGS: "Installing app: {}...",
  STAGE_DOWNLOAD_AND_INSTALL_APK: "Downloading app: ()...",

  // content
  STAGE_OVERWRITE_UNMATCHED_FILES: "Updated {} files",
  STAGE_ON_BLOBS_DOWNLOADED: "Downloaded {} files",
  STAGE_ON_BLOB_DOWNLOADED: "Downloading media files...",
  STAGE_PROCESS_UNMATCHED_MEDIA_FILES: "Updating {} files...",
};

export default STRINGS;
