import React, { useMemo } from "react";

import { useUserProjectPermissions } from "services/ProjectService";
import TeamMemberCard from "./components/TeamMemberCard";

const ProjectTeamMemberCard = ({
  userId,
  projectId,
  onClick,
  canReadPermissions,
  progress,
}) => {
  const { userPermissions } = useUserProjectPermissions({
    userId,
    projectId,
  });

  const permissionsKey = useMemo(() => {
    if (userPermissions) return userPermissions.permissionsKey;
    return null;
  }, [userPermissions]);

  const permissionName = useMemo(() => {
    if (userPermissions) return userPermissions.name;
    return null;
  }, [userPermissions]);

  return (
    <TeamMemberCard
      userId={userId}
      permissionName={permissionName}
      permissionsKey={permissionsKey}
      canReadPermissions={canReadPermissions}
      progress={progress}
      onClick={onClick}
    />
  );
};

export default ProjectTeamMemberCard;
