import {
  convertFromRaw,
  EditorState,
  convertFromHTML,
  ContentState,
} from "draft-js";

// Strategy to find link entities, to be used by decorator
// reference: https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/link/link.html
export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const convertRawToPlainText = (raw) => {
  try {
    const contentState = convertFromRaw(JSON.parse(raw));
    return contentState.getPlainText();
  } catch (error) {
    // input is not in DraftJs raw format
    return raw;
  }
};

export const createEditorState = (text, decorator) => {
  if (!text) return EditorState.createEmpty(decorator);
  try {
    const rawContent = convertFromRaw(JSON.parse(text));
    return EditorState.createWithContent(rawContent, decorator);
  } catch (error) {
    // needed to parse notes that are stored in plain text instead of raw format
    // replace plain text line breaks with html line breaks
    const html = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state, decorator);
  }
};
