import React, { useState } from "react";
import LinkIcon from "@material-ui/icons/Link";
import { useSnackbar } from "notistack";

import {
  useProjectResolution,
  useProjectMediaSize,
  useUserProjectPermissions,
} from "../../services/ProjectService";
import { useCurrentUserId } from "../../services/UserService";
import { createShowroomLink } from "../../services/ApiService";

import ButtonCard from "./ButtonCard";
import MediaLinkFormFields from "../../ui/MediaLinkFormFields";

import { ProjectStrings, DefaultStrings } from "../../strings";

const initialLink = {
  name: "",
  type: "",
  expiry: null,
  customizeSize: undefined,
  retailer: "",
  postcode: "",
  storeId: "",
  customTag: "",
  draftId: "",
  password: "",
};

const CreateMediaLinkCard = ({ projectId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const sizeKey = useProjectResolution(projectId);
  const size = useProjectMediaSize({ key: sizeKey });

  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(initialLink);

  const onLinkChange = (name, value) => {
    setLink({
      ...link,
      [name]: value,
    });
  };

  // permissions control
  const userId = useCurrentUserId();
  const { canWriteShowroom } = useUserProjectPermissions({ userId, projectId });

  const isFormInvalid = () => {
    return !link.name || !link.type;
  };

  const configCard = {
    icon: <LinkIcon />,
    title: ProjectStrings.SHOWROOM_GENERATOR_TITLE,
    buttonLabel: ProjectStrings.SHOWROOM_GENERATOR_BUTTON,
    desc: ProjectStrings.SHOWROOM_GENERATOR_DESC,
    onClick: () => onCreateLink(),
    disableButton: !canWriteShowroom || isFormInvalid() || loading,
    progress: loading,
    fullHeight: false,
  };

  const onCreateLink = async () => {
    const data = {
      ...link,
      projectId,
      height: !link.customizeSize?.height
        ? size.height
        : parseInt(link.customizeSize?.height), // use default media height if user hasn't specified
      width: !link.customizeSize?.width
        ? size.width
        : parseInt(link.customizeSize?.width), // use default media width if user hasn't specified
      autoSize: !link.customizeSize?.height && !link.customizeSize?.width,
    };
    try {
      setLoading(true);
      await createShowroomLink(data);
      setLoading(false);
      setLink(initialLink);
      enqueueSnackbar(ProjectStrings.SHOWROOM_LINK_GENERATED, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <ButtonCard config={configCard} canRead={true}>
      <MediaLinkFormFields
        projectId={projectId}
        link={link}
        onLinkChange={onLinkChange}
        showTypeField={true}
      />
    </ButtonCard>
  );
};
export default CreateMediaLinkCard;
