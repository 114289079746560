import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    visibility: "hidden",
  },
  textPrimary: {
    color: "#777777",
  },
}));
