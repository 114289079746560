import { Storage } from "../App";

const DEFAULTS = {
  FOLDER_ROOT_PROJECT_IMAGE: "projectImages",
  FOLDER_ROOT_ORGANISATION_IMAGE: "organisationImages",
  FOLDER_ROOT_USER_IMAGE: "userImages",
};

// item = file
// return 1 if deleted, 0 if failed
const deleteItem = async (ref) =>
  Storage.child(ref.fullPath)
    .delete()
    .then(() => 1)
    .catch(() => 0);

// prefix = folder
// return number of prefixes + items deleted
const deletePrefix = async (ref) => {
  const res = await ref.listAll();
  const prefixPromises = res.prefixes.map((ref) => deletePrefix(ref));
  // items include the ref itself
  const itemPromises = res.items.map((ref) => deleteItem(ref));
  const deleteRes = await Promise.all([...prefixPromises, ...itemPromises]);
  return deleteRes.reduce((p, c) => p + c);
};

// file = Javascript File
// return downloadURL of the file
export const uploadFile = ({ file, path }) => {
  if (!file || !path) return null;
  console.debug("uploadFile", file.name, path);
  const ref = Storage.child(path);
  return ref.put(file).then((snapshot) => {
    console.debug("uploadFile OK", snapshot.metadata.fullPath);
    return ref.getDownloadURL();
  });
};

export const deleteFile = async ({ path }) => {
  if (!path) return;
  const ref = Storage.child(path);
  try {
    await Storage.child(ref.fullPath).delete();
  } catch (e) {
    console.log(e);
    return;
  }
};

export const uploadUserImage = ({ imageDataUrl, userId }) => {
  if (!imageDataUrl || !userId) return null;
  console.debug("uploadImage", imageDataUrl?.length, userId);
  const path = DEFAULTS.FOLDER_ROOT_USER_IMAGE + "/" + userId;
  const ref = Storage.child(path);
  return ref.putString(imageDataUrl, "data_url").then((snapshot) => {
    console.debug("uploadImage OK", snapshot.metadata.fullPath);
    return ref.getDownloadURL();
  });
};

// file = Javascript File
// return downloadURL of the file
export const uploadProjectImage = ({ file, projectId }) => {
  const path = `${DEFAULTS.FOLDER_ROOT_PROJECT_IMAGE}/${projectId}`;
  return uploadFile({ file, path });
};

export const uploadOrganisationImage = ({ file, organisationId }) => {
  const path = `${DEFAULTS.FOLDER_ROOT_ORGANISATION_IMAGE}/${organisationId}`;
  return uploadFile({ file, path });
};

export const deletePath = async (path) => deletePrefix(Storage.child(path));

export const isPathExist = (path) =>
  Storage.child(path)
    .getDownloadURL()
    .then(() => true)
    .catch(() => false);

export const getFileContent = async (path) => {
  const response = await Storage.child(path).getDownloadURL();
  // might need to use reject to handle error
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open("GET", response, true);
    request.send(null);
    request.onload = () => {
      resolve(request.response);
    };
  });
};
