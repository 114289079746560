import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useProjectInfo } from "../../services/ProjectService";

import ProjectPreviewDialog from "./ProjectPreviewDialog";

import { DefaultStrings, DeviceStrings } from "../../strings";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      width: "60%",
      margin: theme.spacing(1),
      "&:last-child": {
        marginTop: 0,
      },
    },
  };
});

/**
 * sample config
 */
// const config = {
//   projectId: "projectId",
//   onClose: () => {},
//   onConfirm: () => {},
// }

// a change of projectId will trigger open
const ProjectPreviewTransferDialog = ({
  config: { projectId, onClose, onConfirm },
}) => {
  const classes = useStyles();
  const info = useProjectInfo({ projectId });

  if (!info) return <></>;

  const config = {
    info,
    onClose,
  };

  return (
    <ProjectPreviewDialog config={config}>
      <div className={classes.button}>
        <Button variant="contained" fullWidth onClick={onClose}>
          {DefaultStrings.BUTTON_CANCEL}
        </Button>
      </div>
      <div className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onConfirm}
        >
          {DeviceStrings.TRANSFER_BUTTON}
        </Button>
      </div>
    </ProjectPreviewDialog>
  );
};

export default ProjectPreviewTransferDialog;
