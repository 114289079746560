import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    main: (mobile) => ({
      padding: theme.spacing(mobile ? 1 : 1),
      paddingTop: 0,
      width: "100%",
    }),
    container: {
      width: "100%",
      padding: theme.spacing(1),
      margin: `${theme.spacing(2)}px 0`,
      display: "flex",
      flexWrap: "wrap",
    },
    settingsWrapper: {
      padding: theme.spacing(1),
      flex: "1 0 auto",
    },
  };
});
