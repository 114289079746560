import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import WorkspacesOutlinedIcon from "@material-ui/icons/WorkspacesOutlined";
import { useSnackbar } from "notistack";

import { useMobileLayout } from "hooks/uiHooks";
import { transferProject } from "services/ApiService";
import { ProjectStrings, DefaultStrings } from "strings";
import { Switch } from "components";
import { useStyles } from "./style";
import LinearSpinner from "ui/LinearSpinner";

// organisationId = edit existing
// userId = new organisation
export const ProjectTransferDialog = ({
  open,
  organisation,
  projectId,
  onComplete,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [keepUsers, setKeepUsers] = useState(true);

  const onConfirmHandler = async () => {
    setLoading(true);
    // create new organisation
    try {
      await transferProject({
        projectId,
        targetOrganisationId: organisation.organisationId,
        keepUsers,
      });
      setLoading(false);
      enqueueSnackbar(
        ProjectStrings.OVERVIEW_PROJECT_TRANSFER_SUCCESS.replace(
          "{organisation}",
          organisation.name
        ),
        {
          variant: "success",
        }
      );
      if (onComplete) onComplete();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onSwitchChangeHandle = () => {
    setKeepUsers(!keepUsers);
  };

  return (
    <Dialog
      fullWidth
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      {loading && <LinearSpinner />}
      <DialogTitle className={classes.title} disableTypography>
        <Box
          style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
        >
          <WorkspacesOutlinedIcon style={{ marginRight: "16px" }} />
          <Typography variant="h6">
            {ProjectStrings.OVERVIEW_PROJECT_TRANSFER_CONFIRM_TITLE}
          </Typography>
        </Box>
        <Typography variant="body2">
          {ProjectStrings.OVERVIEW_PROJECT_TRANSFER_CONFIRM_DESC.replace(
            "{organisation}",
            organisation?.name
          )}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box m={1} mb={2} display="flex" justifyContent="end">
          <Switch
            label={
              keepUsers
                ? ProjectStrings.OVERVIEW_PROJECT_TRANSFER_CONFIRM_KEEP_USERS_TITLE
                : ProjectStrings.OVERVIEW_PROJECT_TRANSFER_CONFIRM_REMOVE_USERS_TITLE
            }
            checked={keepUsers}
            onChange={onSwitchChangeHandle}
          />
        </Box>
        <Typography variant="body2">
          {keepUsers &&
            ProjectStrings.OVERVIEW_PROJECT_TRANSFER_CONFIRM_KEEP_USERS_DESC}
          {!keepUsers &&
            ProjectStrings.OVERVIEW_PROJECT_TRANSFER_CONFIRM_NOT_KEEP_USERS_DESC}
        </Typography>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <IconButton onClick={onConfirmHandler}>
          <CheckIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
