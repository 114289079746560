import { makeStyles } from "@material-ui/core/styles";
import { navigationBarHeight, navigationBarWidth } from "ui/pageLayout/config";

export const useStyles = makeStyles({
  left: {
    position: "fixed",
    top: "25%",
    left: 0,
    height: "100%",
    width: navigationBarWidth,
    zIndex: 100,
  },
  bottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: navigationBarHeight,
    zIndex: 100,
  },
  mobileAction: {
    position: "absolute",
    right: 0,
    bottom: navigationBarHeight,
  },
});
