import React, { useMemo } from "react";

import { useUserOrganisationPermissions } from "services/OrganisationService";
import TeamMemberCard from "./components/TeamMemberCard";

const OrganisationTeamMemberCard = ({
  userId,
  organisationId,
  onClick,
  canReadPermissions,
  progress,
}) => {
  const { userPermissions } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });

  const permissionsKey = useMemo(() => {
    if (userPermissions) return userPermissions.permissionsKey;
    return null;
  }, [userPermissions]);

  const permissionName = useMemo(() => {
    if (userPermissions) return userPermissions.name;
    return null;
  }, [userPermissions]);

  return (
    <TeamMemberCard
      userId={userId}
      permissionName={permissionName}
      permissionsKey={permissionsKey}
      canReadPermissions={canReadPermissions}
      progress={progress}
      onClick={onClick}
    />
  );
};

export default OrganisationTeamMemberCard;
