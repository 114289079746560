import { connect } from "react-redux";

import ProjectOverview from "./ProjectOverview";
import ProjectMedia from "./ProjectMedia";
import ProjectDevices from "./ProjectDevices";
import ProjectAnalytics from "./ProjectAnalytics";
import ProjectTeam from "./ProjectTeam";

const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

export const ProjectOverviewPage = connect(mapStateToProps)(ProjectOverview);
export const ProjectMediaPage = connect(mapStateToProps)(ProjectMedia);
export const ProjectDevicesPage = connect(mapStateToProps)(ProjectDevices);
export const ProjectAnalyticsPage = connect(mapStateToProps)(ProjectAnalytics);
export const ProjectTeamPage = connect(mapStateToProps)(ProjectTeam);
