import React from "react";
import { useParams } from "react-router-dom";

import { useMobileLayout } from "hooks/uiHooks";
import { useOrganisationChatRoomId } from "services/ChatService";
import { ROOM_TYPES } from "services/ChatService";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import RestrictedContent from "ui/RestrictedContent";
import ChatRoom from "ui/ChatRoom";
import Spinner from "ui/Spinner";
import { isLoading } from "utils/uiUtils";
import { useStyles } from "./style";
import { OrganisationStrings } from "strings";

const Activity = ({ userId }) => {
  const { organisationId } = useParams();
  const mobile = useMobileLayout();

  const classes = useStyles({ mobile });
  const roomId = useOrganisationChatRoomId(organisationId);
  const { canWriteMessage } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });

  if (isLoading(roomId)) return <Spinner />;

  return (
    <div className={classes.root}>
      <RestrictedContent permitted={canWriteMessage}>
        <ChatRoom
          roomId={roomId}
          organisationId={organisationId}
          roomType={ROOM_TYPES.ORGANISATION_MESSAGE}
          emptyDataTitle={OrganisationStrings.CHATROOM_NO_MESSAGE_TITLE}
          emptyDataDescription={OrganisationStrings.CHATROOM_NO_MESSAGE_DESC}
        />
      </RestrictedContent>
    </div>
  );
};

export default Activity;
