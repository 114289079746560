import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import MenuMaterial from "@material-ui/core/Menu";

import { useMobileLayout } from "hooks/uiHooks";
import { MenuItemDelete } from "./MenuItemDelete";
import { TeamCodeStrings } from "strings";
import { useStyles } from "./style";

export const Menu = (props) => {
  const { info, canEdit, onCopyCode, onCopyLink, onDelete } = props;
  const mobile = useMobileLayout();

  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);

  const onOpenHandle = (e) => setAnchor(e.currentTarget);
  const onCloseHandle = () => setAnchor(null);
  const onCopyCodeHandle = () => {
    onCopyCode();
    setAnchor(null);
  };
  const onCopyLinkHandle = () => {
    onCopyLink();
    setAnchor(null);
  };
  const onDeleteHandle = () => {
    onDelete();
    setAnchor(null);
  };

  return (
    <>
      <IconButton
        classes={{ root: classes.iconMore }}
        color="inherit"
        onClick={onOpenHandle}
      >
        <MoreIcon />
      </IconButton>
      <MenuMaterial
        id="menuOverflow"
        anchorEl={anchor}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={anchor !== null}
        onClose={onCloseHandle}
      >
        {mobile && (
          <div>
            <ListSubheader>
              {TeamCodeStrings.TEAM_CODE_CARD_CAPTION_COPY}
            </ListSubheader>
            <MenuItem onClick={onCopyCodeHandle}>
              {TeamCodeStrings.TEAM_CODE_CARD_COPY_CODE}
            </MenuItem>
            <MenuItem onClick={onCopyLinkHandle}>
              {TeamCodeStrings.TEAM_CODE_CARD_COPY_LINK}
            </MenuItem>
            <ListSubheader>
              {TeamCodeStrings.TEAM_CODE_CARD_CAPTION_MANAGE}
            </ListSubheader>
          </div>
        )}
        <MenuItemDelete
          info={info}
          canEdit={canEdit}
          onDelete={onDeleteHandle}
        />
      </MenuMaterial>
    </>
  );
};
