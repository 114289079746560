import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Dashboard from "./dashboard/Dashboard";
import Projects from "./projects/Projects";
import Team from "./team/Team";
import Activity from "./activity/Activity";
import Settings from "./settings/Settings";

import { ROUTES } from "route";
import { withInOrganisationCheck } from "hocs/withInOrganisationCheck";

export const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

const DashboardPage = connect(mapStateToProps)(Dashboard);
const TeamPage = connect(mapStateToProps, null, null, { forwardRef: true })(
  Team
);
const ActivityPage = connect(mapStateToProps)(Activity);
const SettingsPage = connect(mapStateToProps)(Settings);

const Index = () => {
  return (
    <Switch>
      <Route
        path={ROUTES.ORGANISATION_DASHBOARD}
        component={DashboardPage}
        exact
      />
      <Route path={ROUTES.ORGANISATION_PROJECTS} component={Projects} exact />
      <Route
        path={[ROUTES.ORGANISATION_TEAM, ROUTES.ORGANISATION_GUESTS]}
        component={TeamPage}
        exact
      />
      <Route
        path={[ROUTES.ORGANISATION_ACTIVITY]}
        component={ActivityPage}
        exact
      />
      <Route
        path={[ROUTES.ORGANISATION_SETTINGS]}
        component={SettingsPage}
        exact
      />
    </Switch>
  );
};

export const OrganisationPage = connect(mapStateToProps)(
  withInOrganisationCheck(Index)
);
