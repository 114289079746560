import React, {useState, useEffect, useRef} from "react";

import {useGlobalApps} from "../../services/AppService";
import {useProjectApps} from "../../hooks/projectHooks";
import {selectApps, createAppsStateUpdate} from "../../utils/appsUtils";
import {useProjectAppsPermission} from "../../services/PermissionService";
import {updateProjectApps} from "../../services/ApiService";

import AppsList from "../AppsList";
import ButtonCard from "./ButtonCard";
import MyDialog from "../../ui/MyDialog";
import Spinner from "../../ui/Spinner";

import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";

import {ProjectStrings} from "../../strings";

const MyPlayerAppsCard = ({projectId, showAddTasks}) => {
  const permission = useProjectAppsPermission(projectId);
  const canRead = permission?.read;
  const canWrite = canRead && permission?.write;

  // only read apps if permission allows
  const projectApps = useProjectApps(canRead && projectId);
  const globalApps = useGlobalApps(false);
  const [showDialog, setShowDialog] = useState(false);
  const [appState, setAppState] = useState({});
  const [progress, setProgress] = useState(false);
  const mounted = useRef(false);

  const combinedApps = selectApps(globalApps, projectApps);

  // check mounted
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])

  const update = () => {
    // set apps
    const apps = createAppsStateUpdate(projectApps, globalApps, appState);
    setProgress(true);
    updateProjectApps({
      projectId,
      apps: Object.values(apps),
      message: ProjectStrings.ACTIVITY_UPDATED_APPS_MSG,
    }).finally(() => {
      if (mounted.current) setProgress(false);
    });
  };

  const configCard = {
    icon: <ExtensionOutlinedIcon />,
    title: ProjectStrings.MEDIA_MYPLAYER_APPS_TITLE,
    desc: ProjectStrings.MEDIA_MYPLAYER_APPS_DESC,
    buttonLabel: ProjectStrings.MEDIA_EDIT_APPS_TITLE,
    onClick: () => {
      setShowDialog(true);
    },
    fullHeight: true,
    disableButton: !canWrite,
    progress,
  };

  const configDialog = {
    icon: <ExtensionOutlinedIcon />,
    title: ProjectStrings.MEDIA_MYPLAYER_APPS_TITLE,
    onClose: () => {
      setShowDialog(false);
      setAppState({});
    },
    onOk: () => {
      update();
      setShowDialog(false);
      setAppState({});
    },
    disableOk: Object.keys(appState).length === 0,
  };

  const content = projectApps ? (
    <AppsList
      apps={projectApps}
      defaultOnly={false}
    />
  ) : (
    <Spinner />
  );

  return (
    <>
      <ButtonCard config={configCard} canRead={canRead}>
        {content}
      </ButtonCard>
      <MyDialog open={showDialog} config={configDialog}>
        <AppsList
          apps={combinedApps}
          defaultOnly={false}
          edit={{appState, setAppState}}
        />
      </MyDialog>
    </>
  );
};

export default MyPlayerAppsCard;
