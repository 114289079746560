import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: () => ({
    position: "relative",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "unset",
  }),
  contentWrapper: {
    display: "flex",
    alignItems: "center",
  },
  avatarsWrapper: {
    display: "flex",
    overflowX: "auto",
    maxWidth: "320px",
    marginRight: theme.spacing(5),
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));
