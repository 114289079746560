import React, { useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "hooks/uiHooks";
import { DefaultStrings } from "strings";
import { CheckIcon, CloseIcon } from "assets/icons";

const useStyles = makeStyles((theme) => ({
  title: (mobile) => ({
    backgroundColor: theme.palette.background.default,
    width: mobile ? "100vw" : 240,
    height: mobile ? "100vw" : 240,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }),
  content: {
    padding: "8px 12px",
  },
  leftButton: {
    display: "flex",
    justifyContent: "left",
    marginRight: "auto",
  },
}));

const ProfileDialog = ({
  userImage,
  titleChildren,
  children,
  open,
  config: { showButtonBar, onUpload, disableOk, onClose, onOk },
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const fileInput = useRef(null);

  const onChangeImage = (file) => {
    if (onUpload) {
      onUpload(file);
    }
  };

  const onConfirm = () => {
    if (onOk) {
      onOk({});
    }
  };

  const onClosePopup = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      {userImage ? (
        <DialogTitle
          className={classes.title}
          disableTypography
          style={{ backgroundImage: `url(${userImage})` }}
        ></DialogTitle>
      ) : (
        titleChildren
      )}

      <DialogContent className={classes.content}>{children}</DialogContent>
      {showButtonBar && (
        <DialogActions>
          {/* Image Button */}
          {onUpload && (
            <IconButton
              className={classes.leftButton}
              aria-label={DefaultStrings.BUTTON_IMAGE}
              onClick={(e) => fileInput.current.click()}
            >
              <ImageOutlinedIcon />
              <input
                type="file"
                ref={fileInput}
                hidden
                accept="image/gif, image/jpeg, image/png"
                onChange={(e) => onChangeImage(e.target.files[0])}
              />
            </IconButton>
          )}
          <IconButton
            aria-label={DefaultStrings.BUTTON_CANCEL}
            onClick={onClosePopup}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            aria-label={DefaultStrings.BUTTON_CONFIRM}
            onClick={onConfirm}
            disabled={disableOk}
          >
            <CheckIcon />
          </IconButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ProfileDialog;
