import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { getMyProjectsDownloadUrl } from "../utils/storageUtils";

import { DefaultStrings } from "../strings";

const useStyles = makeStyles((theme) => {
  return {
    centered: {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonContainer: {
      margin: theme.spacing(4),
      minWidth: "200px",
    },
  };
});

const DownloadPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.centered}>
      <Typography variant="h3">{DefaultStrings.TITLE}</Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          href={getMyProjectsDownloadUrl()}
        >
          {DefaultStrings.DOWNLOAD}
        </Button>
      </div>
    </div>
  );
};

export default DownloadPage;
