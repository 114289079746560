import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    itemsWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "end",
      alignItems: "center",
    },
    whiteTitle: {
      "& button": {
        color: theme.palette.common.white,
      },
    },
    item: {
      marginRight: theme.spacing(2),
      "& button": {
        padding: "6px 24px",
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
    text: {
      color: theme.palette.grey[600],
      fontWeight: 700,
    },
  };
});
