import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    flexDirection: "column",
  },
  content: (mobile) => ({
    display: "flex",
    flexDirection: "column",
    maxHeight: mobile ? "auto" : "330px",
    padding: "8px 16px",
    position: "relative",
  }),
  chipStartIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "32px",
    color: theme.palette.common.white,
    backgroundColor: "#F78130",
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
  },
  chipEndIcon: {
    color: "inherit",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));
