import React from "react";

import {
  useMediaLink
} from "../../services/DeviceService";
import QrCodeIcon from '@material-ui/icons/QrCode';
import QRCode from "qrcode.react";
import ButtonCard from "./ButtonCard";
import { DeviceStrings } from "../../strings";
const QR_MYPLAYER_ICON = "/static/media/icon_my_player_v3.ed4eaf7f.png";

const DeviceQRCard = ({
  deviceId,
  canRead,
}) => {
  const mediaLink = useMediaLink(deviceId);
  const mediaLinkId = mediaLink ? mediaLink.linkId : undefined;
  const mediaLinkURL = mediaLink ? mediaLink.url : undefined;
  const icon = <QrCodeIcon />;
  const title = DeviceStrings.QR_CODE;
  const desc = DeviceStrings.QR_CODE_DESC;
  const buttonLabel = '';

  const QR_CONFIG = {
    size: 256,
    includeMargin: false,
    imageSettings: {
      src: QR_MYPLAYER_ICON,
      x: null,
      y: null,
      height: 32,
      width: 32,
      excavate: true,
    },
  };

  const configCard = {
    icon,
    title,
    buttonLabel, //DeviceStrings.DOWNLOAD,
    desc,
    fullHeight: true,
    disableButton: false,
  };

  return (
    <ButtonCard config={configCard} canRead={canRead}>
      {mediaLinkId && mediaLinkURL && (
        <QRCode
          key={mediaLinkId}
          size={QR_CONFIG.size}
          includeMargin={QR_CONFIG.includeMargin}
          value={mediaLinkURL}
          imageSettings={QR_CONFIG.imageSettings}
        />
      )}
      {!mediaLinkId && !mediaLinkURL && (
        <div style={{ width: QR_CONFIG.size, height: QR_CONFIG.size }}>
        </div>
      )}
    </ButtonCard>
  );
};

export default DeviceQRCard;
