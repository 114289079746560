import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    cardContainer: ({ mobile, small }) => ({
      padding: theme.spacing(1),
      paddingLeft: mobile ? theme.spacing(2) : theme.spacing(1),
      paddingRight: mobile ? theme.spacing(2) : theme.spacing(1),
      width: mobile ? window.innerWidth : small ? 240 : 320,
      height: small ? 240 : 320,
      boxSizing: "border-box",
    }),
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowX: "hidden",
    },
    main: {
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    filter: ({ mobile }) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 16,
      flexWrap: mobile ? "wrap" : "nowrap",
    }),
    formControl: ({ mobile }) => ({
      padding: theme.spacing(mobile ? 2 : 1),
      minWidth: 150,
      maxWidth: 450,
      width: mobile ? "100%" : "auto",
    }),
    formLabel: ({ mobile }) => ({
      padding: theme.spacing(mobile ? 2 : 1),
    }),
  };
});
