import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    chip: {
      margin: theme.spacing(1),
      padding: "4px 2px",
      backgroundColor: "#E8E9EA",
      color: "#777777",
      borderRadius: "30px",
      height: "auto",
    },
    chipLabel: {
      margin: "0 8px",
    },
    title: {
      fontSize: "14px",
      fontWeight: 700,
    },
    description: {
      fontSize: "10px",
      fontWeight: 400,
    },
  };
});
