import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import ProjectDeviceStatCard from "ui/cards/deviceStatCard/ProjectDeviceStatCard";
import HardwareCard from "ui/cards/HardwareCard";
import PinCard from "ui/cards/PinCard";
import StatusCard from "ui/cards/StatusCard";
import ProjectCard from "ui/cards/projectCard/ProjectCard";
import ProjectDialog from "ui/dialogs/ProjectDialog";
import ProjectNoteCard from "ui/cards/ProjectNoteCard";
import { OrganisationsSelectDialog } from "ui/dialogs/organisationsSelectDialog";

import { leaveProject as leaveProjectApi } from "services/ApiService";
import {
  deleteProject as deleteProjectApi,
  useUserProjectPermissions,
  useProjectInfo,
} from "services/ProjectService";
import { useTotalDevicesCount } from "services/DeviceService";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";
import { JoinDialog, ProjectTransferDialog } from "ui/dialogs";

import { ProjectStrings, DefaultStrings } from "strings";
import { withInProjectCheck } from "hocs";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import { getProjectsRoute } from "route";

const useStyles = makeStyles((theme) => ({
  base: (mobile) => ({
    display: "flex",
    flexWrap: mobile ? "wrap" : "nowrap",
    padding: theme.spacing(mobile ? 1 : 2),
  }),
  groupV: {
    flexGrow: 2,
    display: "flex",
    flexDirection: "column",
  },
  groupH: (mobile) => ({
    flexGrow: 1,
    display: "flex",
    flexWrap: mobile ? "wrap" : "nowrap",
  }),
  infoContainer: (mobile) => ({
    minHeight: 520,
    minWidth: mobile ? "100%" : 320,
    maxWidth: "40%",
    padding: theme.spacing(1),
    flexGrow: 1,
  }),
  container: (mobile) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    minHeight: mobile ? 200 : "auto",
    minWidth: 180,
  }),
}));

// Trello ticket on permissions: https://trello.com/c/Tfm5iRaP/103-add-permission-control-for-project-overview-page
const ProjectOverviewPage = ({ userId }) => {
  const params = useParams();
  const confirm = useConfirm();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const projectId = params.projectId;
  const info = useProjectInfo({ projectId, userId });
  const { actionType, onAction } = useContext(MainContext);

  const [projectIdToJoin, setProjectIdToJoin] = useState(null);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  // permissions control
  const { canWriteProject, canReadDevices, canWriteDevices } =
    useUserProjectPermissions({ userId, projectId });

  const deviceCount = useTotalDevicesCount(canReadDevices && projectId);
  const hasDevice = deviceCount > 0;
  const [projectLoading, setProjectLoading] = useState(false);

  const configDialog = {
    onClose: () => {
      setShowProjectDialog(false);
    },
    onConfirm: () => {
      setShowProjectDialog(false);
      console.debug("setProjectLoading", true);
      setProjectLoading(true);
    },
    onComplete: () => {
      console.debug("setProjectLoading", false);
      setProjectLoading(false);
    },
  };

  const configCard = {
    showBrand: true,
    showDescription: true,
    showPin: false,
    loading: projectLoading,
  };

  const configJoinDialog = {
    projectId: projectIdToJoin,
    userId,
    hideViewBtn: true,
    onClose: () => setProjectIdToJoin(null),
  };

  if (canWriteProject) {
    configCard.onClick = () => {
      setShowProjectDialog(true);
    };
  }

  const onOrgSelect = (organisation) => {
    setShowTransferDialog(true);
    setSelectedOrg(organisation);
  };

  const onOrgSelectDialogClose = () => {
    onAction(GLOBAL_ACION_TYPES.PROJECT_TRANSFER, false);
  };

  const onTransferDialogClose = () => {
    setShowTransferDialog(false);
    setSelectedOrg(null);
  };

  const onTransferDialogComplete = () => {
    onAction(GLOBAL_ACION_TYPES.PROJECT_TRANSFER, false);
    setShowTransferDialog(false);
    setSelectedOrg(null);
  };

  const deleteProject = useCallback(() => {
    confirm({ message: ProjectStrings.OVERVIEW_PROJECT_DELETE_CONFIRM })
      .then(() => {
        // delete project and show toast
        deleteProjectApi(projectId)
          .then(() => {
            onAction(GLOBAL_ACION_TYPES.PROJECT_DELETE, false);
            // redirect to projects page
            history.push(getProjectsRoute());

            enqueueSnackbar(ProjectStrings.OVERVIEW_PROJECT_DELETED, {
              variant: "success",
            });
          })
          .catch((error) => {
            onAction(GLOBAL_ACION_TYPES.PROJECT_DELETE, false);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, {
              variant: "error",
            });
            console.warn(error);
          });
      })
      .catch(() => {});
  }, [confirm, enqueueSnackbar, history, onAction, projectId]);

  const leaveProject = useCallback(() => {
    confirm({ message: ProjectStrings.OVERVIEW_PROJECT_LEAVE_CONFIRM })
      .then(() => {
        leaveProjectApi(projectId)
          .then(() => {
            onAction(GLOBAL_ACION_TYPES.PROJECT_LEAVE, false);
            // redirect to projects page
            history.push(getProjectsRoute());
            enqueueSnackbar(ProjectStrings.OVERVIEW_PROJECT_LEFT, {
              variant: "success",
            });
          })
          .catch((error) => {
            onAction(GLOBAL_ACION_TYPES.PROJECT_LEAVE, false);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
            console.warn(error);
          });
      })
      .catch((error) => {});
  }, [confirm, enqueueSnackbar, history, onAction, projectId]);

  useEffect(() => {
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_DELETE]) {
      deleteProject();
    }
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_LEAVE]) {
      leaveProject();
    }
  }, [actionType, deleteProject, leaveProject]);

  return (
    <>
      <div className={classes.base}>
        <div className={classes.infoContainer}>
          <ProjectCard
            userId={userId}
            projectId={projectId}
            config={configCard}
          />
        </div>
        <div className={classes.groupV}>
          <div className={classes.groupH}>
            {hasDevice && (
              <div
                className={classes.container}
                style={{
                  flexGrow: 3,
                  maxWidth: mobile ? " 100%" : "60%",
                }}
              >
                <ProjectDeviceStatCard
                  projectId={projectId}
                  canRead={canReadDevices}
                  canEdit={canWriteDevices}
                />
              </div>
            )}
            {hasDevice && (
              <div className={classes.container}>
                <PinCard projectId={projectId} userId={userId} />
              </div>
            )}
            <div className={classes.container}>
              <StatusCard userId={userId} projectId={projectId} />
            </div>
          </div>
          <div className={classes.groupH}>
            {hasDevice && (
              <div
                className={classes.container}
                style={{
                  flexGrow: 3,
                  maxWidth: mobile ? " 100%" : "60%",
                }}
              >
                <HardwareCard userId={userId} projectId={projectId} />
              </div>
            )}
            <div
              className={classes.container}
              style={{
                flexGrow: 2,
              }}
            >
              <ProjectNoteCard userId={userId} projectId={projectId} />
            </div>
          </div>
        </div>
      </div>
      <ProjectDialog
        projectId={projectId}
        open={showProjectDialog}
        config={configDialog}
      />
      <OrganisationsSelectDialog
        title={ProjectStrings.OVERVIEW_PROJECT_TRANSFER_TO_ORGANISATION_TITLE}
        open={actionType[GLOBAL_ACION_TYPES.PROJECT_TRANSFER]}
        currentOrganisationId={info?.organisationId}
        onSelect={onOrgSelect}
        onClose={onOrgSelectDialogClose}
        showAllOrganisations
      />
      <ProjectTransferDialog
        open={showTransferDialog}
        projectId={projectId}
        organisation={selectedOrg}
        onComplete={onTransferDialogComplete}
        onClose={onTransferDialogClose}
      />
      <JoinDialog open={false} config={configJoinDialog} />
    </>
  );
};

export default withInProjectCheck(ProjectOverviewPage);
