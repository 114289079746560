import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
  },
  wrapper: ({ isMobile, isTablet, isLargeDesktop }) => ({
    display: "flex",
    flexWrap: isTablet ? "wrap" : "nowrap",
    padding: theme.spacing(isMobile ? 1 : 2),
    width: "100%",
    maxWidth: isLargeDesktop ? "1280px" : "100%",
  }),
  statsWrapper: ({ isMobile }) => ({
    display: "flex",
    flexWrap: isMobile ? "nowrap" : "wrap",
    flexDirection: isMobile ? "column" : "row",
    width: "100%",
  }),
  infoContainer: ({ isTablet }) => ({
    width: isTablet ? "100%" : 320,
    padding: theme.spacing(1),
    flexGrow: 1,
  }),
  cardWrapper: ({ isMobile }) => ({
    flexBasis: isMobile ? "100%" : "50%",
    padding: theme.spacing(1),
    minHeight: isMobile ? 200 : "auto",
    minWidth: 180,
  }),
}));
