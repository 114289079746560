import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

import { useDeviceConnectedDevices } from "services/DeviceService";
import { ccCommand } from "services/ApiService";
import { useRemoteCommandsDefaults } from "services/RemoteService";

import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import ButtonCard from "./ButtonCard";

import { DefaultStrings, DeviceStrings } from "strings";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1),
    marginBottom: 0,
  },
}));

const DeviceConnectedCard = ({ projectId, deviceId, canRead, canEdit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [inProgress, setInProgress] = useState(false);
  const mounted = useRef(false);
  const commandsDefaults = useRemoteCommandsDefaults();

  const connectedDevices = useDeviceConnectedDevices(deviceId);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const onRefresh = () => {
    setInProgress(true);
    ccCommand({
      projectId,
      deviceId,
      command: commandsDefaults.DEVICE_CONNECTED_DEVICES_REFRESH,
    })
      .then((result) => {
        console.debug("onRefresh", result);
        if (result.success) {
          enqueueSnackbar(DeviceStrings.REQUEST_SENT, { variant: "success" });
        } else {
          throw new Error(result.errors);
        }
      })
      .catch((err) => {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(err);
      })
      .finally(() => {
        if (mounted.current) setInProgress(false);
      });
  };

  const config = {
    icon: <LabelOutlinedIcon />,
    title: DeviceStrings.CONNECTED_DEVICES_TITLE,
    buttonLabel: DeviceStrings.CONNECTED_DEVICES_REFRESH,
    onClick: onRefresh,
    progress: inProgress,
    disableButton: inProgress || !canEdit,
  };

  return (
    <ButtonCard config={config} canRead={canRead}>
      {connectedDevices?.map((d, i) => (
        <Chip
          key={`dev-${i}`}
          label={`Port ${d.port}: ${d.productName}`}
          className={classes.chip}
        />
      ))}
    </ButtonCard>
  );
};

export default DeviceConnectedCard;
