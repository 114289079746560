import React from "react";
import Typography from "@material-ui/core/Typography";

import UserAvatar from "ui/UserAvatar";
import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { useUserInfo } from "services/UserService";

export function Header({ userId, onOpen }) {
  const mobile = useMobileLayout();
  const classes = useStyles();
  const userInfo = useUserInfo(userId);
  const avatarTeamSize = mobile ? 4 : 4.5;

  return (
    <div className={classes.container} onClick={onOpen}>
      <UserAvatar userId={userId} size={avatarTeamSize} />
      <div style={{ marginLeft: "8px" }}>
        <Typography variant="caption">{userInfo?.name}</Typography>
        <Typography className={classes.email} variant="body2">
          {userInfo?.email.replace(/~/g, ".")}
        </Typography>
      </div>
    </div>
  );
}
