import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: (mobile) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    transform: "translate(-50%, -50%)",
    padding: mobile ? "0 24px" : 0,
  }),
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: "600",
    color: "#323232",
  },
  description: (mobile) => ({
    color: "#323232",
    maxWidth: mobile ? "100%" : "320px",
    textAlign: "center",
  }),
  icon: {
    "& svg": {
      color: "#323232",
      width: "40px",
      height: "40px",
    },
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(2),
  },
}));
