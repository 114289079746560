import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { CheckIcon, CloseIcon } from "assets/icons";
import LinearSpinner from "ui/LinearSpinner";

const ConfirmDialog = ({
  open,
  title,
  description,
  progress,
  config,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog open={open}>
      {title && (
        <DialogTitle>
          <div style={{ display: "flex" }}>
            {config?.icon && (
              <div style={{ marginRight: "8px" }}>{config.icon}</div>
            )}
            <Typography style={{ fontSize: "1rem" }} variant="caption">
              {title}
            </Typography>
          </div>
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText style={{ color: "#323232" }}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ position: "relative" }}>
        {progress && <LinearSpinner />}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {config?.btOkTitle && (
          <Button color={config.btOkColor || "primary"} onClick={onConfirm}>
            {config.btOkTitle}
          </Button>
        )}
        {!config?.btOkTitle && (
          <IconButton onClick={onConfirm}>
            <CheckIcon />
          </IconButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
