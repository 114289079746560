import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import OverflowTypography from "ui/OverflowTypography";
import { DefaultStrings } from "strings";
import logo from "assets/images/icon_my_player_v3.png";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 24,
    alignSelf: "center",
    marginRight: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: "center",
    fontWeight: "bold",
  },
}));

export const Title = ({ title }) => {
  const classes = useStyles();
  return (
    <>
      <img
        src={logo}
        edge="start"
        className={classes.icon}
        alt={DefaultStrings.APP_NAME}
      />
      <OverflowTypography className={classes.title} variant="h6" noWrap>
        {title}
      </OverflowTypography>
    </>
  );
};
