import {
  GUEST_ACCESS,
  PROJECT_ACCESS,
  PROJECT_JOIN_APPROVAL,
} from "services/OrganisationService";
import { OrganisationStrings } from "strings";

export const GUEST_ACCESS_OPTIONS = [
  {
    title: OrganisationStrings.GUEST_ACCESS_ENABLED,
    value: GUEST_ACCESS.ENABLED,
  },
  {
    title: OrganisationStrings.GUEST_ACCESS_DISABLED,
    value: GUEST_ACCESS.DISABLED,
  },
];

export const PROJECT_ACCESS_OPTIONS = [
  {
    title: OrganisationStrings.PROJECT_ACCESS_OPEN,
    value: PROJECT_ACCESS.OPEN,
  },
  {
    title: OrganisationStrings.PROJECT_ACCESS_PRIVATE,
    value: PROJECT_ACCESS.PRIVATE,
  },
];

export const PROJECT_JOIN_APPROVAL_OPTIONS = [
  {
    title: OrganisationStrings.PROJECT_JOIN_APPROVAL_AUTO,
    value: PROJECT_JOIN_APPROVAL.AUTO,
  },
  {
    title: OrganisationStrings.PROJECT_JOIN_APPROVAL_REQUIRED,
    value: PROJECT_JOIN_APPROVAL.REQUIRED,
  },
];
