import React from "react";
import Card from "@material-ui/core/Card";

import { useUserAwaitingApproval } from "services/ProjectService";
import { useMobileLayout } from "hooks/uiHooks";
import { Header, Actions } from "..";
import { useStyles } from "./style";

export const AwaitingApprovalCard = ({
  userId,
  projectId,
  userRoleToUpdate,
  onOpen,
  onAccept,
  onReject,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const userAwaitingApproval = useUserAwaitingApproval({ userId, projectId });
  const onAcceptHandle = () => onAccept(userId);
  const onRejectHandle = () => onReject(userId);
  const onOpenHandle = () => onOpen(userAwaitingApproval);

  if (!userAwaitingApproval) return null;

  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card}>
        <Header userId={userId} onOpen={onOpenHandle} />
        <Actions
          permissionsKey={
            userRoleToUpdate || userAwaitingApproval?.permissionsKey
          }
          onOpen={onOpenHandle}
          onAccept={onAcceptHandle}
          onReject={onRejectHandle}
        />
      </Card>
    </div>
  );
};
