import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  useProjectMediaSize,
  useProjectResolution,
} from "../../services/ProjectService";
import { useProjectResolutionPermission } from "../../services/PermissionService";
import { updateProjectResolution } from "../../services/ApiService";

import ButtonCard from "./ButtonCard";
import MyDialog from "../../ui/MyDialog";

import PhotoSizeSelectLargeOutlinedIcon from "@material-ui/icons/PhotoSizeSelectLargeOutlined";

import { ProjectStrings } from "../../strings";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      flex: 1,
    },
    item: {
      margin: theme.spacing(1),
      whiteSpace: "nowrap",
      "&.Mui-disabled": {
        color: "#000000",
        backgroundColor: theme.palette.primary[500],
      },
    },
    fontType: {
      fontWeight: "bold",
      textAlign: "center",
    },
    buttonsContainer: {
      display: "flex",
    },
  };
});

const getSizeDisplay = (size) =>
  size ? `${size.width} x ${size.height}` : null;

// sizes = dict
const SizesList = ({ sizes, sizeKey, setSizeKey, title }) => {
  const classes = useStyles();
  if (!sizes || sizes.length === 0) return <></>;
  return (
    <div className={classes.root}>
      <div className={classes.fontType}>{title}</div>
      {sizes.map(([key, size]) => (
        <Button
          disabled={sizeKey === key}
          className={classes.item}
          key={key}
          size="large"
          variant="contained"
          onClick={() => setSizeKey(key)}
        >
          {getSizeDisplay(size)}
        </Button>
      ))}
    </div>
  );
};

const SORT_FUNC_DEFAULT = (a, b) =>
  b[1].width === a[1].width
    ? b[1].height - a[1].height
    : b[1].width - a[1].width;

const RESOLUTION_TYPES = [
  {
    title: ProjectStrings.RESOLUTION_TYPE_LANDSCAPE,
    filterFunc: ([_, item]) => item.width > item.height,
    sortFunc: SORT_FUNC_DEFAULT,
  },
  {
    title: ProjectStrings.RESOLUTION_TYPE_PORTRAIT,
    filterFunc: ([_, item]) => item.height > item.width,
    sortFunc: SORT_FUNC_DEFAULT,
  },
  {
    title: ProjectStrings.RESOLUTION_TYPE_SQUARE,
    filterFunc: ([_, item]) => item.width === item.height,
    sortFunc: SORT_FUNC_DEFAULT,
  },
];

const ResolutionCard = ({ projectId }) => {
  const permission = useProjectResolutionPermission(projectId);
  const canRead = permission?.read;
  const canWrite = canRead && permission?.write;

  // only read apps if permission allows
  const defaultSizeKey = useProjectResolution(canRead && projectId);
  const size = useProjectMediaSize({ key: defaultSizeKey });
  const allSizes = useProjectMediaSize({ useAll: true });
  const [showDialog, setShowDialog] = useState(false);
  const [sizeKey, setSizeKey] = useState(defaultSizeKey);
  const [progress, setProgress] = useState(false);

  const content = !!defaultSizeKey && getSizeDisplay(size);
  const classes = useStyles();

  const configCard = {
    icon: <PhotoSizeSelectLargeOutlinedIcon />,
    title: ProjectStrings.MEDIA_RESOLUTION_TITLE,
    buttonLabel: ProjectStrings.MEDIA_RESOLUTION_BUTTON,
    onClick: () => {
      setShowDialog(true);
    },
    disableButton: !canWrite,
    progress,
    fullHeight: true,
  };

  const configDialog = {
    icon: <PhotoSizeSelectLargeOutlinedIcon />,
    title: ProjectStrings.MEDIA_RESOLUTION_TITLE,
    disableOk: sizeKey === defaultSizeKey,
    onClose: () => {
      setShowDialog(false);
      setSizeKey(defaultSizeKey);
    },
    onOk: () => {
      setShowDialog(false);
      setProgress(true);
      updateProjectResolution({ projectId, resolution: sizeKey }).finally(
        () => {
          setProgress(false);
        }
      );
    },
  };

  const allSizesEntries = allSizes && Object.entries(allSizes);

  return (
    <>
      <ButtonCard config={configCard} canRead={canRead}>
        {content}
      </ButtonCard>
      <MyDialog open={showDialog} config={configDialog}>
        <div className={classes.buttonsContainer}>
          {allSizesEntries &&
            RESOLUTION_TYPES.map((type) => (
              <SizesList
                key={type.title}
                sizes={allSizesEntries
                  .filter(type.filterFunc)
                  .sort(type.sortFunc)}
                sizeKey={sizeKey}
                setSizeKey={setSizeKey}
                title={type.title}
              />
            ))}
        </div>
      </MyDialog>
    </>
  );
};

export default ResolutionCard;
