import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    formControl: {
      width: "100%",
    },
    shareCodeDescription: {
      color: "#323232",
    },
  };
});
