import React from "react";
import { useParams } from "react-router-dom";

import { useOrganisationInfo } from "services/OrganisationService";
import { useBreakpoints } from "services/UiService";
import { useStyles } from "./style";
import {
  useUserOrganisationPermissions,
  updateOrganisation,
} from "services/OrganisationService";
import {
  useTeamOrganisationPermissionsOptions,
  useProjectBasicPermissionsOptions,
} from "services/PermissionService";
import SettingsCard from "ui/cards/SettingsCard";
import ConnectedDomainsCard from "ui/cards/ConnectedDomainsCard";
import {
  GUEST_ACCESS_OPTIONS,
  PROJECT_ACCESS_OPTIONS,
  PROJECT_JOIN_APPROVAL_OPTIONS,
} from "configs/accessConfig";
import { OrganisationStrings } from "strings";
import {
  GuestIcon,
  ProjectIcon,
  UserRoleIcon,
  ProjectJoinApprovalIcon,
} from "assets/icons";
import MultilineText from "ui/MultilineText";

const Settings = ({ userId, loading, onUpdate }) => {
  const { organisationId } = useParams();
  const { isMobile } = useBreakpoints();
  const classes = useStyles({ isMobile });
  const orgInfo = useOrganisationInfo(organisationId);

  // permissions control
  const { canReadOrganisation, canWriteOrganisation } =
    useUserOrganisationPermissions({
      userId,
      organisationId,
    });
  const orgPermissionsOptions = useTeamOrganisationPermissionsOptions();
  const projBasicPermissions = useProjectBasicPermissionsOptions();

  const configCard = {
    showParticipants: false,
    showDescription: true,
    showPin: false,
    loading,
  };

  if (canWriteOrganisation) configCard.onClick = onUpdate;

  const onUpdateHandle = (valueKey, lockedKey) => (value, locked) => {
    const dataInfo = { [valueKey]: value };
    if (typeof locked !== "undefined" && lockedKey) {
      dataInfo[lockedKey] = locked;
    }
    return updateOrganisation({
      organisationId,
      info: dataInfo,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.cardWrapper}>
          <SettingsCard
            value={orgInfo?.guestAccess}
            locked={orgInfo?.guestAccessLocked}
            options={GUEST_ACCESS_OPTIONS}
            icon={<GuestIcon />}
            title={OrganisationStrings.SETTINGS_GUEST_ACCESS_TITLE}
            selectTitle={OrganisationStrings.SETTINGS_GUEST_ACCESS_SELECT_TITLE}
            description={OrganisationStrings.SETTINGS_GUEST_ACCESS_SELECT_DESC}
            info={OrganisationStrings.SETTINGS_GUEST_ACCESS_INFO}
            errorMessage={
              OrganisationStrings.SETTINGS_GUEST_ACCESS_UPDATE_ERROR
            }
            canRead={canReadOrganisation}
            canEdit={canWriteOrganisation}
            onOk={onUpdateHandle("guestAccess", "guestAccessLocked")}
          />
        </div>
        <div className={classes.cardWrapper}>
          <SettingsCard
            value={orgInfo?.projectAccess}
            locked={orgInfo?.projectAccessLocked}
            options={PROJECT_ACCESS_OPTIONS}
            icon={<ProjectIcon />}
            title={OrganisationStrings.SETTINGS_PROJECT_ACCESS_TITLE}
            selectTitle={
              OrganisationStrings.SETTINGS_PROJECT_ACCESS_SELECT_TITLE
            }
            description={
              <MultilineText
                text={OrganisationStrings.SETTINGS_PROJECT_ACCESS_SELECT_DESC}
              />
            }
            info={OrganisationStrings.SETTINGS_PROJECT_ACCESS_INFO}
            errorMessage={
              OrganisationStrings.SETTINGS_PROJECT_ACCESS_UPDATE_ERROR
            }
            canRead={canReadOrganisation}
            canEdit={canWriteOrganisation}
            onOk={onUpdateHandle("projectAccess", "projectAccessLocked")}
          />
        </div>
        <div className={classes.cardWrapper}>
          <SettingsCard
            value={orgInfo?.projectJoinApproval}
            locked={orgInfo?.projectJoinApprovalLocked}
            options={PROJECT_JOIN_APPROVAL_OPTIONS}
            icon={<ProjectJoinApprovalIcon />}
            title={OrganisationStrings.SETTINGS_PROJECT_JOIN_APPROVAL_TITLE}
            selectTitle={
              OrganisationStrings.SETTINGS_PROJECT_JOIN_APPROVAL_SELECT_TITLE
            }
            description={
              <MultilineText
                text={
                  OrganisationStrings.SETTINGS_PROJECT_JOIN_APPROVAL_SELECT_DESC
                }
              />
            }
            info={OrganisationStrings.SETTINGS_PROJECT_JOIN_APPROVAL_INFO}
            errorMessage={
              OrganisationStrings.SETTINGS_PROJECT_JOIN_APPROVAL_UPDATE_ERROR
            }
            canRead={canReadOrganisation}
            canEdit={canWriteOrganisation}
            onOk={onUpdateHandle(
              "projectJoinApproval",
              "projectJoinApprovalLocked"
            )}
          />
        </div>
        <div className={classes.cardWrapper}>
          <SettingsCard
            value={orgInfo?.defaultProjectRole}
            locked={orgInfo?.defaultProjectRoleLocked}
            options={projBasicPermissions}
            icon={<UserRoleIcon />}
            title={OrganisationStrings.SETTINGS_PROJECT_DEFAULT_ROLE_TITLE}
            selectTitle={
              OrganisationStrings.SETTINGS_PROJECT_DEFAULT_ROLE_SELECT_TITLE
            }
            description={
              <MultilineText
                text={
                  OrganisationStrings.SETTINGS_PROJECT_DEFAULT_ROLE_SELECT_DESC
                }
              />
            }
            info={OrganisationStrings.SETTINGS_PROJECT_DEFAULT_ROLE_INFO}
            errorMessage={
              OrganisationStrings.SETTINGS_PROJECT_DEFAULT_ROLE_UPDATE_ERROR
            }
            canRead={canReadOrganisation}
            canEdit={canWriteOrganisation}
            onOk={onUpdateHandle(
              "defaultProjectRole",
              "defaultProjectRoleLocked"
            )}
          />
        </div>
        <div className={classes.cardWrapper}>
          <ConnectedDomainsCard
            organisationId={organisationId}
            connectedDomains={orgInfo?.connectedDomains}
            canRead={canReadOrganisation}
          />
        </div>
        <div className={classes.cardWrapper}>
          <SettingsCard
            value={orgInfo?.defaultOrganisationRole}
            options={orgPermissionsOptions}
            icon={<UserRoleIcon />}
            title={OrganisationStrings.SETTINGS_ORGANISATION_DEFAULT_ROLE_TITLE}
            selectTitle={
              OrganisationStrings.SETTINGS_ORGANISATION_DEFAULT_ROLE_SELECT_TITLE
            }
            description={
              <MultilineText
                text={
                  OrganisationStrings.SETTINGS_ORGANISATION_DEFAULT_ROLE_SELECT_DESC
                }
              />
            }
            info={OrganisationStrings.SETTINGS_ORGANISATION_DEFAULT_ROLE_INFO}
            errorMessage={
              OrganisationStrings.SETTINGS_ORGANISATION_DEFAULT_ROLE_UPDATE_ERROR
            }
            canRead={canReadOrganisation}
            canEdit={canWriteOrganisation}
            onOk={onUpdateHandle("defaultOrganisationRole")}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
