import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    position: "relative",
  },
  action: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center;",
    padding: "16px 16px",
    width: "100%",
  },
  media: {
    height: "180px",
    width: "100%",
  },
  editIcon: {
    "&.MuiSvgIcon-root": {
      height: "20px",
      width: "20px",
    },
  },
}));
