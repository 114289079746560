import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  emptyMedia: (mobile) => ({
    fontSize: 150,
    fontWeight: 900,
    width: mobile ? "100vw" : 240,
    height: mobile ? "100vw" : 240,
  }),
  name: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  email: {
    fontSize: 12,
    marginBottom: theme.spacing(2),
    maxWidth: "210px",
    wordWrap: "break-word",
  },
}));
