import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    textfield: {
      "&.MuiInputBase-root": {
        borderRadius: "4px",
        "&:before, &:after": {
          border: "0 solid transparent",
        },
        "& .MuiFilledInput-input": {
          borderRadius: "inherit",
          "&:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
        },
      },
    },
  };
});
