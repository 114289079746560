import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import BusinessIcon from "@material-ui/icons/Business";
import CardMedia from "@material-ui/core/CardMedia";
import { useSnackbar } from "notistack";

import { useMobileLayout } from "hooks/uiHooks";
import { DefaultStrings, OrganisationStrings } from "strings";
import {
  acceptOrganisationInvite,
  declineOrganisationInvite,
  useUserPendingInvites,
  getOrganisationPreview,
  INVITE_STATUS,
} from "services/OrganisationService";
import { useCurrentOrganisation } from "services/UserService";
import { ROUTES, getOrganisationDashboardRoute } from "route";
import LinearSpinner from "ui/LinearSpinner";
import Spinner from "ui/Spinner";
import { useStyles } from "./style";

export const UserPendingInvitesDialog = ({ userId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const mobile = useMobileLayout();
  const params = useParams();
  const { code } = params;
  const isJoinOrgRoute = useRouteMatch(ROUTES.ORGANISATION_JOIN);
  const isOrgDashboardRoute = useRouteMatch(ROUTES.ORGANISATION_DASHBOARD);
  const classes = useStyles({ mobile, small: false });

  const selectedOrganisationId = useCurrentOrganisation();
  const pendingInvites = useUserPendingInvites({ userId, code });

  const currentInvite = useMemo(() => {
    if (typeof pendingInvites === "undefined") return;
    if (!pendingInvites.length) return null;
    return pendingInvites[0];
  }, [pendingInvites]);

  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [organisation, setOrganisation] = useState(null);

  useEffect(() => {
    if (currentInvite?.status === INVITE_STATUS.PENDING) {
      setShowDialog(true);
      setLoading(true);
      getOrganisationPreview({
        inviteCode: currentInvite.code,
        organisationId: currentInvite.organisationId,
      })
        .then((res) => {
          setOrganisation(res.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentInvite]);

  useEffect(() => {
    if (currentInvite === null) {
      setShowDialog(false);

      if (code && isJoinOrgRoute) {
        enqueueSnackbar(OrganisationStrings.JOIN_INVITE_ERROR, {
          variant: "warning",
        });
        if (selectedOrganisationId) {
          history.push(getOrganisationDashboardRoute(selectedOrganisationId));
        }
      }
    }
  }, [
    code,
    currentInvite,
    enqueueSnackbar,
    history,
    isJoinOrgRoute,
    selectedOrganisationId,
  ]);

  const onAcceptHandle = async () => {
    setSending(true);
    try {
      await acceptOrganisationInvite({
        userId,
        organisationId: currentInvite.organisationId,
        code: currentInvite.code,
      });

      if (isJoinOrgRoute || isOrgDashboardRoute) {
        history.push(
          getOrganisationDashboardRoute(currentInvite.organisationId)
        );
      }
      setOrganisation(null);
      setShowDialog(false);
      enqueueSnackbar(OrganisationStrings.JOIN_ACCEPT_SUCCESS, {
        variant: "success",
      });
    } catch (err) {
      console.warn(err);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
    } finally {
      setSending(false);
    }
  };

  const onDeclineHandle = async () => {
    setSending(true);
    try {
      await declineOrganisationInvite({
        userId,
        organisationId: currentInvite.organisationId,
        code: currentInvite.code,
      });

      setOrganisation(null);
      setSending(false);
      enqueueSnackbar(OrganisationStrings.JOIN_DECLINE_SUCCESS, {
        variant: "success",
      });

      if (isJoinOrgRoute) {
        history.push(getOrganisationDashboardRoute(selectedOrganisationId));
      }
    } catch (err) {
      setSending(false);
      console.warn(err);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
    }
  };

  const orgContent = () => {
    if (organisation === null || loading) return <Spinner />;
    return (
      <>
        {organisation?.imageURL && (
          <CardMedia className={classes.media} image={organisation.imageURL} />
        )}
        <Typography variant="h6" color="textPrimary">
          {organisation.name}
        </Typography>
      </>
    );
  };

  return (
    <Dialog open={showDialog} maxWidth={false} fullScreen={mobile}>
      {sending && <LinearSpinner />}
      {currentInvite && (
        <>
          <DialogTitle className={classes.title} disableTypography>
            <Box className={classes.iconWrapper} pr={2}>
              <BusinessIcon />
            </Box>
            <Typography variant="h6">
              {OrganisationStrings.ORGANISATION_TO_JOIN_TITLE}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <div className={classes.cardContainer}>{orgContent()}</div>
          </DialogContent>
          <DialogActions>
            <div style={{ position: "relative" }}>
              {!currentInvite.forceable && (
                <IconButton
                  aria-label={DefaultStrings.BUTTON_CANCEL}
                  onClick={onDeclineHandle}
                  disabled={sending}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton
                aria-label={DefaultStrings.BUTTON_CONFIRM}
                onClick={onAcceptHandle}
                disabled={sending}
              >
                <CheckIcon />
              </IconButton>
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
