import {getCollectionAllDocument, useCollection} from "./FirestoreService";
import {COLLECTIONS} from "../utils/firestoreUtils";
import {isUndefined} from "../utils/generalUtils";

// defaultOnly true = default apps
// defaulyOnly false = optional apps
const filterApps = ({apps, defaultOnly}) => {
  return (
    apps &&
    Object.fromEntries(
      Object.entries(apps).filter(([k, v]) =>
        isUndefined(defaultOnly) ? true : defaultOnly ? v.default : !v.default
      )
    )
  );
};

export const getGlobalApps = async (defaultOnly) => {
  const apps = await getCollectionAllDocument(COLLECTIONS.APP_INFO);
  return filterApps({apps, defaultOnly});
};

export const useGlobalApps = (defaultOnly) => {
  const apps = useCollection({collection: COLLECTIONS.APP_INFO});
  return filterApps({apps, defaultOnly});
};
