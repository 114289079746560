import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    justifyContent: "space-between",
    color: "#323232",
  },
  labelWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  infoIcon: {
    marginLeft: theme.spacing(2),
    color: "#777777",
  },
}));
