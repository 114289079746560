import { connect } from "react-redux";

import Profile from "./Profile";

const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

export const ProfilePage = connect(mapStateToProps)(Profile);
