import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../../hooks/uiHooks";
import {useSystemAdmin} from "../../services/UserService";

import DefaultAppsCard from "../cards/DefaultAppsCard";
import MyPlayerAppsCard from "../cards/MyPlayerAppsCard";
import RestrictedContent from "../RestrictedContent";

const useStyles = makeStyles((theme) => ({
  container: (mobile) => ({
    height: "100%",
    width: "100%",
    padding: theme.spacing(mobile ? 1 : 2),
    display: "flex",
    alignItems: "stretch",
    flexDirection: mobile ? "column" : "row",
  }),
  stretchWidth: (mobile) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    height: mobile ? 1 : "auto",
  }),
}));

const EditAppsTab = ({ projectId, canRead }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const isAdmin = useSystemAdmin();

  return (
    <RestrictedContent permitted={canRead}>
      <div className={classes.container}>
        <div className={classes.stretchWidth}>
          <DefaultAppsCard projectId={projectId} showAddTasks={isAdmin}/>
        </div>
        <div className={classes.stretchWidth}>
          <MyPlayerAppsCard projectId={projectId} showAddTasks={isAdmin}/>
        </div>
      </div>
    </RestrictedContent>
  );
};

export default EditAppsTab;
