import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PushPin from "@material-ui/icons/PushPin";
import PushPinOutlined from "@material-ui/icons/PushPinOutlined";

import { useStyles } from "./style";

export const Pinned = ({ pinned, onClick }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.pinnedIconButton}>
      {pinned ? (
        <PushPin htmlColor="#FFFFFF" className={classes.pinnedIcon} />
      ) : (
        <PushPinOutlined htmlColor="#BFC2D0" className={classes.unpinnedIcon} />
      )}
    </IconButton>
  );
};
