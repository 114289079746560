import React, {useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";

import {downloadImg} from "../../utils/fileUtils";
import {useMobileLayout} from "../../hooks/uiHooks";

import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

// const config = {
//     title: "Title",
//     img: "https://static.wixstatic.com/media/e7728d_04402f7a7f8f4c098fd082db439bc19c~mv2.png/v1/fill/w_529,h_539,al_c,lg_1,q_85,enc_auto/why_myplayer_software.png",
//     onClose: () => {},
// }

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    padding: 0,
  },
}));

const ImageDialog = ({config, open}) => {
  const classes = useStyles();
  const mobile = useMobileLayout();
  const imageRef = useRef(null);
  const title = config?.title;
  const img = config?.img;
  const onClose = config?.onClose;
  const showTitle = title;
  const onClickDownload = () => {
    downloadImg(imageRef.current, title);
  };
  return (
    <Dialog open={open} maxWidth={false} fullScreen={mobile} onClose={onClose}>
      {showTitle && (
        <DialogTitle>
          <div
            style={{
              display: "flex",
            }}
          >
            <span
              style={{
                flexGrow: 1,
              }}
            >
              {title}
            </span>
            <GetAppOutlinedIcon
              onClick={onClickDownload}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
      )}
      {img && (
        <DialogContent
          classes={{
            root: classes.dialogRoot,
          }}
          onClick={onClose}
        >
          <img
            ref={imageRef}
            crossOrigin="anonymous"
            src={img}
            alt={img}
            style={{
              width: "100%",
            }}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ImageDialog;
