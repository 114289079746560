import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import OverflowTypography from "ui/OverflowTypography";

const useStyles = makeStyles((theme) => ({
  content: {
    fontWeight: 700,
    marginRight: theme.spacing(2),
  },
}));

export const NameContent = ({ name }) => {
  const classes = useStyles();
  return (
    <OverflowTypography
      variant="h6"
      color="textPrimary"
      noWrap
      className={classes.content}
    >
      {name}
    </OverflowTypography>
  );
};
