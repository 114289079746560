import React from "react";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { CustomSwitch } from "./components/CustomSwitch";
import { useStyles } from "./style";

export const Switch = ({ name, label, checked, info, onChange }) => {
  const classes = useStyles();

  const onChangeHandle = () => {
    onChange();
  };

  return (
    <FormGroup>
      <Tooltip title={info || ""} placement="top">
        <FormControlLabel
          className={classes.label}
          label={
            <Box className={classes.labelWrapper}>
              <Typography>{label}</Typography>
              {info && <InfoOutlinedIcon className={classes.infoIcon} />}
            </Box>
          }
          labelPlacement="start"
          control={
            <CustomSwitch
              name={name}
              checked={checked}
              onChange={onChangeHandle}
            />
          }
        />
      </Tooltip>
    </FormGroup>
  );
};
