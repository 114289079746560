import React, { createContext, useCallback, useState } from "react";

import ConfirmDialog from "../ui/dialogs/ConfirmDialog";

export const ConfirmDialogContext = createContext();

const ConfirmDialogProvider = ({ children }) => {
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [cbAction, setCbAction] = useState();
  const [dialogConfig, setDialogConfig] = useState();
  const [resolveReject, setResolveReject] = useState([]);
  const [progress, setProgress] = useState(false);

  const [resolve, reject] = resolveReject;

  const showConfirm = useCallback(
    ({ title, message, config }) =>
      new Promise((resolve, reject) => {
        setTitle(title);
        setDesc(message);
        setDialogConfig(config);
        if (config?.cbAction) {
          setCbAction(config.cbAction);
        }
        setResolveReject([resolve, reject]);
      }),
    []
  );

  const onProgressHandle = useCallback((progress) => {
    setProgress(progress);
  }, []);

  const onClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const onCancel = useCallback(() => {
    if (reject) reject();
    onClose();
  }, [reject, onClose]);

  const onConfirm = useCallback(() => {
    if (resolve) resolve({ onClose, onProgress: onProgressHandle });
    if (!cbAction) onClose();
  }, [cbAction, onClose, onProgressHandle, resolve]);

  return (
    <>
      <ConfirmDialogContext.Provider value={showConfirm}>
        {children}
      </ConfirmDialogContext.Provider>
      <ConfirmDialog
        open={resolveReject.length === 2}
        title={title}
        description={desc}
        config={dialogConfig}
        progress={progress}
        onConfirm={onConfirm}
        onClose={onCancel}
      />
    </>
  );
};

export default ConfirmDialogProvider;
