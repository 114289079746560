/**
 * TrackingService is a portal providing tracking service from various engines.
 * 
 * All engines should provide the following methods:
 * - constructor(id, options, callback)
 * - track(key, data)
 * - set(kev, value)
 */
import { createGoogleAnalytics4 } from "./tracking/GoogleAnalytics4";
import { createMyPlayerAnalytics } from "./tracking/MyPlayerAnalytics";

export const ENGINES = {
  GOOGLE_ANALYTICS_4: "GoogleAnalytics4",
  MYPLAYER_GA: "MyPlayerGA",
};

export const createTracking = (engineType, id, options, callback) => {
  switch (engineType) {
    case ENGINES.MYPLAYER_GA:
      return createMyPlayerAnalytics(id, options);
    case ENGINES.GOOGLE_ANALYTICS_4:
      return createGoogleAnalytics4(id, options, callback);
    default:
      return createGoogleAnalytics4(id, options, callback);
  }
};
