import React, { useMemo, useContext, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";
import QueuePlayNextOutlinedIcon from "@material-ui/icons/QueuePlayNextOutlined";

import { DefaultStrings, ProjectStrings } from "strings";
import { useProjectsTabs } from "./useProjectsTabs";
import { useCurrentOrganisation } from "services/UserService";
import { PROJECT_SORT_OPTION_FIELD, PROJECT_DESC_SORT_OPTIONS } from "./config";
import MainContext from "context/MainContext";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import { ROUTES } from "route";

export const useProjectsConfig = ({ userId }) => {
  const location = useLocation();
  const { sortFieldValue, sortDescValue, onSort, onSearch, onAction, initialSort } =
    useContext(MainContext);
  const selectedOrganisationId = useCurrentOrganisation();
  const tabs = useProjectsTabs(selectedOrganisationId);

  const { canWriteProjects } = useUserOrganisationPermissions({
    userId,
    organisationId: selectedOrganisationId,
  });

    const projectSortOptions = useMemo(
    () =>
      Object.keys(PROJECT_SORT_OPTION_FIELD).map((key) => ({
        label: ProjectStrings[key],
        desc: sortDescValue === null ? false : sortDescValue,
        selected:
          key === (sortFieldValue || Object.keys(PROJECT_SORT_OPTION_FIELD)[0]),
        onClick: () => onSort(key, PROJECT_DESC_SORT_OPTIONS[key]),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  const currentTabIndex = useMemo(
    () =>
      tabs.findIndex((tab) =>
        matchPath(location.pathname, { path: tab.path, exact: true })
      ),
    [location.pathname, tabs]
  );

  useEffect(() => {
    if (matchPath(location.pathname, { path: ROUTES.PROJECTS })) {
      const key = Object.keys(PROJECT_SORT_OPTION_FIELD)[0];
      initialSort(key, PROJECT_DESC_SORT_OPTIONS[key]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, currentTabIndex]);

  return [
    {
      path: currentTabIndex === -1 ? tabs[0]?.path : tabs[currentTabIndex]?.path,
      appBar: {
        onSearch: (value) =>
          value && value.length > 0 ? onSearch(value) : onSearch(null),
        sort: projectSortOptions,
        title: DefaultStrings.TITLE,
        tabDefault:
          currentTabIndex === -1 ? tabs[0]?.key : tabs[currentTabIndex]?.key,
        tabs,
      },
      actionButton: {
        actions: [
          {
            icon: <AddToQueueOutlinedIcon />,
            name: ProjectStrings.ADD_PROJECT_DIALOG_CREATE,
            onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_CREATE, true),
            disabled: selectedOrganisationId && !canWriteProjects,
          },
          {
            icon: <QueuePlayNextOutlinedIcon />,
            name: ProjectStrings.ADD_PROJECT_DIALOG_JOIN,
            onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_JOIN, true),
          },
        ],
      },
    },
  ];
};
