import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  select: ({ isMobile, hasLabel }) => ({
    minWidth: 150,
    width: isMobile ? "100%" : "auto",
    "& .MuiFilledInput-input": {
      padding: hasLabel ? "24px 12px 12px" : "12px",
    },
    "&.MuiFilledInput-underline": {
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: "#F4F4F4",
        "&:before": {
          border: "none",
        },
      },
      "&:hover:before": {
        border: "none",
      },
    },
  }),
  icon: {
    marginLeft: theme.spacing(1.5),
  },
  menuItem: {
    color: "#323232",
    paddingTop: theme.spacing(2.5),
  },
  bottomBorder: {
    position: "absolute",
    borderBottom: "1px solid #E8E9EA",
    bottom: 0,
    left: "8px",
    right: "8px",
  },
}));
