import React from "react";

import { useOrganisationUsersStatistics } from "services/OrganisationService";
import MyCard from "../MyCard";
import { OrganisationStrings } from "strings";
import Content from "./Content";
import Spinner from "ui/Spinner";
import { GuestIcon } from "assets/icons";

const GuestsStatCard = ({ organisationId, canRead }) => {
  const stats = useOrganisationUsersStatistics(organisationId);

  const configCard = {
    icon: <GuestIcon />,
    title: OrganisationStrings.OVERVIEW_GUESTS_STAT_TITLE,
  };

  return (
    <MyCard config={configCard} canRead={canRead} style={{ height: "auto" }}>
      {typeof stats === "undefined" ? (
        <Spinner />
      ) : (
        <Content total={stats?.guests || 0} />
      )}
    </MyCard>
  );
};

export default GuestsStatCard;
