import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    borderBottom: "solid 1px #D0D0D0",
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  email: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
}));
