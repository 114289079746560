import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1),
    backgroundColor: "#eaeaea",
    color: "#865d5d",
    maxWidth: '310px',
  },
  textfield: {
    marginTop: theme.spacing(3),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
}));