import React from "react";
import Box from "@material-ui/core/Box";

import { useStyles } from "./styles";

function StatItem({ header, content }) {
  const classes = useStyles();
  return (
    <Box flexGrow="1">
      <div>{header}</div>
      <div className={classes.content}>{content}</div>
    </Box>
  );
}

export default StatItem;
