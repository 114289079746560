import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams, useRouteMatch } from "react-router-dom";

import ProjectTeamList from "ui/ProjectTeamList";
import RestrictedContent from "ui/RestrictedContent";
import TeamCodePanel from "ui/teamCodePanel/TeamCodePanel";
import { useUserProjectPermissions } from "services/ProjectService";
import { useProjectBasicPermissionsOptions } from "services/PermissionService";
import { useProjectInfo } from "hooks/projectHooks";
import { removeUser, updateUserRole } from "services/ApiService";
import { TeamMemberDialog } from "ui/dialogs";
import {
  ROUTES,
  getProjectTeamRoute,
  getProjectTeamShareTabRoute,
} from "route";
import { TeamCodeStrings } from "strings";
import PendingApprovalsCard from "ui/cards/pendingApprovalsCard/PendingApprovalsCard";
import { withInProjectCheck } from "hocs";
import { updateUrl } from "services/UiService";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

// Trello ticket on permissions: https://trello.com/c/dZzUlDeV/107-add-permission-control-for-project-team-page
const ProjectTeamPage = ({ userId }) => {
  const params = useParams();
  const projectId = params.projectId;
  const matchManage = useRouteMatch(ROUTES.PROJECT_TEAM_SHARE_TAB);
  const projectInfo = useProjectInfo(projectId);
  const { actionType, onAction } = useContext(MainContext);
  const [showPanel, setShowPanel] = useState(!!matchManage);
  const [progressId, setProgressId] = useState();
  const [selectedUser, setSelectedUser] = useState(null);

  // permissions control
  const userPermissions = useUserProjectPermissions({ userId, projectId });
  const { canReadUsers, canReadUserPermissions, canWriteProject } =
    userPermissions;
  const selectedUserPermissions = useUserProjectPermissions({
    userId: selectedUser?.userId,
    projectId,
  });

  const permissionsOptions = useProjectBasicPermissionsOptions();

  const onShowPanel = useCallback(
    (show) => {
      if (!show) onAction(GLOBAL_ACION_TYPES.PROJECT_TEAM_MANAGE, false);
      setShowPanel(show);
      const url = show
        ? getProjectTeamShareTabRoute(projectId)
        : getProjectTeamRoute(projectId);

      updateUrl(url);
    },
    [onAction, projectId]
  );

  useEffect(() => {
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_TEAM_MANAGE]) {
      onShowPanel(true);
    }
  }, [actionType, onShowPanel]);

  const configTeamList = {
    projectId,
    onClick: setSelectedUser,
    onOpenCode: () => setShowPanel(true),
    progressId,
  };

  const configPanel = {
    title: TeamCodeStrings.TEAM_CODE_MANAGE,
    projectInfo,
    onClose: () => onShowPanel(false),
  };

  const onProgressIdHandle = (userId) => setProgressId(userId);
  const onCloseHandle = () => setSelectedUser(null);
  const onRemoveUserHandle = () =>
    removeUser({
      projectId,
      userId: selectedUser.userId,
    });
  const onUpdateUserRoleHandle = (role) =>
    updateUserRole({
      projectId,
      role,
      userId: selectedUser.userId,
    });

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PendingApprovalsCard
          projectId={projectId}
          canWrite={canWriteProject}
          onOpen={() => onShowPanel(true)}
        />
        <RestrictedContent permitted={canReadUsers}>
          <ProjectTeamList
            config={configTeamList}
            canReadPermissions={canReadUserPermissions}
          />
        </RestrictedContent>
      </div>
      <TeamMemberDialog
        targetUser={selectedUser}
        targetUserPermissions={selectedUserPermissions}
        userId={userId}
        userPermissions={userPermissions}
        permissionNames={permissionsOptions}
        confirmTitle={TeamCodeStrings.TEAM_MEMBER_REMOVE_CONFIRM_TITLE}
        confirmDesc={TeamCodeStrings.TEAM_MEMBER_REMOVE_CONFIRM_DESC}
        onProgressId={onProgressIdHandle}
        onRemoveUser={onRemoveUserHandle}
        onUpdateUserRole={onUpdateUserRoleHandle}
        onClose={onCloseHandle}
      />
      <TeamCodePanel
        userId={userId}
        projectId={projectId}
        open={showPanel}
        config={configPanel}
      />
    </>
  );
};

export default withInProjectCheck(ProjectTeamPage);
