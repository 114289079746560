import React from "react";
import MuiChip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./style";

export const Chip = ({ title, description, startIcon, endIcon, onClick }) => {
  const classes = useStyles();

  return (
    <MuiChip
      className={classes.chip}
      icon={startIcon}
      label={
        <div className={classes.chipLabel}>
          <Typography className={classes.title} variant="body1">
            {title}
          </Typography>
          <Typography className={classes.description} variant="body2">
            {description}
          </Typography>
        </div>
      }
      deleteIcon={endIcon}
      onDelete={onClick}
    />
  );
};
