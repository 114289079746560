import React from "react";

import TeamShareCodeCard from "ui/cards/teamShareCodeCard/TeamShareCodeCard";
import { useProjectShareCodes } from "services/ProjectService";
import { useMobileLayout } from "hooks/uiHooks";
import { EmptyData } from "ui/emptyData";
import Spinner from "ui/Spinner";
import { TeamCodeStrings } from "strings";
import { useStyles } from "./style";
import { ShareIcon } from "assets/icons";
import RestrictedContent from "ui/RestrictedContent";

export const ShareCodes = ({ projectId, canReadCode, canEditCode, onOpen }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const codes = useProjectShareCodes(canReadCode && projectId);

  const renderContent = () => {
    if (typeof codes === "undefined") return <Spinner />;
    if (!codes?.length)
      return (
        <EmptyData
          title={TeamCodeStrings.TEAM_CODE_NO_SHARE_CODES}
          description={TeamCodeStrings.TEAM_CODE_ADD_DESC}
          icon={<ShareIcon />}
          actionTitle={TeamCodeStrings.TEAM_CODE_ADD_BUTTON}
          onClick={onOpen}
        />
      );
    return codes.map((code) => (
      <TeamShareCodeCard
        key={code}
        code={code}
        canEdit={canEditCode}
        projectId={projectId}
      />
    ));
  };

  return (
    <div className={classes.main}>
      <div className={classes.codes}>
        <div className={classes.codesWrapper}>
          <RestrictedContent permitted={canEditCode}>
            {renderContent()}
          </RestrictedContent>
        </div>
      </div>
    </div>
  );
};
