import { GLOBAL_ACION_TYPES } from "./globalActionTypes";

export const initValues = {
  actionType: {
    // organisation
    [GLOBAL_ACION_TYPES.ORGANISATION_CREATE]: false,
    [GLOBAL_ACION_TYPES.ORGANISATION_DELETE]: false,
    // organisation team
    [GLOBAL_ACION_TYPES.ORGANISATION_INVITE]: false,
    [GLOBAL_ACION_TYPES.ORGANISATION_LEAVE]: false,
    // project overview
    [GLOBAL_ACION_TYPES.PROJECT_TRANSFER]: false,
    [GLOBAL_ACION_TYPES.PROJECT_DELETE]: false,
    [GLOBAL_ACION_TYPES.PROJECT_JOIN]: null,
    [GLOBAL_ACION_TYPES.PROJECT_LEAVE]: false,
    [GLOBAL_ACION_TYPES.PROJECT_CREATE]: false,
    // project team
    [GLOBAL_ACION_TYPES.PROJECT_TEAM_MANAGE]: false,
    // project analytics
    [GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_CODE]: false,
    [GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_TRACKING_DETAILS]: false,
    // project media
    [GLOBAL_ACION_TYPES.PROJECT_MEDIA_EDIT]: false,
    [GLOBAL_ACION_TYPES.PROJECT_MEDIA_APPROVE]: false,
    [GLOBAL_ACION_TYPES.PROJECT_MEDIA_SHARE]: false,
    [GLOBAL_ACION_TYPES.PROJECT_MEDIA_ACTIVITY]: false,
    // devices
    [GLOBAL_ACION_TYPES.PROJECT_DEVICES_QR_DEVICE_CREATE]: false,
    [GLOBAL_ACION_TYPES.PROJECT_DEVICES_INSTRACTION]: false,
    [GLOBAL_ACION_TYPES.PROJECT_DEVICES_MULTIPLE]: false,
    [GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE]: true,
    [GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING]: false,
    [GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING_ADMIN]: false,
  },
};
