/**
 * IFrameService communicates with one or more iframe9s) to provide sending/receiving of message.
 *
 * Start: IFrameService.subscribeMessage(origin, callback)
 * Post message: IFrameService.postMessage(ref, origin, message)
 * Cleanup: IFrameService.unsubscribeMessage(origin, callback)
 */
const TAG = `[IFrameService]`;
const trimOrigin = (o) => o.replace(/\W/g, "");

class IFrameService {
  constructor() {
    this.buses = {}; // site origin => bus element

    const handler = window.onmessage;
    window.onmessage = (event) => {
      // make sure any existing handler is run first
      if (handler) handler(event);

      const type = trimOrigin(event.origin);
      if (type in this.buses) {
        console.debug(
          `${TAG} onmessage (${event.origin}): ${JSON.stringify(event.data)}`
        );

        // dispatch message to subscribers
        this.buses[type].dispatchEvent(
          new CustomEvent(type, { detail: event.data })
        );
      }
    };
  }

  subscribeMessage(origin, callback) {
    const type = trimOrigin(origin);
    if (!this.buses[type]) {
      // create an event bus for this origin if not already exist
      this.buses[type] = document.createElement(`${type}-${Date.now()}`);
    }
    this.buses[type].addEventListener(type, callback);
  }

  unsubscribeMessage(origin, callback) {
    const type = trimOrigin(origin);
    if (!this.buses[type]) return;
    this.buses[type].removeEventListener(type, callback);
  }

  postMessage(ref, origin, message) {
    if (!ref.current) {
      console.warn(`${TAG} postMessage: invalid ref!`);
      return;
    }
    console.debug(`${TAG} postMessage (${origin}): ${JSON.stringify(message)}`);
    return ref.current.contentWindow.postMessage(message, origin);
  }
}

let instance = null;
if (!instance) instance = new IFrameService();
export default instance;
