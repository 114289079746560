import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: (mobile) => ({
    padding: theme.spacing(1),
    minWidth: mobile ? "100%" : "320px",
  }),
  card: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    boxShadow: "unset",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    cursor: "pointer",
  },
}));
