import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useProjectShareCodeMembers } from "services/ProjectService";
import { useMobileLayout } from "hooks/uiHooks";
import { TeamCodeStrings } from "strings";
import UserAvatar from "ui/UserAvatar";
import { useStyles } from "./style";

export const Team = ({ code }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const members = useProjectShareCodeMembers(code);
  const avatarTeamSize = 5;

  const participants = useMemo(() => {
    if (!members) return null;

    const usersCount = members.length - 1;
    if (usersCount > 5) {
      return ` and ${usersCount - 5} other${usersCount - 5 >= 2 ? "s" : ""}`;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  if (members) {
    return (
      <div className={classes.codeTeam}>
        <Typography variant="body1">
          {TeamCodeStrings.TEAM_CODE_TEAM} ({members?.length})
        </Typography>
        <div className={classes.wapper}>
          <div className={classes.teamWrapper}>
            {members.slice(0, 6).map((m, index) => (
              <Box key={m.usersId} ml={index === 0 ? "unset" : "-6px"}>
                <UserAvatar userId={m.usersId} size={avatarTeamSize} />
              </Box>
            ))}
          </div>
          <Typography
            className={classes.participants}
            color="textSecondary"
            variant="body2"
          >
            {participants}
          </Typography>
        </div>
      </div>
    );
  }
  return null;
};
