import React, { useCallback, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useSnackbar } from "notistack";

import { useMobileLayout, useConfirm } from "../hooks/uiHooks";
import { useUserInfo } from "../services/UserService";
import { getNameInitials } from "../utils/generalUtils";
import { useCurrentUserId } from "../services/UserService";
import { formatLocaleTime } from "../utils/localeUtils";
import { useCanDeleteMessage } from "../services/PermissionService";
import {
  deleteProjectMessage,
  deleteDeviceMessage,
  deleteOrganisationMessage,
} from "../services/ApiService";
import { downloadImg } from "../utils/fileUtils";

import MoreIcon from "@material-ui/icons/MoreVert";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";

import { ChatStrings, DefaultStrings } from "../strings";

const fontSize = 11;

const useStyles = makeStyles((theme) => {
  return {
    message: ({ mobile, fullWidth, isAuthor }) => ({
      display: "flex",
      alignItems: "flex-start",
      width: mobile || fullWidth ? "100%" : "50%",
      marginBottom: theme.spacing(2),
      flexShrink: 0,
      flexDirection: isAuthor ? "row-reverse" : "row",
    }),
    profileImage: {
      width: 40,
      height: 40,
      borderRadius: "50%",
    },
    profileText: {
      backgroundColor: "#D0D0D0",
      fontSize: 18,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: ({ isAuthor, highlight }) => ({
      backgroundColor: highlight
        ? theme.palette.primary.main
        : isAuthor
        ? theme.palette.background.default
        : "white",
      borderRadius: 7,
      border: "1px solid rgb(220,220,220)",
      margin: `0px ${theme.spacing(1)}px`,
      display: "flex",
      flexDirection: "column",
      position: "relative",
    }),
    usernameContainer: {
      padding: theme.spacing(1),
      paddingBottom: 0,
      display: "flex",
    },
    imageContainer: {
      position: "relative",
      display: "grid",
      height: "auto",
      maxWidth: 400,
      maxHeight: 300,
      width: "100%",
    },
    imageTimeContainer: {
      position: "absolute",
      right: theme.spacing(0.5),
      bottom: theme.spacing(0.5),
      padding: "2px 4px",
      color: "white",
      backgroundColor: "#00000080",
      borderRadius: 16,
      fontSize,
    },
    messageImage: {
      maxWidth: 400,
      maxHeight: 300,
      width: "100%",
      objectFit: "contain",
    },
    messageVideo: {
      maxWidth: 400,
      maxHeight: 300,
      width: "100%",
    },
    roundedBottom: {
      borderBottomRightRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
    },
    textContainer: {
      display: "flex",
    },
    text: {
      flexGrow: 1,
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
    },
    time: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      color: "#909090",
    },
    menuContainer: {
      position: "absolute",
      height: 40,
      width: 40,
      top: 2,
      right: 2,
      animationDuration: ".3s",
      animationFillMode: "forwards",
    },
    menuSlideIn: {
      animationName: "$slideIn",
    },
    menuSlideOut: {
      animationName: "$slideOut",
    },
    "@keyframes slideIn": {
      "0%": {
        // right: -400,
        opacity: 0,
        // visibility: "hidden",
        width: 0,
      },
      "100%": {
        // right: 2,
        opacity: 1,
        // visibility: "visible",
        width: 40,
      },
    },
    "@keyframes slideOut": {
      "0%": {
        // right: 2,
        opacity: 1,
        // visibility: "visible",
        width: 40,
      },
      "100%": {
        // right: -400,
        opacity: 0,
        // visibility: "hidden",
        width: 0,
      },
    },
  };
});

const getImageFilename = (contentURL) => {
  const res = contentURL.match(/chatImages.+-.{4}-.{4}-.{4}-.{12}-(.+)\?alt/);
  console.debug(res, decodeURI(res[1]));
  return res?.[1] && decodeURI(res[1]);
};

const MenuLayer = ({ mobile, disabled, onClick, dark }) => {
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(!!mobile);
  const onHoverStart = () => {
    if (!mobile) setShowMenu(true);
  };
  const onHoverEnd = () => {
    if (!mobile) setShowMenu(false);
  };
  return (
    <div
      style={{
        position: "absolute",
        height: 1,
        width: 1,
        top: 0,
        right: 0,
        visibility: disabled ? "hidden" : "visible",
      }}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
    >
      <IconButton
        className={clsx(
          classes.menuContainer,
          showMenu ? classes.menuSlideIn : classes.menuSlideOut
        )}
        color="inherit"
        onClick={onClick}
      >
        <MoreIcon style={{ color: dark ? "white" : "black" }} />
      </IconButton>
    </div>
  );
};

const ChatMessage = ({
  id,
  message,
  fullWidth,
  projectId,
  deviceId,
  organisationId,
  onClickImage,
}) => {
  const currentUserId = useCurrentUserId();
  const { text, createdAt, uid, imageURL, videoURL } = message;
  const info = useUserInfo(uid);
  const mobile = useMobileLayout();
  const isAuthor = uid === currentUserId;
  const [highlight, setHighlight] = useState(false);
  const classes = useStyles({ mobile, fullWidth, isAuthor, highlight });
  const [anchor, setAnchor] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const canDelete = useCanDeleteMessage({ message, projectId, organisationId });
  const deleted = message.deleted;
  const imageRef = useRef(null);

  const onClickMenu = useCallback((e) => {
    setAnchor(e.currentTarget);
    e.stopPropagation();
  }, []);
  const onCloseMenu = useCallback((e) => {
    setAnchor(null);
  }, []);
  const onDelete = useCallback(() => {
    setAnchor(null);
    setHighlight(true);
    confirm({
      message: ChatStrings.CHAT_DELETE_MESSAGE_CONFIRM,
    })
      .then(() => {
        setLoading(true);
        let delFunc = deleteProjectMessage;

        if (organisationId) {
          delFunc = deleteOrganisationMessage;
        } else if (deviceId) {
          delFunc = deleteDeviceMessage;
        }

        delFunc({ projectId, deviceId, id, organisationId })
          .then(() => {
            enqueueSnackbar(ChatStrings.CHAT_DELETE_SUCCESS, {
              variant: "success",
            });
          })
          .catch((err) => {
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
            console.warn(err);
          })
          .finally(() => {
            window.requestAnimationFrame(() => {
              setLoading(false);
            });
          });
      })
      .catch(() => {})
      .finally(() => {
        setHighlight(false);
      });
  }, [confirm, organisationId, deviceId, projectId, id, enqueueSnackbar]);
  const onDownload = useCallback(() => {
    const filename = getImageFilename(imageURL);
    downloadImg(imageRef.current, filename);
    setAnchor(null);
  }, [imageURL]);
  const onClickImageInternal = useCallback(() => {
    if (onClickImage) onClickImage(imageURL, getImageFilename(imageURL));
  }, [imageURL, onClickImage]);

  const displayMessageMedia = () => {
    if (imageURL) {
      return (
        <img
          ref={imageRef}
          crossOrigin="anonymous"
          className={clsx(classes.messageImage, !text && classes.roundedBottom)}
          src={imageURL}
          alt="message"
          style={{
            cursor: "pointer",
          }}
          onClick={onClickImageInternal}
        />
      );
    } else if (videoURL) {
      return (
        <video
          className={clsx(classes.messageVideo, !text && classes.roundedBottom)}
          controls
        >
          <source src={videoURL} type="video/mp4" />
        </video>
      );
    } else {
      return <></>;
    }
  };

  const mediaContent = !deleted && (imageURL || videoURL) && (
    <div className={classes.imageContainer}>
      {displayMessageMedia()}
      <MenuLayer
        mobile={mobile}
        disabled={loading}
        onClick={onClickMenu}
        dark={true}
      />
      {!text && (
        <span className={classes.imageTimeContainer}>
          {formatLocaleTime(createdAt)}
        </span>
      )}
    </div>
  );

  const textTimeContent = (
    <div className={classes.time}>
      <Typography variant="body2">
        <span style={{ fontSize }}>{formatLocaleTime(createdAt)}</span>
      </Typography>
    </div>
  );

  const textContent = !deleted && text && (
    <>
      {!imageURL && !videoURL && (
        <MenuLayer mobile={mobile} disabled={loading} onClick={onClickMenu} />
      )}
      <div className={classes.textContainer}>
        <div className={classes.text}>
          <Typography variant="body2" color="textSecondary">
            {text}
          </Typography>
        </div>
        {textTimeContent}
      </div>
    </>
  );

  const menuContent = !deleted && (
    <Menu
      id="menuOverflow"
      anchorEl={anchor}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={anchor !== null}
      onClose={onCloseMenu}
    >
      <MenuItem onClick={onDelete} disabled={!canDelete}>
        {ChatStrings.CHAT_DELETE_MESSAGE}
      </MenuItem>
      {imageURL && (
        <MenuItem onClick={onDownload}>
          {ChatStrings.CHAT_MENU_DOWNLOAD_IMAGE}
        </MenuItem>
      )}
    </Menu>
  );

  const deletedContent = deleted && (
    <div className={classes.textContainer}>
      <div
        style={{
          paddingLeft: 8,
        }}
      >
        <NotInterestedOutlinedIcon color="action" />
      </div>
      <div className={classes.text}>
        <Typography variant="body2" color="textSecondary">
          {ChatStrings.CHAT_DELETED}
        </Typography>
      </div>
      {textTimeContent}
    </div>
  );

  return (
    <div id={id} className={classes.message}>
      {info?.img ? (
        <img className={classes.profileImage} src={info?.img} alt="profile" />
      ) : (
        <div className={`${classes.profileImage} ${classes.profileText}`}>
          {getNameInitials(info?.name)}
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.usernameContainer}>
          <Typography variant="caption">{info?.name}</Typography>
          <div style={{ width: 40 }} />
        </div>
        {loading && <LinearProgress />}
        {mediaContent}
        {textContent}
        {deletedContent}
      </div>
      {menuContent}
    </div>
  );
};

export default ChatMessage;
