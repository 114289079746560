import React, { useEffect } from "react";
import { useParams, useHistory, generatePath } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  useUserInOrganisation,
  useUserOrganisationPermissions,
} from "services/OrganisationService";
import {
  useMostRecentOrganisationsIds,
  useCurrentOrganisation,
} from "services/UserService";
import { getProjectsRoute } from "route";
import { OrganisationStrings } from "strings";

export const withInOrganisationCheck = (BaseComponent) => {
  // HOC logic using hooks
  return function (props) {
    const { userId, match } = props;
    const params = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const currentOrgId = params.organisationId;

    const orgIds = useMostRecentOrganisationsIds();
    const selectedOrganisationId = useCurrentOrganisation();

    const { isSuperAdmin } = useUserOrganisationPermissions({
      userId,
      organisationId: selectedOrganisationId,
    });

    const userInOrganisation = useUserInOrganisation({
      organisationId: currentOrgId,
      userId,
    });

    useEffect(() => {
      if (isSuperAdmin === false && userInOrganisation === null) {
        if (selectedOrganisationId === null) {
          enqueueSnackbar(OrganisationStrings.NO_PART_OF_ANY_ORGANISATION, {
            variant: "warning",
          });
          history.push(getProjectsRoute());
        } else if (selectedOrganisationId !== currentOrgId) {
          const path = generatePath(match.path, {
            ...params,
            organisationId: selectedOrganisationId,
          });
          history.push(path);
        }
      }
    }, [
      enqueueSnackbar,
      history,
      match,
      orgIds,
      currentOrgId,
      params,
      selectedOrganisationId,
      userInOrganisation,
      isSuperAdmin,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return <BaseComponent {...props} />;
  };
};
