import { DeployStrings } from "../strings";

export const DEPLOY_MAIN_STAGES = [
  "handleStartTasks",
  "onProjectObtained",
  "onDeviceAddedToProject",
  "processUninstalledPkgs",
  "onPackageInstalled",
  "processUnmatchedMediaFiles",
  "onBlobsDownloaded",
  "overwriteUnmatchedFiles",
  "onEndTasksCompleted",
];

// in desc order
export const DEPLOY_ALL_STAGES = [
  "Task RestartServices",
  "notifyDeviceCompliant",
  "onEndTasksCompleted",
  // "handleEndTasks",
  "onUpdatesCompleted",
  "onPackageInstalled",
  "processUninstalledPkgs",
  "onAllMediaFilesHandled",
  // "addMyMediaPlayerRestartToEndTasks",
  // "onProjectMedia",
  "processUnmatchedMediaFiles", // "numberOfUnmatchedFiles"
  "processMediaFiles",
  "onDeviceAddedToProject",
  "onDeviceRegistration",
  "installProject",
  // "alreadyRegistered",
  "onProjectObtained",
  "handleStartTasks",
];

// in desc order
export const DEPLOY_APP_STAGES = [
  "pkgs", // []
  "onPackageInstalled", // "pkgName"
  "processUninstalledPkgs", // "pkg", "pkgName", "progress"
  "downloadAndInstallApk", // "appNameVer"
];

// in desc order
export const DEPLOY_CONTENT_STAGES = [
  "overwriteUnmatchedFiles", // "nUnmatchedFiles"
  "onBlobsDownloaded", // "downloadedBlobs"
  "onBlobDownloaded", // "downloadedBlobs" (0)
  "processUnmatchedMediaFiles", // "numberOfUnmatchedFiles"
];

export const DEPLOY_START_STAGES = ["handleStartTasks", "installProject"];

export const DEPLOY_END_STAGES = ["handleEndTasks", "onEndTasksCompleted"];

const APP_INSTALLED = "installed";

const onPackageInstalled = (info, appInfos) =>
  DeployStrings.STAGE_ON_PACKAGE_INSTALLED.replace(
    "{}",
    appInfos && info.pkgName in appInfos
      ? appInfos[info.pkgName].appName
      : info.pkgName
  );

const processUninstalledPkgs = (info) =>
  DeployStrings.STAGE_PROCESS_UNINSTALL_PKGS.replace(
    "{}",
    `${info.pkg.appName}-${info.pkg.ver}`
  );

export const STAGE_STRINGS = {
  "Task RestartServices": DeployStrings.STAGE_TASTK_RESTART_SERVICES,
  notifyDeviceCompliant: DeployStrings.STAGE_NOTIFY_DEVICE_COMPLIANT,
  onEndTasksCompleted: DeployStrings.STAGE_ON_END_TASKS_COMPLETED,
  // handleEndTasks,
  onUpdatesCompleted: DeployStrings.STAGE_ON_UPDATES_COMPLETED,
  onAllMediaFilesHandled: DeployStrings.STAGE_ON_ALL_MEDIA_FILES_HANDLED,
  // addMyMediaPlayerRestartToEndTasks,
  // onProjectMedia,
  processMediaFiles: DeployStrings.STAGE_PROCESS_MEDIA_FILES,
  installProject: DeployStrings.STAGE_INSTALL_PROJECT,
  onDeviceAddedToProject: DeployStrings.STAGE_ON_DEVICE_ADDED_TO_PROJECT,
  onDeviceRegistration: DeployStrings.STAGE_ON_DEVICE_REGISTRATION,
  // alreadyRegistered,
  onProjectObtained: DeployStrings.STAGE_ON_PROJECT_OBTAINED,
  handleStartTasks: DeployStrings.STAGE_HANDLE_START_TASKS,

  // app
  pkgs: (info, appInfos) =>
    DeployStrings.STAGE_ON_PACKAGE_INSTALLED.replace(
      "{}",
      Object.entries(info)
        .filter(([k, v]) => v.state === APP_INSTALLED)
        .map(([k, v]) => {
          const id = k.replace(/~/g, ".");
          if (appInfos && id in appInfos) return appInfos[id].appName;
          return id;
        })
        .join(", ")
    ),
  onPackageInstalled,
  processUninstalledPkgs,
  downloadAndInstallApk: (info) =>
    DeployStrings.STAGE_DOWNLOAD_AND_INSTALL_APK.replace("{}", info.appNameVer),

  // content
  overwriteUnmatchedFiles: (info) =>
    DeployStrings.STAGE_OVERWRITE_UNMATCHED_FILES.replace(
      "{}",
      info.nUnmatchedFiles
    ),
  onBlobsDownloaded: (info) =>
    DeployStrings.STAGE_ON_BLOBS_DOWNLOADED.replace(
      "{}",
      info.downloadedBlobs.length
    ),
  onBlobDownloaded: DeployStrings.STAGE_ON_BLOB_DOWNLOADED, // "downloadedBlobs" (0)
  processUnmatchedMediaFiles: (info) =>
    info.numberOfUnmatchedFiles && info.numberOfUnmatchedFiles > 0
      ? DeployStrings.STAGE_PROCESS_UNMATCHED_MEDIA_FILES.replace(
          "{}",
          info.numberOfUnmatchedFiles
        )
      : null,
};

const getLastStageText = (progress, stages, extra) => {
  if (!progress) return;
  for (const stage of stages) {
    if (stage in progress) {
      const res = STAGE_STRINGS[stage];
      return typeof res === "function" ? res(progress[stage], extra) : res;
    }
  }
  return null;
};

export const getLastAllStageText = (progress, appInfos) => {
  return getLastStageText(progress, DEPLOY_ALL_STAGES, appInfos);
};

export const getLastAppStageText = (progress, appInfos) => {
  return getLastStageText(progress, DEPLOY_APP_STAGES, appInfos);
};

export const getLastContentStageText = (progress) => {
  return getLastStageText(progress, DEPLOY_CONTENT_STAGES);
};

/**

latestMethodCall: "install"
timestamp: 1596734766.164

stage: "handleStartTasks"
timestamp: 1596734774.767
8.603

stage: "onProjectObtained"
timestamp: 1596734774.881
0.114

  stage: "alreadyRegistered" => "accountId"
  timestamp: 1596734778.258
  3.377

stage: "onDeviceRegistration"
timestamp: 1596734779.285
1.027

  stage: "onDeviceAddedToProject"
  timestamp: 1596734781.837
  2.552

stage: "downloadAndInstallApk" => "appNameVer"
timestamp: 1596734781.983
0.146

stage: "installProject"
timestamp: 1596734782.23
0.247

  stage: "processUninstalledPkgs" => "pkg", "pkgName", "progress"
  timestamp: 1596734785.603
  3.373

? stage: "processMediaFiles" => "numberOfMediaFiles"
timestamp: 1596734786.191
0.588

stage: "onProjectMedia"
timestamp: 1596734786.394
0.203

  stage: "processUnmatchedMediaFiles" => "numberOfUnmatchedFiles"
  timestamp: 1596734807.664
  21.27

  stage: "onBlobsDownloaded" => "downloadedBlobs"
  timestamp: 1596734810.066
  2.402

stage: "onBlobDownloaded" => "downloadedBlobs" (empty)
timestamp: 1596734810.183
0.117

stage: "addMyMediaPlayerRestartToEndTasks"
timestamp: 1596734810.682
0.499

  stage: "overwriteUnmatchedFiles" => "nUnmatchedFiles"
  timestamp: 1596734813.566
  2.884

stage: "onAllMediaFilesHandled"
timestamp: 1596734813.876
0.31

  stage: "onPackageInstalled" => "pkgName"
  timestamp: 1596734819.488
  5.612

stage: "onUpdatesCompleted"
timestamp: 1596734819.588
0.1

stage: "handleEndTasks"
timestamp: 1596734820.07
0.482

  stage: "onEndTasksCompleted"
  timestamp: 1596734821.998
  1.928

stage: "notifyDeviceCompliant"
timestamp: 1596734824.079
2.081

stage: "Task RestartServices"
timestamp: 1596734824.283
0.204


*/
