import { actionTypes } from "redux-firestore";

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true,
  logErrors: false,
  profileFactory: (userData, profileData, firebase) => {
    const email = profileData.email.replace(/\./g, "~");
    return {
      email,
      name: profileData.name,
      accountId: email,
      usersId: userData.user.uid,
    };
  },
  onAuthStateChanged: (auth, firebase, dispatch) => {
    if (!auth) {
      dispatch({ type: actionTypes.CLEAR_DATA });
    }
  },
};

export default rrfConfig;
