import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import ChatMessage from "./ChatMessage";
import ChatRoomForm from "./ChatRoomForm";
import Spinner from "./Spinner";
import InfiniteScroll from "./InfiniteScroll";
import ImageDialog from "./dialogs/ImageDialog";
import MessageRoundedIcon from "@material-ui/icons/MessageRounded";
import CommentRoundedIcon from "@material-ui/icons/CommentRounded";
import TabletAndroidRoundedIcon from "@material-ui/icons/TabletAndroidRounded";

import { isLoading } from "../utils/uiUtils";
import { formatLocaleDateLong } from "../utils/localeUtils";
import {
  getMessagesDates,
  getMessagesCount,
  useChatRoomMessages,
} from "../services/ChatService";
import { getElementTop, useElementOnScroll } from "../services/UiService";
import { useMobileLayout } from "../hooks/uiHooks";
import { EmptyData } from "./emptyData";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      position: "relative",
      backgroundColor: theme.palette.background.default,
    },
    content: (mobile) => ({
      height: 1,
      flexGrow: 1,
      overflowX: "hidden",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
      padding: `0px ${mobile ? 0 : theme.spacing(2)}px`,
    }),
    input: (mobile) => ({
      padding: `${theme.spacing(2)}px ${mobile ? 0 : theme.spacing(2)}px ${
        mobile ? 0 : theme.spacing(2)
      }px`,
      margin: `0px ${mobile ? -theme.spacing(1) - 2 : 0}px ${
        mobile ? -2 : 0
      }px`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
    dateFixed: {
      position: "absolute",
      top: theme.spacing(1),
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1,
    },
    dateContainer: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(1),
      width: "100%",
      flexShrink: 0,
    },
    date: {
      padding: "4px 8px",
      backgroundColor: "#D0D0D0",
      borderRadius: 16,
    },
  };
});

const DEFAULT_MESSAGE_COUNT = 15;

const DATE_FADE_TIMEOUT = 3000;

// timer to show the fixed date
let fadeTimer;

const ChatRoom = ({
  roomId,
  deviceId,
  projectId,
  organisationId,
  fullWidth,
  roomType,
  emptyDataTitle,
  emptyDataDescription,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const [count, setCount] = useState(DEFAULT_MESSAGE_COUNT);
  const messages = useChatRoomMessages(roomId, count);
  const contentRef = useRef(null);
  const [currentDate, setCurrentDate] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [image, setImage] = useState();
  const messageCount = getMessagesCount(messages);
  const dates = getMessagesDates(messages);

  // show the current date on scroll
  // set state -> clear existing timer -> clear state on timer
  const showDate = (date) => {
    setCurrentDate(date);
    if (fadeTimer) clearTimeout(fadeTimer);
    fadeTimer = setTimeout(() => {
      setCurrentDate(null);
    }, DATE_FADE_TIMEOUT);
  };

  const onScroll = (element) => {
    if (!dates) return;

    const parentTop = getElementTop(element);

    // look for the first visible date
    for (let i = dates.length - 1; i >= 0; i--) {
      const date = dates[i];
      const el = document.getElementById(date);
      const top = getElementTop(el);

      // visible
      if (top >= parentTop) {
        showDate(dates[i + 1]);
        return;
      }
    }

    // if no date is visible then show the first (latest) date
    showDate(dates[0]);
  };

  useElementOnScroll({ element: contentRef?.current, onScroll });

  if (isLoading(messages)) return <Spinner />;

  const config = {
    onLoadMore: async () => {
      // load if loaded equals to target number
      if (messageCount === count) {
        setCount(count + DEFAULT_MESSAGE_COUNT);
      }
    },
  };

  const DateRow = ({ id, date, fixed }) => (
    <div
      id={id}
      className={clsx(classes.dateContainer, fixed && classes.dateFixed)}
    >
      <span className={classes.date}>{formatLocaleDateLong(date)}</span>
    </div>
  );

  const onClickImage = (src, filename) => {
    setImage({ src, filename });
    setShowDialog(true);
  };

  const renderContent = () => {
    if (messages === null) {
      return (
        <EmptyData
          title={emptyDataTitle}
          description={emptyDataDescription}
          icon={
            organisationId ? (
              <MessageRoundedIcon />
            ) : deviceId ? (
              <TabletAndroidRoundedIcon />
            ) : (
              <CommentRoundedIcon />
            )
          }
        />
      );
    }
    return (
      <InfiniteScroll config={config}>
        {messages?.map((msg) =>
          typeof msg === "number" ? (
            <DateRow key={msg} id={msg} date={msg} />
          ) : (
            <ChatMessage
              id={msg.id}
              key={msg.id}
              message={msg}
              fullWidth={fullWidth}
              projectId={projectId}
              deviceId={deviceId}
              organisationId={organisationId}
              onClickImage={onClickImage}
            />
          )
        )}
      </InfiniteScroll>
    );
  };

  return (
    <div className={classes.root}>
      {currentDate && <DateRow date={currentDate} fixed={true} />}
      <div className={classes.content} ref={contentRef}>
        {renderContent()}
      </div>
      <div className={classes.input}>
        <ChatRoomForm
          roomId={roomId}
          deviceId={deviceId}
          projectId={projectId}
          organisationId={organisationId}
          fullWidth={fullWidth}
          roomType={roomType}
        />
      </div>
      <ImageDialog
        open={showDialog}
        config={{
          title: image?.filename,
          img: image?.src,
          onClose: () => {
            setShowDialog(!showDialog);
          },
        }}
      />
    </div>
  );
};

export default ChatRoom;
