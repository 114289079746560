import { useSelector } from "react-redux";
import { setData, removeData } from "../redux/myplayerReducer";

export const setMyState = ({ dispatch, path, data }) => {
  console.debug("setMyState", path, data);
  setData({ dispatch, path, data });
};

export const removeMyState = ({ dispatch, path }) => {
  console.debug("removeMyState", path);
  removeData({ dispatch, path });
};

export const useMyState = ({ path }) => {
  const state = useSelector(({ myplayer }) => myplayer);
  let cur = state;
  path.split("/").every((p) => {
    const res = p in cur;
    cur = cur[p];
    return res;
  });
  return cur;
};
