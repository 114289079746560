import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import WorkspacesOutlinedIcon from "@material-ui/icons/WorkspacesOutlined";

import {
  getAllOrganisations,
  useTotalOrganisationCountSuperAdmin,
} from "services/OrganisationService";
import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { OrganisationStrings } from "strings";
import Spinner from "ui/Spinner";
import { ORGANISATION_SORT_OPTION_FIELD } from "utils/organisationUtils";
import InfiniteScroll from "ui/InfiniteScroll";
import { EmptyData } from "ui/emptyData";
import { OrganisationSimpleCard } from "ui/organisationSimpleCard";
import { getOrganisationDashboardRoute } from "route";
import MainContext from "context/MainContext";
import { SearchIcon } from "assets/icons";

const Organisations = () => {
  const history = useHistory();
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, small: false });
  const { sortFieldValue, sortDescValue, searchValue } = useContext(MainContext);

  const [startAfter, setStartAfter] = useState();
  const [organisationsIds, setOrganisationsIds] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [totalOrganisations, setTotalOrganisations] = useState();
  const [loading, setLoading] = useState(false);

  const orgsSuperAdminTotalCount = useTotalOrganisationCountSuperAdmin(true);

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter();
  };

  const getParams = useCallback((params) => {
    const { sortDesc, sortValue, lastKey } = params;
    const orderBy =
      sortValue && sortValue in ORGANISATION_SORT_OPTION_FIELD
        ? ORGANISATION_SORT_OPTION_FIELD[sortValue]
        : Object.keys(ORGANISATION_SORT_OPTION_FIELD)[0];

    return {
      limit: 20,
      orderBy,
      searchValue: params.searchValue,
      lastKey,
      orderDesc: sortDesc,
    };
  }, []);

  const onSelectHandle = (orgInfo) => {
    history.push(getOrganisationDashboardRoute(orgInfo.organisationId));
  };

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        sortDesc: sortDescValue,
        sortValue: sortFieldValue,
        searchValue,
        ...params,
      });

      setLoading(true);

      try {
        const response = await getAllOrganisations(requestParams);
        const { items, total } = response.result;
        setTotalOrganisations(total);

        if (items.length === 0) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setOrganisationsIds([...organisationsIds, ...items]);
        } else {
          setOrganisationsIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
    [
      getParams,
      isRequestSent,
      organisationsIds,
      searchValue,
      sortDescValue,
      sortFieldValue,
    ]
  );

  useEffect(() => {
    // initial request on page loaded
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFieldValue, sortDescValue, searchValue]);

  useEffect(() => {
    // do request if total count changed for organisations
    if (
      typeof totalOrganisations !== "undefined" &&
      typeof orgsSuperAdminTotalCount !== "undefined" &&
      totalOrganisations !== orgsSuperAdminTotalCount
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalOrganisations, orgsSuperAdminTotalCount]);

  const scrollConfig = {
    onLoadMore: () => {
      if (isFetchedAll) return;

      fetchData({ lastKey: startAfter });
    },
  };

  const renderContent = () => {
    if (typeof organisationsIds === "undefined") return <Spinner />;

    if (!organisationsIds.length && !isRequestSent)
      return (
        <EmptyData
          title={
            orgsSuperAdminTotalCount === 0
              ? OrganisationStrings.LIST_NO_ORGANISATIONS
              : OrganisationStrings.LIST_NO_FOR_SEARCH
          }
          icon={
            orgsSuperAdminTotalCount === 0 ? (
              <WorkspacesOutlinedIcon />
            ) : (
              <SearchIcon />
            )
          }
        />
      );

    return (
      <InfiniteScroll config={scrollConfig} size={organisationsIds.length}>
        <div className={classes.main}>
          {organisationsIds.map((organisationId) => (
            <div key={organisationId} className={classes.itemContainer}>
              <OrganisationSimpleCard
                organisationId={organisationId}
                actionTitle={OrganisationStrings.CARD_ITEM_VIEW}
                onSelect={onSelectHandle}
              />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <div className={classes.root}>
      {loading && <Spinner />}
      {renderContent()}
    </div>
  );
};

export default Organisations;
