import { useCollection, useDocument } from "./FirestoreService";
import { useProjectApps } from "./ProjectService";

const COLLECTIONS = {
  REMOTE_COMMANDS: "remote_commands",
};

const DOCUMENTS = {
  DEFAULTS: "_defaults",
}

export const useRemoteCommands = (projectId) => {
  const allCommands = useCollection({
    collection: COLLECTIONS.REMOTE_COMMANDS,
  });
  const apps = useProjectApps(projectId);
  if (!projectId) return allCommands;
  return (
    apps &&
    allCommands &&
    Object.fromEntries(
      Object.entries(allCommands)
        // filter configured apps or always include
        .filter(([k, v]) => k in apps || v.alwaysInclude)
        // filter disabled actions
        .map(([k, v]) => [
          k,
          { ...v, actions: v.actions.filter((a) => !a.disabled) },
        ])
    )
  );
};

export const useRemoteCommandsDefaults = () =>
  useDocument({ collection: COLLECTIONS.REMOTE_COMMANDS, doc: DOCUMENTS.DEFAULTS });
