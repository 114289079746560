import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

/**
 * props = { name, label, width, error, onChange }
 */

const useStyles = makeStyles(() => {
  return {
    helperText: {
      fontWeight: "normal",
    },
  };
});
const PasswordField = ({
  name,
  label,
  error,
  onBlur,
  onChange,
  keyPressed,
  disabled = false,
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const onChangeInternal = (event) => {
    setPassword(event.target.value);
    onChange(event.target.value);
  };

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      FormHelperTextProps={{
        classes: { error: classes.helperText },
      }}
      onKeyDown={keyPressed}
      helperText={error}
      error={error?.length > 0}
      fullWidth
      name={name}
      label={label}
      type={showPassword ? "text" : "password"}
      value={password}
      onChange={onChangeInternal}
      onBlur={onBlur}
      autoComplete="off"
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleVisibility}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
