import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

import { COLLECTIONS } from "../utils/firestoreUtils";

// get user info => dict
export const useUserInfo = (userId) => {
  const collection = COLLECTIONS.USER;
  const config = userId && {
    collection,
    doc: userId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    return isLoaded(infos) && userId in infos && infos[userId];
  });
};

export const useUserName = (userId) => {
  const info = useUserInfo(userId);
  return info && info.name;
};

export const useUserEmail = (userId) => {
  const info = useUserInfo(userId);
  return info && info.email?.replace(/~/g, ".");
};

// get user picture => string
export const useUserPicture = (userId) => {
  const info = useUserInfo(userId);
  return info && info.img;
};

export const useUserId = () => {
  const profile = useSelector(
    ({ firebase: { profile } }) => isLoaded(profile) && profile
  );
  if (profile) return profile.usersId;
  else return;
};
