import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: (mobile) => ({
      height: "100%",
      width: "100%",
      paddingLeft: theme.spacing(mobile ? 1 : 2),
      paddingRight: theme.spacing(mobile ? 1 : 2),
    }),
  };
});
