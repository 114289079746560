import React, { useEffect, useState, useContext } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

import { navigationBarWidth } from "ui/pageLayout/config";
import MyDialog from "../../ui/MyDialog";
import MobileContent from "../../ui/MobileContent";
import RestrictedContent from "../../ui/RestrictedContent";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";

import { useMobileLayout } from "../../hooks/uiHooks";
import { useProjectAnalyticsUrl } from "../../hooks/projectHooks";
import {
  useUserProjectPermissions,
  useProjectTitle,
  useProjectTracking,
} from "../../services/ProjectService";
import {
  updateProjectAnalyticsUrl,
  updateProjectTracking,
} from "../../services/ApiService";

import { ProjectStrings } from "../../strings";
import { withInProjectCheck } from "hocs";
import { EmptyData } from "ui/emptyData";
import { AreaChartIcon } from "assets/icons";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

const useStyles = makeStyles((theme) => {
  return {
    main: (canRead) => ({
      height: "100%",
      marginRight: canRead ? navigationBarWidth : 0,
    }),
    content: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.default,
      border: "none",
    },
    empty: {
      padding: theme.spacing(4),
    },
  };
});

const constructAnalyticsUrlFromCode = (code) =>
  `https://datastudio.google.com/embed/reporting/${code}/page/1M`;

const extractAnalyticsCodeFromUrl = (url) => {
  if (!url) return null;
  const results = url.match(
    /^https:\/\/datastudio.google.com\/embed\/reporting\/(.+)\/page\/1M$/
  );
  if (results && results.length === 2) {
    return results[1];
  } else {
    return null;
  }
};

const TrackingDetailsDialog = ({ projectId }) => {
  const { actionType, onAction } = useContext(MainContext);

  const tracking = useProjectTracking(projectId);
  const showDialog =
    actionType[GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_TRACKING_DETAILS];
  const [trackingCode, setTrackingCode] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [progress, setProgress] = useState(false);

  const onOk = () => {
    setProgress(true);
    updateProjectTracking({ projectId, trackingCode, apiSecret }).finally(
      () => {
        onAction(GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_TRACKING_DETAILS, false);
        setProgress(false);
      }
    );
  };

  const invalidateInput = () =>
    (trackingCode.length >= 2 && trackingCode.substring(0, 2) !== "G-") ||
    progress;

  const configDialog = {
    icon: <BarChartOutlinedIcon />,
    title: ProjectStrings.ANALYTICS_TRACKING_DETAILS_TITLE,
    onClose: () =>
      onAction(GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_TRACKING_DETAILS, false),
    onOk,
    disableOk: invalidateInput(),
  };

  // update states
  useEffect(() => {
    if (tracking) {
      if (tracking.trackingCode && trackingCode === "") {
        setTrackingCode(tracking.trackingCode);
      }
      if (tracking.apiSecret && apiSecret === "") {
        setApiSecret(tracking.apiSecret);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracking]);

  return (
    <MyDialog open={showDialog} config={configDialog}>
      <TextField
        label={ProjectStrings.ANALYTICS_TRACKING_CODE}
        value={trackingCode}
        fullWidth
        onChange={(e) => setTrackingCode(e.target.value)}
      />
      <TextField
        label={ProjectStrings.ANALYTICS_API_SECRET}
        value={apiSecret}
        fullWidth
        onChange={(e) => setApiSecret(e.target.value)}
        style={{
          marginTop: 16,
        }}
      />
    </MyDialog>
  );
};

// Trello ticket on permissions: https://trello.com/c/NmQe9OQi/106-add-permission-control-for-project-analytics-page
const ProjectAnalyticsPage = ({ userId }) => {
  const mobile = useMobileLayout();
  const params = useParams();
  const projectId = params.projectId;
  const { actionType, onAction } = useContext(MainContext);

  const showDialog = actionType[GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_CODE];
  const [input, setInput] = useState("");
  const title = useProjectTitle(projectId);
  // permissions control
  const { canReadAnalytics } = useUserProjectPermissions({
    userId,
    projectId,
  });

  const classes = useStyles(canReadAnalytics ?? false);
  const analyticsUrl = useProjectAnalyticsUrl(canReadAnalytics && projectId);
  const currentCode = extractAnalyticsCodeFromUrl(analyticsUrl);

  const emptyContent = (
    <EmptyData
      title={ProjectStrings.ANALYTICS_PLACEHOLDER_TITLE}
      description={ProjectStrings.ANALYTICS_PLACEHOLDER_DESC}
      // TODO: add an action to add a new alanytics "actionTitle", "onClick"
      icon={<AreaChartIcon />}
    />
  );

  const configDialog = {
    icon: <BarChartOutlinedIcon />,
    title: ProjectStrings.ANALYTICS_TITLE,
    onClose: () => onAction(GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_CODE, false),
    onOk: () => {
      onAction(GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_CODE, false);
      updateProjectAnalyticsUrl({
        projectId,
        url: constructAnalyticsUrlFromCode(input),
      });
    },
    disableOk: input.length === 0 || input === currentCode,
  };

  const content = (
    <RestrictedContent permitted={canReadAnalytics}>
      {analyticsUrl === null ? (
        emptyContent
      ) : (
        <iframe title={title} src={analyticsUrl} className={classes.content} />
      )}
    </RestrictedContent>
  );

  return (
    <>
      {mobile ? (
        <MobileContent
          title={ProjectStrings.ANALYTICS_NA_TITLE}
          desc={ProjectStrings.ANALYTICS_NA_DESC}
        />
      ) : (
        <>
          <MyDialog open={showDialog} config={configDialog}>
            <TextField
              label={ProjectStrings.ANALYTICS_TITLE}
              defaultValue={currentCode}
              fullWidth
              onChange={(e) => setInput(e.target.value)}
            />
          </MyDialog>
          <TrackingDetailsDialog projectId={projectId} />
          <div className={classes.main}>{content}</div>
        </>
      )}
    </>
  );
};

export default withInProjectCheck(ProjectAnalyticsPage);
