import React from "react";

import NoteCard from "./NoteCard";

import { useDeviceNote } from "../../services/DeviceService";
import { updateDeviceNote } from "../../services/ApiService";

const DeviceNoteCard = ({ projectId, deviceId, canRead, canEdit }) => {
  const deviceNote = useDeviceNote(deviceId);

  const onSave = (note) =>
    updateDeviceNote({
      projectId,
      deviceId,
      note,
    });

  return (
    <NoteCard
      defaultNote={deviceNote}
      onSave={onSave}
      canRead={canRead}
      canEdit={canEdit}
    />
  );
};

export default DeviceNoteCard;
