import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, IconButton, LinearProgress } from "@material-ui/core";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import { useSnackbar } from "notistack";

import { useMobileLayout } from "hooks/uiHooks";
import { getDownloadURL } from "services/ChatService";
import {
  sendProjectMessage,
  sendDeviceMessage,
  sendOrganisationMessage,
} from "services/ApiService";
import { MAX_FILE_SIZE } from "services/ChatService";
import { DefaultStrings, DeviceStrings } from "strings";
import {
  VIDEOS_TYPES,
  IMAGE_TYPES,
  isFileImage,
  isFileVideo,
} from "../utils/fileUtils";
import { isObjectEmpty } from "../utils/generalUtils";
import STRINGS from "strings/chat";

const useStyles = makeStyles((theme) => {
  return {
    formContainer: ({ mobile, fullWidth }) => ({
      display: "flex",
      flexDirection: "column",
      width: mobile || fullWidth ? "100%" : "45%",
      border: "1px solid #e8e9ea",
      borderRadius: mobile ? 0 : "5px",
      backgroundColor: theme.palette.common.white,
      padding: "12px 0",
    }),
    form: {
      width: "100%",
      display: "flex",
      alignItems: "flex-end",
    },
    textInputContainer: {
      flex: 1,
      position: "relative",
    },
    textInput: {
      display: "flex",
      "& .MuiFilledInput-input": {
        padding: "14px",
      },
    },
    previewContainer: {
      background: "#DFDFDF",
      position: "absolute",
      bottom: 56 + theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
    preview: {
      display: "inline-block",
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 60,
      color: theme.palette.background.default,
    },
    previewContent: {
      width: 200,
    },
    cancelPreviewIcon: {
      position: "absolute",
      left: 175,
      top: 15,
      color: theme.palette.background.paper,
      cursor: "pointer",
    },
  };
});

const DEFAULTS = {
  MAX_MESSAGE_INPUT: 200,
};

const ChatRoomForm = ({
  roomId,
  deviceId,
  projectId,
  organisationId,
  fullWidth,
  roomType,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, fullWidth });
  const { enqueueSnackbar } = useSnackbar();

  const [formTextValue, setFormTextValue] = useState("");
  const [preview, setPreview] = useState({});
  const [sendingMessage, setSendingMessage] = useState(false);
  const fileInput = useRef();
  const disabled = !formTextValue && !Object.keys(preview)?.length;

  const onSend = async (e) => {
    e.preventDefault();

    if (sendingMessage || disabled) return;
    setSendingMessage(true);
    try {
      let contentURL = "";
      const file = fileInput.current.files[0];
      if (file && file.size < MAX_FILE_SIZE) {
        contentURL = await getDownloadURL(file, roomId);
      } else {
        if (!formTextValue) {
          enqueueSnackbar(STRINGS.FILE_SIZE_EXCEEDED_LIMIT_ERROR, {
            variant: "warning",
          });
          setFormTextValue("");
          fileInput.current.value = null;
          setPreview({});
          setSendingMessage(false);
          return;
        }
      }

      if (deviceId) {
        await sendDeviceMessage({
          projectId,
          deviceId,
          text: formTextValue,
          ...(preview.imageURL && { imageURL: contentURL }),
          ...(preview.videoURL && { videoURL: contentURL }),
        });
      } else if (organisationId) {
        await sendOrganisationMessage({
          organisationId,
          text: formTextValue,
          ...(preview.imageURL && { imageURL: contentURL }),
          ...(preview.videoURL && { videoURL: contentURL }),
        });
      } else {
        await sendProjectMessage({
          projectId,
          text: formTextValue,
          ...(preview.imageURL && { imageURL: contentURL }),
        });
      }
      setFormTextValue("");
      fileInput.current.value = null;
      setPreview({});
      setSendingMessage(false);
    } catch (error) {
      setSendingMessage(false);
      console.debug(error);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
    }
  };

  const onFileInputChange = (e) => {
    e.preventDefault();
    if (!fileInput.current.files[0]) return;
    setPreview({});
    if (
      !isFileImage(fileInput.current.files[0]) &&
      !isFileVideo(fileInput.current.files[0])
    ) {
      fileInput.current.value = null;
      enqueueSnackbar(DeviceStrings.MESSAGES_FILE_TYPE_ERROR, {
        variant: "error",
      });
    }

    const file = fileInput.current.files[0];
    const { type } = fileInput.current.files[0];
    const url = URL.createObjectURL(file);
    if (VIDEOS_TYPES.includes(type)) {
      setPreview({
        videoURL: url,
      });
    } else if (IMAGE_TYPES.includes(type)) {
      setPreview({
        imageURL: url,
      });
    }
  };

  const onRemoveFile = () => {
    setPreview({});
    fileInput.current.value = null;
  };

  const displayMessagePreview = () => {
    if ("imageURL" in preview) {
      return (
        <>
          <CancelOutlinedIcon
            className={classes.cancelPreviewIcon}
            onClick={onRemoveFile}
          />
          <img
            className={classes.previewContent}
            src={preview.imageURL}
            alt="preview"
            onLoad={() => URL.revokeObjectURL(preview.imageURL)}
          />
        </>
      );
    } else if ("videoURL" in preview) {
      return (
        <>
          <video className={classes.previewContent} src={preview.videoURL} />
          <CancelOutlinedIcon
            className={classes.cancelPreviewIcon}
            onClick={onRemoveFile}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className={classes.formContainer}>
      <form className={classes.form} onSubmit={onSend}>
        <div className={classes.iconContainer}>
          <input
            accept={roomType?.acceptFileInput}
            style={{ display: "none" }}
            id="icon-button-file"
            type="file"
            ref={fileInput}
            onChange={onFileInputChange}
          />
          <label htmlFor="icon-button-file">
            <IconButton aria-label="upload picture" component="span">
              <AddPhotoAlternateOutlinedIcon color="inherit" />
            </IconButton>
          </label>
        </div>
        <div className={classes.textInputContainer}>
          {isObjectEmpty(preview) && (
            <div className={classes.previewContainer}>
              <div className={classes.preview}>{displayMessagePreview()}</div>
            </div>
          )}
          <TextField
            classes={{ root: classes.textInput }}
            id="filled-textarea"
            placeholder={DeviceStrings.MESSAGES_PLACEHOLDER}
            variant="filled"
            value={formTextValue}
            onChange={(e) => setFormTextValue(e.target.value)}
            inputProps={{ maxLength: DEFAULTS.MAX_MESSAGE_INPUT }}
          />
          {sendingMessage && <LinearProgress color="primary" />}
        </div>
        <div className={classes.iconContainer}>
          <IconButton
            aria-label={DefaultStrings.PHOTOS}
            onClick={onSend}
            disabled={disabled}
          >
            <SendOutlinedIcon color="primary" />
          </IconButton>
        </div>
      </form>
    </div>
  );
};

export default ChatRoomForm;
