import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import FullscreenOutlinedIcon from "@material-ui/icons/FullscreenOutlined";

const useStyles = makeStyles((theme) => ({
  actions: {
    position: "fixed",
    bottom: theme.spacing(2),
    backgroundColor: "#FFFFFF2F",
    borderRadius: theme.spacing(6),
    "box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.2)",
    zIndex: 10,
    display: "flex",
    alignItems: "center",
  },
}));

// Floating action bar for media control
const MediaActions = ({
  onZoomIn,
  onZoomOut,
  onFullscreen,
  fullscreenDisabled,
  name,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.actions}>
      <IconButton onClick={onZoomIn}>
        <AddCircleOutline />
      </IconButton>
      <IconButton onClick={onZoomOut}>
        <RemoveCircleOutline />
      </IconButton>
      <IconButton onClick={onFullscreen} disabled={fullscreenDisabled}>
        <FullscreenOutlinedIcon />
      </IconButton>
      <div
        style={{
          paddingLeft: 8,
          paddingRight: 16,
        }}
      >
        {name}
      </div>
    </div>
  );
};

export default MediaActions;
