import React, { useEffect, useState, useCallback } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import WorkspacesOutlinedIcon from "@material-ui/icons/WorkspacesOutlined";

import { useMobileLayout } from "hooks/uiHooks";
import {
  getAllOrganisations,
  getMyOrganisations,
  useTotalOrganisationCountSuperAdmin,
  useTotalUserOrganisationsCount,
} from "services/OrganisationService";
import { debounce } from "services/UiService";
import Spinner from "ui/Spinner";
import InfiniteScroll from "ui/InfiniteScroll";
import Search from "ui/Search";
import { useStyles } from "./style";
import { OrganisationStrings } from "strings";
import { EmptyData } from "ui/emptyData";
import { OrganisationSimpleCard } from "ui/organisationSimpleCard";

const LIMIT = 10;

export const OrganisationsSelectDialog = ({
  userId,
  title,
  open,
  loading,
  currentOrganisationId,
  showAllOrganisations,
  onSelect,
  onClose,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const [organisationsIds, setOrganisationsIds] = useState();
  const [startAfter, setStartAfter] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [searching, setSearching] = useState(false);
  const [totalOrganisations, setTotalOrganisations] = useState();
  const orgsSuperAdminTotalCount = useTotalOrganisationCountSuperAdmin(
    open && showAllOrganisations
  );
  const orgsUserTotalCount = useTotalUserOrganisationsCount(
    open && !showAllOrganisations && userId
  );

  const resetData = () => {
    setStartAfter();
    setIsFetchedAll(false);
    setIsRequestSent(true);
  };

  const getParams = useCallback(
    (params) => ({
      limit: LIMIT,
      orderBy: "name",
      orderDesc: false,
      ...params,
    }),
    []
  );

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        searchValue,
        lastKey: startAfter,
        ...params,
      });

      try {
        let responseData;

        if (showAllOrganisations) {
          // get all organisations for SuperAdmin
          const res = await getAllOrganisations(requestParams);
          responseData = res.result.items;
          setTotalOrganisations(res.result.total);
        } else {
          // get all user's organisations
          const res = await getMyOrganisations(requestParams);
          responseData = res.result.items;
          setTotalOrganisations(res.result.total);
        }

        if (responseData.length === 0 || responseData.length < LIMIT) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }
        if (params?.lastKey && !isRequestSent) {
          setOrganisationsIds([...organisationsIds, ...responseData]);
        } else {
          setOrganisationsIds(responseData);
          setIsRequestSent(false);
        }

        if (responseData.length) {
          setStartAfter(responseData[responseData.length - 1]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [
      getParams,
      isRequestSent,
      organisationsIds,
      searchValue,
      showAllOrganisations,
      startAfter,
    ]
  );

  useEffect(() => {
    if (open) {
      fetchData();
    }
    if (!open) {
      setOrganisationsIds();
      setStartAfter();
      setSearchValue("");
      setIsFetchedAll(false);
      setIsRequestSent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    // do request if total count changed for SuperAdmin organisations
    if (
      open &&
      showAllOrganisations &&
      typeof totalOrganisations !== "undefined" &&
      typeof orgsSuperAdminTotalCount !== "undefined" &&
      totalOrganisations !== orgsSuperAdminTotalCount
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, totalOrganisations, orgsSuperAdminTotalCount]);

  useEffect(() => {
    // do request if total count changed for user organisations
    if (
      open &&
      !showAllOrganisations &&
      typeof totalOrganisations !== "undefined" &&
      typeof orgsUserTotalCount !== "undefined" &&
      totalOrganisations !== orgsUserTotalCount
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, totalOrganisations, orgsUserTotalCount]);

  const config = {
    onLoadMore: () => {
      if (isFetchedAll || !startAfter || !open) return;
      fetchData({ lastKey: startAfter });
    },
  };

  const onSearchHandle = debounce((value) => {
    setSearchValue(value);
    resetData();
    fetchData({ searchValue: value, lastKey: null });
  }, 500);

  const onSearchingHandle = (value) => {
    if (searchValue === value) {
      setSearching(!value);
      return;
    }

    setSearching(value !== null);
    return onSearchHandle(value);
  };

  const onCloseHandle = () => onClose();

  const renderContent = () => {
    if (!open) return null;
    if (loading || typeof organisationsIds === "undefined") return <Spinner />;

    if (!organisationsIds.length)
      return (
        <Box style={{ minHeight: "330px" }}>
          <EmptyData
            title={
              searchValue
                ? OrganisationStrings.LIST_NO_FOR_SEARCH
                : OrganisationStrings.LIST_NO_ORGANISATIONS
            }
            icon={<WorkspacesOutlinedIcon />}
          />
        </Box>
      );
    return (
      <InfiniteScroll config={config} size={organisationsIds.length}>
        <div>
          {organisationsIds.map((organisationId) => (
            <OrganisationSimpleCard
              key={organisationId}
              userId={userId}
              organisationId={organisationId}
              currentOrganisationId={currentOrganisationId}
              onSelect={onSelect}
            />
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      fullScreen={mobile}
      onClose={onCloseHandle}
      fullWidth
    >
      <DialogTitle className={classes.title} disableTypography>
        {(!mobile || !searching) && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <WorkspacesOutlinedIcon style={{ marginRight: "16px" }} />
            <Typography variant="h6">{title}</Typography>
          </Box>
        )}
        <Search mobile={mobile} onSearch={onSearchingHandle} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box>{renderContent()}</Box>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onClose} disabled={loading}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
