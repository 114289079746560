import React, { useState, forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, InputBase } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { DefaultStrings } from "../strings";

const useStyles = makeStyles((theme) => ({
  search: {
    alignSelf: "center",
    padding: "0 6px",
  },
}));

const Search = forwardRef(({ mobile, onSearch }, ref) => {
  const [searchText, setSearchText] = useState(null);
  const classes = useStyles();
  const searching = searchText !== null;

  const toggleSearch = () => {
    if (onSearch) onSearch(searching ? null : "");
    setSearchText(searching ? null : "");
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      setSearchText(null);
    },
  }));

  const onBlur = (ev) => {
    if (searchText.length === 0 || ev.target.value.length === 0) {
      toggleSearch();
    }
  };

  const onChange = (e) => {
    if (onSearch) onSearch(e.target.value);
    setSearchText(e.target.value);
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case "Esc":
      case "Escape":
        toggleSearch();
        e.preventDefault();
        e.stopPropagation();
        break;
      default:
        break;
    }
  };

  return searching ? (
    <>
      {mobile && (
        <IconButton
          edge="start"
          aria-label={DefaultStrings.BUTTON_BACK}
          color="inherit"
          onClick={toggleSearch}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <InputBase
        className={classes.search}
        placeholder={DefaultStrings.SEARCH}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoFocus
      />
    </>
  ) : (
    <IconButton
      aria-label={DefaultStrings.BUTTON_SEARCH}
      color="inherit"
      onClick={toggleSearch}
    >
      <SearchIcon />
    </IconButton>
  );
});

export default Search;
