import React, { useState } from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CodeIcon from "@material-ui/icons/Code";
import QrCodeIcon from "@material-ui/icons/QrCode";
import TabletIcon from "@material-ui/icons/Tablet";

import MediaLink from "../MediaLink";
import Spinner from "../Spinner";
import RestrictedContent from "../RestrictedContent";
import InfiniteScroll from "../../ui/InfiniteScroll";
import EditMediaLinkDialog from "../dialogs/EditMediaLinkDialog";

import {
  useProjectSharedMediaLinks,
  useUserProjectPermissions,
  useTotalProjectActiveSharedMediaLink,
} from "../../services/ProjectService";
import { useCurrentUserId } from "../../services/UserService";
import { isLoading } from "../../utils/uiUtils";
import {
  MEDIA_LINKS_SORT_OPTION,
  MEDIA_LINKS_SORT_OPTON_FIELD,
} from "../../services/MediaService";
import { debounce } from "../../services/UiService";

import { ProjectStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  main: {
    height: "100%",
    display: "flex",
    position: "relative",
  },
  cardContent: {
    width: "100%",
    overflow: "auto",
  },
  link: {
    background: theme.palette.background.default,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.spacing(0.5),
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
  },
  input: {
    flex: 1,
    fontSize: 12,
    padding: "4px 8px",
  },
  more: {
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(0.5),
    backgroundColor: "white",
    borderTop: "solid 1px #E0E0E0",
    position: "absolute",
    bottom: theme.spacing(-2),
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const config = {
  [ProjectStrings.SHOWROOM_EMBED]: {
    icon: <CodeIcon />,
    title: ProjectStrings.SHOWROOM_MENU_ITEM_EMBED,
    buttonText: ProjectStrings.SHOWROOM_EMBED_BUTTON,
  },
  [ProjectStrings.SHOWROOM_PREVIEW]: {
    icon: <TabletIcon />,
    title: ProjectStrings.SHOWROOM_MENU_ITEM_PREVIEW,
    buttonText: ProjectStrings.SHOWROOM_PREVIEW_BUTTON,
  },
  [ProjectStrings.SHOWROOM_QR]: {
    icon: <QrCodeIcon />,
    title: ProjectStrings.SHOWROOM_MENU_ITEM_QR_CODE,
    buttonText: ProjectStrings.SHOWROOM_QR_BUTTON,
  },
};

const MediaLinksCard = ({ projectId, searchText, sortOption, sortDesc }) => {
  const [limit, setLimit] = useState(5);
  const [linkLoadingId, setLinkLoadingId] = useState();
  const [showMediaLinkDialog, setShowMediaLinkDialog] = useState(false);
  const [linkToEdit, setLinkToEdit] = useState();
  const classes = useStyles();

  const sort =
    sortOption && sortOption in MEDIA_LINKS_SORT_OPTON_FIELD
      ? MEDIA_LINKS_SORT_OPTON_FIELD[sortOption]
      : MEDIA_LINKS_SORT_OPTON_FIELD[MEDIA_LINKS_SORT_OPTION[0]];

  const params = {
    filter: searchText,
    limit,
    sort,
    sortDesc,
  };

  const links = useProjectSharedMediaLinks({ projectId, params });
  const totalLinks = useTotalProjectActiveSharedMediaLink({ projectId });
  const userId = useCurrentUserId();
  // permissions control
  const { canReadShowroom } = useUserProjectPermissions({ userId, projectId });

  const onLoadMore = debounce(() => {
    if (!limit) return;
    let newLimit = limit + 5;
    if (newLimit >= totalLinks) {
      // load whole array
      newLimit = undefined;
    }
    setLimit(newLimit);
  }, 500);

  const configScroll = {
    onLoadMore,
  };

  const onEditMediaLink = (link) => {
    setLinkToEdit(link);
    setShowMediaLinkDialog(true);
  };

  const onCloseMediaLinkDialog = () => {
    setShowMediaLinkDialog(false);
    setLinkToEdit(null);
  };

  const content = isLoading(links) ? (
    // loading
    <Spinner />
  ) : !totalLinks ? (
    // empty
    <div>
      <Box m={1}>
        <Typography variant="caption">
          {ProjectStrings.SHOWROOM_ADD_NEW_LINK_TITLE}
        </Typography>
      </Box>
      <Box m={1}>{ProjectStrings.SHOWROOM_ADD_NEW_LINK_MSG}</Box>
    </div>
  ) : (
    links && (
      <>
        <InfiniteScroll
          config={configScroll}
          size={links.length}
          max={links.length}
        >
          {links.map((link) => (
            <MediaLink
              loading={linkLoadingId === link.linkId}
              onEdit={onEditMediaLink}
              key={link.linkId}
              link={link}
              projectId={projectId}
              config={config[link.type]}
            />
          ))}
        </InfiniteScroll>
        {totalLinks - links.length > 0 && (
          <div className={classes.more}>
            {ProjectStrings.LIST_MORE_HIDDEN_MEDIA_LINKS.replace(
              "{}",
              totalLinks - links.length
            )}
          </div>
        )}
      </>
    )
  );

  return (
    <Card className={classes.main}>
      <CardContent className={classes.cardContent}>
        <RestrictedContent permitted={canReadShowroom}>
          {content}
          {linkToEdit && (
            <EditMediaLinkDialog
              setLinkLoadingId={setLinkLoadingId}
              onClose={onCloseMediaLinkDialog}
              open={showMediaLinkDialog}
              link={linkToEdit}
              projectId={projectId}
            />
          )}
        </RestrictedContent>
      </CardContent>
    </Card>
  );
};
export default MediaLinksCard;
