import React, { useCallback, useEffect, useState, useContext } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import ProjectActivityDrawer from "../../ui/ProjectActivityDrawer";
import EditMediaPanel from "../../ui/EditMediaPanel";
import MyDialog from "../../ui/MyDialog";
import MobileContent from "../../ui/MobileContent";
import FullScreenMedia from "../../ui/FullScreenMedia";
import { MediaContent, MediaFrame } from "../../ui/media";

import { useMobileLayout } from "../../hooks/uiHooks";
import {
  useProjectDraftExist,
  useProjectDraftName,
} from "../../services/ProjectService";
import { updateUrl } from "../../services/UiService";
import { approveMediaDraft } from "../../services/ApiService";
import { setMyState, useMyState } from "../../services/StateService";

import { ProjectStrings } from "../../strings";
import {
  ROUTES,
  getProjectMediaRoute,
  getProjectMediaEditTabRoute,
  getProjectMediaMessagesRoute,
  getProjectMediaPreviewRoute,
} from "../../route";
import { withInProjectCheck } from "hocs";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

const useStyles = makeStyles((theme) => {
  return {
    main: (fullscreen) => ({
      position: fullscreen ? "static" : "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: "100%",
      height: "100%",
    }),
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  };
});

// Trello ticket on permissions: https://trello.com/c/NNhYtf2f/104-add-permission-control-for-project-media-page
const ProjectMediaPage = ({ userId }) => {
  const params = useParams();
  const projectId = params.projectId;
  const mobile = useMobileLayout();
  const dispatch = useDispatch();
  const { actionType, onAction } = useContext(MainContext);

  // preview draft
  const matchPreview = useRouteMatch(ROUTES.PROJECT_MEDIA_PREVIEW);
  const version = useMyState({ path: `${projectId}/media/version` });
  // current state override path param
  const draftId = version?.id ?? matchPreview?.params.draftId;
  const _draftName = useProjectDraftName({ projectId, draftName: draftId });
  const draftName =
    _draftName === null ? ProjectStrings.MEDIA_LIVE : _draftName;

  // set version state from path param
  useEffect(() => {
    if (!draftId || !draftName) return;
    if (version?.id !== draftId && version?.name !== draftName)
      setMyState({
        dispatch,
        path: `${projectId}/media/version`,
        data: {
          id: draftId,
          name: draftName,
        },
      });
  }, [draftId, draftName, dispatch, projectId, version]);

  const mediaExist = useProjectDraftExist({
    projectId,
    draftName: draftId,
  });

  const { enqueueSnackbar } = useSnackbar();
  const matchMessages = !!useRouteMatch(ROUTES.PROJECT_MEDIA_MESSAGES);

  // edit urls
  const matchEditTab = useRouteMatch(ROUTES.PROJECT_MEDIA_EDIT_TAB);
  const [tabName, setTabName] = useState(matchEditTab?.params.tab);

  const onShowEditPanel = useCallback(
    (show, name) => {
      // update url
      if (show) {
        // set the tab name to be passed to EditMediaPanel
        setTabName(name ?? "content");
        // set url to edit tab
        updateUrl(getProjectMediaEditTabRoute(projectId, name ?? "content"));
      } else {
        updateUrl(
          draftId
            ? // set url to media preview
              getProjectMediaPreviewRoute(projectId, draftId)
            : // set url to media default
              getProjectMediaRoute(projectId)
        );
      }
      setShowEditMediaPanel(show);
    },
    [projectId, draftId]
  );

  // detect media version change
  useEffect(() => {
    console.debug("ProjectMedia", version);

    if (version) onShowEditPanel(false);
  }, [version, onShowEditPanel, projectId]);

  const [showDrawer, setShowDrawer] = useState(matchMessages);
  const [fullscreen, setFullscreen] = useState(false);
  const [showEditMediaPanel, setShowEditMediaPanel] = useState(!!matchEditTab);
  const classes = useStyles(fullscreen);

  const onShowDrawer = useCallback(
    (show) => {
      if (!show) {
        if (actionType[GLOBAL_ACION_TYPES.PROJECT_MEDIA_SHARE]) {
          onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_SHARE, false);
        }
        if (actionType[GLOBAL_ACION_TYPES.PROJECT_MEDIA_ACTIVITY]) {
          onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_ACTIVITY, false);
        }
      }
      setShowDrawer(show);
      const url = show
        ? getProjectMediaMessagesRoute(projectId)
        : getProjectMediaRoute(projectId);
      updateUrl(url);
    },
    [actionType, onAction, projectId]
  );

  useEffect(() => {
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_MEDIA_EDIT]) {
      onShowEditPanel(true);
    }
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_MEDIA_SHARE]) {
      onShowEditPanel(true, "showroom");
    }
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_MEDIA_ACTIVITY]) {
      onShowDrawer(true);
    }
  }, [actionType, onShowDrawer, onShowEditPanel, showDrawer]);

  const configDialog = {
    onClose: () => {
      onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_APPROVE, false);
    },
    onOk: () => {
      onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_APPROVE, false);
      approveMediaDraft({
        projectId,
        draftName: draftId,
        message: ProjectStrings.MEDIA_DRAFT_APPROVE_MSG.replace(
          "{draft}",
          draftName
        ),
      }).then(() => {
        enqueueSnackbar(ProjectStrings.MEDIA_APPROVE_OK, {
          variant: "success",
        });
      });
    },
  };

  const onFullscreen = () => {
    setFullscreen(true);
  };

  const onClose = () => setFullscreen(false);

  const onCloseMediaPanel = () => {
    onShowEditPanel(false);
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_MEDIA_SHARE]) {
      onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_SHARE, false);
    }
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_MEDIA_EDIT]) {
      onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_EDIT, false);
    }
  };

  if (fullscreen)
    return (
      <FullScreenMedia onClose={onClose}>
        <MediaFrame projectId={projectId} draftId={draftId} />
      </FullScreenMedia>
    );

  const drawer = (
    <ProjectActivityDrawer
      projectId={projectId}
      show={showDrawer}
      onClose={(e) => onShowDrawer(false)}
    />
  );

  return (
    <>
      {mobile ? (
        <>
          <MobileContent
            title={ProjectStrings.MEDIA_PROJECT_CONTENT_NA_TITLE}
            desc={ProjectStrings.MEDIA_PROJECT_CONTENT_NA_DESC}
          />
          {drawer}
        </>
      ) : (
        <div className={classes.main}>
          <MediaContent
            projectId={projectId}
            onFullscreen={onFullscreen}
            fullscreenDisabled={!mediaExist}
            draftId={draftId}
            draftName={draftName}
          />
          {drawer}
          <MyDialog
            open={actionType[GLOBAL_ACION_TYPES.PROJECT_MEDIA_APPROVE]}
            config={configDialog}
          >
            {ProjectStrings.MEDIA_APPROVE_CONFIRM.replace("{draft}", draftName)}
          </MyDialog>
        </div>
      )}
      <EditMediaPanel
        projectId={projectId}
        userId={userId}
        open={showEditMediaPanel}
        onClose={onCloseMediaPanel}
        tabName={tabName}
      />
    </>
  );
};

export default withInProjectCheck(ProjectMediaPage);
