import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    margin: theme.spacing(1),
    padding: theme.spacing(1.5),
    backgroundColor: "#eaeaea",
    borderRadius: "6px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    height: "36px",
    width: "36px",
    marginRight: theme.spacing(2),
    borderRadius: "6px",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  current: {
    color: theme.palette.grey[600],
    minWidth: "80px",
    textAlign: "center",
    fontWeight: 500,
  },
  selectButton: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
  },
}));
