import React, { useState, useEffect } from "react";

import { useUserPendingInvite } from "services/OrganisationService";
import { useTeamOrganisationPermissionsOptions } from "services/PermissionService";
import { OrganisationStrings } from "strings";
import { VerifiedUserIcon } from "assets/icons";
import SettingsDialog from "ui/dialogs/SettingsDialog";
import MultilineText from "ui/MultilineText";

export function UserRoleSelector({
  open,
  organisationId,
  code,
  progress,
  canWrite,
  onClose,
  onOk,
}) {
  const [userRole, setUserRole] = useState("");

  const userInvite = useUserPendingInvite({
    code: open && code,
    organisationId,
  });
  const permissionsOptions = useTeamOrganisationPermissionsOptions();

  useEffect(() => {
    if (userInvite?.permissionsKey) {
      setUserRole(userInvite.permissionsKey);
    }
  }, [userInvite]);

  const configDialog = {
    config: {
      selectTitle:
        OrganisationStrings.SETTINGS_ORGANISATION_DEFAULT_ROLE_SELECT_TITLE,
      description: (
        <MultilineText
          text={
            OrganisationStrings.SETTINGS_ORGANISATION_DEFAULT_ROLE_SELECT_DESC
          }
        />
      ),
      options: permissionsOptions,
      canEdit: canWrite,
      hideLockedSwitch: true,
      onSelectChange: (event) => {
        const roleToUpdate = event.target.value;
        setUserRole(roleToUpdate);
      },
    },
    dialog: {
      icon: <VerifiedUserIcon />,
      title: OrganisationStrings.ORGANISATION_USER_ROLE_TITLE,
      progress,
      disableOk: progress,
      onClose: onClose,
      onOk: () => onOk(userRole),
    },
  };

  return <SettingsDialog open={open} config={configDialog} value={userRole} />;
}
