const STRINGS = {
  CHAT_DELETE_MESSAGE: "Delete message",
  CHAT_DELETE_MESSAGE_CONFIRM: "Are you sure you want to delete this message?",

  CHAT_DELETE_SUCCESS: "Message deleted",
  CHAT_DELETED: "Deleted message",

  CHAT_MENU_DOWNLOAD_IMAGE: "Download image",
  FILE_SIZE_EXCEEDED_LIMIT_ERROR: "File size exceeds the limit of 10 MB"
};

export default STRINGS;
