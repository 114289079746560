import React, { useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import { useStyles } from "./style";

export const FileInput = ({ label, onChange }) => {
  const classes = useStyles();

  const fileInput = useRef(null);
  const [inputImage, setInputImage] = useState(null);
  const [fileName, setFileName] = useState(null);

  const onClickHandle = () => fileInput.current && fileInput.current.click();
  const onChangeHandle = (ev) => {
    const file = ev.target.files[0];
    const frUrl = new FileReader();
    frUrl.onload = (r) => setInputImage(r.target.result);
    frUrl.readAsDataURL(file);
    setFileName(file.name);
    onChange(file);
  };

  return (
    <Box className={classes.container} onClick={onClickHandle}>
      <Box className={classes.wrapper}>
        {inputImage && (
          <Box className={classes.logoWrapper}>
            <Box
              className={classes.logo}
              style={{ backgroundImage: `url(${inputImage})` }}
            />
            <Typography className={classes.fileName}>{fileName}</Typography>
          </Box>
        )}
        <Box className={classes.labelWrapper}>
          <Box className={classes.iconWrapper}>
            <AddOutlinedIcon />
          </Box>
          {!inputImage && (
            <Typography className={classes.label}>{label}</Typography>
          )}
        </Box>
      </Box>
      <input
        type="file"
        ref={fileInput}
        hidden
        accept="image/gif, image/jpeg, image/png"
        onChange={onChangeHandle}
      />
    </Box>
  );
};
