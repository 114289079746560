import { useHistory } from "react-router-dom";

import {
  getProjectOverviewRoute,
  getProjectMediaRoute,
  getProjectDevicesRoute,
  getProjectAnalyticsRoute,
  getProjectTeamRoute,
} from "route";
import { ProjectStrings } from "strings";

const tabs = {
  OVERVIEW: {
    label: ProjectStrings.TAB_TITLE_OVERVIEW,
    routeFunc: getProjectOverviewRoute,
  },
  MEDIA: {
    label: ProjectStrings.TAB_TITLE_MEDIA,
    routeFunc: getProjectMediaRoute,
  },
  DEVICES: {
    label: ProjectStrings.TAB_TITLE_DEVICES,
    routeFunc: getProjectDevicesRoute,
  },
  ANALYTICS: {
    label: ProjectStrings.TAB_TITLE_ANALYTICS,
    routeFunc: getProjectAnalyticsRoute,
  },
  TEAM: {
    label: ProjectStrings.TAB_TITLE_TEAM,
    routeFunc: getProjectTeamRoute,
  },
};

// return an array of tabs to be used by MyAppBar
export const useProjectTabs = (projectId) => {
  const history = useHistory();

  return Object.entries(tabs).map(([key, cfg]) => ({
    key,
    label: cfg.label,
    onClick: () => {
      history.push(cfg.routeFunc(projectId));
    },
  }));
};
