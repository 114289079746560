import React, { useState } from "react";
import { DeviceStrings } from "../../strings";
import { getCountryFromLocale } from "utils/localeUtils";
import { isUndefined } from "../../utils/generalUtils";
import { makeStyles } from "@material-ui/core/styles";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import PinIcon from "@material-ui/icons/PinOutlined";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
    Button
} from "@material-ui/core";
import { useCountries, useRetailers } from "services/ProjectService";
import { Box, TextField, DialogTitle, Typography } from "@material-ui/core";

const MIN_HEIGHT = 360;
const TOP_MARGIN = 48;

const useStyles = makeStyles((theme) => ({
    spacer: {
        height: "10px"
    },
    caption: {
        fontWeight: 300
    },
    title: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 0,
    },
    secondaryTitle: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 0,
        marginTop: '20px'
    },
    generalCardContainer: (mobile) => ({
        flex: 1,
        marginTop: mobile ? theme.spacing(2) : 0,
        "&:first-child": {
            marginTop: 0,
        },
    }),
    generalCardContainerRow: (mobile) => ({
        marginLeft: mobile ? 0 : theme.spacing(2),
        "&:first-child": {
            marginLeft: 0,
        },
    }),
    countryContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
    },
    container: ({ showHalf, mobile }) => ({
        height: Math.max(
            MIN_HEIGHT,
            mobile === true
                ? window.innerHeight
                : showHalf === true
                    ? (window.innerHeight - TOP_MARGIN) / 2
                    : window.innerHeight - TOP_MARGIN
        ),
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        transition: "all 0.5s ease",
        overflow: "hidden",
    }),
    titleBar: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        minHeight: 56,
    },
    generalCardContainerColumn: (mobile) => ({
        marginTop: theme.spacing(2),
        "&:first-child": {
            marginTop: 0,
        },
    }),
}));


const RegisterDeviceForm = ({ storeInfo, editStoreInfo, setEditStoreInfo, showPin, onConfirm, inProgress, next }) => {

    const countries = useCountries() || [];
    const retailers = useRetailers() || [];
    const classes = useStyles();
    const [confirmEnabled, setConfirmEnabled] = useState(false);
    const currentStoreInfo = isUndefined(editStoreInfo)
        ? storeInfo
        : editStoreInfo;

    const requiredKeys = ['pinCode', 'country', 'retailerName', 'storeId', 'postcode']

    const filterOptionsRetailers = createFilterOptions({
        stringify: ({ name }) => name,
    });

    const filterOptionsCountries = createFilterOptions({
        stringify: ({ iso, displayName }) => `${displayName} ${iso}`,
    })

    const sortedCountries = countries.sort((a, b) => {
        return a.displayName.localeCompare(b.displayName)
    });

    const getCountryFlagUrl = (country) =>
        `https://flagcdn.com/w40/${country?.iso?.toLowerCase()}.png`;

    const updateStoreInfo = (key, value) => {
        const updatedStoreInfo = {
            ...currentStoreInfo,
            [key]: value,
        }
        setEditStoreInfo(updatedStoreInfo);
        const allFilled = Object.values(updatedStoreInfo).every(item => item.trim());
        const hasRequiredFields = requiredKeys.every(key => updatedStoreInfo.hasOwnProperty(key));
        const hasValidPin = updatedStoreInfo['pinCode'] && updatedStoreInfo['pinCode'].length > 3;
        setConfirmEnabled(allFilled && hasRequiredFields && hasValidPin)
    };

    return (
        <>
            <Box>
                <DialogTitle className={classes.title} disableTypography>
                    <Box className={classes.iconWrapper} pr={2}>
                        <StoreOutlinedIcon />
                    </Box>
                    <Typography variant="caption">{DeviceStrings.STORE_TITLE}</Typography>
                </DialogTitle>
                <Box mt={2}>
                    <Autocomplete
                        className={classes.autoComplete}
                        options={sortedCountries}
                        filterOptions={filterOptionsCountries}
                        classes={{
                            option: classes.option,
                        }}
                        onChange={(_, newValue) =>
                            newValue && updateStoreInfo("country", newValue.iso)
                        }
                        getOptionLabel={(option) => option.displayName || ""}
                        defaultValue={{
                            displayName: getCountryFromLocale(storeInfo && storeInfo.country),
                        }}
                        getOptionSelected={(option, value) =>
                            option.iso === value.iso ||
                            value.displayName === getCountryFromLocale(option.iso)
                        }
                        autoHighlight
                        renderOption={(option) => (
                            <div className={classes.countryContainer}>
                                <img
                                    width="20"
                                    src={getCountryFlagUrl(option)}
                                    alt="country-flag"
                                />
                                <span>{option.displayName}</span>
                            </div>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={DeviceStrings.STORE_CHOOSE_COUNTRY_LABEL}
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                }}
                            />
                        )}
                    />
                </Box>
                <Box mt={2}>
                    <Autocomplete
                        className={classes.autoComplete}
                        options={retailers}
                        filterOptions={filterOptionsRetailers}
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={(option) =>
                            option.name ? currentStoreInfo["retailerName"] : ''
                        }
                        defaultValue={{
                            name: storeInfo?.retailer,
                        }}
                        freeSolo
                        onChange={(_, newValue) =>
                            newValue && updateStoreInfo("retailerName", newValue.name ?? newValue)
                        }
                        onInputChange={(_, newInputValue) =>
                            updateStoreInfo("retailerName", newInputValue)
                        }
                        getOptionSelected={(option, value) => option.name === value.name}
                        autoHighlight
                        required
                        renderOption={(option) => <span>{option.name}</span>}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={DeviceStrings.STORE_CHOOSE_RETAILER_LABEL}
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                }}
                            />
                        )}
                    />
                </Box>
                <Box mt={2}>
                    <TextField
                        label={DeviceStrings.STORE_ID_NAME}
                        value={currentStoreInfo?.storeId || ""}
                        fullWidth
                        onChange={(e) => updateStoreInfo("storeId", e.target.value)}
                    />
                </Box>
                <Box mt={2}>
                    <TextField
                        label={DeviceStrings.STORE_EDIT_POSTCODE}
                        value={currentStoreInfo?.postcode || ""}
                        fullWidth
                        onChange={(e) => updateStoreInfo("postcode", e.target.value)}
                    />
                </Box>
                {showPin && (
                    <>
                        <DialogTitle className={classes.secondaryTitle} disableTypography>
                            <Box className={classes.iconWrapper} pr={2}>
                                <PinIcon />
                            </Box>
                            <Typography variant="caption">{DeviceStrings.STORE_PIN_CODE}</Typography>
                        </DialogTitle>
                        <Box>
                            <TextField
                                label={DeviceStrings.STORE_PIN_CODE}
                                value={currentStoreInfo?.pinCode || ""}
                                type="number"
                                fullWidth
                                onChange={(e) => updateStoreInfo("pinCode", e.target.value)}
                            />
                            <div className={classes.spacer}></div>
                            {!next && (
                                <Typography variant="caption" className={classes.caption}>{DeviceStrings.DEVICE_PIN_HELPER}</Typography>
                            )}
                        </Box>
                    </>
                )}
            </Box>
            <Box mt={4}>
                <Button variant="contained"
                    color="primary"
                    disabled={!confirmEnabled || inProgress}
                    fullWidth onClick={onConfirm}>
                    {DeviceStrings.STORE_CONFIRM_DETAILS}
                </Button>
            </Box>
        </>
    )
}

export default RegisterDeviceForm;