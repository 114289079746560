import React, { useRef, useState, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import { useSnackbar } from "notistack";

import { useMobileLayout } from "hooks/uiHooks";
import { PATTERN_NO_PUNCTUATION } from "utils/generalUtils";
import {
  useOrganisationInfo,
  updateOrganisation,
} from "services/OrganisationService";
import { OrganisationStrings } from "strings";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.background.default,
    minHeight: 240,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: "#eaeaea",
    color: "#865d5d",
    maxWidth: "310px",
  },
}));

// organisationId = edit existing
// userId = new organisation
export const OrganisationDialog = ({
  organisationId,
  open,
  onComplete,
  onClose,
  onRequest,
  onRequestFailed,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();
  const classes = useStyles();
  const [imageURLValue, setImageURLValue] = useState(null);
  const [nameValue, setNameValue] = useState(null);
  const [inputImage, setInputImage] = useState(null);

  const fileInput = useRef(null);
  const orgInfo = useOrganisationInfo(organisationId);
  const onChangeImage = (file) => {
    const frUrl = new FileReader();
    frUrl.onload = (r) => setInputImage(r.target.result);
    frUrl.readAsDataURL(file);
    setImageURLValue(file);
  };

  const imageURLFieldValue = useMemo(() => {
    if (imageURLValue !== null) return imageURLValue;
    return orgInfo?.imageURL || "";
  }, [imageURLValue, orgInfo]);

  const nameFieldValue = useMemo(() => {
    if (nameValue !== null) return nameValue;
    return orgInfo?.name || "";
  }, [nameValue, orgInfo]);

  const shouldDisable = useMemo(() => {
    return (
      //name mandatory
      !nameFieldValue ||
      nameFieldValue.length === 0 ||
      // no change
      (orgInfo &&
        orgInfo?.name === nameFieldValue &&
        orgInfo?.imageURL === imageURLFieldValue)
    );
  }, [imageURLFieldValue, nameFieldValue, orgInfo]);

  const resetData = () => {
    setInputImage(null);
    setImageURLValue(null);
    setNameValue(null);
  };

  const onConfirmHandler = async () => {
    const info = {
      name: nameFieldValue,
    };
    // update image only if changed
    if (inputImage) {
      info.imageURL = imageURLFieldValue;
    }

    if (!organisationId) return;
    if (onRequest) onRequest();

    try {
      await updateOrganisation({ organisationId, info });
      enqueueSnackbar(OrganisationStrings.UPDATE_ORGANISATION_SUCCESS, {
        variant: "success",
      });
      resetData();
      if (onComplete) onComplete();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(OrganisationStrings.UPDATE_ORGANISATION_ERROR, {
        variant: "error",
      });
      if (onRequestFailed) onRequestFailed();
    }
  };

  const onTextfieldUpdateHandle = (e) => {
    const input = e.target;

    if (input.validity.valid) {
      if (input.name === "name") {
        setNameValue(input.value);
      }
    }

    const start = input.validity.valid
      ? input.selectionStart
      : input.selectionStart - 1;
    window.requestAnimationFrame(() => {
      input.selectionStart = start;
      input.selectionEnd = start;
    });
  };

  return (
    <Dialog
      fullWidth
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        className={classes.title}
        disableTypography
        onClick={(e) => fileInput.current.click()}
        style={{
          backgroundImage: `url(${
            inputImage ? inputImage : imageURLFieldValue
          })`,
        }}
      >
        <AddPhotoAlternateOutlinedIcon fontSize="large" hidden />
        <input
          type="file"
          ref={fileInput}
          hidden
          accept="image/gif, image/jpeg, image/png"
          onChange={(e) => onChangeImage(e.target.files[0])}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box m={1}>
          <TextField
            label={OrganisationStrings.DIALOG_NAME}
            value={nameFieldValue}
            name="name"
            onChange={onTextfieldUpdateHandle}
            inputProps={{
              pattern: PATTERN_NO_PUNCTUATION,
            }}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <IconButton disabled={shouldDisable} onClick={onConfirmHandler}>
          <CheckIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
