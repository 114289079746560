import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = (props) => {
  const { title, description } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content="https://www.myplayer.io" />
      <meta property="og:site_name" content="MyPlayer" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default MetaTags;
