/**
 * IFrameTrackingService talks to an iframe with specific origin that shares the same protocol to provide analytics tracking service to the iframe child.
 *
 * Start: IFrameTrackingService.connect(ref, trackingId, attributes)
 *
 * Subsequent call to connect will override previous one
 *
 * Values to be updated on Firestore:
 * - User details (eg. media visited)
 *
 * Values to be updated on local storage
 * - User ID (generated uuid to save in storage)
 *
 * Values to be tracked
 * - User ID
 * - Project ID
 * - Custom dimensions:
 *   ~ retailer
 *   ~ postcode
 *   ~ store id
 *   ~ custom tag
 */
import IFrameService from "./IFrameService";
import { ENGINES, createTracking } from "./TrackingService";
import { DEVICE_PLATFORMS } from "./DeviceService";
import { v4 as uuidv4 } from "uuid";

const TAG = "[IFrameTrackingService]";

const TRACKING_MODES = {
  SEND_TO_PARENT: "SEND_TO_PARENT",
  SEND_TO_GA4: "SEND_TO_GA4",
  NO_TRACKING: "NO_TRACKING",
};

const ACTIONS = {
  READY: "READY",
  TRACKING_MODE: "TRACKING_MODE",
  TRACK_EVENT: "TRACK_EVENT",
};

// to be stored in Firestore
const CONFIG = {
  siteOrigin: "https://storage.googleapis.com",
  trackingEngine: ENGINES.GOOGLE_ANALYTICS_4,
  globalTrackingId: "",
  ga4: {
    options: {
      send_page_view: false,
      debug_mode: process.env.REACT_APP_DEMO_MODE, // only track with debug_mode on dev/testing
    },
  },
  trackingMode: TRACKING_MODES.SEND_TO_PARENT,
  //   trackingMode: TRACKING_MODES.SEND_TO_GA4,
  //   trackingMode: TRACKING_MODES.NO_TRACKING,
};

/**
 * Communication protocol
 *
 * Message format: {
 *   action: "ACTION",
 *   payload: {},
 *   promiseId: "UNIQUE_ID", // if require response
 * }
 *
 * action list:
 * - READY
 * - TRACKING_MODE
 * - TRACK_EVENT
 *
 * Handshake:
 * 1) iframe send action="READY"
 * 2) parent send action="TRACKING_MODE" with promiseId, payload.mode:
 *   - "SEND_TO_PARENT" (send events to parent)
 *   - "SEND_TO_GA4" (track directly to GA4, with ID)
 *   - "SEND_TO_API" (track to API cloud functions, with host and other details)
 *   - "NO_TRACKING" (do not track/send)
 * 3) iframe reply action="TRACKING_MODE" with original promiseId:
 *   - Ok: payload.ok = true, payload.errors = null
 *   - Fail: payload.ok = false, payload.errors = ["Error 1", "Error 2"]
 *   - Old content: no response
 *
 * Events for "SEND_TO_PARENT":
 * 1) iframe send action="TRACK_EVENT", payload={ eventName: "", eventParams: {} }
 *   - eventName and eventParams to be passed over to tracking provider directly
 *
 * Other actions:
 * - eg. config retrieval
 */

let self = null;

class IFrameTrackingService {
  constructor() {
    console.debug(`${TAG} constructor`);
    self = this;

    this.ref = null; // iframe ref
    this.tracker = null;
    this.link = null;
  }

  /**
   * Connect given iframe ref to a showroom link
   *
   * @param {*} ref - ref from useRef() for the iframe window
   * @param {*} link - showroom link object
   * @param {*} attributes - additional attributes do pass over to tracking service
   */
  connect(ref, link, attributes) {
    console.info(`${TAG} connect: ${!!ref}, ${JSON.stringify(link)}`);
    // unsubscribe existing
    if (self.ref) {
      IFrameService.unsubscribeMessage(CONFIG.siteOrigin, self.onmessage);
    }
    // forget existing tracker
    if (self.tracker) self.tracker = null;

    if (ref) {
      IFrameService.subscribeMessage(CONFIG.siteOrigin, self.onmessage, ref);
      if (link.type === DEVICE_PLATFORMS.QRID) {
        const deviceId = link.deviceId;
        self.tracker = createTracking(ENGINES.MYPLAYER_GA, deviceId);
      }
      else {
        const trackingId = link.trackingIdGa4;
        if (CONFIG.trackingMode === TRACKING_MODES.SEND_TO_PARENT && trackingId) {
          // concat globalTrackingId if given
          const listIds = [trackingId];
          if (CONFIG.globalTrackingId) listIds.push(CONFIG.globalTrackingId);

          // combine options with attributes
          const opts = {
            ...CONFIG.ga4.options,
            ...attributes,
            ...{
              projectId: link.projectId, // ga4: projectId
              retailer: link.retailer, // previously: retailer, ga4: retailerName, firestore: retailer
              storeId: link.storeId, // previously: store_id, ga4: storeId, firestore: storeId
              postcode: link.postcode,
              customTag: link.customTag, // previously: custom_tag, firestore: customTag
            },
          };
          // create tracker
          self.tracker = createTracking(CONFIG.trackingEngine, listIds, opts);
        }

      }
    }
    self.link = link.link;
    self.ref = ref;
  }

  onReady(payload, promiseId) {
    console.debug(`${TAG} onReady (${promiseId})`);
    // self.ref should be set already
    if (self.ref) {
      IFrameService.postMessage(self.ref, CONFIG.siteOrigin, {
        action: ACTIONS.TRACKING_MODE,
        payload: { mode: CONFIG.trackingMode, link: self.link },
        promiseId: uuidv4(),
      });
    }
  }

  onTrackEvent(payload, promiseId) {
    const { eventName, eventParams } = payload;
    console.debug(
      `${TAG} onTrackEvent (${!!self.tracker}): ${eventName}=${JSON.stringify(
        eventParams
      )}`
    );
    if (self.tracker) self.tracker.track(eventName, eventParams);
    else console.warn(`${TAG} onTrackEvent: no tracker provided!`);
  }

  onmessage(data) {
    const { action, payload, promiseId } = data.detail;
    switch (action) {
      case ACTIONS.READY:
        self.onReady(payload, promiseId);
        break;
      case ACTIONS.TRACK_EVENT:
        self.onTrackEvent(payload, promiseId);
        break;
      case ACTIONS.TRACKING_MODE:
        // ignore ACK
        break;

      default:
        console.warn(`${TAG} unknown message: ${JSON.stringify(data.detail)}`);
        break;
    }
  }
}

let instance = null;
if (!instance) instance = new IFrameTrackingService();
export default instance;
