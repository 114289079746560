import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../../hooks/uiHooks";

import RestrictedContent from "../RestrictedContent";
import CreateMediaLinkCard from "../cards/CreateMediaLinkCard";
import MediaLinksCard from "../cards/MediaLinksCard";

const useStyles = makeStyles((theme) => ({
  main: (mobile) => ({
    height: "100%",
    width: "100%",
    padding: theme.spacing(mobile ? 1 : 2),
    display: "flex",
    flexDirection: mobile ? "row" : "row-reverse",
    flexWrap: mobile ? "wrap" : "nowrap",
    alignItems: "stretch",
    overflow: "auto",
  }),
  create: (mobile) => ({
    width: mobile ? "100%" : 1,
    flexGrow: 1,
    padding: theme.spacing(1),
  }),
  links: (mobile) => ({
    width: mobile ? "100%" : 1,
    flexGrow: 2,
    padding: theme.spacing(1),
  }),
}));

const EditShowroomTab = ({
  projectId,
  canRead,
  showroomSearchText,
  sortOption,
  sortDesc,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  return (
    <RestrictedContent permitted={canRead}>
      <div className={classes.main}>
        <div className={classes.create}>
          <CreateMediaLinkCard projectId={projectId} />
        </div>
        <div className={classes.links}>
          <MediaLinksCard
            projectId={projectId}
            searchText={showroomSearchText}
            sortOption={sortOption}
            sortDesc={sortDesc}
          />
        </div>
      </div>
    </RestrictedContent>
  );
};

export default EditShowroomTab;
