import { actionTypes } from "redux-firestore";

// refer to dataReducer

// storeAs > path > collection
// skip storeAs if want to set by collection/doc
export const addDataAction = ({ collection, doc, data, storeAs, path }) => ({
  custom: "addDataAction",
  type: actionTypes.GET_SUCCESS,
  meta: { collection, doc, storeAs, path },
  payload: {
    data,
  },
});

export const modifyDataAction = ({ collection, doc, data, storeAs, path }) => ({
  custom: "modifyDataAction",
  type: actionTypes.DOCUMENT_MODIFIED,
  meta: { collection, doc, storeAs, path },
  payload: {
    data,
  },
});

export const setMultipleDataAction = ({ collection, data, storeAs, path }) => ({
  custom: "setMultipleDataAction",
  type: actionTypes.GET_SUCCESS,
  meta: { collection, storeAs, path },
  payload: {
    data,
  },
});

export const deleteDataAction = ({ collection, doc, storeAs, path }) => ({
  custom: "deleteDataAction",
  type: actionTypes.DELETE_SUCCESS,
  meta: { collection, doc, storeAs, path },
});
