import React from "react";
import AvatarEditor from "react-avatar-editor";
import { Box, IconButton, Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import OpenWithOutlinedIcon from "@material-ui/icons/OpenWithOutlined";

import { ProfileStrings } from "../strings";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  drag: {
    position: "absolute",
    top: 30,
    left: "50%",
    color: "#FFFFFF",
    backgroundColor: "#0000005F",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.spacing(4),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    transform: "translateX(-50%)",
    whiteSpace: "nowrap",
  },
}));

const ImageCrop = ({
  imageSrc,
  changeEditorRef,
  scaleValue,
  onScaleChange,
}) => {
  const classes = useStyles();

  const min = 1;
  const max = 3;
  const step = 0.01;

  const zoomIn = () => {
    onScaleChange(null, scaleValue + step);
  };

  const zoomOut = (val) => {
    onScaleChange(null, scaleValue - step);
  };

  return (
    <div className={classes.root}>
      <div className={classes.drag}>
        <OpenWithOutlinedIcon fontSize="small" />
        <Box m={1}>{ProfileStrings.PROFILE_EDIT_DRAG_TO_RESPOSITION}</Box>
      </div>

      <AvatarEditor
        image={imageSrc}
        border={[70,15]}
        scale={scaleValue}
        rotate={0}
        ref={changeEditorRef}
        crossOrigin="anonymous"
      />

      <div className={classes.bar}>
        <IconButton onClick={zoomOut} disabled={scaleValue <= min}>
          <RemoveOutlinedIcon />
        </IconButton>
        <Slider
          value={scaleValue}
          onChange={onScaleChange}
          min={min}
          max={max}
          step={step}
        />
        <IconButton onClick={zoomIn} disabled={scaleValue >= max}>
          <AddOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ImageCrop;
