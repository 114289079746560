import React, { memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Badge,
  Card,
  CardActionArea,
  Collapse,
  Tooltip,
  Typography,
} from "@material-ui/core";

import UserAvatar from "../UserAvatar";
import OverflowTypography from "../OverflowTypography";
import Empty from "../Empty";

import { useEventMessage, redirect } from "../../services/SystemEventService";
import { useProjectInfo } from "../../services/ProjectService";
import { useMobileLayout } from "../../hooks/uiHooks";
import { areDictsEqual } from "../../utils/generalUtils";
import {
  getRelativeTimeString,
  getTimeString,
  formatLocaleTime,
} from "../../utils/localeUtils";

import logo from "assets/images/icon_my_player_v3.png";

import { DefaultStrings } from "../../strings";
import { useDeviceStoreInfo } from "services/DeviceService";
import StoreDetailsToolTip from "ui/StoreDetailsToolTip";

const CONFIG = {
  AVATAR_SIZE: 5,
};

const useStyles = makeStyles((theme) => ({
  cardBase: ({ mobile, read }) => ({
    margin: mobile
      ? theme.spacing(1)
      : `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: read ? "transparent" : "white",
  }),
  cardAction: ({ mobile }) => ({
    display: "flex",
    justifyContent: "stretch",
    alignItems: "stretch",
    ...(mobile && { flexDirection: "column", alignItems: "stretch" }),
  }),
  //   cardContent: ({ mobile }) => ({
  //     flexGrow: 1,
  //     display: "flex",
  //     alignItems: "center",
  //   }),
  cardRow: ({ mobile }) => ({
    flexGrow: 1,
    display: "flex",
    alignItems: mobile ? "stretch" : "center",
    "&:nth-child(2)": {
      borderTop: `solid 1px ${theme.palette.border}`,
    },
  }),
  cardAvatar: ({ mobile }) => ({
    margin: theme.spacing(2),
    marginRight: theme.spacing(mobile ? 1 : 2),
    display: "flex",
    alignItems: "center",
    minWidth: 40,
    maxWidth: 1,
  }),
  cardMessage: ({ mobile }) => ({
    flexGrow: 1,
    ...(mobile && { paddingTop: theme.spacing(2) }),
  }),
  cardMainColumn: {
    flexGrow: 1,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    overflowX: "hidden",
  },
  cardTime: {
    textAlign: "right",
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(2),
  },
  cardProject: {
    display: "flex",
    flexDirection: "column",
  },
  cardProjectColumn: {
    marginLeft: 16,
    borderLeft: `solid 1px ${theme.palette.border}`,
    minWidth: "30%",
    maxWidth: 1,
    padding: 16,
  },
  cardCollapsed: {
    height: 20,
    backgroundColor: "#F0F0F0",
    border: "solid 1px #C0C0C0",
    borderRadius: 6,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    transition: "bottom 0.3s ease",
  },
  cardCollapsedFirst: ({ mobile, expand }) => ({
    width: mobile ? "calc(100% - 18px)" : "calc(100% - 34px)",
    bottom: expand ? 20 : -6,
    zIndex: -1,
  }),
  cardCollapsedSecond: ({ mobile, expand }) => ({
    width: mobile ? "calc(100% - 20px)" : "calc(100% - 36px)",
    bottom: expand ? 20 : -11,
    zIndex: -2,
  }),
  cardGroupAvatar: {
    height: theme.spacing(CONFIG.AVATAR_SIZE),
    width: theme.spacing(CONFIG.AVATAR_SIZE),
  },
}));

const ProjectContent = ({ event, storeDetails }) => {
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile });

  const organisationName = event?.organisationName;
  const projectName = event?.projectName;
  const projectBrand = event?.projectBrand;
  const useEventInfo = !!organisationName || !!projectName || !!projectBrand;
  // should we query projectInfo? in theory only valid project event will be passed to this point
  const projectId = event?.projectId;
  const projectInfo = useProjectInfo(!useEventInfo && { projectId });

  if (!event) return null;

  const name = useEventInfo
    ? projectName || organisationName
    : projectInfo
    ? projectInfo.name
    : "";
  const brand = useEventInfo
    ? projectBrand
    : projectInfo
    ? projectInfo.brand
    : "";
  const deviceId = projectInfo ? event.deviceId : "";

  return (
    <div className={classes.cardProject}>
      <OverflowTypography variant="caption">{name}</OverflowTypography>
      <OverflowTypography variant="body2">{brand}</OverflowTypography>
      <div>
        {deviceId && !mobile ? (
          <Tooltip
            arrow
            title={<StoreDetailsToolTip storeDetails={storeDetails} />}
          >
            <span>{deviceId}</span>
          </Tooltip>
        ) : mobile ? (
          <Typography variant="body2">{deviceId}</Typography>
        ) : !mobile ? (
          <Empty />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const EventCard = memo(({ event, lastRead, onClick, showGroupBadge }) => {
  const mobile = useMobileLayout();
  const read = lastRead && event?.timestamp && lastRead >= event?.timestamp;
  const classes = useStyles({ mobile, read });
  const msg = useEventMessage(event);
  const history = useHistory();
  const storeDetails = useDeviceStoreInfo(event.deviceId);

  const onClickInt = () => {
    console.debug(event);
    if (onClick) onClick();
    else redirect({ event, history });
  };

  const avatar = event.isGroup ? (
    <Badge
      badgeContent={event.list.length}
      color="primary"
      invisible={!showGroupBadge}
    >
      <Avatar src={logo} className={classes.cardGroupAvatar} />
    </Badge>
  ) : (
    <UserAvatar userId={event?.userId} size={CONFIG.AVATAR_SIZE} />
  );

  const content = mobile ? (
    <div className={classes.cardMainColumn}>
      <span className={classes.cardMessage}>{msg}</span>
      <span className={classes.cardTime}>
        {formatLocaleTime(event?.timestamp)}
      </span>
    </div>
  ) : (
    <>
      <span className={classes.cardMessage}>{msg}</span>
      <Tooltip title={getTimeString(event?.timestamp)}>
        <span className={classes.cardTime}>
          {getRelativeTimeString(event?.timestamp)}
        </span>
      </Tooltip>
    </>
  );

  const extra = mobile ? (
    <div className={classes.cardRow}>
      <div className={classes.cardAvatar}></div>
      <div className={classes.cardMainColumn}>
        <ProjectContent event={event} />
      </div>
    </div>
  ) : (
    <div className={classes.cardProjectColumn}>
      <ProjectContent event={event} storeDetails={storeDetails} />
    </div>
  );

  return (
    <Card className={classes.cardBase}>
      <CardActionArea className={classes.cardAction} onClick={onClickInt}>
        <div className={classes.cardRow}>
          <div className={classes.cardAvatar}>{avatar}</div>
          {content}
        </div>
        {extra}
      </CardActionArea>
    </Card>
  );
}, areDictsEqual);

const EventGroup = ({ group, lastRead }) => {
  const mobile = useMobileLayout();
  const [expand, setExpand] = useState(false);
  const classes = useStyles({ mobile, expand });
  const onClick = () => {
    setExpand(!expand);
  };
  return (
    <Collapse in={expand} collapsedSize={mobile ? 151 : 107}>
      <div
        style={{
          position: "relative",
        }}
      >
        <EventCard event={group} onClick={onClick} showGroupBadge={!expand} />
        <div
          className={`${classes.cardCollapsed} ${classes.cardCollapsedFirst}`}
        />
        <div
          className={`${classes.cardCollapsed} ${classes.cardCollapsedSecond}`}
        />
      </div>
      <div
        style={{
          marginLeft: 16,
          visibility: expand ? "inherit" : "hidden",
        }}
      >
        {group.list.map((e) => (
          <EventCard event={e} key={e.id} lastRead={lastRead} />
        ))}
      </div>
    </Collapse>
  );
};

const EventGroupOrCard = ({ event, lastRead }) => {
  return event?.isGroup ? (
    <EventGroup group={event} lastRead={lastRead} />
  ) : (
    <EventCard event={event} lastRead={lastRead} />
  );
};

// only group DEVICE_NEW
export const groupEvents = (messages) => {
  const total = messages?.length;
  const res = [];
  for (let i = 0; i < total; i++) {
    const event = messages[i];
    if (
      event.type !== "DEVICE_NEW" ||
      // don't group if just one
      (i < total - 1 && messages[i + 1].type !== "DEVICE_NEW")
    ) {
      res.push(event);
      continue;
    }
    const group = {
      isGroup: true,
      list: [],
      id: `group-${messages[i].id}`,
      projectId: messages[i].projectId,
      timestamp: messages[i].timestamp,
      msg: DefaultStrings.EVENT_NEW_DEVICES,
    };
    let j = i;
    while (j < total && messages[j].type === "DEVICE_NEW") {
      group.list.push(messages[j]);
      j++;
    }
    res.push(group);
    i = j - 1;
  }
  return res;
};

export default EventGroupOrCard;
