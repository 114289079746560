import React, { useRef, useEffect } from "react";
import QrCodeIcon from "@material-ui/icons/QrCode";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MyDialog from "ui/MyDialog";
import { CUSTOMISE_QR_URL } from "configs/urlConfig";
import useCustomQRMessageService from "services/CustomQRMessageService";
import { useStyles } from "./style";

export const CustomQRCodeDialog = ({
  open,
  title,
  folderName,
  settings,
  contentSettings,
  urlType,
  onOk,
  onClose,
  onUpdate,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles(isMobile);

  const iframeRef = useRef(null);
  const mountedDateRef = useRef(null);

  useEffect(() => {
    mountedDateRef.current = Date.now();

    return () => {
      mountedDateRef.current = null;
    };
  }, []);

  const { onConfirm } = useCustomQRMessageService({
    ref: iframeRef,
    settings,
    contentSettings,
    folderName,
    urlType,
    isShowing: open,
    onUpdate,
  });

  const onOkHandle = () => {
    onOk();
    onConfirm();
  };

  const onCloseHandle = () => {
    onClose();
  };

  const config = {
    icon: <QrCodeIcon />,
    title,
    onClose: onCloseHandle,
    onOk: onOkHandle,
    large: true,
  };

  return (
    <MyDialog open={open} config={config}>
      <div className={classes.iframeWrapper}>
        <iframe
          ref={iframeRef}
          className={classes.iframe}
          title={title}
          src={`${CUSTOMISE_QR_URL}?appearance=embeded&origin=${window.location.origin}&t=${mountedDateRef.current}`}
        />
      </div>
    </MyDialog>
  );
};
