import {useEffect, useRef, useState} from "react";
import {useFirestore} from "react-redux-firebase";

import {COLLECTIONS} from "../utils/firestoreUtils";
import {
  DEPLOY_MAIN_STAGES,
  DEPLOY_START_STAGES,
  DEPLOY_END_STAGES,
} from "../utils/deployUtils";
// import { setDataAction } from "../redux/firestoreActions";

// get the last heartbeat
export const useDeviceLastUpdate = ({deviceId, stages, latestMethodCall}) => {
  const firestore = useFirestore();
  const [heartbeat, setHeartbeat] = useState();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    let unsub;
    if (deviceId) {
      // a composite index is required for orderBy to work with onSnapshot
      let query = firestore
        .collection(COLLECTIONS.DEVICE_HEARTBEAT)
        .where("deviceId", "==", deviceId);

      if (latestMethodCall)
        query = query.where("latestMethodCall", "==", latestMethodCall);

      if (stages && stages.length > 0)
        query = query.where("stage", "in", stages);

      query = query.orderBy("timestamp", "desc").limit(1);

      // get to check the stages exist
      query.get().then((doc) => {
        if (doc.size === 0) {
          if (mounted.current) setHeartbeat(null);
        } else {
          unsub = query.onSnapshot(
            (res) => {
              if (mounted.current) setHeartbeat(res.docs[0].data());
            },
            () => {}
          );
        }
      });
    }
    return () => {
      mounted.current = false;
      if (unsub) unsub();
    };
  }, [firestore, deviceId, stages, latestMethodCall]);

  return heartbeat;
};

//  get the last timestamp of deploy start using "latestMethodCall"
export const useDeviceLastDeployStart = (deviceId) => {
  // const stage = useDeviceLastUpdate({
  //   deviceId,
  //   latestMethodCall: "install",
  // });
  const stage = useDeviceLastUpdate({deviceId, stages: DEPLOY_START_STAGES});

  if (typeof stage === "undefined") return undefined;
  if (!stage) return null;
  // * 1000 to convert from s to ms
  return stage.timestamp * 1000;
};

//  get the last timestamp of deploy end using "stages"
export const useDeviceLastDeployEnd = (deviceId) => {
  const stage = useDeviceLastUpdate({deviceId, stages: DEPLOY_END_STAGES});

  if (typeof stage === "undefined") return undefined;
  if (!stage) return null;
  // * 1000 to convert from s to ms
  return stage.timestamp * 1000;
};

export const useDeviceDeploying = (deviceId) => {
  const start = useDeviceLastDeployStart(deviceId);
  const end = useDeviceLastDeployEnd(deviceId);

  if (typeof start === "undefined" || typeof end === "undefined")
    return undefined;
  // never started => null
  if (!start) return null;
  // never ended or completed
  return !end || start > end;
};

export const useDeviceDeployLastStage = (deviceId) => {
  const stage = useDeviceLastUpdate({deviceId, stages: DEPLOY_MAIN_STAGES});

  if (typeof stage === "undefined") return undefined;
  if (!stage) return null;
  return stage;
};
