import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    fullscreenContainer: {
      height: window.innerHeight,
      width: window.innerWidth,
    },
    closeButtonContainer: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 2000,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(4),
    },
  };
});

const FullScreenMedia = ({ children, onClose }) => {
  const classes = useStyles();
  return (
    <div className={classes.fullscreenContainer}>
      {children}
      {onClose && (
        <div className={classes.closeButtonContainer}>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default FullScreenMedia;
