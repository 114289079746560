// const APP_PREFIX = "io.myplayer.";

export const NAMES = {
  MYPROJECTS: "MyProjects",
};

export const PACKAGES = {
  MYPROJECTS: "io.myplayer.android.myprojects",
};

export const KEYS = {
  VERSION: "ver",
};

export const getAppDisplayName = (app) =>
  `${app?.displayName || app?.appName} (${app.ver})`;
export const getAppVersionName = (app, ver) =>
  `${app?.displayName || app?.appName} (${ver})`;

export const getAppName = (app) => app.appName;
export const getAppPackageName = (app) => app.pkgName;
export const getAppVersion = (app) => app.ver;
export const getAppIconUrl = (app) => app.img;
export const getAppAltVersions = (app) => app.alt_versions;
export const isAppDefault = (app) => app.default;
export const isAppSelected = (app) => !!app.selected;

export const setAppVersion = (app, ver) => {
  app.ver = ver;
};

export const setAppSelected = (app, selected) => {
  app.selected = selected;
};

export const setAppName = (app, name) => {
  app.appName = name;
};

export const setAppIconUrl = (app, url) => {
  app.img = url;
};

export const setAppAltVersions = (app, alt_versions) => {
  app.alt_versions = alt_versions;
};

// copy apps info from global to project
// basically only keep the version from project
// alt_versions, appName, default, img,
export const copyAppsInfo = (projectApps, globalApps) => {
  const apps = {};
  if (projectApps && globalApps) {
    Object.entries(projectApps).forEach(([id, app]) => {
      // copy only if app exist in global
      if (id in globalApps) {
        apps[id] = {
          ...globalApps[id],
          // ...{
          //   ver: app.ver,
          // },
        };
        if ("ver" in app) apps[id].ver = app.ver;
        if ("selected" in app) apps[id].selected = app.selected;
      }
    });
  }
  return apps;
};

export const selectApps = (appsTarget, appsSource) => {
  const apps = {};
  if (appsTarget && appsSource) {
    Object.entries(appsTarget).forEach(([id, app]) => {
      apps[id] = {...app};
      if (id in appsSource) {
        apps[id].ver = appsSource[id].ver;
        apps[id].selected = true;
      }
    });
  }
  return apps;
};

export const createAppsStateUpdate = (projectApps, globalApps, state) => {
  const apps = Object.fromEntries(
    Object.entries(projectApps)
      // remove app, either not in state (no change) or selected (version change)
      .filter(([id, app]) => !(id in state) || state[id].selected)
      .map(([id, app]) => {
        if (id in state) {
          // update ver
          return [id, {...app, ...{ver: state[id].ver}}];
        } else {
          return [id, {...app}];
        }
      })
  );
  // add new apps
  Object.entries(state)
    .filter(([id, app]) => app.original !== true && app.selected)
    .forEach(([id, app]) => {
      apps[id] = {...globalApps[id], ...{ver: app.ver}};
    });
  return apps;
};
