import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

import { COLLECTIONS } from "../utils/firestoreUtils";

// get info of a project => dict
export const useProjectInfo = (projectId) => {
  const collection = COLLECTIONS.PROJECT_INFO;
  const config = projectId && {
    collection,
    doc: projectId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    return isLoaded(infos) && infos[projectId] && infos[projectId];
  });
};

// => string
export const useProjectAnalyticsUrl = (projectId) => {
  const collection = COLLECTIONS.PROJECT_ANALYTICS;
  const config = projectId && {
    collection,
    doc: projectId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    if (!isLoaded(infos)) return undefined;
    const projectInfo = infos[projectId];
    if (!projectInfo || !projectInfo.analytics) return null;
    return projectInfo.analytics;
  });
};

// get the selected (installed) apps from a project
// convert the array and return as dict
// for future purpose should only make use of "pkgName" and "ver"
// default => {}
export const useProjectApps = (projectId) => {
  const collection = COLLECTIONS.PROJECT;
  const config = projectId && {
    collection,
    doc: projectId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    if (!isLoaded(infos)) return undefined;
    const projectInfo = infos[projectId];
    // project/pkgs not found => return empty as default
    if (!projectInfo || !projectInfo.pkgs) return {};
    else
      return projectInfo.pkgs.reduce(
        (total, current) => ({
          ...total,
          ...{ [current.pkgName]: current },
        }),
        {}
      );
  });
};

// default = ""
export const useProjectName = (projectId) => {
  const info = useProjectInfo(projectId);
  return (info && info.name) || "";
};
