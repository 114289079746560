import React from "react";

import StatCard from "./statCard/StatCard";
import { useOrganisationSmartTagsStatistics } from "services/OrganisationService";
import { OrganisationStrings } from "strings";
import { SmartTagIcon } from "assets/icons";

const OrganisationSmartTagsStatCard = ({ organisationId, canRead }) => {
  const stats = useOrganisationSmartTagsStatistics(organisationId);

  return (
    <StatCard
      icon={<SmartTagIcon />}
      title={OrganisationStrings.OVERVIEW_SMART_TAGS_STAT_TITLE}
      loading={typeof stats === "undefined"}
      live={stats?.live || 0}
      online={stats?.online || 0}
      canRead={canRead}
    />
  );
};

export default OrganisationSmartTagsStatCard;
