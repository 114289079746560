import React from "react";

import { useMobileLayout } from "hooks/uiHooks";
import ActionButton from "ui/ActionButton";
import NavigationBar from "ui/NavigationBar";
import { useStyles } from "./style";

const Sidebar = ({ config, path }) => {
  const mobile = useMobileLayout();
  const classes = useStyles();

  return (
    <div className={mobile ? classes.bottom : classes.left}>
      <div className={mobile ? classes.mobileAction : null}>
        <ActionButton mobile={mobile} config={config} />
      </div>
      <NavigationBar mobile={mobile} path={path} />
    </div>
  );
};

export default Sidebar;
