import React, { useState } from "react";

import { MediaActions, MediaCard } from ".";

const scale = 1.1;

// Bundle of MediaCard with MediaActions
// either projectId or mediaLink should be provided
const MediaContent = ({
  mediaLink,
  projectId,
  onFullscreen,
  disabled,
  draftId,
  draftName,
}) => {
  const [zoom, setZoom] = useState(1);

  const onZoomIn = () => {
    const newZoom = zoom * scale;
    if (newZoom < 2) setZoom(newZoom);
  };

  const onZoomOut = () => {
    const newZoom = zoom / scale;
    if (newZoom > 0.7) setZoom(newZoom);
  };

  return (
    <>
      <MediaCard
        projectId={projectId}
        mediaLink={mediaLink}
        zoom={zoom}
        draftId={draftId}
        draftName={draftName}
      />
      <MediaActions
        onZoomIn={onZoomIn}
        onZoomOut={onZoomOut}
        onFullscreen={onFullscreen}
        disabled={disabled}
        name={draftName}
      />
    </>
  );
};

export default MediaContent;
