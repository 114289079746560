import React from "react";
import { Card, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useDeviceOnlineDays } from "../../services/DeviceService";
import { useMobileLayout } from "../../hooks/uiHooks";

import RestrictedContent from "../RestrictedContent";

import { DeviceStrings } from "../../strings";

const DEFAULTS = {
  LAST_DAYS: 30,
  LAST_DAYS_MOBILE: 7,
  LABEL_DAYS: 15,
  LABEL_DAYS_MOBILE: 7,
};

const useStyles = makeStyles((theme) => ({
  column: {
    margin: theme.spacing(2),
    "&:first-child": {
      marginRight: 0,
    },
    display: "flex",
    alignItems: "center",
  },
  main: {
    padding: 0,
    minHeight: 64,
    display: "flex",
    alignItems: "stretch",
  },
  heartbeats: ({ mobile }) => ({
    flexGrow: 1,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "stretch",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(mobile ? 2 : 4),
  }),
  onlineContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  onlineTop: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    position: "relative",
    marginBottom: 20,
  },
  onlineLeft: {
    borderRight: `solid 1px ${theme.palette.border}`,
    flexGrow: 1,
  },
  onlineRight: {
    flexGrow: 1,
  },
  onlineDot: ({ online }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: "orange",
    transform: "translate(-50%, -50%)",
    visibility: online ? "visible" : "hidden",
  }),
  onlineLabel: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: 10,
  },
}));

const OnlineDay = ({ online, title, label }) => {
  const classes = useStyles({ online });

  return (
    <Tooltip title={title}>
      <div className={classes.onlineContainer}>
        <div className={classes.onlineTop}>
          <div className={classes.onlineLeft} />
          <div className={classes.onlineRight} />
          <div className={classes.onlineDot} />
        </div>
        <div className={classes.onlineLabel}>
          <span>{label}</span>
        </div>
      </div>
    </Tooltip>
  );
};

const DeviceHeartbeatsCard = ({ projectId, deviceId, canRead }) => {
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile });

  const onlineDays = useDeviceOnlineDays({
    deviceId,
    days: mobile ? DEFAULTS.LAST_DAYS_MOBILE : DEFAULTS.LAST_DAYS,
  });

  return (
    <Card>
      <div className={classes.main}>
        <div className={classes.column}>
          <span className="material-symbols-outlined">monitor_heart</span>
        </div>
        <div className={classes.column}>
          <Typography variant="caption">
            {DeviceStrings.GENERAL_HEARTBEAT_TITLE}
          </Typography>
        </div>
        <div className={`${classes.column} ${classes.heartbeats}`}>
          <RestrictedContent permitted={canRead}>
            {onlineDays?.map((d, i) => {
              const label =
                i === 0
                  ? DeviceStrings.GENERAL_HEARTBEAT_TODAY
                  : i %
                      (mobile
                        ? DEFAULTS.LABEL_DAYS_MOBILE
                        : DEFAULTS.LABEL_DAYS) ===
                    0
                  ? `-${i}`
                  : "";
              return (
                <div
                  key={`online-day-${i}`}
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <OnlineDay
                    online={d[1]}
                    title={new Date(d[0]).toLocaleDateString()}
                    label={label}
                  />
                </div>
              );
            })}
          </RestrictedContent>
        </div>
      </div>
    </Card>
  );
};

export default DeviceHeartbeatsCard;
