import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: 120,
    textTransform: "capitalize",
    fontWeight: 700,
  },
}));

export const TabBar = ({ mobile, currentTab, onTabChange, tabs }) => {
  const classes = useStyles();
  return (
    <Tabs
      name="tab"
      centered={mobile ? false : true}
      value={currentTab >= 0 ? currentTab : false}
      onChange={onTabChange}
      variant={mobile ? "scrollable" : "standard"}
      indicatorColor="primary"
      textColor="primary"
      scrollButtons={mobile ? "on" : "auto"}
    >
      {tabs.map((tab) => (
        <Tab className={classes.tab} key={tab.label} label={tab.label} />
      ))}
    </Tabs>
  );
};
