import { useRef, useEffect, useCallback, useState } from "react";

import IFrameService from "./IFrameService";
import { getItemLocalStorage } from "./LocalStorageService";
import {
  updateMediaDraftFavoriteChapters,
  updateMediaDraftSticker,
} from "./ApiService";
import { useProjectDraftPublicInfo } from "./ProjectService";
import { STORY_SHARE_URL } from "configs/urlConfig";

const CONFIG = {
  siteOrigin: "https://storage.googleapis.com",
};

const ACTIONS = {
  READY: "READY",
  LIKE_CHAPTER: "LIKE_CHAPTER",
  USER_SETUP: "USER_SETUP",
  PUBLIC_INFO: "PUBLIC_INFO",
  STICKER_UPDATE: "STICKER_UPDATE",
};

const TAG = "[useMyStoriesV2Service]";

function useMyStoriesV2Service(ref, mediaLink) {
  const iframeRef = useRef(null);
  const gauidRef = useRef(null);
  const mediaLinkRef = useRef(null);
  const apiTokenRef = useRef(null);
  const [isReadySent, setIsReadySent] = useState(false);
  const [mediaDraftId, setMediaDraftId] = useState(null);
  const publicInfo = useProjectDraftPublicInfo({
    projectId: mediaLinkRef.current?.projectId,
    draftName: mediaDraftId,
  });

  const sendPublicInfo = useCallback((info) => {
    if (!info) return;
    const { apiToken, ...restInfoData } = info;

    apiTokenRef.current = apiToken;
    IFrameService.postMessage(iframeRef.current, CONFIG.siteOrigin, {
      action: ACTIONS.PUBLIC_INFO,
      payload: restInfoData,
    });
  }, []);

  useEffect(() => {
    if (isReadySent && publicInfo) {
      setIsReadySent(false);
    }

    sendPublicInfo(publicInfo);
  }, [publicInfo, isReadySent, sendPublicInfo, mediaDraftId]);

  const onLikeChapter = useCallback(
    ({ chapterId, mediaDraftId: localMediaDraftId }, promiseId) => {
      updateMediaDraftFavoriteChapters({
        projectId: mediaLinkRef.current.projectId,
        draftId: localMediaDraftId,
        chapterId,
        gauid: gauidRef.current,
        apiToken: apiTokenRef.current,
      });

      const data = {
        action: ACTIONS.LIKE_CHAPTER,
        payload: chapterId,
        promiseId,
        success: "success",
      };

      IFrameService.postMessage(iframeRef.current, CONFIG.siteOrigin, data);
    },
    []
  );

  const onStickerUpdate = useCallback(
    (
      { chapterId, answer, stickerType, mediaDraftId: localMediaDraftId },
      promiseId
    ) => {
      updateMediaDraftSticker({
        projectId: mediaLinkRef.current.projectId,
        draftId: localMediaDraftId,
        chapterId,
        answer,
        stickerType,
        gauid: gauidRef.current,
        apiToken: apiTokenRef.current,
      });

      const data = {
        action: ACTIONS.STICKER_UPDATE,
        payload: chapterId,
        promiseId,
        success: "success",
      };

      IFrameService.postMessage(iframeRef.current, CONFIG.siteOrigin, data);
    },
    []
  );

  const onReady = useCallback((args, promiseId) => {
    if (args && args.mediaDraftId) {
      const { mediaDraftId: localMediaDraftId } = args;
      setMediaDraftId(localMediaDraftId);
    }

    console.debug(`${TAG} onReady (${promiseId})`, args);
    gauidRef.current = getItemLocalStorage({ key: "gauid" });
    setIsReadySent(true);

    IFrameService.postMessage(iframeRef.current, CONFIG.siteOrigin, {
      action: ACTIONS.USER_SETUP,
      payload: {
        gauid: gauidRef.current,
        mediaLink: window.location.href,
        shareUrl: STORY_SHARE_URL,
      },
    });
  }, []);

  useEffect(() => {
    const onMessage = (data) => {
      const { action, payload, promiseId } = data.detail;
      switch (action) {
        case ACTIONS.READY:
          onReady(payload, promiseId);
          break;
        case ACTIONS.LIKE_CHAPTER:
          onLikeChapter(payload, promiseId);
          break;
        case ACTIONS.STICKER_UPDATE:
          onStickerUpdate(payload, promiseId);
          break;

        default:
          console.warn(
            `${TAG} unknown message: ${JSON.stringify(data.detail)}${action}`
          );
          break;
      }
    };

    if (!mediaLinkRef.current && mediaLink) {
      mediaLinkRef.current = mediaLink;
    }

    if (!mediaLink || !mediaLinkRef.current) return;

    // unsubscribe existing
    if (iframeRef.current) {
      console.debug("unsubscribeMessage");
      IFrameService.unsubscribeMessage(CONFIG.siteOrigin, onMessage);
    }

    if (ref && !iframeRef.current) {
      console.debug("subscribeMessage");
      iframeRef.current = ref;
      IFrameService.subscribeMessage(CONFIG.siteOrigin, onMessage, ref);
    }
  }, [mediaLink, onLikeChapter, ref, publicInfo, onStickerUpdate, onReady]);
}

export default useMyStoriesV2Service;
