import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import UserAvatar from "../UserAvatar";

import List from "@material-ui/core/List";

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: theme.spacing(6),
    width: theme.spacing(6),
    margin: " auto 0",
    borderRadius: "50%",
    color: "#5F5F5F",
    cursor: "pointer",
  },
  offline: {
    display: "flex",
    justifyContent: "flex-end",
    overflowX: "auto",
  },
  live: {
    display: "flex",
    justifyContent: "flex-end",
    overflowX: "auto",
  },
  userAvatarContainer: {
    margin: 8,
  },
  listContainer: {
    display: "flex",
    maxWidth: "100%",
    paddingRight: (expandApprovalItem) => (expandApprovalItem ? 10 : 0),
  },
}));

const DEFAULT_DISPLAY_APPROVALS = 2;

const Avatars = ({ approvals, expandApprovalItem, onClickExpand, live }) => {
  const classes = useStyles(expandApprovalItem);
  const numberOfApprovals = approvals && Object.keys(approvals).length;
  const showExpand =
    !expandApprovalItem && numberOfApprovals - DEFAULT_DISPLAY_APPROVALS > 0;

  return (
    <div className={live ? classes.live : classes.offline}>
      {showExpand && (
        <div onClick={() => onClickExpand()} className={classes.userAvatar}>
          <span>{`+${numberOfApprovals - DEFAULT_DISPLAY_APPROVALS} `}</span>
        </div>
      )}
      <List className={classes.listContainer}>
        {approvals &&
          Object.keys(approvals)
            .slice(expandApprovalItem ? 0 : -DEFAULT_DISPLAY_APPROVALS)
            .map((userId, index) => (
              <div key={index} className={classes.userAvatarContainer}>
                <UserAvatar
                  key={userId}
                  userId={userId}
                  size={6}
                  showAvatar={true}
                />
              </div>
            ))}
      </List>
    </div>
  );
};

export default Avatars;
