const PREFIX = "@@myplayer";
const TYPES = {
  SET: `${PREFIX}/SET`,
  REMOVE: `${PREFIX}/REMOVE`,
};

const set = (state, path, data) => {
  if (!path || !data) return;
  const newState = Object.assign({}, state);
  const paths = path.split("/");
  let cur = newState;
  paths.forEach((p, i) => {
    if (!(p in cur)) cur[p] = {};
    if (i === paths.length - 1) cur[p] = data;
    else cur = cur[p];
  });
  return newState;
};

const remove = (state, path) => {
  if (!path) return;
  const newState = Object.assign({}, state);
  const paths = path.split("/");
  let cur = newState;
  paths.forEach((p, i) => {
    if (!(p in cur)) cur[p] = {};
    if (i === paths.length - 1) delete cur[p];
    else cur = cur[p];
  });
  return newState;
};

// const action = {
//     type: "",
//     data: {},
//     path: "",
// }
const myplayerReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.SET:
      return set(state, action.path, action.data);
    case TYPES.REMOVE:
      return remove(state, action.path);
    default:
      break;
  }
  return state;
};

const setData = ({ dispatch, path, data }) => {
  dispatch({
    type: TYPES.SET,
    path,
    data,
  });
};

const removeData = ({ dispatch, path }) => {
  dispatch({
    type: TYPES.REMOVE,
    path,
  });
};

export default myplayerReducer;
export { setData, removeData };
