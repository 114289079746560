import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(1),
      padding: "14px 12px",
      backgroundColor: theme.palette.grey[100],
      borderRadius: "4px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    wrapper: {
      display: "flex",
      flexDirection: "row",
      color: "#5F5F5F",
      alignItems: "center",
      justifyContent: "space-between",
    },
    labelWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    iconWrapper: {
      display: "flex",
      height: "20px",
      width: "24px",
      margin: "4px 0",
      border: "2px dotted #323232",
      color: "#323232",
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        height: "18px",
        width: "18px",
      },
    },
    label: {
      marginLeft: theme.spacing(2),
    },
    logoWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    logo: {
      width: "28px",
      height: "28px",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    fileName: {
      fontSize: "16px",
      color: "#777777",
      textTransform: "lowercase",
      marginLeft: theme.spacing(1),
    },
  };
});
