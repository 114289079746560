import React from "react";
import { Box, Typography } from "@material-ui/core";

const MobileContent = ({ title, desc }) => (
  <Box p={4}>
    {title && <Typography variant="caption">{title}</Typography>}
    {desc && (
      <Typography variant="body1" color="textSecondary">
        {desc}
      </Typography>
    )}
  </Box>
);

export default MobileContent;
