import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";

export const MenuItemStyled = withStyles((theme) => ({
  root: {
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
  },
}))(MenuItem);

export const Image = withStyles({
  root: {
    height: "36px",
    width: "36px",
    marginRight: "10px",
    borderRadius: "50%",
  },
})(({ classes, src, alt }) => (
  <img className={classes.root} src={src} alt={alt} />
));

export const Title = withStyles((theme) => ({
  root: {
    fontSize: "16px",
  },
}))(Typography);

export const Border = withStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 0,
    left: "8px",
    right: "8px",
    margin: "0 8px",
    borderBottom: `1px solid #E8E9EA`,
  },
}))(Box);
