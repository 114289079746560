import React, { useMemo } from "react";
import { useSnackbar } from "notistack";
import ContentCopyIcon from "@material-ui/icons/ContentCopy";
import Typography from "@material-ui/core/Typography";

import { useProjectShareCodeInfo } from "services/ProjectService";
import { deleteProjectCode } from "services/ApiService";
import { copyText } from "utils/generalUtils";
import { useConfirm, useMobileLayout } from "hooks/uiHooks";
import { DefaultStrings, TeamCodeStrings } from "strings";
import { getJoinUrl, getJoinRoute } from "route";
import { useStyles } from "./style";
import { Creator, Team, Menu } from "./components";

function TeamShareCodeCard({ code, canEdit, projectId }) {
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();

  const classes = useStyles({ mobile });
  const confirm = useConfirm();
  const codeInfo = useProjectShareCodeInfo(code);

  const projectJoinUrl = useMemo(() => getJoinUrl(code), [code]);
  const joinPath = useMemo(() => {
    if (!codeInfo) return;

    const path = getJoinRoute(codeInfo.code);
    if (path.startsWith("/")) {
      return path.slice(1);
    }
    return path;
  }, [codeInfo]);

  const onCopy = async (text, message) => {
    try {
      await copyText(text);
      enqueueSnackbar(message, { variant: "info" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
    }
  };

  const onCopyLinkHandle = () => {
    onCopy(projectJoinUrl, TeamCodeStrings.TEAM_CODE_LINK_COPIED);
  };
  const onCopyCodeHandle = () => onCopy(code, TeamCodeStrings.TEAM_CODE_COPIED);
  const onDeleteHandle = async () => {
    const title = `${TeamCodeStrings.TEAM_CODE_DELETE_CONFIRM_TITLE} (${code})`;
    confirm({
      title,
      message: TeamCodeStrings.TEAM_CODE_PROJECT_DELETE_CONFIRM_DESC,
    })
      .then(async ({ onProgress, onClose }) => {
        onProgress(true);

        try {
          await deleteProjectCode({ projectId, code });
          enqueueSnackbar(TeamCodeStrings.TEAM_CODE_DELETED, {
            variant: "success",
          });
        } catch (error) {
          console.warn(error);
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        } finally {
          onProgress(false);
          onClose();
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.codeName} variant="body1">
            {codeInfo?.name}
          </Typography>
          <button className={classes.copyUrl} onClick={onCopyLinkHandle}>
            <Typography className={classes.url} variant="body1">
              {joinPath}
              <ContentCopyIcon className={classes.copyIcon} />
            </Typography>
          </button>
        </div>
        <div className={classes.menuWrapper}>
          <Typography className={classes.role} variant="body1">
            {codeInfo?.defaultRoleName}
          </Typography>
          <Menu
            canEdit={canEdit}
            info={codeInfo}
            onCopyCode={onCopyCodeHandle}
            onCopyLink={onCopyLinkHandle}
            onDelete={onDeleteHandle}
          />
        </div>
      </div>
      <div className={classes.users}>
        <div className={classes.codeLeft}>
          <Creator userId={codeInfo?.creatorId} />
        </div>
        <div className={classes.codeRight}>
          <Team code={code} />
        </div>
      </div>
    </div>
  );
}

export default TeamShareCodeCard;
