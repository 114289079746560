export const COLLECTIONS = {
  APP_INFO: "apps_and_info",
  PERMISSIONS: "default_project_permissions",
  ORGANISATION_PERMISSIONS: "default_organisation_permissions",

  PROJECT: "projects",
  PROJECT_ANALYTICS: "projects_and_analytics",
  PROJECT_APPS: "apps",
  PROJECT_DEFAULT: "project_defaults",
  PROJECT_DEVICES: "projects_and_devices",
  PROJECT_INFO: "projects_and_info",
  PROJECT_CODES: "projectcodes",

  DEVICE: "devices",
  DEVICE_STATUS: "device_status",
  DEVICE_DEPLOYMENT: "device_deployment",
  DEVICE_HEARTBEAT: "device_heartbeats",

  USER_PROJECT: "users_and_projects",
  USER: "users",
};

export const DOCUMENTS = {
  PROJECT_SIZE: "common_project_sizes",

  DEFAULT_DEVICE_PROJECT: "defaultDeviceProject",
};
