import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(1),
    color: "black",
    fontSize: 32,
    fontWeight: 700,
  },
  chartContainer: {
    display: "flex",
    justifyContent: "start",
    marginTop: 25,
    marginBottom: 25,
    alignItems: "center",
    fontWeight: "bold",
    lineHeight: 1.66,
  },
}));
