import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    cardContainer: ({ mobile, small }) => ({
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      paddingLeft: mobile ? theme.spacing(2) : theme.spacing(1),
      paddingRight: mobile ? theme.spacing(2) : theme.spacing(1),
      width: mobile ? window.innerWidth : small ? 240 : 320,
      height: small ? 240 : 320,
      boxSizing: "border-box",
    }),
    title: {
      display: "flex",
      alignItems: "center",
    },
    media: {
      flexGrow: 2,
      height: 1,
    },
  };
});
