import React, { useState, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import WorkspacesOutlinedIcon from "@material-ui/icons/WorkspacesOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { useSnackbar } from "notistack";

import { useMobileLayout } from "hooks/uiHooks";
import { PATTERN_NO_PUNCTUATION, isDomainValid } from "utils/generalUtils";
import {
  checkOrganisationDomain,
  createOrganisation,
} from "services/OrganisationService";
import { OrganisationStrings } from "strings";
import { TextInput, FileInput, Chip, Switch } from "components";
import { useStyles } from "./style";
import LinearSpinner from "ui/LinearSpinner";

// organisationId = edit existing
// userId = new organisation
export const OrganisationCreateDialog = ({ open, onComplete, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();
  const classes = useStyles();
  const [nameValue, setNameValue] = useState("");
  const [domainValue, setDomainValue] = useState("");
  const [domains, setDomains] = useState([]);
  const [inputImage, setInputImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [domainCheckLoading, setDomainCheckLoading] = useState(false);
  const [enableDomains, setEnableDomains] = useState(true);
  const [isDomainValueValid, setIsDomainValueValid] = useState(false);

  const shouldDisable = useMemo(() => {
    return (
      //name mandatory
      !nameValue ||
      nameValue.length === 0 ||
      (enableDomains && domains && domains.length === 0)
    );
  }, [domains, enableDomains, nameValue]);

  const resetData = () => {
    setInputImage(null);
    setNameValue("");
    setDomains([]);
  };

  const onConfirmHandler = async () => {
    const info = {
      name: nameValue,
      connectedDomains: domains,
    };
    // update image only if changed
    if (inputImage) {
      info.imageURL = inputImage;
    }

    setLoading(true);
    // create new organisation
    try {
      await createOrganisation(info);
      setLoading(false);
      enqueueSnackbar(OrganisationStrings.ADD_ORGANISATION_SUCCESS, {
        variant: "success",
      });
      resetData();
      if (onComplete) onComplete();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(OrganisationStrings.ADD_ORGANISATION_ERROR, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const checkDomain = async () => {
    const domainsUpdate = [...domains];
    domainsUpdate.push(domainValue);
    const domainAddedToList = domains.find((d) => d === domainValue);

    if (domainAddedToList) {
      enqueueSnackbar(
        OrganisationStrings.ADD_ORGANISATION_DOMAIN_ALREADY_ON_LIST.replace(
          "{domain}",
          domainValue
        ),
        {
          variant: "warning",
        }
      );
      return;
    }

    try {
      setDomainCheckLoading(true);
      const res = await checkOrganisationDomain(domainValue);
      setDomainCheckLoading(false);

      if (res.result.isDomainExists) {
        enqueueSnackbar(
          OrganisationStrings.ADD_ORGANISATION_DOMAIN_EXISTS.replace(
            "{domain}",
            domainValue
          ),
          {
            variant: "warning",
          }
        );
      } else {
        setDomains(domainsUpdate);
        setDomainValue("");
      }
    } catch (err) {
      setDomainCheckLoading(false);
      console.error(err);
      enqueueSnackbar(OrganisationStrings.ADD_ORGANISATION_DOMAIN_CHECK_ERROR, {
        variant: "error",
      });
    }
  };

  const onLogoChangeHandle = (file) => {
    setInputImage(file);
  };

  const onDeleteHandle = (domain) => () => {
    let toUpdate = [...domains];
    const index = toUpdate.findIndex((d) => d === domain);

    if (index !== -1) {
      toUpdate.splice(index, 1);
      setDomains(toUpdate);
    }
  };

  const onTextfieldUpdateHandle = (e) => {
    const input = e.target;
    const { name, value } = input;

    if (input.validity.valid) {
      if (name === "name") {
        setNameValue(value);
      }
      if (name === "domain") {
        setIsDomainValueValid(isDomainValid(value));
        setDomainValue(value);
      }
    }

    const start = input.validity.valid
      ? input.selectionStart
      : input.selectionStart - 1;
    window.requestAnimationFrame(() => {
      input.selectionStart = start;
      input.selectionEnd = start;
    });
  };

  const onClickAddEmailHandle = () => {
    checkDomain(domainValue);
  };

  const onTextfieldUKeydownHandle = async (e) => {
    const input = e.target;

    if (e.key === "Enter") {
      if (input.name === "domain") {
        // check is domain valid
        const isValid = isDomainValid(domainValue);
        setIsDomainValueValid(isValid);
        // is invalid
        if (!isValid) return;
        checkDomain(domainValue);
      }
    }
  };

  const onEnableDomainsChangeHandle = () => {
    setEnableDomains(!enableDomains);
    if (!enableDomains) {
      setDomains([]);
      setDomains([]);
      setDomainValue("");
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      {loading && <LinearSpinner />}
      <DialogTitle className={classes.title} disableTypography>
        <Box
          style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
        >
          <WorkspacesOutlinedIcon style={{ marginRight: "16px" }} />
          <Typography variant="h6">
            {OrganisationStrings.ADD_DIALOG_TITLE}
          </Typography>
        </Box>
        <Typography variant="body2">
          {OrganisationStrings.ADD_DIALOG_DESCRIPTION}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box m={1}>
          <TextInput
            name="name"
            label={OrganisationStrings.ADD_DIALOG_NAME}
            value={nameValue}
            pattern={PATTERN_NO_PUNCTUATION}
            onChange={onTextfieldUpdateHandle}
          />
        </Box>
        <Box m={1}>
          <FileInput
            name="image"
            label={OrganisationStrings.ADD_DIALOG_LOGO}
            startIcon={<AddOutlinedIcon />}
            onChange={onLogoChangeHandle}
          />
        </Box>
        <Box m={1}>
          <Switch
            info={OrganisationStrings.ADD_DIALOG_ADD_DOMAINS_INFO}
            label={OrganisationStrings.ADD_DIALOG_ADD_DOMAINS}
            checked={enableDomains}
            onChange={onEnableDomainsChangeHandle}
          />
        </Box>
        {enableDomains && (
          <Box m={1}>
            <TextInput
              label={OrganisationStrings.ADD_DIALOG_DOMAIN}
              value={domainValue || ""}
              name="domain"
              isInValid={isDomainValueValid}
              endActionIcon={isDomainValueValid && <AddOutlinedIcon />}
              onClick={onClickAddEmailHandle}
              onChange={onTextfieldUpdateHandle}
              onKeyDown={onTextfieldUKeydownHandle}
              fullWidth
            />
            {domainCheckLoading && (
              <Box sx={{ position: "relative" }}>
                <LinearSpinner />
              </Box>
            )}
            <Box className={classes.domainsWrapper}>
              {domains.map((d) => (
                <Chip
                  key={d}
                  title={d}
                  endIcon={
                    <DeleteOutlineOutlinedIcon
                      className={classes.chipEndIcon}
                    />
                  }
                  onClick={onDeleteHandle(d)}
                />
              ))}
            </Box>
            <Typography className={classes.domainDescription}>
              {OrganisationStrings.ADD_DIALOG_DOMAIN_DESC}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <IconButton disabled={shouldDisable} onClick={onConfirmHandler}>
          <CheckIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
