import React from "react";
import logo from "assets/images/icon_my_player_v3.png";
import { DefaultStrings } from "../../strings";
import { Typography } from "@material-ui/core";

const SuccessScreen = () => {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        margin: 8,
      }}
    >
      <img
        src={logo}
        edge="start"
        alt={DefaultStrings.APP_NAME}
        style={{ width: "100px", margin: "40px" }}
      />
      <h2>Registration Successful</h2>
      <Typography style={{ textAlign: "center" }}>
        Please go back to the device and press confirm registration
      </Typography>
    </div>
  );
};

export default SuccessScreen;
