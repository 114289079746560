import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import { useMobileLayout } from "../../hooks/uiHooks";

import MetaTags from "../../ui/MetaTags";
import ProfileCard from "../../ui/cards/ProfileCard";
import UserAccountCard from "../../ui/cards/UserAccountCard";
import NotificationsCard from "../../ui/cards/NotificationsCard";

import { HomeStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  cardContainer: (mobile) => ({
    padding: theme.spacing(1),
    paddingLeft: mobile ? theme.spacing(2) : theme.spacing(1),
    paddingRight: mobile ? theme.spacing(2) : theme.spacing(1),
    minWidth: mobile ? document.documentElement.clientWidth : 400,
    minHeight: 140,
  }),
  height500: (mobile) => ({
    height: 500,
  }),
  height140: (mobile) => ({
    height: 140,
  }),
  main: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
  },
}));

const ProfilePage = ({ userId }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  return (
    <>
      <MetaTags
        title={HomeStrings.META_TITLE_PROFILE}
        description={HomeStrings.META_DESCRIPTION_PROFILE}
      />
      <div className={classes.main}>
        <div className={clsx(classes.height500, classes.cardContainer)}>
          <ProfileCard userId={userId} />
        </div>
        <div className={clsx(classes.height140, classes.cardContainer)}>
          <UserAccountCard></UserAccountCard>
        </div>
        <div className={clsx(classes.height140, classes.cardContainer)}>
          <NotificationsCard />
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
