import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  Card,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from "notistack";
import {useDrag, useDrop} from "react-dnd";
import update from "immutability-helper";

import {useMobileLayout, useConfirm} from "../../hooks/uiHooks";
import {
  useProjectTasks,
  useProjectAppsAll,
} from "../../services/ProjectService";
import {
  removeProjectTask,
  moveProjectTask,
  addProjectTasks,
} from "../../services/ApiService";
import {useGlobalApps} from "../../services/AppService";

import RestrictedContent from "../RestrictedContent";
import MyCard from "../cards/MyCard";
import ButtonCard from "../cards/ButtonCard";

import {selectMenuProps} from "../../configs/propsConfig";
import {DefaultStrings, ProjectStrings} from "../../strings";

const useStyles = makeStyles((theme) => ({
  main: (mobile) => ({
    padding: theme.spacing(mobile ? 1 : 2),
    height: "100%",
    display: "flex",
    flexDirection: mobile ? "column-reverse" : "row",
  }),
  leftContainer: (mobile) => ({
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    flexGrow: 3,
    overflowY: "auto",
    width: mobile ? "auto" : 1,
  }),
  rightContainer: (mobile) => ({
    margin: theme.spacing(1),
    flexGrow: mobile ? 0 : 2,
    width: mobile ? "auto" : 1,
  }),
  tasksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  taskCard: ({isDragging}) => ({
    padding: 4,
    marginBottom: 8,
    opacity: isDragging ? 0.2 : 1,
  }),
  taskCardContent: (mobile) => ({
    marginRight: theme.spacing(mobile ? 0 : 6),
    display: "flex",
    flexDirection: "column",
  }),
  taskCardDivider: {
    border: "solid 0.5px #C0C0C0",
    height: 1,
    marginBottom: theme.spacing(2),
  },
  taskCardChips: {
    padding: theme.spacing(2),
    backgroundColor: "white",
    border: "solid 0.5px #C0C0C0",
    borderRadius: theme.spacing(0.5),
  },
  taskCardDropdown: {
    flexGrow: 1,
    width: 1,
    margin: 5,
  },
  taskCommand: {
    overflowWrap: "anywhere",
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
}));

const TaskCard = ({
  projectId,
  task, // task info object
  canRead,
  progress, // in progress (loading)
  index, // index on list for drag and drop
  onDragging,
  onEndDrag,
  onExpand,
}) => {
  const confirm = useConfirm();
  const {enqueueSnackbar} = useSnackbar();
  const ref = useRef(null);
  const startIndex = index;

  // display target and displayName if available
  const taskDisplayName = `${task.target ? task.target + " - " : ""}${
    task.displayName || task.name
  }`;

  const [, drop] = useDrop({
    accept: "card",
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverBoundingRect.height * 0.8
      ) {
        return;
      }
      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverBoundingRect.height * 0.2
      ) {
        return;
      }
      // Time to actually perform the action
      onDragging(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{isDragging}, drag] = useDrag({
    type: "card",
    item: () => ({startIndex, index}),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (onEndDrag) onEndDrag(item.startIndex, item.index);
    },
  });

  const classes = useStyles({isDragging});

  const onClickRemove = () => {
    const message = `${ProjectStrings.TASKS_MSG_CONFIRM_REMOVE_TASK} ${taskDisplayName}?`;
    confirm({message}).then(() => {
      removeProjectTask({projectId, name: task.name, index}).then((result) => {
        if (result.success) {
          enqueueSnackbar(ProjectStrings.TASKS_MSG_TASK_REMOVED, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(DefaultStrings.ERROR_MSG, {variant: "error"});
          console.warn("removeProjectTask", result.errors[0]);
        }
      });
    });
  };

  const onExpandInternal = () => {
    if (onExpand) onExpand(index);
  };

  const config = {
    icon: <span className="material-symbols-outlined">feed</span>,
    title: `${taskDisplayName} (${task.priority})`,
    progress,
    overflow: [
      {
        label: task.expanded
          ? ProjectStrings.TASKS_MENU_COLLAPSE_TASK
          : ProjectStrings.TASKS_MENU_EXPAND_TASK,
        onClick: onExpandInternal,
      },
      {
        label: ProjectStrings.TASKS_MENU_DELETE_TASK,
        onClick: onClickRemove,
      },
    ],
  };
  const tags = [];
  if (task.async) tags.push(ProjectStrings.TASKS_TASK_TAG_ASYNC);
  if (task.sudo) tags.push(ProjectStrings.TASKS_TASK_TAG_SUDO);
  if (task.requireSuccess)
    tags.push(ProjectStrings.TASKS_TASK_TAG_REQUIRESUCCESS);

  drag(drop(ref));

  return (
    <div ref={ref} className={classes.taskCard}>
      <MyCard config={config} canRead={canRead}>
        {task.expanded && (
          <Collapse in={task.expanded}>
            <div className={classes.taskCardContent}>
              <div className={classes.taskCardDivider} />
              <div style={{marginBottom: 16}}>
                {task.cmds?.map((c, ci) => (
                  <span key={`cmds-${ci}`} className={classes.taskCommand}>{c}</span>
                ))}
              </div>
              <div className={classes.taskCardChips}>
                {tags.map((t) => (
                  <Chip key={`chip-${t}`} label={t} style={{marginLeft: 8}} />
                ))}
              </div>
            </div>
          </Collapse>
        )}
      </MyCard>
    </div>
  );
};

// hash all the tasks as useEffect dependency
const tasksHash = (t) => JSON.stringify(t);

const customTarget = ProjectStrings.TASKS_TARGET_CUSTOM_LABEL;
const customTask = {
  async: false,
  priority: "end",
  requireSuccess: false,
  sudo: true,
};

const EditTasksTab = ({projectId, canRead}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const {enqueueSnackbar} = useSnackbar();
  // tasks is from database
  const tasks = useProjectTasks(projectId);
  // tasksCache is temporary for display and dragging
  const [tasksCache, setTasksCache] = useState();
  const [updating, setUpdating] = useState();

  const [target, setTarget] = useState("");
  const [targetTask, setTargetTask] = useState("");
  const [commandInput, setCommandInput] = useState("");
  const [customTaskName, setCustomTaskName] = useState("");

  // projectApps: configured apps for this project
  // globalApps: global apps info (for task)
  // eligibleApps: configured apps with task + custom
  const projectApps = useProjectAppsAll(canRead && projectId);
  const globalApps = useGlobalApps();
  const eligibleApps =
    globalApps &&
    projectApps &&
    Object.fromEntries(
      Object.entries(globalApps)
        .filter(([k, v]) => !!v.tasks && k in projectApps)
        .concat([[customTarget, customTask]])
    );
  const eligibleTasks = target ? eligibleApps[target].tasks : [];
  const isCustomTarget =
    eligibleApps && target === Object.keys(eligibleApps).pop();

  const hash = tasksHash(tasks);

  const updateCache = useCallback(() => {
    setTasksCache((prevCache) =>
      tasks.map((t, ti) => ({
        ...t,
        id: ti,
        expanded: prevCache?.[ti] ? prevCache[ti].expanded : false,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (!tasks) return;
    updateCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, updateCache]);

  const onDragging = useCallback((dragIndex, hoverIndex) => {
    setTasksCache((prevCache) =>
      update(prevCache, {
        $splice: [
          [dragIndex, 1], // remove dragging
          [hoverIndex, 0, prevCache[dragIndex]], // insert dragging into hover
        ],
      })
    );
  }, []);

  const onEndDrag = (startIndex, index) => {
    // same position
    if (startIndex === index) return;
    setUpdating(startIndex);
    moveProjectTask({
      projectId,
      index: startIndex,
      newIndex: index,
    })
      .then((result) => {
        if (result.success) {
          enqueueSnackbar(ProjectStrings.TASKS_MSG_TASK_MOVED, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(DefaultStrings.ERROR_MSG, {variant: "error"});
          console.warn("moveProjectTask", result.errors[0]);
        }
      })
      .finally(() => {
        setUpdating(null);
        updateCache();
      });
  };

  const onChangeTarget = (event) => {
    setTarget(event.target.value);
    setTargetTask("");
    setCommandInput("");
  };

  const onChangeTask = (event) => {
    const index = event.target.value;
    setTargetTask(index);
    setCommandInput(eligibleTasks[index].cmds[0]);
  };

  const onChangeCommand = (event) => {
    setCommandInput(event.target.value);
  };

  const onChangeTaskName = (event) => {
    setCustomTaskName(event.target.value);
  };

  const onAddTask = () => {
    // addTask, tasks: task object to be added
    // addTarget: task target (package or custom)
    const addTask =
      target === customTarget
        ? {...customTask, name: customTaskName, cmds: [commandInput]}
        : globalApps[target].tasks[targetTask];
    const addTarget =
      target === customTarget
        ? customTarget
        : globalApps[target].displayName || globalApps[target].appName;
    const tasks = {
      ...addTask,
      target: addTarget,
    };

    addProjectTasks({projectId, tasks}).then((result) => {
      if (result.success) {
        enqueueSnackbar(ProjectStrings.TASKS_MSG_TASK_ADDED, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, {variant: "error"});
        console.warn("addProjectTasks", result.errors[0]);
      }
    });
  };

  const configCard = {
    icon: <span className="material-symbols-outlined">assignment_add</span>,
    title: ProjectStrings.TASKS_ADD_TASK_TITLE,
    desc: ProjectStrings.TASKS_ADD_TASK_DESC,
    buttonLabel: ProjectStrings.TASKS_ADD_TASK_BUTTON,
    onClick: onAddTask,
    fullHeight: true,
    disableButton: !commandInput,
    progress: false,
  };

  const onExpandTask = (index) => {
    setTasksCache((prevCache) =>
      prevCache.map((t) => (t.id === index ? {...t, expanded: !t.expanded} : t))
    );
  };

  return (
    <RestrictedContent permitted={canRead}>
      <div className={classes.main}>
        <Card className={classes.leftContainer}>
          <div className={classes.tasksContainer}>
            {tasksCache?.map((t) => {
              return (
                <TaskCard
                  key={`task-${t.id}`}
                  projectId={projectId}
                  task={t}
                  index={t.id}
                  canRead={canRead}
                  onDragging={onDragging}
                  onEndDrag={onEndDrag}
                  progress={updating === t?.id}
                  onExpand={onExpandTask}
                />
              );
            })}
          </div>
        </Card>
        <div className={classes.rightContainer}>
          <ButtonCard config={configCard} canRead={canRead}>
            <div style={{display: "flex"}}>
              <FormControl className={classes.taskCardDropdown}>
                <InputLabel>{ProjectStrings.TASKS_TARGET_LABEL}</InputLabel>
                <Select
                  value={target}
                  onChange={onChangeTarget}
                  MenuProps={selectMenuProps}
                >
                  {eligibleApps &&
                    Object.entries(eligibleApps).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v.displayName || v.appName || k}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl className={classes.taskCardDropdown}>
                <InputLabel>{ProjectStrings.TASKS_TASK_LABEL}</InputLabel>
                <Select
                  disabled={!target || isCustomTarget}
                  value={targetTask}
                  onChange={onChangeTask}
                  MenuProps={selectMenuProps}
                >
                  {eligibleTasks?.map((t, i) => (
                    <MenuItem key={`appTask-${i}`} value={i}>
                      {t.displayName || t.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Collapse in={isCustomTarget}>
              <div className={classes.topMargin}>
                <TextField
                  label={ProjectStrings.TASKS_TASK_NAME_LABEL}
                  value={customTaskName}
                  fullWidth
                  onChange={onChangeTaskName}
                />
              </div>
            </Collapse>
            <div className={classes.topMargin}>
              <TextField
                label={ProjectStrings.TASKS_COMMAND_LABEL}
                value={commandInput}
                fullWidth
                onChange={onChangeCommand}
                disabled={!isCustomTarget}
              />
            </div>
          </ButtonCard>
        </div>
      </div>
    </RestrictedContent>
  );
};

export default EditTasksTab;
