import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80px",
  },
  content: (mobile) => ({
    display: "flex",
    flexDirection: "column",
    maxHeight: mobile ? "auto" : "330px",
    padding: "8px 16px",
  }),
}));
