import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  pinnedIconButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  pinnedIcon: {
    filter: "drop-shadow( 0 0 3px rgba(0, 0, 0, .7))",
  },
  unpinnedIcon: {
    filter: "drop-shadow( 0 0 3px white)",
  },
}));
