import React from "react";
import DevicesOutlinedIcon from "@material-ui/icons/DevicesOutlined";

import {
  useLiveDevicesCount,
  useCompliantDevicesCount,
  useOnlineDevicesCount,
  useDecommissionedDevicesCount,
} from "services/DeviceService";
import { useProjectDevicesOnlineState } from "services/ProjectService";
import StatCard from "./statCard/StatCard";
import { ProjectStrings } from "strings";

const ProjectDeviceStatCard = ({ projectId, canRead, canEdit }) => {
  const live = useLiveDevicesCount(canRead && projectId);
  const online = useOnlineDevicesCount(canRead && projectId);
  const charts = useProjectDevicesOnlineState(projectId, 90);

  const compliant = useCompliantDevicesCount(canRead && projectId);
  const decommissioned = useDecommissionedDevicesCount(canRead && projectId);

  return (
    <StatCard
      icon={<DevicesOutlinedIcon />}
      title={ProjectStrings.OVERVIEW_DEVICES_TITLE}
      live={live}
      online={online}
      compliant={compliant}
      decommissioned={decommissioned}
      charts={charts}
      canRead={canRead}
      canEdit={canEdit}
    />
  );
};

export default ProjectDeviceStatCard;
