const STRINGS = {
  NEWS_TITLE: "News",
  NEWS_EMPTY_TITLE: "No news",
  NEWS_EMPTY_DESC:
    "There is no news for you yet. News will show all notifications and activity in your projects and organisation",

  NEWS_DISABLED_MSG: "All notifications are disabled",
  NEWS_DISABLED_CONFIG: "Config",
};

export default STRINGS;
