import React, { useState } from "react";
import LinkIcon from "@material-ui/icons/Link";
import { useSnackbar } from "notistack";

import MyDialog from "../../ui/MyDialog";
import MediaLinkFormFields from "../../ui/MediaLinkFormFields";
import { ProjectStrings, DefaultStrings } from "../../strings";
import {
  useProjectResolution,
  useProjectMediaSize,
} from "../../services/ProjectService";
import { updateShowroomLink } from "../../services/ApiService";

const EditMediaLinkDialog = ({
  onClose,
  open,
  link,
  projectId,
  setLinkLoadingId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const sizeKey = useProjectResolution(projectId);
  const size = useProjectMediaSize({ key: sizeKey });

  const [editedLink, setEditedLink] = useState({
    ...link,
    expiry: link.expiry && link.expiry.toDate(),
    customizeSize: !link.autoSize && {
      width: link.width,
      height: link.height,
    },
  });
  const onLinkChange = (name, value) => {
    setEditedLink({
      ...editedLink,
      [name]: value,
    });
  };

  const onUpdateLink = async () => {
    const data = {
      ...editedLink,
      projectId,
      height: !editedLink.customizeSize?.height
        ? size.height
        : parseInt(editedLink.customizeSize?.height), // use default media height if user hasn't specified
      width: !editedLink.customizeSize?.width
        ? size.width
        : parseInt(editedLink.customizeSize?.width), // use default media width if user hasn't specified
      autoSize:
        !editedLink.customizeSize?.height && !editedLink.customizeSize?.width,
    };
    try {
      onClose();
      setLinkLoadingId(editedLink.linkId);
      await updateShowroomLink(data);
      setLinkLoadingId(null);
      enqueueSnackbar(ProjectStrings.SHOWROOM_LINK_UPDATED, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
      setLinkLoadingId(null);
      onClose();
    }
  };

  const configDialog = {
    icon: <LinkIcon />,
    title: ProjectStrings.SHOWROOM_EDIT_DIALOG_TITLE,
    disableOk: false,
    onClose,
    onOk: onUpdateLink,
  };

  return (
    <MyDialog open={open} config={configDialog}>
      <MediaLinkFormFields
        projectId={projectId}
        link={editedLink}
        onLinkChange={onLinkChange}
        showTypeField={false}
        editing={true}
        updatePassword={true}
      />
    </MyDialog>
  );
};

export default EditMediaLinkDialog;
