import React, { useState } from "react";
import DeveloperBoardOutlinedIcon from "@material-ui/icons/DeveloperBoardOutlined";

import { Tags } from "components";
import {
  useProjectHardware,
  useUserProjectPermissions,
} from "services/ProjectService";
import { updateProjectHardware } from "services/ApiService";
import { ProjectStrings } from "strings";

const OVERFLOW_ID = "hardwareCard";

const HardwareCard = ({ userId, projectId }) => {
  const { canReadProjectExtra, canWriteProjectExtra } =
    useUserProjectPermissions({
      userId,
      projectId,
    });
  const projectHardwares = useProjectHardware(canReadProjectExtra && projectId);

  const [progress, setProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const configCard = {
    overflowId: OVERFLOW_ID,
    info: ProjectStrings.OVERVIEW_HARDWARE_CARD_INFO,
    onClick: () => setShowDialog(true),
    progress: progress,
  };

  const configDialog = {
    inputTitle: ProjectStrings.OVERVIEW_HARDWARE_INPUT_TITLE,
    maxTagsCount: 30,
    errorMessage: ProjectStrings.OVERVIEW_HARDWARE_MAX_LIMIT,
    onModalClose: () => setShowDialog(false),
    onModalOk: (itemsToUpdate) => {
      setShowDialog(false);
      setProgress(true);
      updateProjectHardware({
        projectId,
        hardware: itemsToUpdate,
      }).finally(() => {
        setProgress(false);
      });
    },
  };

  const config = {
    icon: <DeveloperBoardOutlinedIcon />,
    title: ProjectStrings.OVERVIEW_HARDWARE_CARD_TITLE,
    items: projectHardwares,
    card: configCard,
    dialog: configDialog,
  };

  return (
    <Tags
      config={config}
      showDialog={showDialog}
      canRead={canReadProjectExtra}
      canEdit={canWriteProjectExtra}
      variant="my-card"
    />
  );
};

export default HardwareCard;
