import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Typography } from "@material-ui/core";

const OverflowTypography = (props) => {
  const [overflown, setOverflown] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (!props.children) return;
    if (ref.current)
      setOverflown(ref.current.scrollWidth > ref.current.clientWidth);
  }, [ref, props.children]);
  const children = props.children || "";
  return (
    <Tooltip arrow title={children} disableHoverListener={!overflown}>
      <Typography ref={ref} {...props} noWrap>
        {children}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTypography;
