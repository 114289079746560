import React, { useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { useUserInfo, updateUserProfile } from "../../services/UserService";
import { isLoading } from "../../utils/uiUtils";
import { getNameInitials } from "../../utils/generalUtils";

import ImageCrop from "../CropImage";
import ProfileDialog from "../dialogs/ProfileDialog";
import Spinner from "../Spinner";

import { ProfileStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  action: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  media: {
    flexGrow: 3.5,
    height: 1,
  },
  emptyMedia: {
    width: "100%",
    height: 280,
    fontSize: 150,
    fontWeight: 900,
  },
  profileRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
}));

const ProfileCard = ({ userId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const userInfo = useUserInfo(userId);
  const userNameLetters = getNameInitials(userInfo?.name);

  const [userProfile, setUserProfile] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [editor, setEditor] = useState(null);
  const [scaleValue, setScaleValue] = useState(1);
  const [selectedImage, setSelectedImage] = useState();
  const [isImageChanged, setIsImageChanged] = useState(false);

  const defaultProfile = userInfo
    ? {
        name: userInfo?.name,
        jobTitle: userInfo?.jobTitle,
        company: userInfo?.company,
      }
    : undefined;
  const profile = userProfile ? userProfile : defaultProfile;

  const ProfileDialogConfig = {
    showButtonBar: true,
    disableOk:
      !profile?.name ||
      (userInfo?.name === profile?.name &&
        userInfo?.jobTitle === profile?.jobTitle &&
        userInfo?.company === profile?.company &&
        scaleValue === 1 &&
        isImageChanged === false),
    onUpload: (file) => {
      setSelectedImage(file);
      setIsImageChanged(true);
    },
    onClose: () => {
      clearPopup();
      setUserProfile(defaultProfile);
    },
    onOk: () => {
      onConfirmUpdateProfile();
      enqueueSnackbar(ProfileStrings.PROFILE_UPDATE_PROFILE_SUCCESS_MESSAGE, { variant: "success" });
      clearPopup();
    },
  };

  const clearPopup = () => {
    setShowDialog(false);
    setScaleValue(1);
    setIsImageChanged(false);
  };

  const gotoProfile = () => {
    setShowDialog(true);
  };

  const changeEditorRef = (editor) => setEditor(editor);

  const cropImage = () => editor?.getImageScaledToCanvas().toDataURL();

  const onScaleChange = (event, newValue) => {
    setScaleValue(newValue);
  };

  const onConfirmUpdateProfile = () => {
    let imageDataUrl = null;
    if (scaleValue > 1 || isImageChanged === true) {
      imageDataUrl = cropImage();
    }
    updateUserProfile({
      name: profile.name,
      jobTitle: profile.jobTitle,
      company: profile.company,
      ...(imageDataUrl && { imageDataUrl }),
    });
  };

  const onUpdateName = (e) =>
    onUpdateProfile({ key: "name", value: e.target.value });

  const onUpdateJobTitle = (e) =>
    onUpdateProfile({ key: "jobTitle", value: e.target.value });

  const onUpdateCompany = (e) =>
    onUpdateProfile({ key: "company", value: e.target.value });

  const onUpdateProfile = ({ key, value }) => {
    setUserProfile({ ...profile, [key]: value });
  };

  const avatarContent = isLoading(userInfo) ? (
    <Spinner />
  ) : userInfo?.img ? (
    <CardMedia className={classes.media} image={userInfo.img} />
  ) : (
    <Avatar className={classes.emptyMedia} variant="square">
      {userNameLetters}
    </Avatar>
  );

  // image can be a url from info or a file selected from edito
  const profileDialogImage = selectedImage ?? userInfo?.img ?? null;

  return (
    <>
      <Card style={{ height: "100%" }}>
        <CardActionArea className={classes.action} onClick={gotoProfile}>
          {avatarContent}
          <CardContent style={{ height: 1, flexGrow: 1 }}>
            {userInfo && (
              <>
                <div className={classes.profileRow}>
                  <Typography variant="caption">{userInfo.name}</Typography>
                </div>
                <div className={classes.profileRow}>{userInfo.jobTitle}</div>
                <div className={classes.profileRow}>{userInfo.company}</div>
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>

      <ProfileDialog
        open={showDialog}
        config={ProfileDialogConfig}
        titleChildren={
          <>
            {profileDialogImage ? (
              <ImageCrop
                imageSrc={profileDialogImage}
                changeEditorRef={changeEditorRef}
                scaleValue={scaleValue}
                onScaleChange={onScaleChange}
              />
            ) : (
              <Avatar className={classes.emptyMedia} variant="square">
                {userNameLetters}
              </Avatar>
            )}
          </>
        }
      >
        <Box pt={1} pb={1}>
          <TextField
            label={ProfileStrings.PROFILE_SECURITY_FULL_NAME}
            value={profile?.name ?? ""}
            fullWidth
            onChange={onUpdateName}
          />
        </Box>
        <Box pt={1} pb={1}>
          <TextField
            label={ProfileStrings.PROFILE_SECURITY_JOB_TITLE}
            value={profile?.jobTitle ?? ""}
            fullWidth
            onChange={onUpdateJobTitle}
          />
        </Box>
        <Box pt={1} pb={1}>
          <TextField
            label={ProfileStrings.PROFILE_SECURITY_COMPANY}
            value={profile?.company ?? ""}
            fullWidth
            onChange={onUpdateCompany}
          />
        </Box>
      </ProfileDialog>
    </>
  );
};

export default ProfileCard;
