import React from "react";
import Box from "@material-ui/core/Box";

import StatItem from "./StatItem";
import { OrganisationStrings } from "strings";

function Content({ total, admins, members }) {
  return (
    <div style={{ width: "100%" }}>
      <Box display="flex">
        <StatItem
          header={OrganisationStrings.OVERVIEW_TEAM_STAT_TOTAL}
          content={total}
        />
        <StatItem
          header={OrganisationStrings.OVERVIEW_TEAM_STAT_ADMINS}
          content={admins}
        />
        <StatItem
          header={OrganisationStrings.OVERVIEW_TEAM_STAT_MEMBERS}
          content={members}
        />
      </Box>
    </div>
  );
}

export default Content;
