import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

import {
  getItemLocalStorage,
  setItemLocalStorage,
} from "./LocalStorageService";
import { createDocument, updateDocument } from "./FirestoreService";
import { ProjectStrings } from "../strings";
import { FieldValue } from "../App";
import { changeThemeColor } from "./UiService";

const TAG = "Create.ShowroomMessageService";

const CUSTOM_DIMENSIONS_MAP = {
  weather: "dimension1",
  country: "dimension2",
  language: "dimension3",
  estate: "dimension4",
  group: "dimension5",
  storeId: "dimension6",
  device: "dimension6",
  retailer: "dimension8",
  postcode: "dimension9",
  storeAddress: "dimension10",
  customTag: "dimension11",
};

const POST_ACTIONS = {
  SETTINGS: "SETTINGS", // this is for ga4 templates - combines custom dimensions and userid settings
  SET_CUSTOM_DIMENSIONS: "SET_CUSTOM_DIMENSIONS", // sets google analytics custom dimensions
  SET_USER_TRACKING: "SET_USER_TRACKING", // sets google analytics user id
};

const IFRAME_TARGET_ORIGIN = "https://storage.googleapis.com";

const LOCAL_STORAGE_KEYS = {
  GAUID: "gauid",
};

const COLLECTIONS = {
  GA_SETTINGS: "google_analytics_settings",
  GA_USERS: "google_analytics_users",
};

const useGoogleAnalyticsUser = ({ gauid }) => {
  const collection = COLLECTIONS.GA_USERS;
  const config = gauid && {
    collection,
    doc: gauid,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    return isLoaded(infos) && infos[gauid] && infos[gauid];
  });
};

export const useGASettings = () => {
  const collection = COLLECTIONS.GA_SETTINGS;
  const config = {
    collection,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    return isLoaded(infos) && infos;
  });
};

// get gauid from local storage,
// otherwise create document in firestore and add guid to local storage
export const useGoogleAnalyticsUserId = () => {
  const [gauid, setGauid] = useState();
  useEffect(() => {
    const gauid = getItemLocalStorage({ key: LOCAL_STORAGE_KEYS.GAUID });
    if (gauid) {
      setGauid(gauid);
    } else {
      createDocument({
        collection: COLLECTIONS.GA_USERS,
        data: {
          deleted: false,
        },
        batch: false,
      })
        .then((gauid) => {
          setGauid(gauid);
          setItemLocalStorage({ key: LOCAL_STORAGE_KEYS.GAUID, value: gauid });
        })
        .catch((e) => console.error(e));
    }
  }, []);

  return gauid;
};

// sends google analytics custom dimensions to iframe content
export const useMediaLinkPostMessage = ({ iframeRef, mediaLink }) => {
  const gauid = useGoogleAnalyticsUserId();
  const gaUser = useGoogleAnalyticsUser({ gauid });
  const gaSettings = useGASettings();

  const ga4CustomDimensionsMap = useMemo(() => {
    if (!gaSettings) return;
    return gaSettings.ga4_custom_dimensions;
  }, [gaSettings]);

  const uaCustomDimensions = useMemo(() => {
    if (!mediaLink) return;

    return [
      { [CUSTOM_DIMENSIONS_MAP.retailer]: mediaLink.retailer },
      { [CUSTOM_DIMENSIONS_MAP.postcode]: mediaLink.postcode },
      { [CUSTOM_DIMENSIONS_MAP.storeId]: mediaLink.storeId },
      { [CUSTOM_DIMENSIONS_MAP.customTag]: mediaLink?.customTag },
    ];
  }, [mediaLink]);

  const ga4CustomDimensions = useMemo(() => {
    if (!ga4CustomDimensionsMap) return;
    if (!mediaLink) return;

    return [
      { [ga4CustomDimensionsMap.retailer]: mediaLink.retailer },
      { [ga4CustomDimensionsMap.postcode]: mediaLink.postcode },
      { [ga4CustomDimensionsMap.store_id]: mediaLink.storeId },
      { [ga4CustomDimensionsMap.custom_tag]: mediaLink?.customTag },
    ];
  }, [ga4CustomDimensionsMap, mediaLink]);

  const addMediaLinktoGaUser = useCallback(() => {
    if (!mediaLink) return;
    if (mediaLink.type === ProjectStrings.SHOWROOM_PREVIEW) return;

    const collection = COLLECTIONS.GA_USERS;
    const doc = gauid;
    const data = {
      mediaLinksVisited: FieldValue.arrayUnion(mediaLink.linkId),
    };
    const batch = false;
    return updateDocument({ collection, doc, data, batch });
  }, [gauid, mediaLink]);

  const sendCustomDimensions = useCallback(() => {
    if (!mediaLink || !uaCustomDimensions) return;
    if (mediaLink.type === ProjectStrings.SHOWROOM_PREVIEW) return;

    if (!iframeRef.current) {
      console.debug(TAG, "iframe not loaded");
      return;
    }

    iframeRef.current.contentWindow.postMessage(
      {
        action: POST_ACTIONS.SET_CUSTOM_DIMENSIONS,
        value: uaCustomDimensions,
      },
      IFRAME_TARGET_ORIGIN
    );
  }, [iframeRef, mediaLink, uaCustomDimensions]);

  const sendUserTracking = useCallback(() => {
    if (!mediaLink || !gaUser) return;
    if (mediaLink.type === ProjectStrings.SHOWROOM_PREVIEW) return;

    if (!iframeRef.current) {
      console.debug(TAG, "iframe not loaded");
      return;
    }

    iframeRef.current.contentWindow.postMessage(
      {
        action: POST_ACTIONS.SET_USER_TRACKING,
        value: {
          ...gaUser,
          userId: gauid,
          currentMediaLink: mediaLink.linkId,
        },
      },
      IFRAME_TARGET_ORIGIN
    );
  }, [gaUser, gauid, iframeRef, mediaLink]);

  // send google analytics userId
  // universal analytics custom dimensions
  // ga4 custom dimensions
  const sendSettings = useCallback(() => {
    if (
      !mediaLink ||
      !gaSettings ||
      !ga4CustomDimensions ||
      !uaCustomDimensions
    )
      return;
    if (mediaLink.type === ProjectStrings.SHOWROOM_PREVIEW) return;

    if (!iframeRef.current) {
      console.debug(TAG, "iframe not loaded");
      return;
    }

    iframeRef.current.contentWindow.postMessage(
      {
        action: POST_ACTIONS.SETTINGS,
        value: {
          gaUserId: gauid,
          uaCustomDimensions,
          ga4CustomDimensions,
          ga4GlobalTrackers: gaSettings.ga4_global_trackers,
          uaGlobalTrackers: gaSettings.ua_global_trackers,
        },
      },
      IFRAME_TARGET_ORIGIN
    );
  }, [
    ga4CustomDimensions,
    gaSettings,
    gauid,
    iframeRef,
    mediaLink,
    uaCustomDimensions,
  ]);

  const onActionReady = () => {
    // old universal analytics templates
    sendCustomDimensions();
    sendUserTracking();
    // new ga4 templates
    sendSettings();
    // log that user has visited this mediaLink
    addMediaLinktoGaUser();
  };

  // changes the background and address bar to match a color - used in mystories
  const onActionTheme = (message) => {
    const color = message.payload;
    changeThemeColor(color);
  };

  // redirects user - used for mystories CTA button and myqr
  const onActionRedirect = (message) => {
    if (message.payload.url) {
      // Feature for the MyStories v2
      const { url, behaviour } = message.payload;
      window.open(url, behaviour);
    } else {
      // Default replace href if prev feature is not supported, e.g. for MyStories v1
      const redirectUrl = message.payload;
      window.location.href = redirectUrl;
    }
  };

  useEffect(() => {
    const LISTENER_ACTIONS = {
      READY: onActionReady, // When the iframe content has set up it's postMessage listener
      THEME: onActionTheme, // Sets the theme-color metatag and the body background to a hex colour
      REDIRECT: onActionRedirect, // changes window.location.href to received URL.
    };

    const messageHandler = (message) => {
      if (message.action in LISTENER_ACTIONS) {
        console.debug(TAG, message.action, message.payload);
        LISTENER_ACTIONS[message.action](message);
      }
    };

    const handler = window.onmessage;
    window.onmessage = (e) => {
      if (e.origin === IFRAME_TARGET_ORIGIN) messageHandler(e.data);
      if (handler) handler(e);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
