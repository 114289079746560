import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";

import { Firestore, FieldValue } from "../App";

import {
  isUndefined,
  extractKeyValuesFromDict,
  splitArray,
  filterObjectNull,
} from "../utils/generalUtils";
import {
  addDataAction,
  setMultipleDataAction,
  deleteDataAction,
  modifyDataAction,
} from "../redux/firestoreActions";

const useError = (id) =>
  useSelector(
    ({
      firestore: {
        errors: { allIds, byQuery },
      },
    }) => {
      if (allIds.includes(id)) {
        return byQuery[id];
      }
      return null;
    }
  );

// use firestore data with given collection
//
// return undefined = loading
// return null = not exist
export const useSelectorWithCollection = (collection) => {
  const result = !useError(collection) && collection;
  return useSelector(({ firestore: { data } }) => {
    if (isUndefined(result)) return undefined;
    else if (!result) return null;
    const infos = data[collection];
    if (!isLoaded(infos)) return undefined;
    return infos;
  });
};

export const useSelectorWithPath = (path) => {
  const paths = path?.split("/");
  const basePath = paths?.[0];
  const result = !useError(basePath) && basePath;
  return useSelector(({ firestore: { data } }) => {
    if (isUndefined(result)) return undefined;
    else if (!result) return null;
    let docs = data;
    if (!isLoaded(docs)) return undefined;
    if (paths) {
      paths.forEach((p) => {
        docs = docs?.[p];
      });
    }
    return docs;
  });
};

// use a document with given collection and doc
//
// return undefined = loading
// return null = not exist
export const useDocument = ({ collection, doc, storeAs, path }) => {
  const config = doc &&
    collection &&
    typeof doc === "string" && {
      collection,
      doc,
      storeAs,
      path,
    };
  useFirestoreConnect(config);
  const fromCollection = useSelectorWithCollection(storeAs ?? collection);
  const fromPath = useSelectorWithPath(path);
  if (path) return fromPath;
  if (!fromCollection) return fromCollection;
  return storeAs ? fromCollection : fromCollection[doc];
};

// CAUTION: when using path for subcollection, update may not be received to the original path
export const useCollection = ({
  collection,
  where,
  storeAs,
  path,
  orderBy,
  limit,
}) => {
  // construct storeAs if storeAs path not given
  if (!path && !storeAs) {
    // a lazy and ugly way to make up a storeAs name
    storeAs =
      collection &&
      `${collection}${where ? "/" + convertWheresToString(where) : ""}`;
  }
  const config = collection && {
    collection,
    where,
    storeAs,
    path,
    ...(orderBy && { orderBy }),
    ...(orderBy && limit && { limit }),
  };
  useFirestoreConnect(config);
  const fromCollection = useSelectorWithCollection(storeAs ?? collection);
  const fromPath = useSelectorWithPath(path);
  return path ? filterObjectNull(fromPath) : filterObjectNull(fromCollection);
};

export const queryCollection = ({
  collection,
  where,
  orderBy,
  limit,
  keys,
}) => {
  let query = Firestore.collection(collection);
  where.forEach((w) => {
    query = query.where(w[0], w[1], w[2]);
  });
  if (orderBy) {
    orderBy.forEach((o) => {
      query = query.orderBy(o[0], o[1]);
    });
  }
  if (limit) {
    query = query.limit(1);
  }
  return query
    .get()
    .then((snapshot) =>
      Object.fromEntries(
        snapshot.docs.map((d) => [
          d.id,
          extractKeyValuesFromDict(d.data(), keys),
        ])
      )
    );
};

export const useSubCollection = ({ collection, doc, subcollection }) => {
  console.warn(
    "This function should not be used as it seems unable to detect delected document, useCollection() instead."
  );
  const storeAs = `${collection}/${doc}/${subcollection}`;
  const config = collection &&
    doc &&
    subcollection && {
      collection,
      doc,
      subcollections: [{ collection: subcollection }],
      storeAs,
    };
  useFirestoreConnect(config);
  return useSelectorWithCollection(storeAs);
};

// subscribe to a collection/subcollection with query and data population
// right now only support single orderBy and sinlge population
// return an ordered list
//
// orderBy should be an array of array
// eg. orderBy: [["createdAt", "desc"]],
//
// TODO: merge with useSubCollection() which returns a map at the moment
//
// populates = {
//   key,
//   collection,
//   newKey,
// }
export const useCollectionOrdered = ({
  collection,
  doc,
  subcollection,
  orderBy,
  limit,
  populates,
}) => {
  const storeAs = `${collection}/${doc}/${subcollection}`;
  const config = collection && {
    collection,
    storeAs,
    ...(doc && { doc }),
    ...(subcollection && { subcollections: [{ collection: subcollection }] }),
    ...(orderBy && { orderBy }),
    ...(limit && { limit }),
    ...(populates && {
      populates: [
        {
          child: populates.key,
          root: populates.collection,
        },
      ],
    }),
  };
  useFirestoreConnect(config);
  const docs = useSelectorWithCollection(config && storeAs);
  const pop = useSelectorWithCollection(config && populates?.collection);
  const docsArray =
    docs &&
    Object.entries(docs)
      .filter(([key, obj]) => !!obj) // filter null docs
      .map(([key, obj]) => ({
        ...obj,
        id: key, // append id
      }));
  const data = !populates
    ? docsArray // no need to populate
    : docsArray && // populate data
      pop &&
      docsArray.map((msg) => ({
        ...msg,
        // append populated data
        [populates.newKey]: msg && pop[msg[populates.key]],
      }));

  if (!orderBy) return data;

  // TODO: need a loop to support multiple orderBy
  const orderByKey = orderBy[0][0];
  const orderByDesc = orderBy[0][1] === "desc";
  return (
    data &&
    data.sort((a, b) =>
      orderByDesc
        ? b[orderByKey] - a[orderByKey]
        : a[orderByKey] - b[orderByKey]
    )
  );
};

export const useSubCollectionDocument = ({
  collection,
  doc,
  subcollection,
  subdoc,
}) => {
  const storeAs = `${collection}/${doc}/${subcollection}/${subdoc}`;
  const config = collection &&
    doc &&
    subcollection &&
    subdoc && {
      collection,
      doc,
      subcollections: [{ collection: subcollection, doc: subdoc }],
      storeAs,
    };
  useFirestoreConnect(config);
  return useSelectorWithCollection(storeAs);
};

//  eg. where = [["linkId", "==", "23VfiogOzCjg84jATpBM"]];
export const useCollectionGroup = ({ collection, where }) => {
  const storeAs = collection;
  const config = collection && {
    collectionGroup: collection,
    where,
    storeAs,
  };
  useFirestoreConnect(config);
  return useSelectorWithCollection(storeAs);
};

export const getCollectionGroup = ({ collection, where }) => {
  let query = Firestore.collectionGroup(collection);
  if (where) {
    where.forEach((w) => {
      query = query.where(w[0], w[1], w[2]);
    });
  }
  return query
    .get()
    .then((snapshot) =>
      Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.id,
          { ...doc.data(), path: doc.ref.path },
        ])
      )
    );
};

export const hasDocument = ({ collection, doc }) =>
  collection &&
  doc &&
  Firestore.collection(collection)
    .doc(doc)
    .get()
    .then((doc) => doc.exists)
    .catch(() => false);

export const getDocument = ({ collection, doc }) =>
  Firestore.collection(collection)
    .doc(doc)
    .get()
    .then((doc) => doc.data())
    .catch(() => null);

export const getCollectionAllDocument = (collection) =>
  Firestore.collection(collection)
    .get()
    .then((snapshot) =>
      // snapshot = QuerySnapshot
      // convert to a dict
      snapshot.docs.reduce(
        (total, doc) => ({ ...total, [doc.id]: doc.data() }),
        {}
      )
    );

// create a new document under a collection and return the id
export const createDocument = ({ collection, data, batch }) =>
  updateDocument({ collection, data, batch });

export const updateDocument = ({ collection, doc, data, batch }) => {
  const docRef = doc
    ? Firestore.collection(collection).doc(doc)
    : Firestore.collection(collection).doc();

  if (batch) {
    // add to batch if provided
    batch.set(docRef, data, { merge: true });
    // return a Promise to align with the case of no batch
    return Promise.resolve(docRef.id);
  } else {
    // set data with merge, and return the id
    return docRef.set(data, { merge: true }).then(() => docRef.id);
  }
};

export const deleteDocument = ({ collection, doc }) =>
  Firestore.collection(collection).doc(doc).delete();

// CAUTION: delete all documents inside a collection
// double check the collection is guarded with security rules
export const deleteCollectionAllDocuments = (collection) => {
  Firestore.collection(collection)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        doc.ref.delete();
      });
    });
};

export const subscribeDocument = ({
  collection,
  doc,
  dispatch,
  storeAs,
  path,
  constructPath,
  constructData,
  onData,
  onError,
}) =>
  Firestore.collection(collection)
    .doc(doc)
    .onSnapshot(
      (res) => {
        if (!res.exists) {
          if (dispatch) {
            dispatch(deleteDataAction({ collection, doc, storeAs, path }));
          } else if (onData) {
            onData(null);
          }
          return;
        }
        if (dispatch) {
          dispatch(
            addDataAction({
              collection,
              doc: res.id,
              data: constructData
                ? constructData({ id: res.id, data: res.data() })
                : res.data(),
              storeAs,
              path: constructPath ? constructPath({ id: res.id, path }) : path,
            })
          );
        } else if (onData) {
          onData(res.data());
        }
      },
      (err) => {
        console.warn(collection, doc, err);
        if (dispatch) {
          dispatch(deleteDataAction({ collection, doc, storeAs, path }));
        } else if (onError) {
          onError(err);
        }
      }
    );

// unsubscribe will set the target data to null instead of remove
export const unsubscribeDocument = ({
  collection,
  doc,
  storeAs,
  path,
  dispatch,
}) => {
  dispatch(deleteDataAction({ collection, doc, storeAs, path }));
};

// subscribe to a collection without adding data into store
//
// collection: root level collection
// where: array of array, eg. [["key", "==", "abc"]]
// orderBy: array of order, eg. ["key", "desc"]
// limit: number
// processData: (id, data) => {}
export const useCollectionNoStore = ({
  collection,
  where,
  orderBy,
  limit,
  processData,
}) => {
  const [docs, setDocs] = useState();
  // CAUTION: this will flatten where, eg. "1" vs 1, true vs "true"
  const whereString = convertWheresToString(where);
  const orderString = orderBy?.join("_");
  useEffect(() => {
    if (!collection) return;
    const onSet = (changes) =>
      setDocs((docs) => ({
        ...docs,
        ...Object.fromEntries(
          changes.map((c) => [
            c.doc.id,
            processData ? processData(c.doc.id, c.doc.data()) : c.doc.data(),
          ])
        ),
      }));

    const onRemove = (changes) =>
      setDocs((docs) => {
        const res = Object.assign({}, docs);
        changes.forEach((c) => delete res[c.doc.id]);
        return res;
      });
    const onEmpty = () => setDocs(() => null);
    return subscribeCollection({
      collection,
      where,
      orderBy,
      limit,
      onAdd: onSet,
      onModify: onSet,
      onRemove,
      onEmpty,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, orderString, limit, whereString]);
  return docs;
};

const getCollectionQuery = ({ collection, where, orderBy, limit }) => {
  // construct the query
  let query = Firestore.collection(collection);
  if (where) where.forEach((w) => (query = query.where(w[0], w[1], w[2])));
  if (orderBy && limit)
    query = query.orderBy(orderBy[0], orderBy[1]).limit(limit);
  return query;
};

export const subscribeCollection = ({
  collection,
  where,
  orderBy,
  limit,
  onEmpty,
  onAdd, // onAdd(changes)
  onModify, // onModify(changes)
  onRemove, // onRemove(changes)
  onError, // onError(err);
}) => {
  const query = getCollectionQuery({ collection, where, orderBy, limit });
  return query.onSnapshot((res) => {
    const addedDocs = res.docChanges().filter((c) => c.type === "added");
    const modifiedDocs = res.docChanges().filter((c) => c.type === "modified");
    const removedDocs = res.docChanges().filter((c) => c.type === "removed");
    if (res.size === 0) {
      if (onEmpty) onEmpty();
    } else {
      if (addedDocs.length && onAdd) onAdd(addedDocs);
      if (modifiedDocs.length && onModify) onModify(modifiedDocs);
      if (removedDocs.length && onRemove) onRemove(removedDocs);
    }
  }, onError);
};

/*
const storeConfig = {
  // ignoreDelete = ignore all "removed" changes
  ignoreDelete: true,
  // ignoreEmpty = do nothing on empty result, default is setting null
  ignoreEmpty: true,
  // ignoreDoc = ignore doc when adding/modifying data (ie. rely on storeAs/path)
  ignoreDoc: true,
  // setMultiThreshold = min number of changes to set multiple data at once (will override existing)
  setMultiThreshold: 20,
  // constructPath = function to construct path in store
  constructPath: ({ id, path, custom }) => {},
  // constructPath = function to construct final data to be stored in store
  constructData: ({ id, data, multi, modify, custom }) => {},
  // custom = custom data object to pass to constructPath and constructData
  custom: {},
};
*/

// subscribe to a root collection
// return unsubscribe function to be called on unmount
//
// collection: firestore root collection
// where: array of array query, eg. [["key","==","abcd"]]
// storeAs: root path to store as in redux store
// path: path to store in redux store divided by /
// orderBy: array of order, eg. ["key", "desc"]
// limit: number
// constructPath: ({ id, data, path, custom }) => {}
// constructData: ({ id, data, multi, modify, custom }) => {}
// skipDoc: skip passing doc to action
//
// storeAs > path > collection
//
// storeAs will always set at root, use path if you don't want to override
export const subscribeCollectionToStore = ({
  collection,
  where,
  storeAs,
  path,
  orderBy,
  limit,
  dispatch,
  config: {
    ignoreDelete = false,
    ignoreEmpty = true,
    ignoreDoc = false,
    setMultiThreshold = 0,
    constructPath,
    constructData,
    custom,
  },
}) => {
  const query = getCollectionQuery({ collection, where, orderBy, limit });
  const basePath = constructPath ? constructPath({ path, custom }) : path;

  return query.onSnapshot(
    (res) => {
      const addedDocs = res.docChanges().filter((c) => c.type === "added");
      const modifiedDocs = res
        .docChanges()
        .filter((c) => c.type === "modified");
      const removedDocs = res.docChanges().filter((c) => c.type === "removed");
      if (res.size === 0) {
        // no result, add with null
        if (!ignoreEmpty) {
          dispatch(
            deleteDataAction({
              collection,
              storeAs,
              path: basePath,
            })
          );
        }
        return;
      }
      // CAUTION: threshold of assuming this is initial snapshot
      if (setMultiThreshold && addedDocs.length > setMultiThreshold) {
        dispatch(
          setMultipleDataAction({
            collection,
            data: Object.fromEntries(
              addedDocs.map((c) => [
                c.doc.id,
                constructData
                  ? constructData({
                      id: c.doc.id,
                      data: c.doc.data(),
                      multi: true,
                      custom,
                    })
                  : c.doc.data(),
              ])
            ),
            storeAs,
            path: basePath,
          })
        );
      } else {
        addedDocs.forEach((c) => {
          dispatch(
            addDataAction({
              collection,
              ...(!ignoreDoc && { doc: c.doc.id }),
              data: constructData
                ? constructData({ id: c.doc.id, data: c.doc.data(), custom })
                : c.doc.data(),
              storeAs,
              path: constructPath
                ? constructPath({
                    id: c.doc.id,
                    path,
                    custom,
                    data: c.doc.data(),
                  })
                : path,
            })
          );
        });
      }
      modifiedDocs.forEach((c) => {
        dispatch(
          modifyDataAction({
            collection,
            ...(!ignoreDoc && { doc: c.doc.id }),
            data: constructData
              ? constructData({
                  id: c.doc.id,
                  data: c.doc.data(),
                  modify: true,
                  custom,
                })
              : c.doc.data(),
            storeAs,
            path: constructPath
              ? constructPath({
                  id: c.doc.id,
                  path,
                  custom,
                })
              : path,
          })
        );
      });
      if (!ignoreDelete) {
        removedDocs.forEach((c) => {
          dispatch(
            deleteDataAction({
              collection,
              ...(!ignoreDoc && { doc: c.doc.id }),
              storeAs,
              path: constructPath
                ? constructPath({
                    id: c.doc.id,
                    path,
                    custom,
                  })
                : path,
            })
          );
        });
      }
    },
    (err) => {
      console.warn(collection, storeAs, path, err);
      dispatch(
        deleteDataAction({
          collection,
          storeAs,
          path: basePath,
        })
      );
    }
  );
};

// unsubscribe will set the target data to null instead of remove
export const unsubscribeCollection = ({
  collection,
  storeAs,
  path,
  dispatch,
}) => {
  dispatch(deleteDataAction({ collection, storeAs, path }));
};

export const getCollectionNewDocId = (collection) =>
  Firestore.collection(collection).doc().id;

export const createBatch = () => Firestore.batch();

export const commitBatch = (batch) => batch.commit();

// TODO: it should be renamed as queryCollection() to align with useCollection()
export const queryDocuments = ({ collection, wheres }) => {
  let query = Firestore.collection(collection);
  wheres.forEach((where) => {
    query = query.where(where[0], where[1], where[2]);
  });
  return query
    .get()
    .then((doc) => doc.docs.map((d) => ({ ...d.data(), id: d.id })));
};

export const getDocumentsById = async ({ collection, idKey, ids }) => {
  const array = splitArray(ids);
  const promises = [];
  array.forEach((partialIds) => {
    const wheres = [[idKey, "in", partialIds]];
    promises.push(queryDocuments({ collection, wheres }));
  });
  return (await Promise.all(promises)).flat();
};

export const getServerTimeStamp = () => {
  console.warn(
    "This function should not be used as it updates the timestamp with a delay leaving a gap where the field can be null."
  );
  return FieldValue.serverTimestamp();
};

/**
 * wheres = [
 *  ["a", "==", 1],
 *  ["b", "==", "x"],
 * ]
 */
export const convertWheresToString = (wheres) =>
  wheres?.map((w) => w.join("_")).join("/");
