import React from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@material-ui/core";

import { useMobileLayout } from "hooks/uiHooks";
import { useStyles, TextWrapper, MainText, AdditionalText } from "./styles";
// const config = {
//   label: "Label",
//   items: ["Item 1", "Item 2"],
//   selected: ["Item 1"],
//   onChange: (value) => {},
//   onRenderValue = (v) => {},
// };

const Dropdown = (props) => {
  const { label, items, selected, onChange, onRenderValue } = props;
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`Dropdown-${label}`} className={classes.formLabel}>
        {label}
      </InputLabel>
      <Select
        labelId={`Dropdown-${label}`}
        value={selected}
        onChange={onChange}
        input={<Input />}
        multiple
        renderValue={onRenderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {items.map(({ title, additionalText }) => (
          <MenuItem key={`${label}-${title}`} value={title}>
            <Checkbox checked={selected.includes(title)} color="primary" />
            <TextWrapper>
              <MainText primary={title} />
              {additionalText && <AdditionalText primary={additionalText} />}
            </TextWrapper>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
