import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
  },
  userRoleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: " space-between",
    color: "#747474",
    cursor: "pointer",
  },
  userRole: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
  button: {
    color: theme.palette.common.black,
  },
}));
