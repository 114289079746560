import { useRef, useEffect, useCallback } from "react";

import IFrameService from "./IFrameService";
import { CUSTOMISE_QR_URL } from "configs/urlConfig";

const CONFIG = { siteOrigin: CUSTOMISE_QR_URL };

const ACTIONS = {
  READY: "READY",
  QR_SETUP: "QR_SETUP",
  QR_UPDATE_CONFIRM: "QR_UPDATE_CONFIRM",
  UPDATE: "UPDATE",
};

const TAG = "[useCustomQRMessageService]:";

function useCustomQRMessageService({
  ref,
  settings,
  contentSettings,
  urlType,
  folderName,
  isShowing,
  onUpdate,
}) {
  const iframeRef = useRef(null);
  const onReadyHandle = useCallback(async () => {
    if (!settings || !iframeRef.current?.current) return;

    console.debug(`${TAG} onReady`);

    const { qrSetup, options } = settings;

    const payload = {
      options,
      qrSetup,
      folderName,
      contentSettings,
      urlType,
    };

    IFrameService.postMessage(iframeRef.current, CONFIG.siteOrigin, {
      action: ACTIONS.QR_SETUP,
      payload,
    });
  }, [folderName, settings, contentSettings, urlType]);

  const onUpdateHandle = useCallback(
    async (payload) => {
      if (!ref.current) return;
      console.debug(`${TAG} onUpdate`);
      await onUpdate(payload);
    },

    [onUpdate, ref]
  );

  const onConfirmHandle = () => {
    console.debug(`${TAG} onConfirm: close dialog`);
    IFrameService.postMessage(iframeRef.current, CONFIG.siteOrigin, {
      action: ACTIONS.QR_UPDATE_CONFIRM,
    });
  };

  const onMessage = useCallback(
    (data) => {
      const { action, payload } = data.detail;
      switch (action) {
        case ACTIONS.READY:
          onReadyHandle(payload);
          break;
        case ACTIONS.UPDATE:
          onUpdateHandle(payload);
          break;

        default:
          console.warn(
            `${TAG} unknown message: ${JSON.stringify(data.detail)}${action}`
          );
          break;
      }
    },
    [onReadyHandle, onUpdateHandle]
  );

  useEffect(() => {
    if (!settings) return;

    // unsubscribe existing on close
    if (iframeRef.current && !isShowing) {
      console.debug(`${TAG} unsubscribeMessage: dialog closed`);

      iframeRef.current = null;
      IFrameService.unsubscribeMessage(CONFIG.siteOrigin, onMessage);
      return;
    }

    // subscribe existing
    if (ref && !iframeRef.current && isShowing) {
      console.debug(`${TAG} subscribeMessage`);
      iframeRef.current = ref;
      IFrameService.subscribeMessage(CONFIG.siteOrigin, onMessage, ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing, settings, ref]);

  return { onConfirm: onConfirmHandle };
}

export default useCustomQRMessageService;
