const firebaseConfig = process.env.REACT_APP_DEMO_MODE
  ? {
      // myplayertest
      // test config
      apiKey: "AIzaSyAzOd8vZBkpuJioGqyvXFc8zMPq2ut1jfM",
      authDomain: "myplayertest.firebaseapp.com",
      databaseURL: "https://myplayertest.firebaseio.com",
      projectId: "myplayertest",
      storageBucket: "myplayertest.appspot.com",
      messagingSenderId: "209604178303",
      appId: "1:209604178303:web:2b9f57a6a5ce0f8d25aa4a",
    }
  : {
      // myplayerbase
      // production config
      apiKey: "AIzaSyAXRZrgD935w7S3CCIPlzsj5ZcZIL6LG68",
      authDomain: "myplayerbase.firebaseapp.com",
      databaseURL: "https://myplayerbase.firebaseio.com",
      projectId: "myplayerbase",
      storageBucket: "myplayerbase.appspot.com",
      messagingSenderId: "448033846169",
      appId: "1:448033846169:web:61062ef4862d2ce748d908",
    };

export default firebaseConfig;
