const TAG = "[GA4]";

function gtag() {
  if (!window.dataLayer) window.dataLayer = [];
  window.dataLayer.push(arguments);
}

class GoogleAnalytics4 {
  constructor(id, options, callback) {
    const script = document.createElement("script");
    // element has to be attached to DOM before setting onload
    document.head.appendChild(script);
    script.type = "text/javascript";
    script.async = true;
    // convert id to list to support both list of single element
    const listIds = [id].flatMap((a) => a);
    // onload has to be set before setting src
    script.onload = () => {
      gtag("js", new Date());
      listIds.forEach((i) => {
        gtag("config", i, options);
      });
      console.info(`${TAG} ready`);

      if (callback) {
        // if not using setTimeout then the gtm events may not be loaded properly
        setTimeout(callback, 100);
      }
    };
    script.src = `https://www.googletagmanager.com/gtag/js?id=${listIds[0]}`;
  }

  track(key, data) {
    console.info(`${TAG} track: ${key} = ${JSON.stringify(data)}`);

    gtag("event", key, data);
  }

  set(key, value) {
    console.info(`${TAG} set: ${key} = ${JSON.stringify(value)}`);

    gtag("set", {[key]: value});
  }
}

export const createGoogleAnalytics4 = (id, options, callback) => {
  return new GoogleAnalytics4(id, options, callback);
};
