import React, { useEffect } from "react";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useMobileLayout } from "../hooks/uiHooks";
import {
  useUserProjectPermissions,
  useProjectDraftsMenu,
} from "../services/ProjectService";
import { useCurrentUserId } from "../services/UserService";
import { useMyState } from "../services/StateService";
import { isLoading } from "../utils/uiUtils";
import PasswordField from "./PasswordField";
import { ProjectStrings } from "../strings";
const md5 = require("md5");

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: "100%",
  },
  formControlMulti: (mobile) => ({
    marginRight: mobile ? 0 : theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
  }),
  topMargin: {
    marginTop: theme.spacing(2),
  },
  formRow: (mobile) => ({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: mobile ? "wrap" : "nowrap",
  }),
  helperText: {
    fontWeight: "normal",
  },
  passwordContainer: {
    marginTop: theme.spacing(3),
  },
}));

const CustomizeContent = ({ disabled, size, onLinkChange }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const onChangeWidth = (e) => {
    onLinkChange("customizeSize", { ...size, width: e.target.value });
  };

  const onChangeHeight = (e) => {
    onLinkChange("customizeSize", { ...size, height: e.target.value });
  };

  return (
    <>
      <div className={classes.topMargin}>
        <Typography variant="caption">
          {ProjectStrings.SHOWROOM_CUSTOMISE_TITLE}
        </Typography>
      </div>
      <FormGroup className={classes.topMargin}>
        <div className={classes.formRow}>
          <FormControl
            className={`${classes.formControl} ${classes.formControlMulti}`}
          >
            <TextField
              fullWidth
              value={size?.width ?? ""}
              onChange={onChangeWidth}
              label={ProjectStrings.SHOWROOM_WIDTH_LABEL}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              disabled={disabled}
            />
          </FormControl>
          <FormControl
            className={`${classes.formControl} ${classes.formControlMulti}`}
          >
            <TextField
              fullWidth
              value={size?.height ?? ""}
              onChange={onChangeHeight}
              label={ProjectStrings.SHOWROOM_HEIGHT_LABEL}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              disabled={disabled}
            />
          </FormControl>
        </div>
      </FormGroup>
    </>
  );
};

const CustomizeTags = ({
  disabled,
  retailer,
  postcode,
  storeId,
  customTag,
  onLinkChange,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const onChangeRetailer = (e) => onLinkChange("retailer", e.target.value);
  const onChangePostcode = (e) => onLinkChange("postcode", e.target.value);
  const onChangeStoreId = (e) => onLinkChange("storeId", e.target.value);
  const onChangeCustomTag = (e) => onLinkChange("customTag", e.target.value);
  return (
    <>
      <div className={classes.topMargin}>
        <Typography variant="caption">
          {ProjectStrings.SHOWROOM_TAGS_TITLE}
        </Typography>
      </div>
      <FormGroup className={classes.topMargin}>
        <FormControl className={classes.formControl}>
          <TextField
            FormHelperTextProps={{
              classes: { error: classes.helperText },
            }}
            value={retailer}
            onChange={onChangeRetailer}
            label={ProjectStrings.SHOWROOM_RETAILER_LABEL}
            disabled={disabled}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            FormHelperTextProps={{
              classes: { error: classes.helperText },
            }}
            value={postcode}
            onChange={onChangePostcode}
            label={ProjectStrings.SHOWROOM_POSTCODE_LABEL}
            disabled={disabled}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            FormHelperTextProps={{
              classes: { error: classes.helperText },
            }}
            value={storeId}
            onChange={onChangeStoreId}
            label={ProjectStrings.SHOWROOM_STORE_ID_LABEL}
            disabled={disabled}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            FormHelperTextProps={{
              classes: { error: classes.helperText },
            }}
            value={customTag}
            onChange={onChangeCustomTag}
            label={ProjectStrings.SHOWROOM_CUSTOM_LABEL}
            disabled={disabled}
          />
        </FormControl>
      </FormGroup>
    </>
  );
};

const Password = ({ disabled, onLinkChange, updatePassword }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const handlePasswordChange = (password) =>
    onLinkChange("password", !password.length ? password : md5(password));

  return (
    <>
      <div className={classes.topMargin}>
        <Typography variant="caption">
          {updatePassword
            ? ProjectStrings.SHOWROOM_UPDATE_PASSWORD_TITLE
            : ProjectStrings.SHOWROOM_PASSWORD_TITLE}
        </Typography>
      </div>
      <div className={classes.passwordContainer}>
        <PasswordField
          name="password"
          label={
            updatePassword
              ? ProjectStrings.SHOWROOM_UPDATE_PASSWORD_LABEL
              : ProjectStrings.SHOWROOM_PASSWORD_LABEL
          }
          onChange={handlePasswordChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

const AnalyticsContent = ({ trackingIdGa4, onLinkChange, disabled }) => {
  const classes = useStyles();
  const onChangeTrackingId = (e) =>
    onLinkChange("trackingIdGa4", e.target.value);

  return (
    <>
      <div className={classes.topMargin}>
        <Typography variant="caption">
          {ProjectStrings.SHOWROOM_TITLE_ANALYTICS}
        </Typography>
      </div>
      <FormControl className={classes.formControl}>
        <TextField
          FormHelperTextProps={{
            classes: { error: classes.helperText },
          }}
          value={trackingIdGa4 || ""}
          onChange={onChangeTrackingId}
          label={ProjectStrings.SHOWROOM_TRACKING_ID_GA4}
          disabled={disabled}
        />
      </FormControl>
    </>
  );
};

const MediaLinkFormFields = ({
  projectId,
  link,
  onLinkChange,
  showTypeField,
  editing = false,
  updatePassword,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  // drafts
  const version = useMyState({ path: `${projectId}/media/version` });
  const draftsMenu = useProjectDraftsMenu(projectId);
  const liveId = draftsMenu?.[0];
  const draftsList = draftsMenu?.[1];

  // get the draft option, if not in current list then use live (or empty)
  const draft = draftsList?.some((d) => d.id === link.draftId)
    ? link.draftId
    : liveId ?? "";

  // detect current draft id
  useEffect(() => {
    if (isLoading(liveId) || editing) return;

    if (version) {
      // previewing draft
      onLinkChange("draftId", version.id);
    } else {
      // default preview live
      onLinkChange("draftId", liveId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveId, version]);

  // permissions control
  const userId = useCurrentUserId();
  const { canWriteShowroom } = useUserProjectPermissions({ userId, projectId });
  const canUseTags = [
    ProjectStrings.SHOWROOM_EMBED,
    ProjectStrings.SHOWROOM_QR,
  ].includes(link.type);

  if (isLoading(draftsMenu)) return <CircularProgress />;

  if (draftsList?.length === 0)
    return <div>{ProjectStrings.SHOWROOM_NO_DRAFT}</div>;

  // default expiry = tomorrow
  const defaultExpiry = new Date().setDate(new Date().getDate() + 1);

  const onChangeDraft = (e) => {
    const id = e.target.value;
    if (id) onLinkChange("draftId", id);
  };

  const onChangeExpiry = (date) => {
    onLinkChange("expiry", new Date(date).setHours(0, 0, 0, 0));
  };

  const contentDraft = (
    <FormControl className={classes.formControl} variant="filled">
      <InputLabel id="select-link-draft-label">
        {ProjectStrings.SHOWROOM_DRAFT_LABEL}
      </InputLabel>
      <Select
        labelId="select-link-draft-label"
        id="select-link-draft"
        value={draft}
        onChange={onChangeDraft}
        disabled={!canWriteShowroom || editing}
      >
        {draftsList?.map((d, i) => (
          <MenuItem key={`draft-${i}`} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <>
      <FormGroup>
        <FormControl className={classes.formControl}>
          <TextField
            FormHelperTextProps={{
              classes: { error: classes.helperText },
            }}
            value={link.name}
            onChange={(e) => onLinkChange("name", e.target.value)}
            id="link-name"
            label={ProjectStrings.SHOWROOM_NAME_LABEL}
            disabled={!canWriteShowroom}
          />
        </FormControl>
        {contentDraft}
        {showTypeField && (
          <FormControl className={classes.formControl} variant="filled">
            <InputLabel id="select-link-type-label">
              {ProjectStrings.SHOWROOM_TYPE_LABEL}
            </InputLabel>
            <Select
              labelId="select-link-type-label"
              id="select-link-type"
              value={link.type}
              onChange={(e) => onLinkChange("type", e.target.value)}
              disabled={!canWriteShowroom}
            >
              <MenuItem value={ProjectStrings.SHOWROOM_PREVIEW}>
                {ProjectStrings.SHOWROOM_MENU_ITEM_PREVIEW}
              </MenuItem>
              <MenuItem value={ProjectStrings.SHOWROOM_EMBED}>
                {ProjectStrings.SHOWROOM_MENU_ITEM_EMBED}
              </MenuItem>
              <MenuItem value={ProjectStrings.SHOWROOM_QR}>
                {ProjectStrings.SHOWROOM_MENU_ITEM_QR_CODE}
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {/* EXPIRY DATE */}
        <FormControl className={classes.formControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={link.expiry}
              onChange={onChangeExpiry}
              minDate={defaultExpiry}
              label={ProjectStrings.SHOWROOM_DATE_LABEL}
              format="dd/MM/yyyy"
              variant="inline"
              autoOk={true}
              disabled={!canWriteShowroom}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </FormGroup>
      <CustomizeContent
        disabled={!canWriteShowroom}
        size={link.customizeSize}
        onLinkChange={onLinkChange}
      />
      {canUseTags && (
        <CustomizeTags
          retailer={link.retailer}
          postcode={link.postcode}
          storeId={link.storeId}
          customTag={link.customTag}
          onLinkChange={onLinkChange}
          disabled={!canWriteShowroom}
        />
      )}
      {canUseTags && (
        <AnalyticsContent
          trackingIdGa4={link.trackingIdGa4}
          onLinkChange={onLinkChange}
          disabled={!canWriteShowroom}
        />
      )}
      {link.type === ProjectStrings.SHOWROOM_PREVIEW && (
        <Password
          onLinkChange={onLinkChange}
          disabled={!canWriteShowroom}
          updatePassword={updatePassword}
        />
      )}
    </>
  );
};
export default MediaLinkFormFields;
