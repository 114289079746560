import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Editor, RichUtils } from "draft-js";

import { isUrlAbsolute } from "../../utils/generalUtils";
import { forwardRef } from "react";

// preset values defined by Draft.js
const HANDLE_VALUES = {
  HANDLED: "handled",
  NOT_HANDLED: "not-handled",
};

const handleMaxLength = (len, max) =>
  len > max ? HANDLE_VALUES.HANDLED : HANDLE_VALUES.NOT_HANDLED;

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#3b5998",
    textDecoration: "underline",
  },
}));

// Component for Decorator to be used with Draft.js
export const RichLink = ({ contentState, entityKey, children }) => {
  const classes = useStyles();
  let { url } = contentState.getEntity(entityKey).getData();
  if (!isUrlAbsolute(url)) {
    url = `https://${url}`;
  }
  return (
    <a
      href={url}
      className={classes.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

// Editor component to be used with Draft.js
// 1) limits max characters
// 2) support keyboard commands
//
// editorState and setEditorState from Draft.js
export const RichEditor = forwardRef(
  ({ editorState, setEditorState, maxLength }, ref) => {
    useEffect(() => {
      ref.current.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // run once on first render

    // handle key commands like cmd+B for bold, cmd+I for italics
    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return HANDLE_VALUES.HANDLED;
      }
      return HANDLE_VALUES.NOT_HANDLED;
    };

    // limit max length for input
    const handleBeforeInput = (chars, editorState) => {
      const len =
        editorState.getCurrentContent().getPlainText().length + chars.length;
      return handleMaxLength(len, maxLength);
    };

    // limit max length for paste
    const handlePastedText = (text, html, editorState) => {
      const len =
        editorState.getCurrentContent().getPlainText().length + text.length;
      return handleMaxLength(len, maxLength);
    };

    // limit max length for return (enter)
    const handleReturn = (e, editorState) => {
      const len = editorState.getCurrentContent().getPlainText().length;
      return handleMaxLength(len, maxLength);
    };

    return (
      <Editor
        ref={ref}
        editorState={editorState}
        onChange={setEditorState}
        handleKeyCommand={handleKeyCommand}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        handleReturn={handleReturn}
      />
    );
  }
);
