import React, { createContext, useMemo, useState, useCallback, useEffect } from "react";
import { debounce } from "services/UiService";
import { initValues } from "./initValues";
import { useLocation } from "react-router-dom";

const MainContext = createContext(initValues);

export const MainContextProvider = ({ children }) => {
    const [sortFieldValue, setSortFieldValue] = useState(null);
    const [sortDescValue, setSortDescValue] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const location = useLocation();
    const [actionType, setActionType] = useState(initValues.actionType);

    const onSortHandle = useCallback(
        (opt, defaultDesc) => {
            console.log("onSortHandle : ", opt);
            if (sortFieldValue === opt) {
                setSortDescValue(!sortDescValue);
            } else if (typeof defaultDesc !== "undefined") {
                setSortDescValue(defaultDesc);
            }
            setSortFieldValue(opt);
        },
        [sortDescValue, sortFieldValue]
    );

    const initialSortHandle = useCallback((opt, desc) => {
        setSortFieldValue(opt);
        setSortDescValue(desc);
    }, []);

    const onSearchHandle = debounce((value) => {
        if (searchValue === value) return;
        setSearchValue(value);
    }, 300);

    useEffect(() => {
        setSearchValue(null);
    }, [location.pathname]);

    const onActionHandle = useCallback(
        (key, action) => {
            setActionType({ ...actionType, [key]: action });
        },
        [actionType]
    );

    const contextValues = useMemo(
        () => ({
            sortFieldValue,
            sortDescValue,
            searchValue,
            actionType,
            onSort: onSortHandle,
            initialSort: initialSortHandle,
            onSearch: onSearchHandle,
            onAction: onActionHandle,
        }),
        [
            sortFieldValue,
            sortDescValue,
            searchValue,
            actionType,
            onSortHandle,
            initialSortHandle,
            onSearchHandle,
            onActionHandle,
        ]
    );

    return <MainContext.Provider value={contextValues}>{children}</MainContext.Provider>;
};

export default MainContext;
