import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: () => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
  }),
  cardContainer: (mobile) => ({
    padding: theme.spacing(1),
    width: mobile ? (window.innerWidth / 2) * 0.9 : 190,
    height: 300,
  }),
  filter: ({ mobile }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 16,
    flexWrap: mobile ? "wrap" : "nowrap",
  }),
}));
