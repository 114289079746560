export const teamContainsFilter = ({ user, filter }) => {
  if (!user || !filter) return true;
  if (filter.length === 0) return true;

  const filterLower = filter.toLowerCase();
  const nameLower = user.name && user.name.toLowerCase();
  const permissionsKeyLower =
    user.permissionsKey && user.permissionsKey.toLowerCase();
  return (
    (nameLower && nameLower.includes(filterLower)) ||
    (permissionsKeyLower && permissionsKeyLower.includes(filterLower))
  );
};

export const isCodeMutable = (info) => info && info.immutable === false;
