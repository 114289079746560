import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  progress: {
    position: "absolute",
    width: "100%",
    left: 0,
    top: 0,
    zIndex: 1,
  },
});

const Spinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.progress}>
      <LinearProgress />
    </div>
  );
};

export default Spinner;
