import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import { useUserName, useUserPicture, useUserEmail } from "hooks/userHooks";
import { getNameInitials } from "utils/generalUtils";
import { useStyles } from "./styles";
import LinearSpinner from "ui/LinearSpinner";

const TeamMemberCard = ({
  userId,
  permissionName,
  permissionsKey,
  canReadPermissions,
  progress,
  onClick,
}) => {
  const classes = useStyles();
  const userName = useUserName(userId);
  const userEmail = useUserEmail(userId);
  const userImage = useUserPicture(userId);

  const userNameLetters = getNameInitials(userName);

  const firstName = userName && userName.length > 0 && userName.split(" ")[0];

  const gotoProfile = () => {
    if (onClick)
      onClick({
        userId,
        userImage,
        userEmail,
        userNameLetters,
        userName,
        permissionName,
        permissionsKey,
      });
  };

  return (
    <Card className={classes.card}>
      {progress && <LinearSpinner />}
      <CardActionArea
        className={classes.action}
        onClick={!progress ? gotoProfile : null}
      >
        {userImage ? (
          <CardMedia className={classes.media} image={userImage} />
        ) : (
          <Avatar
            className={clsx(classes.media, classes.emptyMedia)}
            variant="square"
          >
            {userNameLetters}
          </Avatar>
        )}
        <CardContent className={classes.content}>
          {firstName && (
            <Typography className={classes.name}>{firstName}</Typography>
          )}
          {permissionName && canReadPermissions && (
            <Typography className={classes.permission}>
              {permissionName}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TeamMemberCard;
