import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    devicesRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowX: "hidden",
    },
    devicesMain: {
      padding: theme.spacing(2),
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    deviceCardContainer: (mobile) => ({
      padding: theme.spacing(1),
      paddingLeft: mobile ? theme.spacing(2) : theme.spacing(1),
      paddingRight: mobile ? theme.spacing(2) : theme.spacing(1),
      width: mobile ? window.innerWidth : 260,
    }),
    devicesMore: {
      width: "80%",
      textAlign: "center",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      backgroundColor: "white",
      border: "solid 1px #E0E0E0",
    },
  }));