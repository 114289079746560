import React, {useEffect, useRef, useState} from "react";
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {ccGet, ccPut} from "../../services/ApiService";
import {
  useDeviceDownloadReady,
  useDeviceDownloadPath,
} from "../../services/DeviceService";

import ButtonCard from "../cards/ButtonCard";
import {DeviceStrings} from "../../strings";

const useStyles = makeStyles((theme) => ({
  filesMain: {
    padding: 24,
    height: "100%",
    display: "flex",
  },
  filesContainer: {
    flexGrow: 1,
    marginLeft: 24,
    "&:first-child": {
      marginLeft: 0,
    },
  },
  filesLink: ({ready}) => ({
    pointerEvents: ready ? "all" : "none",
    display: ready ? "inline" : "none",
    padding: "4px 0px 4px 10px",
  }),
}));

const RemotePanelFiles = ({
  projectId,
  deviceId,
  disabled,
  onStart,
  onComplete,
}) => {
  const [pathGet, setPathGet] = useState("");
  const [pathPut, setPathPut] = useState("");
  const [downloadRequested, setDownloadRequested] = useState(false);
  const mounted = useRef(true);
  const downloadRef = useRef(null);

  const ready = useDeviceDownloadReady(deviceId) && downloadRequested;
  const downloadPath = useDeviceDownloadPath(deviceId);

  const classes = useStyles({ready});

  useEffect(() => {
    // track mounted state
    // this is necessary to avoid state update after unmounted
    mounted.current = true;
    downloadRef.current.href = ready ? downloadPath : "/";
    return () => {
      mounted.current = false;
    };
  }, [ready, downloadPath]);

  const get = () => {
    onStart();
    setDownloadRequested(false);

    ccGet({
      projectId,
      deviceId,
      path: pathGet,
    })
      .then((result) => {
        console.debug("ccGet", result);
        if (mounted.current) setDownloadRequested(true);
      })
      .finally(() => {
        onComplete();
      });
  };
  const put = () => {
    onStart();

    ccPut({
      projectId,
      deviceId,
      path: pathPut,
    })
      .then((result) => {
        console.debug("ccPut", result);
      })
      .finally(() => {
        onComplete();
      });
  };

  const onChangePathGet = (event) => {
    setPathGet(event.target.value);
  };

  const onChangePathPut = (event) => {
    setPathPut(event.target.value);
  };

  return (
    <div className={classes.filesMain}>
      <div className={classes.filesContainer}>
        <ButtonCard
          config={{
            title: DeviceStrings.REMOTE_PANEL_FILES_PUT_TITLE,
            buttonLabel: DeviceStrings.REMOTE_PANEL_FILES_PUT_BUTTON_LABEL,
            desc: DeviceStrings.REMOTE_PANEL_FILES_PUT_DESC,
            icon: (
              <span
                className="material-symbols-outlined"
                style={{fontSize: 30}}
              >
                upload
              </span>
            ),
            fullHeight: true,
            onClick: put,
            disableButton: disabled || !pathPut,
          }}
          canRead={true}
        >
          <TextField
            fullWidth
            disabled={disabled}
            label={DeviceStrings.REMOTE_PANEL_FILES_PUT_TEXT_LABEL}
            value={pathPut}
            onChange={onChangePathPut}
          />
        </ButtonCard>
      </div>
      <div className={classes.filesContainer}>
        <ButtonCard
          config={{
            title: DeviceStrings.REMOTE_PANEL_FILES_GET_TITLE,
            buttonLabel: DeviceStrings.REMOTE_PANEL_FILES_GET_BUTTON_LABEL,
            desc: DeviceStrings.REMOTE_PANEL_FILES_GET_DESC,
            icon: (
              <span
                className="material-symbols-outlined"
                style={{fontSize: 30}}
              >
                download
              </span>
            ),
            fullHeight: true,
            onClick: get,
            disableButton: disabled || !pathGet,
          }}
          canRead={true}
        >
          <TextField
            fullWidth
            disabled={disabled}
            label={DeviceStrings.REMOTE_PANEL_FILES_GET_TEXT_LABEL}
            value={pathGet}
            onChange={onChangePathGet}
          />
          <a
            className={classes.filesLink}
            ref={downloadRef}
            href="/"
            download
            target="_blank"
          >
            Link
          </a>
        </ButtonCard>
      </div>
    </div>
  );
};

export default RemotePanelFiles;
