import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  iframeWrapper: (mobile) => ({
    maxHeight: mobile ? "600px" : "650px",
    height: "100vh",
    width: "100%",
    padding: mobile ? 0 : "32px",
  }),
  iframe: (mobile) => ({
    border: 0,
    width: "100%",
    height: "100%",
  }),
}));
